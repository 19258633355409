const TRANSLATIONS_EN = {
  login: {
    title: 'Login',
  },
  button_group: {
    yes: 'Yes',
    no: 'No',
    next: 'NEXT',
    back: 'BACK',
    ok: 'OK',
    login: 'LOGIN',
    close: 'Close',
    logout: 'Logout',
    managePreferences: 'Manage Preferences',
    changePassword: 'Change Password',
    add: 'ADD',
    update: 'UPDATE',
    add_new: 'Add new',
    added: 'ADDED',
    get_report: 'GET REPORT',
    cancel: 'CANCEL',
    add_product: 'ADD PRODUCT',
    change_password: 'CHANGE PASSWORD',
    move: 'MOVE',
    skip_and_add: 'SKIP & ADD',
    add_new: 'ADD NEW',
    skip_add: 'SKIP & ADD',
    skip_update: 'SKIP & UPDATE',
    profile: 'Profile',
    profile_icon_updated: 'Profile icon updated',
    remove: 'Remove',
  },

  footer: {
    copywright: 'Karan Retail Ltd © 2024',
    terms_of_use: 'Terms of use',
    privacy_policy: 'Privacy Policy',
    contact: 'Contact',
  },

  login_page: {
    login_description:
      'Login to connect your business with your suppliers & customers.',
    use_myda_account: 'Use your KRL Account to login.',
    email_address: 'Email address',
    sign_with_email: 'Sign in with email',
    sign_with_mobile: 'Sign in with mobile',
    create_account: 'Create KRL Account',
    no_account_exist: "That KRL Account doesn't exist",
    enter_valid_email: 'Enter a valid email address',
    email_required: 'Email required',
    password: 'Password',
    password_invalid: 'That password is incorrect',
    password_required: 'Password required',
    forgot_password: 'Forgot password',
    you_receive_sms_opt:
      'By continuing you will receive an SMS for verification.',
    enter_otp_description:
      'Enter OTP sent to your mobile ending with {{suffix_number}}',
    enter_otp: 'Enter OTP',
    resend_otp: 'Resend OTP',
    invalid_otp: 'Invalid OTP. Try again.',
    reset_password: 'Reset Password',
    back_to_login: 'Back to login',
    new_myda: 'New to KRL?',
  },

  reset_password_page: {
    change_password: 'Change password',
    change_password_success:
      'Password changed successfully. You can now login with your new password.',
    expired_code: 'Invalid or expired reset code',
  },
  select_store_page: {
    select_store_or_business: 'Select store or business',
    search_name: 'Enter store or merchant name',
  },

  language_popup: {
    change_language: 'Change language',
  },

  error_group: {
    error_email: 'That KRL account doesn’t exist',
    error_password: 'That password is incorrect',
  },

  header: {
    workboard: 'Workboard',
    'business-manager': 'Business Manager',
    'user-management': 'User management',
    settings: 'Settings',
    'touchpoints-management': 'Touchpoints',
    'order-types-workflows-management': 'Order Types & Workflows',
    'subscription-management': 'Subscriptions',
    import: 'Import',
    help: 'help',
    all_stores: 'All Stores',
    modules: 'Modules',
    collectASAP: 'Collect ASAP',
    order_type: {
      change_order_type: 'Change order type',
      collection: 'Collection',
      collection_time: 'Collection time',
      today_asap: 'Today ASAP',
      select_date_time: 'Select date & time',
      delivery: 'Delivery',
      delivery_to: 'Delivery to',
      delivery_time: 'Delivery time',
      add_new_address: 'Add new address',
      enter_address: 'Enter address',
      confirm: 'Confirm',
      new_address_added: 'New address added',
      address_placeholder: 'Enter address',
      collect: 'Collect',
      deliver: 'Deliver',
      asap: 'ASAP',
      to: 'to',
      dining_in: 'Dining In',
      takeaway: 'Takeaway',
      click: 'Collection',
      dining_in_time: 'Dining In time',
      table_info: 'Table Info',
      no_of_guests: 'No of Guests',
      select_table_no: 'Select Table No',
    },
  },

  menu: {
    services: 'Services',
    workBoard: 'Workboard',

    /*businessManager */
    businessManager: 'Business Manager',
    'store-profile': 'Store profile',
    orders: 'Orders',
    expenses: 'Expenses',
    products: 'Products',
    style: 'Styles',
    allergy: 'Allergies',
    recipe: 'Product builders',
    ingredient: 'Raw Materials',
    net_price: 'Net price',
    raw_materials: 'Raw materials',
    add_ons: 'Add-ons',
    customers: 'Customers',
    suppliers: 'Suppliers',
    addon: 'Add-ons',
    /*businessManager */

    insights: 'Insights',
    admin: 'Admin',
    stores: 'Stores',
    warehouses: 'Ware houses',

    /*userManagement */
    userManagement: 'User management',
    users: 'Users',
    archivedUsers: 'Archived users',
    manageTeams: 'Manage teams',
    teams: 'Teams',
    archived: 'Archived Users',
    /*userManagement */

    settings: 'Settings',

    /*touchpoints */
    touchPoints: 'Touchpoints',
    archivedTouchpoints: 'Archived Touchpoints',

    automationWorkflows: 'Automation & Workflows',
    subscriptions: 'Subscriptions',
    plans: 'Plans',
    paymentMethods: 'Payment methods',
    import: 'Import data',
    orderTypeWorkflows: 'Order Types & Workflows',
    orderTypes: 'Order types',
    workflows: 'Workflows',
    modules: 'Modules',
    orders: 'Orders',
    vouchers: 'Vouchers',
  },

  signup: {
    title: 'Sign up',
    signup_for_myda: 'Sign up for KRL Account.',
    use_email: 'Use Email',
    use_mobile: 'Use Mobile',
    email_address: 'Email address',
    email_rquired: 'Email required',
    enter_valid_email: 'Enter a valid email',
    password: 'Password',
    confirm_password: 'Confirm Password',
    password_required: 'Password required',
    confirm_required: 'Confirm required',
    password_mismatch: 'Password mismatched',
    lowercase_required: 'Lower case required',
    uppercase_required: 'Upper case required',
    number_required: 'Number required',
    min_chars: 'Min chars 8',
    by_clicking_signup: 'By clicking Sign Up, you agree to our ',
    terms_policy: 'Terms of use and Privacy Policy.',
    you_receive_sms_opt:
      'You may receive SMS notifications & email communications from us and you can opt out or manage preferences at any time.',
    signup: 'SIGN UP',
    copyright: 'Karan Retail Ltd © 2024',
    need_verification: 'We need to verify you are who you are.',
    check_your_email:
      'An email is sent to {{email}}. Check your email and and follow instructions.',
    login: 'LOG IN',
    resend_email: 'Resend email',
    change_email: 'Change email',
    first_name: 'First name',
    first_name_required: 'First name required',
    second_name: 'Second name',
    second_name_required: 'Second name required',
    mobile_number: 'Mobile number',
    mobile_number_required: 'Mobile number required',
    invalid_number: 'Invalid Phone number',
    enter_otp:
      'Enter OTP sent to your mobile ending with {{suffix_number}}',
    resend_otp: 'Resend OTP',
    change_number: 'Change mobile number',
    invalid_otp: 'Invalid OTP. Try again.',
    change_language: 'Change language',
    close: 'Close',
    email_verify_success: 'Email verified. You can login now.',
  },

  businessManager: {
    store_overview: 'Store Overview',
    view_and_update_storefront: 'View & Update Storefront',
    store_profile: 'Store profile',
    orders: 'Orders',
    expenses: 'Expenses',
    inventory: 'Inventory',
    promotions: 'Promotions',
    customers: 'Customers',
    run_report: 'Run Report',
    add_order: 'Add Order',
    add_expense: 'Add Expenses',
    add_product: 'Add Product',
    add_stock: 'Add Stock',
    add_promotion: 'Add Promotion',
    add_customer: 'Add Customer',
    settings: 'Settings',
    touchpoints: 'Touchpoints',
    workflows: 'Workflows',
    users: 'Users',
    subscriptions: 'Subscriptions',
    integrations: 'Integrations',
    import_data: 'Import Data',
    store_name_required: 'Store name is required!',
    nick_name: 'Nick name',
    nickname: 'Nickname',
    nickname_description:
      'Each of your stores can be given a Nickname to identify them and filter data across all KRL platforms and applications which will only be visible to you and not your customers or suppliers',
    home: 'Home',
    orders: 'Orders',
    expenses: 'Expenses',
    products: 'Products',
    style: 'Styles',
    allergy: 'Allergies',
    recipe: 'Product builders',
    ingredient: 'Raw Materials',
    net_price: 'Net price',
    raw_materials: 'Raw materials',
    add_ons: 'Add-ons',
    customers: 'Customers',
    suppliers: 'Suppliers',
    addon: 'Add-ons',
  },

  categories: {
    categories: 'Categories',
    add_category: 'Add Category',
    add_product: 'Add Products',
    category_name: 'Category name',
    type: 'Type',
    no_products: 'No. of products',
    taxes: 'Taxes',
    parent: 'Parent',
    sub_category: 'Sub Category',
    vat_exempt: 'VAT Exempt',
    empty_list_of_products: 'Your product list is empty',
    add_first_product: 'Add first product',
    add_first_category: 'Add first category',
    import_products: 'Import products',
    move_products_another_store: 'Move products from another store',
    no_match_found: 'No match found. Try again.',
    no_products_found: 'No products found',
    no_categories_found: 'No matching categories found.',
    no_categories_with_no_products_found:
      'No categories with no products found. Try again.',
    no_matching_products_found: 'No matching products found.',
    age_restriction: 'Age restriction',
    age_restriction_description:
      'If switched on, supported touchpoints (POS, Kiosk, etc.) will require users to verify the customer’s age before allowing the sale to continue.',
    sold_hours: 'Sold hours',
    sold_hours_description:
      'If switched on, supported touchpoints (POS, Kiosk, etc.) will prevent users from selling items in this category outside the selected period.',
    commission_sales: 'Commission sales',
    commission_sales_description:
      'If switched on, revenue from items from this category will be calculated as commission sales in reports. Recommended if you are selling products for commission.',
    chef_note: 'Chef note',
    chef_note_description:
      'If switched on, supported touchpoints (POS, Kiosk, etc.) will send the items in this category to the back kitchen using a connected printer or order display system. Recommended for fast food, restaurant & beverage outlets.',
    enter_minimum_age: 'Enter minimin age',
    add_new_category: 'Add new category',
    cancel: 'Cancel',
    add: 'Add',
    category_created_successfully: 'Category created successfully',
    category_updated_successfully: 'Category updated successfully',

    general_information: 'General information',
    category_image: 'Category image',
    rules: 'Rules',
    this_is_parent: 'This is parent',
    edit_category_title: 'Edit category',
    update_category: 'Update',
    age_required: 'Age is required',
    category_name: 'Category name',
    parent_category: 'Parent category',
    select: 'Select',
    product: 'Product',
    ean_upc_mpc: 'EAN/UPC/MPC',
    price_tax_inc: 'Price & Tax Inc',
    edit: 'Edit',
    move_products: 'Move products',
    archive: 'Archive',
    update_general_info: 'Update general info & Price',
    update_stock_info: 'Update stock information',
    update_image_description: 'Update image & Description',
    update_allergy: 'Update allergy',
    update_addons: 'Update add-ons',
    update_product_builder: 'Update product builder',
    delete: 'Delete',
    deleted: 'Deleted',
    move_items: 'Move Items',
    move_from: 'Move from',
    move_to: 'Move to',
    select_category: 'Select category',
    move_items_description:
      'You are moving all of the items under one category to another. All of your connected touchpoints such as POS, Kiosk, Digital storefront will be updated.',
    items_moved: 'Items moved',
  },

  settings: {
    add: 'Add',
    added: 'Added',
    updated: 'Updated',
    update: 'Update',
    select: 'Select',
    save: 'Save',
    copy: 'Copy',
    copied: 'Copied',
    cancel: 'Cancel',
    business_manager: 'Business Manager',
    settings: 'Settings',
    error: {
      update: 'Something went wrong! Please try again.',
    },
    business_and_finance: {
      title: 'Business & Finance',
      sub_title: 'Sales taxes',
      select_type: 'Select type',
      registered_name_required: 'Register name is required!',
      trading_name_required: 'Trading name is required!',
      registered_address_required: 'Registered address is required!',
      business_type_required: 'Business type is required!',
      registration_type_required: 'Registration type is required!',
      registration_number_required:
        'Registration number is required!',
      registration_tax_required: '{{name}} registration is required!',
      vat_number_required: '{{name}} number is required!',
      update_registration_tax: 'Update {{name}} registration',
      gst_registration_required: 'GST registration is required!',
      gst_number_required: 'GST number is required!',
      registered_address_sub_title:
        'Start typing your address to find and select.',
      input: {
        registered_name: 'Registered name',
        trading_name: 'Trading name',
        registered_address: 'Registered address',
        business_type: 'Business type',
        registration_type: 'Registration type',
        registration_number: 'Registration number',
        registration_tax: '{{name}} registration',
        vat_number: '{{name}} number',
        gst_registration: 'GST registration',
        gst_number: 'GST number',
      },
      address: {
        street_number: 'Street number',
        building_name: 'Building name',
        floor_number: 'Floor number',
        unit_number: 'Unit number',
        street_name: 'Street name',
        city: 'City',
        post_code: 'Postcode',
        stress_name_fill: 'Enter your street name',
        street_name_required: 'Street name is required',
        street_number_fill: 'Enter your street number',
        street_number_required: 'Street number is required',
        city_fill: 'Enter your city name',
        city_required: 'City name is required',
        postcode_fill: 'Enter your post code',
        postcode_required: 'Post code is required',
        country_fill: 'Enter your country',
        country_required: 'Country is required',
        building_name_fill: 'Enter your building name',
      },
    },
    reports: {
      title: 'Reports',
      to_update: 'to update.',
      click_here: 'Click here ',
      financial_year_end: 'Financial year end',
      financial_year_end_sub_title:
        'Select date and month of financial year end.',
      financial_year_end_description:
        'This is the completion date of one year or 12 month accounting period. ',
      week_commencing_day: 'Week commencing day',
      week_commencing_day_description:
        'This is the week start day to calculate weekly reports. Weekly reports will start from the week commencing day. ',
      day_end_time: 'Day end time',
      day_end_time_sub_title:
        'Select hour, minute and seconds in order.',
      day_end_time_description:
        'Transactions received after this time will be recorded in the following day. ',
    },
    chart_of_accounts: {
      title: 'Chart of Accounts',
      add_bank: 'Add bank',
      add_account: {
        add: 'Add',
        update: 'Update',
        add_button: 'Add account',
        modal_title_add: 'Add Account',
        modal_title_edit: 'Update Account',
        name_required: 'Account name is required',
        name_max: 'Maximum of 150 characters',
        description_max: 'Maximum of 400 characters',
        type_required: 'Account type is required',
        account_name: 'Account name',
        account_description: 'Description (optional)',
        account_type: 'Account type',
        reports: 'Reports',
        account_show_in_performance_report:
          'Show in performance overview',
        new_account_added: '{{name}} added',
        account_updated: '{{name}} updated',
        account_archived: '{{name}} archived',
      },
      all_account: {
        account_name: 'Account name',
        type: 'Type',
        opening_balance: 'Opening balance',
        edit: 'Edit',
        merge: 'Merge',
        archived: 'Archived',
        tax: 'Tax',
      },
      no_account_found: 'No Account Found!',
    },
    inventory: {
      title: 'Inventory',
      pricing: 'Pricing',
      price_types: 'Price types',
      price_types_description:
        'Multiple price types can help you manage multiple prices for each product, enabling you to offer bespoke pricing for order types or selected customer segments.',
      select_modules: 'Select modules',
      price_exclusive_taxes: 'Show prices exclusive of taxes.',
      price_exclusive_taxes_description:
        'Recommended to switch on if your customers are businesses. This will show display product prices in inventory, orders and receipts exclusive of taxes. Taxes will be displayed separately.',
      product_price_across:
        'Update product price across all stores when edited.',
      product_price_across_description:
        'Enabling this will change the product price across all stores when its updated in any one of your stores.',
      raw_materials: 'Raw materials',
      raw_materials_description:
        'Raw materials module can help you to manage raw materials / ingredients and optimise stock control.',
      add_ons: 'Add-ons',
      add_ons_description:
        'Add-ons module can help you to create & manage product options / variants to help staff up-sell and help your customers all of the options available with each product. .',
      recipe_management: 'Product builder',
      recipe_manegement_description:
        'Product builder allows you to add raw materials and products used to make a product. This will allow you to gain access to accurate stock insights and ensure the right stock is available at all times.',
      allergy_management: 'Allergy management',
      allergy_management_description:
        'Allergy management gives you the capabilities to manage allergy information of each and every items sold and share it with staff and customers.',
      style_management: 'Style management',
      style_management_description:
        'Style management can help you create & manage style variations of clothing or accessories you manufacture or sell.',
      measures: 'Measures',
      measures_description:
        'Select measure types required to manage your inventory items and raw materials to optimise stock control.',
    },
    branding: {
      title: 'Branding',
    },
    touchpoint_pos: {
      title: 'Point of Sale (POS) Touchpoint',
      general_settings: 'General Settings',
      automatic_receipt_printing: 'Automatic Receipt Printing',
      automatic_receipt_printing_description:
        'Switching on Automatic Product builder Printing will automatically print customer receipts when an order is paid in full.',
      automatic_expense_receipt_printing:
        'Automatic Expense Receipt Printing',
      automatic_expense_receipt_printing_description:
        'Switching on Automatic Expense Product builder Printing will automatically print a receipts when an expense is paid.',
      float: 'Float',
      float_description:
        'Switch on if you use float. This is the amount kept in cash-drawer at the start of a shift. The amount entered will be automatically deducted from available cash to calculate cash takings.',
      manual_amount_authorisation: 'Manual Amount Authorisation',
      manual_amount_authorisation_description:
        'Switching on will allow you to set a maximum amount that can be manually added to cart without confirmation.',
      auto_lock: 'Auto Lock',
      auto_lock_description:
        'Enter the idle time in second before POS should automatically lock for enhanced security.',
      seconds: 'Seconds',
      cash_drawer_alarm: 'Cash Drawer Alarm',
      cash_drawer_alarm_desciption:
        'Switching on will set a timer to alert cashiers if cash drawer remains open for longer than the selected minutes. Enter in minutes.',
      group_same_items_in_cart: 'Group Same  Items in cart',
      group_same_items_in_cart_desctiption:
        'Switching on this feature will group items in shopping cart in supported touchpoints.',
      payment_types: 'Payment types',
      payment_types_desciption:
        'Your POS is set to accept Cash, Card, PayPal and GoPay. Click on update to add or remove payment types.',
      update_payment_types_accepted: 'Update payment types accepted',
      images: 'Images',
      banners: 'Banners',
      banners_description:
        'Upload a banner that can be displayed when all users logged out of POS is locked. Recommended file size is size 1280 x 800 (Jpg / Png)',
      customer_display_images: 'Customer display images',
      customer_display_images_description:
        'Add up to 4 images to be displayed in customer display screens if you use a support device with a customer display screen.',
      product_arrangement: 'Product Arrangement',
      alphabetical_order: 'Alphabetical order',
      sort_by_alphabetical_order: 'Sort by alphabetical order',
      sort_by_alphabetical_order_description:
        'Switching on will display categories & products in alphabetical order regardless of category positions set.',
      category_display_position: 'Category display & position',
      category_display_position_description:
        'Customise what categories to be displayed in POS application and in what order. Choose between alphabetical order or custom order.',
      category_display_order: 'Category display order',
      category_display_order_description:
        'Customise what categories to be displayed in POS application and in what order. Choose between alphabetical order or custom order.',
      add_ons_group_display_order: 'Add ons group display order',
      add_ons_group_display_order_description:
        'Select in which order add-on groups are displayed to when products with add-on options are sold.',
      arrange_category_visibility: 'Arrange category visibility',
      category_name: 'Category name',
      show_hide: 'Show / Hide',
      position: 'Position',
      receipt_settings: 'Receipt Settings',
      logo: 'Logo',
      logo_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      tax_summary: 'Tax summary',
      tax_summary_description:
        'Enable this option if you want receipt to include sales tax summary in customer receipts printed by default.',
      phone_number: 'Phone number',
      phone_numner_desctiption:
        'Switching on will print your store phone number in customer receipt when printed if you have a phone number added to your store profile.',
      cashier_name: 'Cashier name',
      cashier_name_description:
        'Switching on will print the name of the cashier in full who completed the order.',
      registration_number: 'Registration number',
      registration_number_description:
        'Switching on will print registration number in customer receipt if you have a registration number is updated in business settings.',
      website_address: 'Website address',
      website_address_description:
        'Switching on will print your website address in customer receipt if you have your website address updated in store profile.',
      qr_code: 'QR code',
      qr_code_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      address_type: 'Address type',
      address_type_description:
        'Your registered address will be printed in customer receipt. Click on update to print store address.',
      custom_message: 'Custom message',
      custom_message_description:
        'Your custom message is “{{message}}”. Click on update if you want to change it',
      custom_message_description_empty:
        'You do not have a custom message added. Add a greeting to your customer. ',
      print_promotion: 'Print promotion',
      print_promotion_description:
        'You have no promotions selected to print in your receipt. Click on select to pick a promotion to be printed in your receipt to encourage customers to visit again.',
      float_amount: 'float amount',
      amount: 'amount',
      lock_time: 'auto lock time in seconds',
      cash_drawer_alarm_timer: 'cash drawer alarm timer in seconds',
      select_a_promotion: 'Select a promotion',
      promotion_placeholder: 'Enter promotion name or product name',
      promotion_found: 'All promotions found!',
      loading: 'Loading...',
      select_address_type: 'Select address type',
      store_address: 'Store address',
      store_address_description:
        'Address of the store in which POS is used.',
      registered_address: 'Registered address',
      update_banner: 'Update banner',
      update_customer_display: 'Update customer display',
      print_promotion_not_empty:
        '"{{promotions}}", selected to print in your receipt. Click on update to change.',
      print_promotion_placeholder:
        'Enter promotion name or product name',
      no_promotion_found: 'No promotion found.',
    },
    touchpoint_kiosk: {
      title: 'Touchpoint - Kiosk',
    },
    touchpoint_storefront: {
      title: 'Digital Storefront Touchpoint',
      general: 'General',
      temporary_url: 'Temporary URL',
      order_types: 'Order types',
      order_types_description:
        'You are set to accept Collection & Delivery orders through your digital storefront. . Click on update to add or remove order types',
      update_order_types_accepted: 'Update order types accepted',
      product_arrangement: 'Product Arrangement',
      group_and_display_products_by_category:
        'Group & display products by category',
      group_and_display_products_by_category_description:
        'Display products grouped by category. Disabling this option may display all selected products in a single page',
      sell_selected_products: 'Sell selected products',
      sell_selected_products_description:
        'Display only selected prducts from your inventroy in your kiosk. Switching this option off would display all products in your inventory.',
      select_products: 'Select products',
      select_products_placeholder: 'Enter product name or barcode',
      loading: 'Loading...',
      all_product_found: 'All products found!',
      no_product_found: 'No product found.',
      product_name: 'Product name',
      show_hide: 'Show / Hide',
      position: 'Position',
      display_in_alphabetical_order: 'Display in alphabetical order',
      display_in_alphabetical_order_description:
        'Display products grouped by category. Disabling this option may display all selected products in a single page',
      categories_and_position: 'Categories & position',
      categories_and_position_desciption:
        'Choose between Alphabetical order and custom order in which your products & categories (if grouped) displayed',
      receipt_settings: 'Receipt Settings',
      logo: 'Logo',
      logo_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      website_address: 'Website address',
      website_address_description:
        'Switching on will print your website address in customer receipt if you have your website address updated in store profile.',
      phone_number: 'Phone number',
      phone_numner_desctiption:
        'Switching on will print your store phone number in customer receipt when printed if you have a phone number added to your store profile.',
      address_type: 'Address type',
      address_type_description:
        'Your registered address will be printed in customer receipt. Click on update to print store address.',
      registration_number: 'Registration number',
      registration_number_description:
        'Switching on will print registration number in customer receipt if you have a registration number is updated in business settings.',
      print_promotion: 'Print promotion',
      print_promotion_description:
        'You have no promotions selected to print in your receipt. Click on select to pick a promotion to be printed in your receipt to encourage customers to visit again.',
      print_promotion_not_empty:
        '"{{promotions}}", selected to print in your receipt. Click on update to change.',
      qr_code: 'QR code',
      qr_code_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      custom_message: 'Custom message',
      custom_message_description:
        'Your custom message is “{{message}}”. Click on update if you want to change it',
      custom_message_description_empty:
        'You do not have a custom message added. Add a greeting to your customer. ',
    },
  },
  addStore: {
    find_manage_your_bussiness: 'Find & Manage your business',
    cant_find_store: "Can't find your store?",
    add_store_manually: 'Add store manually',
    enter_bussiness_type:
      'Enter business type that best describes your business.',
    enter_bussiness_type_information:
      '  This gives you access to most relevant tools and customers find you if they are looking for businesses like yours.',
    whats_your_store_name: 'What is your store name?',
    whats_your_store_name_information:
      'This helps customers find your business in KRL and for us to identify your business.',
    add_store: 'Add Store',
    whats_your_store_address: 'What is your store address?',
    whats_your_store_address_information:
      ' This helps us setup your account with relevant records and customers find your store if they need to place an order or collect items.',
    street_number: 'Street number',
    building_name: 'Building name',
    floor_number: 'Floor number',
    unit: 'Unit number',
    street_name: 'Street name',
    city: 'City',
    postcode: 'Postcode',
    country: 'Country',
    adding_new_store: 'Adding new store',
  },
  workboard: {
    today: 'Today',
    yesterday: 'Yesterday',
    week_to_date: 'Week to date',
    month_to_date: 'Month to date',
    select_dates: 'Select Dates',
    quick_access: {
      quick_access: 'Quick Access',
    },
    run_reports: {
      reports: 'Reports',
      reports_description: 'Reports description',
      select_store: 'Select store',
      report: 'Report',
      business_overview: 'Business overview',
      report_type: 'Report type',
      daily_report: 'Daily report',
      weekly_report: 'Weekly report',
      monthly_report: 'Monthly report',
      quarterly_report: 'Quartely report',
      annual_report: 'Annual report',
      custom_report: 'Custom report',
      year: 'Year',
      select_period: 'Select period',
      print: 'PRINT',
      export_pdf: 'EXPORT PDF',
      export_csv: 'EXPORT CSV',
      today: 'Today',
      week_to_date: 'Week to date',
      month_to_date: 'Month to date',
      quarter_to_date: 'Quarter to date',
      future_time_alert: "Don't select time in future",
    },
    trends: {
      revenue: 'Revenue',
      expenses: 'Expenses',
      atv: 'Average transaction value',
      basket_size: 'Basket size',
      number_of_transactions: 'Number of transactions',
    },
    product_sales_analysis: {
      gross_sales: 'Gross sales',
      no_items: 'No of items',
      product: 'Product',
      category: 'Category',
      barcode: 'Barcode',
      no_items_sold: 'No of items sold',
      net_sales: 'Net sales',
      taxes: 'Taxes',
      gross_sales: 'Gross sales',
    },
    no_data: 'No data available.',
    performance_overview: {
      compare: 'Compare',
      select_store: 'Select store',
      total_sales: 'Total sales',
      atv: 'ATV',
      avg_basket_size: 'Avg Basket Size',
      not: 'NoT',
      items_sold: 'Items sold',
      football: 'Football',
      estate: 'Estate',
    },
    product_intelligence: {
      find_product:
        'Search & Find product to view insights & predictions.',
      enter_name_barcode: 'Enter product name or barcode',
      no_matching_products: 'No matching products found.',
      loading_products: 'Loading...',
      store: 'Store',
      product: 'Product',
      barcode: 'Barcode',
      gross_sales: 'Gross sales',
      net_sales: 'Net sales',
      tax_sales: 'Tax liability',
      no_items: 'No of items sold',
      not: 'NoT',
      atv: 'ATV',
      current_stock: 'Current stock',
      stockout: 'Stockout',
    },
    no_data: 'No data available.',
    drop_here_to_remove: 'Drop here to remove',
    widget_removed: '{{name}} removed',
    widget_added: '{{name}} added',
    drag_drop: 'Drag & drop',
  },
  globalWidgets: {
    insights_market_place: 'Insights market place',
    choose_widgets:
      'Pick & Choose widgets to build to customise Workboard.',
    all_widgets: 'All Widgets',
    all_available_data: 'All available data',
    performance_overview: 'Performance Overview',
    performance_overview_description:
      'Gives you a performance overview all KPIs that has a direct impact on your bottomline.',
    product_sales_analysis: 'Product Sales Analysis',
    product_sales_analysis_description:
      'Gives you access to performance intelligence of top selling & least selling products to help you increase their sales.',
    category_sales_analysis: 'Category Sales Analysis',
    category_sales_analysis_description:
      'Gives you a deeper understanding of the different factors that increase or decrease the demand for a product category.',
    trends: 'Trends',
    trends_description:
      'Measure performance of key performance indicators to understand trends and compare performance to improve performance over time.',
    stockout_prediction: 'Stockout Predictions',
    stockout_prediction_description:
      'Gives you access to powerful prediction about stockout to help you stock the right item at the right time and place to minimise loss of revenue.',
    employee_kpis: 'Employee KPIs',
    employee_kpis_description:
      'Gives you access to employee performance metrics to help you offer better training to help them improve their performance and satisfaction.',
    chart_of_accounts: 'Chart Of Accounts',
    chart_of_accounts_description: 'Chart of accounts description',
    product_intelligence: 'Product Intelligence',
    product_intelligence_description:
      'Gives you access to intelligence & comparison about the performance of a product sold.',
  },
  error: {
    title: 'Something went wrong ! Please try again later...',
    back_to_home: 'Back to home page',
  },
  image: {
    upload: 'Upload',
    library: 'Library',
    instagram: 'Instagram',
    dropbox: 'Drop box',
    upload_image: 'Upload image',
    browse: 'Browse.',
    drop_title: 'Drop your image here or',
    change_image: 'Change image',
    dimension_error:
      'At least {{width}} x {{height}} image size required',
  },

  userManagement: {
    users: {
      users: 'Users',
      business_manager: 'Business Mananger',
      user_management: 'User Management',
      search_placeholder: 'Enter user name or email or mobile',
      all_role: 'All roles',
      all_teams: 'All teams',
      add_new_user: 'Add New User',
      name: 'Name',
      role: 'Role',
      teams: 'Teams',
      status: 'Status',
      last_login: 'Last login',
      all_users: 'All users',
      active_users: 'Active users',
      inactive_users: 'Inactive users',
      active: 'Active',
      invitation_sent: 'Invitation sent',
      resend_invitation: 'Resend invitation',
      cancel_invitation: 'Cancel invitation',
      invitation_canceled: 'Invitation canceled',
      loading: 'Loading...',
      all_users_found: 'All users found!',
      role_and_permissions: 'Role & Permissions',
      stores: 'Stores',
      licenses: 'Licenses',
      add_new_user_dialog_title: 'Add new user',
      update_user_dialog_title: 'Update user',
      invitation: 'Invitation',
      email: 'Email',
      email_placeholder: 'Enter valid email address',
      role: 'Role',
      permissions: 'Permissions',
      inventory: 'Inventory',
      orders: 'Orders',
      expenses: 'Expenses',
      select_team: 'Select team',
      next: 'Next',
      add_user: 'Add user',
      price_subscription:
        '{{time}} subscription - {{currency}} per {{unit}}',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Annual',
      day: 'day',
      week: 'week',
      month: 'Month',
      year: 'year',
      or: 'OR',
      all_stores: 'All stores',
      notify_add_user_successfully: 'Invitation sent. Please wait.',
      email_required: 'Email is required!',
      team_required: 'Team is required!',
      email_invalid_format: 'Please check your email format!',
      update_team: 'Update team',
      update_role_and_permission: 'Update role & permissions',
      update_store_access: 'Update store access',
      update_license: 'Update license',
      block: 'Block',
      activate: 'Activate',
      select_team: 'Select team',
      user_team_updated: 'User team updated',
      no_user_found: 'No User Found!',
      block_user: '{{value}} blocked',
      pay: 'Pay',
    },
    archivedUsers: {
      activate_user: '{{value}} activated',
    },
    teams: {
      add_team: 'Add team',
      teams: 'Teams',
      name: 'Name',
      edit: 'Edit',
      delete: 'Delete',
      search_placeholder: 'Enter team name',
      teams_name_required: 'Team name is required!',
      new_team_added: 'New team added',
      add_teams_dialog_title: 'Enter team name',
      edit: 'Edit',
      delete: 'Delete',
      team_updated: 'Team updated',
      team_delete: 'Team deleted',
      no_team_found: 'No Team Found!',
    },
  },
  addressAutoComplete: {
    add_manually: 'Address not listed. Add manually.',
    placeholder: '150 Palace road, London, W1H 8HA',
    no_options_found: 'No options found',
  },

  invitation: {
    invite: {
      accept: 'Accept',
      decline: 'Decline',
      description:
        'Invited you to access their business information.',
      remind_later: 'Remind me later',
      accept_invitation_message: 'You now have access to {{name}}',
      decline_invitation_message: 'Invitation declined.',
    },
    accessMerchant: {
      textFooter: 'Karan Retail Ltd © {{year}}',
      accept: {
        sub_title:
          'Enter password to create account and get instant access to all the tools and information.',
        access_to_merchant: 'Access to {{name}}',
        first_name: 'First name',
        sur_name: 'Surname',
        password: 'Password',
        confirm_password: 'Confirm Password',
        first_name_required: 'First name is required!',
        sur_name_required: 'Surname is required!',
        password_required: 'Password is required!',
        confirm_password_required: 'Confirm Password is required!',
        access: 'Access',
        by_clicking_signup: 'By clicking Access, you agree to our ',
        terms_policy: 'Terms of use and Privacy Policy.',
        you_receive_sms_opt:
          'You may receive SMS notifications & email communications from us and you can opt out or manage preferences at any time.',
        password_mismatch: 'Password mismatched',
      },
      decline: {
        cancel: 'Cancel',
        decline: 'Decline',
        description: 'Are you sure to decline the invitation?',
        invitation_declined: 'Invitation Declined.',
        email_received: 'Email received without your consent? ',
        contact_us: 'Contact us',
        privacy_seriously:
          ' so that we can investigate further. We take your privacy seriously.',
      },
      expired: {
        invitation_expired: 'Invitation expired.',
        resend_invitation: 'Request merchant to resend invitation.',
      },
    },
  },
  notification: {
    title: 'Notifications',
    no_notifications: 'No notifications.',
  },
  help: {
    help: 'Help',
    start_tutorial: 'Start Tutorial',
  },

  add_product: {
    add_new_product: 'Add new product',
    update_product: 'Update product',
    basic_information: 'Basic information',
    stock_information: 'Stock information',
    images_descriptions: 'Images & descriptions',
    addon_options: 'Add-on options',
    recipe: 'Product builder',
    general_information: 'General information',
    barcode: 'Barcode - EAN / UPC / MPC',
    product_name: 'Product name',
    category: 'Category',
    measures: 'Measures',
    sold_individually: 'Sold individually',
    measured_in_kg: 'Measured in Kg',
    measured_in_g: 'Measured in grams',
    measured_in_m: 'Measured in meters',
    pricing: 'Pricing',
    price: 'Price',
    enter: 'Enter',
    add: 'Add',
    select: 'Select',
    barcode_required: 'Barcode required',
    product_name_required: 'Product name required',
    category_required: 'Category required',
    price_required: 'Price required',
    tax_required: 'Tax required',
    inner_items_num_required: 'Inner items required',
    inner_item_required: 'Inner item required',
    qty: 'Sold individually',
    gram: 'Measured in grams',
    kgram: 'Measured in Kg',
    pack: 'Pack',
    multi_pack: 'Multi pack',
    weighed_item: 'Weighed item',
    inner_item_num: 'Number of inner items',
    select_inner_item:
      'Enter product name or barcode to select inner item',
    add_product_success: 'Product added successfully',
    update_product_success: 'Product updated successfully',
    barcode_already_exist:
      'This bar_code already exists in your inventory.',
    locations: 'Locations',
    sku_number: 'SKU number',
    item_expires: 'Item expires',
    aisle: 'Aisle',
    section: 'Section',
    add_new_location: 'Add new location',
    aisle_and_shelf: 'Aisle & Shelf',
    floor: 'Floor',
    area: 'Area',
    shelf: 'Shelf',
    customer_access_permitted: 'Customer access permitted',
    manage_floor_shelf:
      'Manage floors, areas, aisles and shelves in ',
    modules: 'modules.',
    find_addon_group: 'Search & find add-on groups to add.',
    enter_addon_group_name: 'Enter add on group name',
    no_addon_groups_added: 'No Add-on groups added.',
    addons: 'add-ons',
    addon_description:
      'Drag and drop add-on groups to change the order in which they should be displayed in touchpoints. (POS, Kiosk, website, etc.) Rules changed in this page will override the add-on group rule for this product only. It will not change the add-on group’s rules.',
    multi_selection: 'Multi selection',
    default_all: 'Select all by default',
    limit_free_options: 'Limit free options',
    free_addons_no: 'No of free add-ons',
    remove: 'Remove',
    no_of_free_options: 'No of free add-ons',
    raw_material: 'This is a raw material and no sold.',
    description: 'Description',
    short_description: 'Short Description',
    characters_left: '{{character}} characters left',
    images: 'Images',
    input_description_here: 'You can input some descriptions here...',
    enter_product_ingredient_name:
      'Enter product or raw material name or barcode',
    find_inventory:
      'Search & find products and raw material used to produce this item',
    no_ingredients_added: 'No product builders added',
    allergies: 'Allergies',
    search_and_find_allergies:
      'Search & find allergies to add or add new.',
    no_allergies_added: 'No allergies added.',
    allergies_placeholder: 'Enter allergy name. Ex: Dairy',
    add_new: 'Add new',
  },

  touchpointsManagement: {
    touchpoints: {
      title: 'Touchpoints',
      name: 'Name',
      type: 'Type',
      store: 'Store',
      add_new: 'Add new',
      add: 'Add',
      update: 'Update',
      all_stores: 'All stores',
      all_types: 'All types',
      search_placeholder: 'Enter touchpoints name',
      no_touchpoints_found: 'No Touchpoints Found!',
      update: 'Update',
      archive: 'Archive',
      activate: 'Activate',
      add_new_touchpoint: 'Add New Touchpoint',
      touchpoint_name: 'Touchpoint name',
      touchpoint_type: 'Touchpoint type',
      touchpoint_name_required: 'Touchpoint name is required!',
      touchpoint_type_required: 'Touchpoint type is required!',
      touchpoint_store_required: 'Store is required!',
      new_touchpoint_added: '{{name}} added',
      touchpoint_updated: '{{name}} updated',
      touchpoint_archived: '{{name}} archived',
      touchpoint_activated: '{{name}} activated',
      update_touchpoint: 'Update Touchpoint',
    },
  },

  joy_rider: {
    next: 'Got it!',
    back: 'Back',
    close: ' Let’s start.',
    skip: 'Skip tutorial',
    welcome_myda: 'Welcome KRL Business Manager',
    start_connect:
      'Start connecting with your customers and suppliers, sell more and create a sustainable business.',
    simple_start: 'Getting started is simple.',
    quick_guide: 'Quick guide',
    start: {
      title: 'It all starts here.',
      content:
        'Click to access services and settings to get started.',
    },
    store_profile: {
      title: 'Manage store profile & Storefront',
      content:
        'You can manage your store address, contact details, opening hours, and customise digital storefront.',
    },
    inventory: {
      title: 'Add & Manage products',
      content:
        'You can add and manage products you sell, raw materials you use to produce goods, pricing, stock locations, and more. ',
    },
    orders: {
      title: 'orders title',
      content: 'orders content',
    },
    expenses: {
      title: 'expenses title',
      content: 'expenses content',
    },
    customers: {
      title: 'customers title',
      content: 'customers content',
    },
    user_management: {
      title: 'Invite & Manage staff ',
      content:
        'You can invite users to access your business tools and apps and manage their roles, permissions and licenses. ',
    },
    teams: {
      title: 'Create & Manage teams.',
      content:
        'You can create teams or departments and assign users to respective teams or departments. ',
    },
    settings: {
      title: 'Here you can customise ',
      content:
        'Manage and update critical business information and make apps and services to support your business need. ',
    },
    touchpoints: {
      title: 'Add & Manage touchpoints',
      content:
        'Here you can create and manage touchpoints such as POS, Kiosk & Digital storefront. You can select what product to sell where and configure touchpoints via Settings.  ',
    },
    run_report: {
      title: 'Business Reports',
      content:
        'You can run reports to understand your business performance such as daily sales, weekly sales, quarterly sales, and more. ',
    },
    add_widgets: {
      title: 'Customise your workboard.',
      content:
        'Here you can pick and choose widgets from the marketplace to build your workboard so that you can see what you want to see every time you log in. ',
    },
    store_select: {
      title: 'Switch to a different store or business.',
      content:
        'If you have access to more than one store or business, you can switch to a different store/region/business from here.',
    },
  },

  import: {
    title: 'Import',
    import_data: 'Import data',
    description:
      'Import your products, pricing, categories & description to Myda Cloud.',
    import_product: 'Import you products to Myda Cloud',
    upload_your_file: 'Upload your file',
    before_upload:
      'Before you upload your files below, make sure your file is ready to be imported. ',
    download_sample: 'Download sample file here.',
    drag_and_drop: 'Drag and drop or ',
    choose_file: 'choose a file ',
    from_your_computer: 'from your computer to upload your Products.',
    type_support:
      'All .csv, .xlsx, and .xls file types are supported.',
    map_column_property: 'Map columns and properties',
    map_column_property_description:
      'Each column from your file should be mapped to a Inventory property in Myda Cloud. Based on the names, some of it are already mapped.',
    change_manually:
      'You can change it as well as manually map anything that’s not been mapped automatically.',
    data_from_your_file: 'Data from your file',
    properties_in_myda: 'Properties in Myda Cloud',
    do_not_import_first_row: 'Do not import first row from my file',
    map_to: 'Map to',
    product_imported: 'Products imported.',
    file_type_invalid:
      'File is unsupported or corrupted. Upload a csv or xlsx or xls file.',
  },

  orderTypeWorkflows: {
    order_type_and_workflows: 'Order types & Workflows',
    order_type: 'Order types',
    add_new: 'Add New',
    name: 'Name',
    type: 'Type',
    price_type: 'Price type',
    workflow: 'Workflow',
    update: 'Update',
    remove: 'Remove',
    order_type_name: 'Order type name',
    add_new_order_type: 'Add new order type',
    order_type_name_required: 'Order type name is required!',
    price_type_required: 'Price type is required!',
    workflow_required: 'Workflow is required',
    order_type_added: 'Order type added',
    update_order_type: 'Update order type',
    order_type_updated: 'Order type updated',
    order_type_removed: 'Order type removed',
    workflows: 'Workflows',
    configure: 'Configure',
    archive: 'Archive',
    no_order_type_found: 'No Order Type Found!',
    no_workflows_found: 'No Workflow Found!',
  },
  addons: {
    no_matching_addon_groups_found: 'No matching addon groups found.',
    no_addon_groups_found: 'No addon groups found.',
    group_name: 'Group name',
    no_of_options: 'No of options',
    update_name_description: 'Update name & description',
    update_rules_options: 'Update rules & options',
    delete: 'Delete',
    name_description: 'Name & Description',
    rules_options: 'Rules & Options',
    group_name: 'Group name',
    short_description: 'Short Description',
    character_left: 'characters left',
    add: 'Add',
    group_name_required: 'Group name required',
    description_required: 'Short Description required',
    rules: 'Rules',
    make_it_mandatory: 'Make it mandatory',
    select_all_default: 'Select all by default',
    allow_multi_select: 'Allow multi select',
    limit_free_options: 'Limit free options',
    no_of_free_options: 'No of free options',
    find_product: 'Search & find product to add',
    qty: 'Qty',
    weight_g: 'Weight (g)',
    weight_kg: 'Weight (kg)',
    cm: 'Cm',
    inch: 'Inch',
    meter: 'M',
    yard: 'Yard',
    litre: 'Litre',
    ml: 'Ml',
    price: 'Price',
    max: 'Max',
    force_charge: 'Force charge',
    select_by_default: 'Select by default',
    remove: 'Remove',
    add_addon_success: 'Add-on added successfully',
    update_addon_success: 'Add-on updated successfully',
    add_one_addon: 'Add one add-on at least',
    group_deleted: 'Add-on group deleted',
  },
  subscriptions: {
    plans: 'Plans',
    plan_overview:
      'Each plan comes with options for you to pay Monthly or yearly. For permanent employees you can choose to pay yearly and for part time or temporary workers you can choose to pay monthly. All prices are inclusive of taxes where appropriate.',
    next_bill: 'Next Bill: ',
    next_payment_date: 'Next Payment date: ',
    payment_methods: 'Payment Method: ',
    payment_options: 'Payment options',
    cost: 'Cost',
    no_of_user_store: 'No of {{type}}',
    manage: 'Manage',
    ending_with: '(Ending with {{number}})',
    no_subscription_found: 'No Subscription Found!',
    select_payment_options: 'Select payment option',
    add: 'Add',
    payment_method_added: 'Payment method added',
    payment_method_title: 'Payment method',
    payment_methods_title: 'Payment methods',
    add_new: 'Add new',
    card_number: 'Card number',
    type: 'Type',
    delete: 'Delete',
    default: 'Default',
    set_as_default: 'Set as default',
    no_payment_methods_available: 'No payment methods available.',
    add_payment_method: 'Add Payment method',
    default_payment_method_changed: 'Default payment method changed',
    payment_method_delete: 'Payment method deleted',
    not_subscription_yet:
      'Licenses are optional. If license are added, you will only be invoiced when the invitation is accepted by the user.',
    pro_rated:
      'You will be charged a pro-rated fee of {{price}} today until your next billing date {{date}}',
  },
  modules: {
    title: 'Modules',
    module: 'Modules',
    status: 'Status',
    deactive: 'Deactive',
    configure: 'Configure',
    no_module_found: 'No Module Found!',
    inactive: 'Inactive',
    active: 'Active',
    module_activated: 'Module activated.',
    module_deactivated: 'Module deactivated.',
    floor_and_shelf: {
      floor_and_shelf_management: 'Floor & Shelf Management',
      floors: 'Floors',
      add_floor: 'Add Floor',
      areas: 'Areas',
      area: 'Area',
      floor: 'Floor',
      add_area: 'Add Area',
      shelves: 'Shelves',
      aisle: 'Aisle',
      shelf: 'Shelf',
      customer_access_permitted: 'Customer access permitted',
      add_new_location: 'Add new location',
      floor_added: 'Floor added',
      floor_deleted: 'Floor deleted',
      floor_name_required: 'Floor name is required!',
      floor_and_shelves_updated: 'Floor & shelves updated',
    },
  },
  measures: {
    qty: 'Sold individually',
    g: 'Gram',
    kg: 'Kilogram',
    cm: 'Centimeter',
    in: 'Inch',
    m: 'Meter',
    yd: 'Yard',
    l: 'Litre',
    ml: 'Millimeter',
  },
  ingredients: {
    add_new_ingredient: 'Add new Raw Material',
    update_ingredient: 'Update Raw Material',
    update_ingredient_success: 'Update Raw Material success',
    add_ingredient_success: 'Add Raw Material success',
    delete: 'Delete',
    ingredient_deleted: 'Raw Material deleted',
    no_ingredients_found: 'No Raw Materials found',
    no_matching_ingredients_found: 'No matching Raw Materials found',
    product: 'Product',
    ingredient: 'Raw Material',
    ingredient_name: 'Raw Material name',
    ean_upc_mpc: 'EAN/UPC/MPC',
    basic_information: 'Basic information',
    stock_information: 'Stock information',
  },
  user: {
    account: 'Account',
    profile: {
      title: 'Profile',
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      mobile_number: 'Mobile number',
      first_name_is_required: 'First name is required!',
      last_name_is_required: 'Last name is required!',
      email_is_required: 'Email is required',
      verified: 'Verified',
      unverified: 'Unverified',
    },
    privacyAndSecurity: {
      title: 'Security',
      change_password: 'Change password',
      delete_account: 'Delele account',
    },
    preferences: {
      title: 'Preferences',
      language: 'Language',
      communications: 'Communications',
      marketing: 'Marketing',
      product_update: 'Product update',
    },
    setting_shop: 'Shop',
    setting_promotions: 'Promotions',
    setting_profile: 'Profile',
    setting_preferences: 'Preferences',
    setting_pivacy_and_security: 'Privacy & Security',
    setting_logout: 'Log out',
    tab_updated: '{{name}} updated',
  },
  changePassword: {
    title: 'Change Password',
    current_password: 'Current password',
    new_password: 'New password',
    current_password_is_required: 'Current password is required',
    new_password_is_required: 'New password is required',
    password_updated: 'Password updated',
  },
  deleteAccount: {
    title: 'Delete Account',
    description:
      'We are sorry to see you go. If there is anything we can do to change your mind, let us know.',
    action_description:
      'Type DELETE ACCOUNT below and click on Delete Account button.',
    account_deleted: 'Account deleted',
  },
  store: {
    login: 'Login',
    settings_posts: 'Posts',
    setting_about: 'About',
    setting_products: 'Products',
    setting_promotions: 'Promotions',
    logo_updated: 'Logo updated',
    banner_updated: 'Banner updated',
    search_placeholder: 'Enter store name',
    loading: 'Loading...',
    no_store_found: 'No store found!',
    open_now: 'Open now',
    closed: 'Closed',
    specialities: 'Specialities',
    update_specialities: 'Update Specialities',
    description_specialities:
      'Enter up to three specialities so that visitors will know what you sell.',
    speciality_placeholder: 'Enter speciality. Ex: Groceries',
    no_speciality_found: 'No speciality found!',
    specialities_updated: 'Specialities updated',
    products: 'Products',
    about: 'About',
    located_in: 'Located In',
    hours: 'Hours',
    all_categories: 'All Categories',
    price: 'Price: ',
    in_stock: '{{qty}} in stock',
    add_to_cart: 'Add to cart',
    search_product_name: 'Search product name',
    no_products_found: 'No products found',
    update_about_us: 'Update about us',
    about_us_character_length: 'Max 125 characters',
    about_description_updated: 'About description updated',
    description_about_us:
      'Write a shot description about your business so that customers can visit. your store..',
    add: 'Add',
    add_new: 'Add new',
    open: 'Open',
    close: 'Close',
    add_hours: 'Add hours',
    remove: 'Remove',
    update_opening_hours: 'Update opening hours',
    opening_hours_updated: 'Opening hours updated',
    add_seasonal_opening_hours: 'Add seasonal opening hours',
    time_validation_message:
      'Open time must be earlier than close time',
    update_merchant_logo: 'Update merchant logo',
    update_store_banner: 'Update store banner',
    product: {
      product_description: 'Product Description',
      allergy_information: 'Allergy Information',
      buy_now: 'Buy now',
      select_options: 'Select options',
      back: 'Back',
    },
    cart: {
      select_an_option: 'Select an option',
      unselect_the_selected_one: 'Unselect the selected one',
    },
    shoppingCart: {
      title: 'Shopping cart',
      sub_total: 'Sub total ({{quantity}} items):',
      checkout: 'Checkout',
      clear_cart: 'Clear Cart',
      product_added_to_cart: '{{name}} added to cart',
      total: 'Total',
    },
  },
  landing: {
    open: 'Open',
    closed: 'Closed',
    closing_at: '. Closing at',
    opening_at: '. Opening at',
  },
  cart: {
    back_to: 'Back to {{name}}',
    back: 'Back',
    save_for_later: 'Save for later',
    delete_cart: 'Delete cart',
    store: 'Store',
    payment_type: 'Payment type',
    checkout: 'Checkout',
    pay: 'Pay',
    item: 'Item',
    price: 'Price',
    remove: 'Remove',
    add_to_shopping_list: 'Add to shopping list for later',
    qty: 'Qty',
    stock: 'Stock',
    store: 'Store',
    payment_type: 'Payment type',
    total_due: 'Total Due',
    pay: 'Pay',
    add_new: 'Add new',
    change: 'Change',
    item: 'Item',
    price: 'Price',
    stock: 'Stock: {{quantity}}',
    order_added: 'Order added',
    select_delivery_address: 'Select Delivery Address',
    select_table_no: 'Select Table No',
  },
  orderManagement: {
    shopping_lists: 'Shopping lists',
    orders: 'Orders',
    store: 'Store',
    order_no: 'Order No',
    total: 'Total',
    payment_status: 'Payment status',
    order_type: 'Order type',
    status: 'Status',
    view_order: 'View order',
    order_again: 'Order again',
    deliver_to: 'Deliver to',
    order_detail: 'Order Detail',
    order_no_with_local: 'Order No: {{order_no}}',
    order_time: 'Time: {{time}}',
    table: 'Table: {{table_no}}',
    delivery_to: 'Delivery: {{to}}',
    items: 'Items',
    sub_total: 'Sub Total',
    vat_included: 'VAT Included',
    vat_excluded: 'VAT Excluded',
    payment_type: 'Payment Type',
    no_orders: 'No Orders',
  },
};

export default TRANSLATIONS_EN;
