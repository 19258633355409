import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CURRENT_USER } from '@/services/userService';
import { gql, useQuery } from '@apollo/client';
import PreLoader from '@/components/preLoader';
import AuthActions from '@/store/modules/auth/actions';
import MerchantActions from '@/store/modules/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { selectSelectedStore } from '@/store/modules/store';
import _ from 'lodash';

const userQueries = gql`
  ${CURRENT_USER}
`;

const AuthenticationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const selectedStore = useSelector(selectSelectedStore);
  const [cookies] = useCookies();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const tokenCookie = token;
    if (tokenCookie) {
      localStorage.setItem('token', tokenCookie);
      dispatch(AuthActions.setAccessToken(tokenCookie));
    }
    if (_.isEmpty(selectedStore)) {
      dispatch(
        MerchantActions.setSelectedStore({
          merchant_id: cookies?.bm_merchant_id,
          id: cookies?.bm_store_id,
        })
      );
    }
  }, []);

  const { loading: loadingCurrentUser } = useQuery(userQueries, {
    skip: _.isEmpty(token),
    onCompleted: (data) => {
      const currentUser = data.currentUser;
      if (currentUser) {
        dispatch(AuthActions.setCurrentUser(currentUser));
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  return <Box>{children}</Box>;
};

export default AuthenticationProvider;
