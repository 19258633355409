import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Grid, Link } from '@material-ui/core';
import TextField from '../../../components/textField';
import Button from '../../../components/button';

import useStyles from './styles';

const EmailForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { defaultEmail, onSignup, signupError } = props;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('signup.enter_valid_email'))
      .required(t('signup.email_rquired')),
    password: Yup.string()
      .required(t('signup.password_required'))
      .test(
        'uppercase-validation',
        t('signup.uppercase_required'),
        function (value) {
          return /[A-Z]/.test(value);
        }
      )
      .test(
        'lowercase-validation',
        t('signup.lowercase_required'),
        function (value) {
          return /[a-z]/.test(value);
        }
      )
      .test(
        'number-validation',
        t('signup.number_required'),
        function (value) {
          return /\d/.test(value);
        }
      )
      .test(
        'length-validation',
        t('signup.min_chars'),
        function (value) {
          return value?.length >= 8;
        }
      ),
    confirm_password: Yup.string().test(
      'passwords-match',
      t('signup.password_mismatch'),
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const onSubmit = (values) => {
    onSignup(values);
  };

  return (
    <Formik
      initialValues={{
        email: defaultEmail,
        password: '',
        confirm_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            autoFocus
            label={t('signup.email_address')}
            name="email"
            value={values.email}
            onChange={(e) => setFieldValue('email', e.target.value)}
            onBlur={() => setFieldTouched('email')}
            error={!!(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            className={classes.emailField}
          />
          <Grid
            container
            justify="space-between"
            className={classes.passwordsContainer}
          >
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.passwordWrapper}
            >
              <TextField
                isPassword={true}
                label={t('signup.password')}
                name="password"
                value={values.password}
                onChange={(e) =>
                  setFieldValue('password', e.target.value)
                }
                onBlur={() => setFieldTouched('password')}
                error={!!(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                className={classes.passwordField}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.confirmWrapper}
            >
              <TextField
                isPassword={true}
                label={t('signup.confirm_password')}
                name="confirm_password"
                value={values.confirm_password}
                onChange={(e) =>
                  setFieldValue('confirm_password', e.target.value)
                }
                onBlur={() => setFieldTouched('confirm_password')}
                error={
                  !!(
                    touched.confirm_password &&
                    errors.confirm_password
                  )
                }
                helperText={
                  touched.confirm_password && errors.confirm_password
                }
                className={classes.passwordField}
              />
            </Grid>
          </Grid>
          <div className={classes.bottomContainer}>
            <span className={classes.bottomText}>
              {t('signup.by_clicking_signup')}
            </span>
            <Link href="https://krlmedia.com/terms" underline="none">
              <span className={classes.bottomBlueText}>
                {t('signup.terms_policy')}{' '}
              </span>
            </Link>
            <span className={classes.bottomText}>
              {t('signup.you_receive_sms_opt')}
            </span>
          </div>
          <Grid
            container
            justify="center"
            className={classes.buttonWrapper}
          >
            <Button
              className={classes.signupButton}
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {}}
            >
              {t('signup.signup')}
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

EmailForm.propTypes = {
  onSignup: PropTypes.func,
  signupError: PropTypes.bool,
};

export default EmailForm;
