import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont10,
    },
    selectInput: {
      width: theme.spacing(15),
      marginRight: theme.spacing(10),
      '& .MuiSelect-select.MuiSelect-select': {
        fontSize: 12,
        fontWeight: 600,
      },
      '& .MuiInputBase-input': {
        padding: theme.spacing(1, 0, 0, 0),
      },
      '& .MuiSvgIcon-root': {
        marginTop: theme.spacing(1),
        fontSize: 20,
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    menuItem: {
      ...CommonFonts.darkFont14,
    },
  })
);

export default useStyles;
