import React from 'react';
import {
  FormControlLabel,
  Typography,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { capitalizeEachLetter } from '@/utils/stringUtil';

const SingleCheckboxInput = ({
  name,
  label,
  labelClass='',
  description,
  value,
  onChange,
  style,
  isShowToast = true,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleChange = async (valueCheck) => {
    try {
      await onChange(valueCheck);
      if (isShowToast) {
        enqueueSnackbar(
          `${capitalizeEachLetter(label)} ${t('settings.updated')}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.boxChecking} style={style}>
      <FormControlLabel
        className={labelClass || classes.titleText}
        control={
          <GreenCheckbox
            size="small"
            color="default"
            checked={value}
            onChange={(event) => handleChange(event.target.checked)}
            name={name}
            disabled={disabled}
          />
        }
        label={label}
      />
      {description && (
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      )}
    </div>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default SingleCheckboxInput;
