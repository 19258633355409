import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont18,
      fontWeight: '600',
    },
    productName: {
      ...CommonFonts.darkFont16,
      fontWeight: 600,
    },
    stock: {
      ...CommonFonts.darkFont12,
    },
    image: {
      objectFit: 'cover',
      height: theme.spacing(27),
      width: theme.spacing(42.5),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: Colors.GRAY_BORDER1_20,
      borderRadius: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(10, 0),
    },
    remove: {
      ...CommonFonts.grayFont12,
      cursor: 'pointer',
    },
    addShoppingList: {
      ...CommonFonts.grayFont12,
      cursor: 'pointer',
      marginLeft: theme.spacing(8),
    },
    currency: {
      ...CommonFonts.darkFont12,
      fontWeight: 600,
    },
    price: {
      ...CommonFonts.darkFont16,
      fontWeight: 600,
    },
    boxOption: {
      marginTop: theme.spacing(3),
    },
    boxAddRemove: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    boxPriceTax: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
      },
    },
  })
);

export default useStyles;
