import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { isEqual } from 'lodash';

const CategoryItem = ({
  data,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { name, id } = data || {};
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      onClick={() => setSelectedCategory(id)}
      className={classNames(
        classes.boxItem,
        isEqual(id, selectedCategory) && classes.boxItemSelected
      )}
    >
      {name}
    </Box>
  );
};

export default CategoryItem;
