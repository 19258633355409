import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import I18n from '@/i18n';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import TextField from '@/components/textField';
import Button from '@/components/button';
import { Grid, Typography } from '@material-ui/core';

import AuthActions, { AuthTypes } from '@/store/modules/auth/actions';
import { selectIsApiProgress } from '@/store/modules/apiLoopState';

import useStyles from '../styles';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(I18n.t('login_page.enter_valid_email'))
    .required(),
});

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    onChangeLoginLayout,
    onNext,
    checkUserError,
    initialEmail,
    setCurrentStep,
    centerAlign,
  } = props;

  return (
    <Formik
      initialValues={{ email: initialEmail }}
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={10}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <span className={classes.title}>
                {t('login_page.reset_password')}
              </span>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t('login_page.email_address')}
                name="email"
                value={values.email}
                onChange={(e) =>
                  setFieldValue('email', e.target.value)
                }
                onBlur={handleBlur}
                error={
                  !!(
                    (values.email && touched.email && errors.email) ||
                    checkUserError
                  )
                }
                helperText={checkUserError || errors.email}
                // className={classes.emailField}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justify="center"
                className={classes.buttonWrapper}
              >
                <Grid item xs={11} md={7}>
                  <Button
                    className={classes.nextButton}
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={() => {}}
                    // style={{ width: 280 }}
                  >
                    {t('button_group.next')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: 30 }}
              style={{ textAlign: centerAlign ? 'center' : 'left' }}
            >
              <Typography
                className={classes.createText}
                onClick={() => setCurrentStep('EMAIL')}
              >
                {t('login_page.back_to_login')}
              </Typography>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  isCreatingUser: selectIsApiProgress(state, AuthTypes.CREATE_USER),
  isSendingPhoneOtp: selectIsApiProgress(
    state,
    AuthTypes.SEND_PHONE_OTP
  ),
  isMobileLoggingin: selectIsApiProgress(
    state,
    AuthTypes.MOBILE_LOGIN
  ),
});

const actions = { ...AuthActions };

ForgotPassword.propTypes = {
  onChangeLoginLayout: PropTypes.func,
  onNext: PropTypes.func,
  checkUserError: PropTypes.string,
  initialEmail: PropTypes.string,
};

export default connect(
  mapStateToProps,
  actions
)(memo(ForgotPassword));
