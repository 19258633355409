import {
  Box,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import AppLayout from '@/components/appLayout';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { TOUCHPOINT_STOREFRONT_SETTING } from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import StoreInfoCard from './components/StoreInfo';
import OrderInfoCard from './components/OrderInfo';
import PaymentInfoCard from './components/PaymentInfo';
import CheckoutCard from './components/Checkout';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectedOrderType,
  selectShoppingCart,
  selectOrderTypes,
  selectNetPrice,
} from '@/store/modules/store';
import ProductsCheckout from './components/ProductsCheckout';
import MerchantActions from '@/store/modules/store/actions';
import { MERCHANT_SETTINGS } from '@/services/storeService';
import { GET_ORDER_TYPE_WORKFLOW } from '@/services/orderTypesWorkflowsService';

const storefrontQueries = gql`
  ${TOUCHPOINT_STOREFRONT_SETTING}
`;

const merchantSettingsQueries = gql`
  ${MERCHANT_SETTINGS}
`;

const orderTypeWorkflowQueries = gql`
  ${GET_ORDER_TYPE_WORKFLOW}
`;

const CartPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const { merchantId, storeId } = useParams();
  const history = useHistory();
  const selectOrderType = useSelector(selectedOrderType);
  const shoppingCarts = useSelector(selectShoppingCart);
  const orderTypes = useSelector(selectOrderTypes);
  const netPrice = useSelector(selectNetPrice);

  const userCart = shoppingCarts?.find(
    (cart) =>
      _.isEqual(cart.merchantId, merchantId) &&
      _.isEqual(cart.storeId, storeId)
  )?.cart;

  const { data, loading: loadingStorefront } = useQuery(
    storefrontQueries,
    {
      context: {
        headers: { storeId, merchantId },
      },
      fetchPolicy: 'no-cache',
    }
  );
  const currentStore = data?.store;

  const orderType = orderTypes.find(
    (item) => item.type === selectOrderType.type
  );

  const onBack = () =>
    history.push(`/store/${merchantId}/${storeId}`);

  const onDeleteCart = () => {
    onBack();
    dispatch(MerchantActions.clearCart({ merchantId, storeId }));
  };

  const loading = loadingStorefront;

  useEffect(() => {
    if (!userCart?.length) {
      history.goBack();
    }
  }, [userCart?.length]);

  return (
    <AppLayout header withFooter className={classes.appLayout}>
      <Box padding={5}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY={4}
        >
          <Button
            onClick={onBack}
            className={classes.backToButton}
            startIcon={<ChevronLeftIcon />}
          >
            {isMobile
              ? t('cart.back')
              : t('cart.back_to', { name: currentStore?.name })}
          </Button>
          <Box>
            <Button
              onClick={onDeleteCart}
              className={classes.backToButton}
            >
              {t('cart.delete_cart')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.boxInfo}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <StoreInfoCard currentStore={currentStore} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <OrderInfoCard />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <PaymentInfoCard />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <CheckoutCard
              currentStore={currentStore}
              userCart={userCart}
              isNetPrice={netPrice}
              orderTypes={orderType}
            />
          </Grid>
        </Grid>
      </Box>
      {!_.isEmpty(userCart) && (
        <ProductsCheckout
          userCart={userCart}
          isNetPrice={netPrice}
          localisation={currentStore?.localisation}
        />
      )}
      {loading && <PreLoader size={30} />}
    </AppLayout>
  );
};

export default CartPage;
