import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
    },
    boxInput: {
      marginTop: theme.spacing(10),
    },
    actionText: {
      ...CommonFonts.darkFont16,
      cursor: 'pointer',
    },
    deleteText: {
      color: Colors.RED,
    },
  })
);

export default useStyles;
