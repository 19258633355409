import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import OrderDateTimePicker from '../dateTimePicker';
import _ from 'lodash';
import { TIME_RANGE } from '../dateTimePicker/helper';
import moment from 'moment';
import { ORDER_TYPE } from '@/constants/store';
import { ASAP } from '@/constants/dateAndTime';

const TakeawayType = ({ takeawayData, updateOrderType }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { time } = takeawayData;
  const separateDateTime = time?.split(' ');
  const initialDate = separateDateTime[0];
  const initialTime = separateDateTime[1];

  useEffect(() => {
    if (
      initialDate &&
      initialTime &&
      new Date(initialDate).getTime() < new Date().getTime()
    ) {
      updateOrderType(ORDER_TYPE.TAKEAWAY, {
        ...takeawayData,
        time: `${moment(new Date()).format(
          'YYYY-MM-DD'
        )} ${initialTime}`,
      });
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className={classes.title}>
        {t('header.order_type.takeaway_time')}
      </Typography>

      <RadioGroup
        aria-label="takeaway-type-group"
        name="takeaway-type-group"
        onChange={(e) =>
          updateOrderType(ORDER_TYPE.TAKEAWAY, {
            time: e.target.value,
          })
        }
        className={classes.collectionTypeGroup}
      >
        <Box marginY={4} display="flex" flexDirection="column">
          <FormControlLabel
            classes={{
              label: classes.radioFormControlLabel,
            }}
            className={classes.radioFormControl}
            control={
              <CustomRadio
                checked={_.isEqual(time, ASAP)}
                value={ASAP}
                name={ASAP}
              />
            }
            label={t('header.order_type.today_asap')}
          />
          <FormControlLabel
            classes={{
              label: classes.radioFormControlLabel,
            }}
            className={classes.radioFormControl}
            control={
              <CustomRadio
                checked={!_.isEqual(time, ASAP)}
                value={`${moment(new Date()).format('YYYY-MM-DD')} ${
                  TIME_RANGE[0].value
                }`}
                name="selectDateTime"
              />
            }
            label={
              <OrderDateTimePicker
                defaultDate={initialDate}
                defaultTime={initialTime}
                onChange={(value) =>
                  updateOrderType(ORDER_TYPE.TAKEAWAY, {
                    time: value,
                  })
                }
              />
            }
          />
        </Box>
      </RadioGroup>
    </Box>
  );
};

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default TakeawayType;
