import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link } from '@material-ui/core';

import SwitchLanguage from '../switchLanguage';

import useStyles from './styles';

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        direction="row-reverse"
        className={classes.root}
      >
        <Grid item xs={12} className={classes.copywrightWrapper}>
          <span className={classes.footerText}>
            {t('footer.copywright')}
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
