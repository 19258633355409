import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';

import {
  Box,
  Dialog,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import OrderItem from '@/components/orderItem';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { GET_ORDER_BY_ID } from '@/services/orderService';
import {
  getDateFromOrder,
  getTableNoString,
} from '@/utils/orderUtil';
import { ORDER_TYPE } from '@/constants/store';
import { getFullAddressOfStore } from '@/utils/stringUtil';
import {
  getCurrencySymbol,
  getLocalisationVal,
} from '@/utils/localisationUtil';
import {
  getTotalPriceOfOrderItem,
  getTotalTax,
} from '@/utils/orderUtil';
import {
  selectLocalisation,
  selectNetPrice,
} from '@/store/modules/store/selectors';

const orderQuery = gql`
  ${GET_ORDER_BY_ID}
`;

const OrderUpdateModal = ({ order, isVisible, onCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { merchantId, storeId } = useParams();
  const classes = useStyles();
  const localisation = useSelector(selectLocalisation);
  const netPrice = useSelector(selectNetPrice);

  const [currentOrder, setCurrentOrder] = useState({
    ...order,
    items: order.items?.filter(
      (item) =>
        item.price.quantity -
          item.price.refunded -
          item.price.rejected >
        0
    ),
  });

  const subTotalPriceOfOrder = currentOrder?.items?.reduce(
    (total, item) => total + getTotalPriceOfOrderItem(item, netPrice),
    0
  );
  const totalPriceOfOrder = currentOrder?.items?.reduce(
    (total, item) => total + getTotalPriceOfOrderItem(item, false),
    0
  );

  const delivery_rate = currentOrder?.delivery_request?.rate || 0;
  const minimum_fee =
    currentOrder?.delivery_request?.minimum_fee || 0;
  const delivery_fee = (delivery_rate * totalPriceOfOrder) / 100;

  const small_order_fee =
    delivery_fee <= 0 ? 0 : minimum_fee - delivery_fee;

  const { loading: loadingOrders } = useQuery(orderQuery, {
    variables: {
      id: order.id,
    },
    onCompleted: (data) => {
      setCurrentOrder(data.orders?.[0]);
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
      open={isVisible}
      onClose={onCloseModal}
    >
      <IconButton
        onClick={onCloseModal}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Grid container justifyContent="center">
        <Typography className={classes.title}>
          {t('orderManagement.order_detail')}
        </Typography>
      </Grid>

      <div className={classes.mainContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs={6}>
            <Typography className={classes.label}>
              {t('orderManagement.order_no_with_local', {
                order_no: currentOrder.order_series
                  ? `${currentOrder.order_series} (${currentOrder.order_no})`
                  : currentOrder.order_no,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>
              {`${t('orderManagement.order_type')}: ${
                currentOrder.order_type?.name
              }`}
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            direction="row"
            style={{ marginTop: 20 }}
          >
            <Grid item xs={6}>
              <Typography className={classes.label}>
                {t('orderManagement.order_time', {
                  time: getDateFromOrder(
                    currentOrder,
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                })}
              </Typography>
            </Grid>
            {currentOrder.order_type.type ===
              ORDER_TYPE.DINING_IN && (
              <Grid item xs={6}>
                <Typography className={classes.label}>
                  {t('orderManagement.table', {
                    table_no: getTableNoString(
                      currentOrder.table?.tables
                    ),
                  })}
                </Typography>
              </Grid>
            )}
            {currentOrder.order_type.type === ORDER_TYPE.DELIVERY &&
              currentOrder?.delivery?.address && (
                <Grid item xs={6}>
                  <Typography className={classes.label}>
                    {t('orderManagement.delivery_to', {
                      to: getFullAddressOfStore(
                        currentOrder.delivery.address
                      ),
                    })}
                  </Typography>
                </Grid>
              )}
          </Grid>
        </Grid>

        <Typography
          className={classes.boldLabel}
          style={{ marginTop: 20 }}
        >
          {t('orderManagement.items')}
        </Typography>
        {currentOrder.items?.map((item, id) => (
          <Box key={`item-${id}`} className={classes.itemContainer}>
            <OrderItem
              item={item}
              promotions={[]}
              netPrice={netPrice}
              localisation={localisation}
            />
          </Box>
        ))}
        <Box
          display="flex"
          justifyContent={'space-between'}
          style={{ marginTop: 30 }}
        >
          <Typography className={classes.boldLabel}>
            {t('orderManagement.sub_total')}
          </Typography>
          <Typography className={classes.boldLabel}>
            {getCurrencySymbol(localisation)}
            {getLocalisationVal(
              localisation,
              subTotalPriceOfOrder + Math.max(0, small_order_fee)
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent={'space-between'}
          style={{ marginTop: 5 }}
        >
          <Typography className={classes.itemText}>
            {netPrice
              ? t('orderManagement.vat_excluded')
              : t('orderManagement.vat_included')}
          </Typography>
          <Typography className={classes.boldLabel}>
            {getCurrencySymbol(localisation)}
            {getLocalisationVal(
              localisation,
              getTotalTax(currentOrder?.items)
            )}
          </Typography>
        </Box>
        {Object.keys(
          groupBy(
            currentOrder.payments,
            (payment) => payment.payment_type.name
          )
        ).map((paymentName, id) => (
          <Box
            key={`payment-${id}`}
            display="flex"
            justifyContent={'space-between'}
            style={{ marginTop: 10 }}
          >
            <Typography className={classes.itemText}>
              {t('orderManagement.payment_type')}
            </Typography>
            <Typography className={classes.boldLabel}>
              {paymentName}
            </Typography>
          </Box>
        ))}
      </div>

      {loadingOrders && <PreLoader size={25} />}
    </Dialog>
  );
};

export default OrderUpdateModal;
