import React from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import SpecialitiesInput from './specialities';
import StoryInput from './story';
import OpeningTimeInput from './openingTime';
import { getFullAddressOfStore } from '@/utils/stringUtil';

const StoreAbout = ({
  currentStore,
  onRefetchStore,
  hasUpdateStore,
  loadingStorefront,
}) => {
  const { address } = currentStore || {};
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box padding={10}>
      <Grid container>
        <Grid item md={6}>
          <Grid container spacing={10}>
            <Grid item md={3} xs={12} className={classes.gridTitle}>
              <Typography className={classes.title}>
                {t('store.located_in')}
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Typography className={classes.time}>
                {getFullAddressOfStore(address)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item md={3} xs={12} className={classes.gridTitle}>
              <Typography className={classes.title}>
                {t('store.hours')}
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <OpeningTimeInput
                currentStore={currentStore}
                onRefetchStore={onRefetchStore}
                hasUpdateStore={hasUpdateStore}
              />
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item md={3} xs={12} className={classes.gridTitle}>
              <Typography className={classes.title}>
                {t('store.about')}
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <StoryInput
                currentStore={currentStore}
                onRefetchStore={onRefetchStore}
                hasUpdateStore={hasUpdateStore}
              />
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item md={3} xs={12} className={classes.gridTitle}>
              <Typography className={classes.title}>
                {t('store.specialities')}
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <SpecialitiesInput
                hideLabel
                currentStore={currentStore}
                onRefetchStore={onRefetchStore}
                loadingStorefront={loadingStorefront}
                hasUpdateStore={hasUpdateStore}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoreAbout;
