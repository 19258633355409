import { Box, Typography, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { GET_ORDERS } from '@/services/orderService';
import OrderCard from './orderCard';
import OrderUpdateModal from './orderUpdateModal';
import moment from 'moment';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton } from '@material-ui/lab';
import { selectCurrentUser } from '@/store/modules/auth/selectors';
import { selectSelectedStore } from '@/store/modules/store/selectors';
import MerchantActions from '@/store/modules/store/actions';
import NoOrders from './noOrders';
import { ONE_PAGE_LIMIT, ORDER_STATE } from '@/constants/order';
import { getOrderTime } from './helper';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const userOrdersQueries = gql`
  ${GET_ORDERS}
`;

const OrdersPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectSelectedStore);

  const [openOrderView, setOpenOrderView] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [isAllOrdersLoaded, setIsAllOrdersLoaded] = useState(false);

  const [getUserOrders, { loading: loadingOrders }] = useLazyQuery(
    userOrdersQueries,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.orders?.length < ONE_PAGE_LIMIT) {
          setIsAllOrdersLoaded(true);
        }
        let lastDate = _.isEmpty(orders)
          ? null
          : moment(_.last(orders).creation.time).format('YYYY-MM-DD');
        const newOrders = data?.orders
          ?.filter((order) => order.state !== ORDER_STATE.DRAFT)
          ?.map((order) => {
            if (
              moment(order.creation.time).format('YYYY-MM-DD') !==
              lastDate
            ) {
              lastDate = moment(order.creation.time).format(
                'YYYY-MM-DD'
              );
              return {
                ...order,
                showDate: true,
              };
            }
            return order;
          });
        setOrders(orders.concat(newOrders));
      },
      onError: () => {
        setIsAllOrdersLoaded(true);
      },
    }
  );

  useEffect(() => {
    getUserOrders({
      variables: {
        shopper_id: currentUser.id,
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT,
          orderBy: 'creation_time|DESC,created_at|DESC',
        },
      },
    });
  }, [currentPage]);

  const onViewOrder = (order) => {
    setSelectedOrder(order);
    setOpenOrderView(true);
  };

  const onRepeatOrder = (order) => {
    const merchantId = currentStore.merchant_id;
    const storeId = currentStore.id;
    dispatch(MerchantActions.clearCart({ merchantId, storeId }));

    const cart = order.items?.map((item) => ({
      id: uuid(),
      product_id: item.product?.id,
      product: item.product,
      name: item.product?.name,
      bar_code: item.bar_code,
      addons: item.addons?.map((addon) => ({
        addon_id: addon.addon_id,
        name: addon.name,
        quantity: addon.price?.quantity,
        group: {
          id: addon.group.id,
          name: addon.group.name,
        },
        price: {
          amount: addon.price?.amount,
          quantity: addon.price?.quantity,
          taxes: addon.price?.taxes?.map((tax) => ({
            id: tax.id,
            rate: tax.rate,
            name: tax.name,
            amount: tax.amount,
          })),
        },
      })),
      measure: item.product.measure,
      category: {
        id: item.category.id,
        name: item.category.name,
      },
      price: {
        amount: item.price.amount,
        quantity: item.price.quantity,
        taxes: item.price?.taxes?.map((tax) => ({
          id: tax.id,
          rate: tax.rate,
          name: tax.name,
          amount: tax.amount,
        })),
      },
    }));

    setTimeout(() => {
      dispatch(
        MerchantActions.addOrderToCart({
          storeId,
          merchantId,
          cart,
        })
      );
      history.push(`/cart/${merchantId}/${storeId}`);
    }, 100);
  };

  const handleLoadMore = () => {
    if (_.isEmpty(orders)) return;
    setCurrentPage(currentPage + 1);
  };

  return (
    <Box paddingX={5} paddingY={7}>
      <Typography className={classes.title}>
        {t('orderManagement.orders')}
      </Typography>
      <Button
        onClick={() => history.goBack()}
        className={classes.backToButton}
        startIcon={<ChevronLeftIcon />}
      >
        {t('cart.back')}
      </Button>
      <Box>
        {_.isEmpty(orders) && !loadingOrders ? (
          <NoOrders />
        ) : (
          <InfiniteScroll
            dataLength={orders.length}
            next={handleLoadMore}
            hasMore={!isAllOrdersLoaded}
            loader={
              loadingOrders && (
                <div>
                  <Skeleton
                    variant="rect"
                    height={150}
                    style={{ marginTop: 20 }}
                  />
                  <Skeleton
                    variant="rect"
                    height={150}
                    style={{ marginTop: 20 }}
                  />
                  <Skeleton
                    variant="rect"
                    height={150}
                    style={{ marginTop: 20 }}
                  />
                </div>
              )
            }
          >
            <Box marginTop={5}>
              {orders.map((order) => (
                <Box key={order.id}>
                  {!!order.showDate && (
                    <Typography className={classes.groupName}>
                      {getOrderTime(order)}
                    </Typography>
                  )}
                  <OrderCard
                    data={order}
                    onViewOrder={() => onViewOrder(order)}
                    onRepeatOrder={() => onRepeatOrder(order)}
                  />
                </Box>
              ))}
            </Box>
          </InfiniteScroll>
        )}
      </Box>

      {openOrderView && selectedOrder && (
        <OrderUpdateModal
          order={selectedOrder}
          isVisible={openOrderView}
          onCloseModal={() => {
            setOpenOrderView(false);
            setSelectedOrder(null);
          }}
        />
      )}
    </Box>
  );
};

export default OrdersPage;
