import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      padding: theme.spacing(10),
      backgroundColor: Colors.WHITE,
      height: 554,
    },
    root: {
      '&>div.MuiPaper-root.MuiPopover-paper': {
        marginTop: 22,
        marginLeft: -13,
        height: 554,
      },
    },
  })
);

export default useStyles;
