import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.WHITE,
    },
    productName: {
      ...CommonFonts.darkFont20,
      fontWeight: 600,
    },
    allergyTitle: {
      marginTop: theme.spacing(3),
      ...CommonFonts.darkFont18,
      fontWeight: 600,
    },
    imageGallery: {
      '& .image-gallery-image': {
        objectFit: 'cover',
      },
      '& .image-gallery-thumbnails-container': {
        textAlign: 'left',
      },
      '& .image-gallery-slide-wrapper': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.GRAY_BORDER1_20,
        borderRadius: theme.spacing(1),
      },
      '& .image-gallery-thumbnail .image-gallery-thumbnail-image': {
        objectFit: 'cover',
      },
    },
    priceTitle: {
      ...CommonFonts.darkFont16,
    },
    price: {
      ...CommonFonts.darkFont16,
      fontWeight: 600,
      marginLeft: theme.spacing(1.5),
    },
    inStock: {
      marginLeft: theme.spacing(7),
      ...CommonFonts.darkFont16,
    },
    quantityInput: {
      '& .MuiOutlinedInput-root': {
        width: theme.spacing(37),
        height: theme.spacing(9),
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiOutlinedInput-root': {
          width: theme.spacing(15),
          height: theme.spacing(8.25),
        },
      },
    },
    addUpdateCart: {
      textTransform: 'none',
      fontSize: 14,
      margin: theme.spacing(0, 3),
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    buyNow: {
      textTransform: 'none',
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    backText: {
      cursor: 'pointer',
      ...CommonFonts.darkFont14,
      fontWeight: 600,
      marginLeft: theme.spacing(3),
    },
    leftIcon: {
      cursor: 'pointer',
      borderRadius: '50%',
      fontSize: 18,
      padding: theme.spacing(0.75),
      backgroundColor: Colors.GRAY_LIGHT,
    },
  })
);

export default useStyles;
