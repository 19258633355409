import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  getCurrencySymbol,
  getLastAndFirstName,
} from '@/utils/stringUtil';
import { v4 as uuid } from 'uuid';
import { gql, useMutation } from '@apollo/client';
import { MUTATE_ADD_ORDERS } from '@/services/storeService';
import { randomString } from '@/utils/stringUtil';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';
import PreLoader from '@/components/preLoader';
import { useDispatch, useSelector } from 'react-redux';
import MerchantActions from '@/store/modules/store/actions';
import { selectUserIsLoggedIn } from '@/store/modules/auth/selectors';
import { selectedOrderType } from '@/store/modules/store/selectors';
import { totalPriceAmount, totalTaxesAmount } from './calculator';
import LoginModal from '@/components/header/authorize/loginModal';
import { selectCurrentUser } from '@/store/modules/auth';
import _, { isEmpty } from 'lodash';
import { ORDER_STATE } from '@/constants/order';
import moment from 'moment';
import { ORDER_TYPE } from '@/constants/store';
import { getTimeFromSlot } from '@/utils/momentUtil';
import { ASAP } from '@/constants/dateAndTime';
import { getLocalisationVal } from '@/utils/localisationUtil';

const CheckoutCard = ({ currentStore, userCart, orderTypes }) => {
  const { localisation } = currentStore || {};
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { merchantId, storeId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const currency = getCurrencySymbol(localisation?.currency_symbol);
  const isDecimal = localisation?.currency_decimal;
  const currentUser = useSelector(selectCurrentUser);
  const orderType = useSelector(selectedOrderType);

  useEffect(() => {
    if (isLoggedIn) {
      setOpenLoginModal(false);
    }
  }, [isLoggedIn]);

  const calculatingTotalPrice = () => {
    let price = 0;
    userCart?.map((itemCart) => {
      let addonsPrice = 0;
      if (itemCart.addons) {
        addonsPrice = itemCart?.addons?.reduce(
          (accAddon, argAddon) => {
            const addonTaxes = argAddon?.price?.taxes?.reduce(
              (accTax, argTax) => {
                return accTax + argTax.amount;
              },
              0
            );
            return (
              accAddon +
              (argAddon.price.amount + addonTaxes) *
                argAddon.price.quantity
            );
          },
          0
        );
      }

      const itemTaxesAmount =
        itemCart?.price?.taxes?.reduce(
          (accTax, argTax) => accTax + argTax.amount,
          0
        ) || 0;

      price =
        parseFloat(isDecimal ? price.toFixed(2) : price) +
        (itemCart.price.amount + addonsPrice + itemTaxesAmount) *
          itemCart.price.quantity;
    }, 0);

    return getLocalisationVal(localisation, price);
  };

  const [addOrders] = useMutation(
    gql`
      ${MUTATE_ADD_ORDERS}
    `
  );

  const onPay = async () => {
    if (isLoggedIn) {
      const deliveryAddress = currentUser?.addresses?.find(
        (address) => address.id === orderType[ORDER_TYPE.DELIVERY].to
      );
      let validationMsg = '';
      if (
        orderType.type === ORDER_TYPE.DELIVERY &&
        !deliveryAddress
      ) {
        validationMsg = t('cart.select_delivery_address');
      }
      if (
        orderType.type === ORDER_TYPE.DINING_IN &&
        isEmpty(orderType[ORDER_TYPE.DINING_IN]?.tables)
      ) {
        validationMsg = t('cart.select_table_no');
      }
      if (validationMsg) {
        enqueueSnackbar(validationMsg);
        return;
      }

      setIsLoading(true);

      const calculateTotalPriceAmount = totalPriceAmount(
        userCart,
        isDecimal
      );
      const calculateTotalTaxesAmount = totalTaxesAmount(
        userCart,
        isDecimal
      );

      const workflowStages = orderTypes?.workflow?.start;
      const orderTime = getTimeFromSlot(
        orderType[orderType.type].time
      );

      const collectionPara =
        orderType.type === ORDER_TYPE.COLLECTION
          ? {
              collection_time: orderTime,
            }
          : {};
      const tablePara =
        orderType.type === ORDER_TYPE.DINING_IN
          ? {
              table: {
                tables: orderType[ORDER_TYPE.DINING_IN]?.tables?.map(
                  (table) => ({
                    table_id: table.id,
                    table_no: table.table_no,
                  })
                ),
                no_of_guests: parseInt(
                  orderType[ORDER_TYPE.DINING_IN]?.no_of_guests
                ),
                booking_time: orderTime,
              },
            }
          : {};
      const deliveryPara =
        orderType.type === ORDER_TYPE.DELIVERY
          ? {
              delivery: {
                address: _.omit(deliveryAddress, [
                  'id',
                  '__typename',
                ]),
                name: getLastAndFirstName(
                  currentUser.first_name,
                  currentUser.last_name
                ),
                time: orderTime,
              },
            }
          : {};

      const input = {
        id: uuid(),
        order_id: uuid(),
        order_no: randomString(),
        order_type: {
          id: orderTypes.id,
          type: orderTypes.type,
          name: orderTypes.name,
        },
        state: ORDER_STATE.PENDING,
        statuses: {
          id: workflowStages?.id,
          name: workflowStages?.name,
          order: workflowStages?.order,
        },

        payment_request: {
          id: uuid(),
          type: 'mobile',
          payment_type_id: '1',
          amount: parseFloat(calculatingTotalPrice()),
          currency: localisation.currency_code,
          save: true,
        },
        client_engine: 'storefront',
        is_shopper: true,
        shopper: {
          id: currentUser.id,
          first_name: currentUser.first_name || '',
          last_name: currentUser.last_name || '',
        },
        creation: {
          time: moment().format(),
        },
        device: {
          device_id: 'storefront',
          device_name: 'storefront',
          device_type: 'Web',
        },
        items: userCart.map((item) => ({
          addons: item?.addons,
          item_id: item.id,
          product_id: item.product_id,
          name: item.name,
          bar_code: item.bar_code,
          category: {
            id: item.category.id,
            name: item.category.name,
          },
          price: {
            amount: item.price.amount,
            quantity: item.price.quantity,
            taxes: item.price.taxes,
            item_measure: {
              id: item.measure.id,
              type: item.measure.type,
            },
          },
        })),
        total_price: {
          amount: parseFloat(calculateTotalPriceAmount),
          taxes: calculateTotalTaxesAmount,
        },
        ...collectionPara,
        ...tablePara,
        ...deliveryPara,
      };
      console.log('input', input);

      const payload = input;

      await addOrders({
        variables: {
          input: payload,
        },
      })
        .then(() => {
          enqueueSnackbar(t('cart.order_added'));
          dispatch(
            MerchantActions.clearCart({ merchantId, storeId })
          );
          history.push(`/store/${merchantId}/${storeId}`);
        })
        .catch((error) => {
          const message = error?.message || error?.errors[0]?.message;
          enqueueSnackbar(message, { variant: 'error' });
        })
        .finally(() => setIsLoading(false));
    } else {
      setOpenLoginModal(true);
    }
  };

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>
        {t('cart.checkout')}
      </Typography>
      <Box
        marginTop={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.text}>
          {t('cart.total_due')}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography className={classes.currency}>
            {currency}
          </Typography>
          <Typography className={classes.amount}>
            {calculatingTotalPrice()}
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={onPay}
        variant="contained"
        className={classes.payButton}
        color="primary"
      >
        {t('cart.pay')}
      </Button>
      {isLoading && <PreLoader size={25} />}

      {openLoginModal && (
        <LoginModal
          isVisible={openLoginModal}
          onCloseModal={() => setOpenLoginModal(false)}
        />
      )}
    </Box>
  );
};

export default CheckoutCard;
