export const getSecondFromTimeString = (value) =>
  value
    ? Number(value.split(':')[0]) * 60 * 1000 +
      Number(value.split(':')[1]) * 1000
    : 0;

export const dateToNumber = (date) => {
  const d = date.split('/');
  return Number(d[2] + d[1] + d[0]);
};
