import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 70,
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        height: 60,
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    workBoardWrapper: {
      marginLeft: theme.spacing(4),
    },
    image: {
      cursor: 'pointer',
    },
    menuWrapper: {
      padding: theme.spacing(3),
    },
    drawerContent: {
      width: 435,
    },
    backWrapper: {
      padding: theme.spacing(6, 6, 10, 6),
    },
    menuIcon: {
      color: 'black',
    },
    menuList: {
      padding: theme.spacing(0, 6, 8, 6),
    },
    list: {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    nameMenuList: {
      marginBottom: theme.spacing(5),
      ...CommonFonts.darkFont20,
      letterSpacing: '-0.35px',
      fontWeight: 600,
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
  })
);

export default useStyles;
