import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    radioFormControl: {
      margin: theme.spacing(0, 5),
    },
    radioFormControlLabel: {
      ...CommonFonts.darkFont16,
    },
    title: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
      textAlign: 'center',
    },
    collectionTypeGroup: {
      width: '100%',
    },
    addNewAddress: {
      fontSize: 14,
      textTransform: 'none',
      margin: theme.spacing(0, 5),
    },
  })
);

export default useStyles;
