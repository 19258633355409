import React, { useState } from 'react';

import {
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import Button from '@/components/button';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { MUTATE_DELETE_ACCOUNT } from '@/services/userService';
import AuthActions from '@/store/modules/auth/actions';
import { useDispatch } from 'react-redux';

const DeleteAccountDialog = ({ isVisible, onCloseDialog }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseModal = () => {
    onCloseDialog();
    handleReset();
  };

  const validationSchema = Yup.object().shape({
    deleteAccount: Yup.string().test(
      'type-validation',
      function (value) {
        return _.isEqual(
          value,
          t('deleteAccount.title').toUpperCase()
        );
      }
    ),
  });

  const {
    handleSubmit,
    handleReset,
    handleChange,
    handleBlur,
    values,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      deleteAccount: '',
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        setIsLoading(true);
        await mutateDeleteUser();
        dispatch(AuthActions.logout());
        onCloseModal();
        enqueueSnackbar(t('deleteAccount.account_deleted'));
      } catch (error) {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const [mutateDeleteUser] = useMutation(
    gql`
      ${MUTATE_DELETE_ACCOUNT}
    `
  );

  const isDisable = !isValid || _.isEmpty(values?.deleteAccount);

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={isLoading ? () => {} : onCloseModal}
      aria-labelledby={`{delete-account-dialog-title}`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`{delete-account-dialog-title}`}
        onClose={onCloseModal}
      />
      <DialogContent>
        <Box padding={10} paddingTop={0}>
          <Typography className={classes.title}>
            {t('deleteAccount.title')}
          </Typography>
          <Box marginBottom={12}>
            <Typography className={classes.description}>
              {t('deleteAccount.description')}
            </Typography>
            <Typography className={classes.description}>
              {t('deleteAccount.action_description')}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              placeholder={t('deleteAccount.title').toUpperCase()}
              name="deleteAccount"
              value={values.deleteAccount}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.inputText}
            />
            <Box paddingX={isMobile ? 0 : 20}>
              <Button
                type="submit"
                className={classes.actionButton}
                variant="contained"
                disabled={isDisable}
              >
                {t('deleteAccount.title')}
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
      {isLoading && <PreLoader size={25} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

export default DeleteAccountDialog;
