import moment from 'moment';
import { v4 as getUUID } from 'uuid';
import { omit, isEmpty, sumBy, isEqual } from 'lodash';
import { PROMOTION_TYPE } from '@/constants/promotion';
import {
  calculateProductPrice,
  getPrice,
  calcPriceFromGrossPrice,
} from './priceUtil';
import { getProductTaxNames, getOrderItemTaxNames } from './taxUtil';
import {
  getCurrencySymbol,
  getLocalisationVal,
} from './localisationUtil';
import { roundToFour } from './mathUtil';

const DEFAULT_PROMOTION_VALID_FROM = '1970-01-01T00:00:00.000Z';
const DEFAULT_PROMOTION_VALID_TO = '2050-12-31T23:59:59.000Z';

export const isVoucherOrPersonalPromotion = (promotion) => {
  return promotion.is_voucher || !isEmpty(promotion.audience_id);
};

export const isPromotionAvailable = (promotion) => {
  if (isVoucherOrPersonalPromotion(promotion)) {
    const expired_time =
      promotion.voucher?.expired_time || DEFAULT_PROMOTION_VALID_TO;
    return moment() < moment(expired_time);
  }
  const valid_from =
    promotion.valid_from || DEFAULT_PROMOTION_VALID_FROM;
  const valid_to = promotion.valid_to || DEFAULT_PROMOTION_VALID_TO;

  const today = moment().utc();
  return (
    today.isBetween(valid_from, valid_to) &&
    !!promotion.active &&
    promotion.type !== PROMOTION_TYPE.MEAL_DEAL // For now, we ignore meal-deal promotions
  );
};

export const getDiscountOfProduct = (
  product,
  promotion,
  pricingType,
  netPrice
) => {
  if (!promotion || !product) return 0;
  if (
    promotion.type === PROMOTION_TYPE.MANUAL ||
    promotion.type === PROMOTION_TYPE.REDUCTION
  ) {
    return (
      (calculateProductPrice(product, pricingType, {
        includeTax: !netPrice,
      }) *
        (promotion.promo_value || 0)) /
      100
    );
  } else if (promotion.type === PROMOTION_TYPE.BOGOF) {
    return (
      calculateProductPrice(product, pricingType, {
        includeTax: !netPrice,
      }) / promotion.item_numbers
    );
  } else if (promotion.type === PROMOTION_TYPE.MULTI_BUY) {
    let totalDiscount =
      calculateProductPrice(product, pricingType, {
        includeTax: !netPrice,
      }) *
        promotion.item_numbers -
      promotion.fixed_price;

    return Math.max(totalDiscount / promotion.item_numbers, 0);
  }
};

export const getDiscountOfOrderItem = (orderItem, netPrice) => {
  if (isEmpty(orderItem.promotions)) {
    return 0;
  }
  let promotionNetAmount = orderItem.promotions[0].amount * -1;
  let promotionTaxes = orderItem.promotions[0].taxes;
  let promotionTaxAmount =
    sumBy(promotionTaxes, (tax) => tax.amount) * -1;
  if (netPrice) {
    return promotionNetAmount;
  } else {
    return promotionNetAmount + promotionTaxAmount;
  }
};

export const getPromotionAppliedToProduct = (
  product,
  promotions,
  pricingType,
  netPrice
) => {
  const appliedPromotions =
    promotions.filter((promotion) => {
      const products = promotion.options?.[0]?.products || [];
      const categories = promotion.options?.[0]?.categories || [];
      if (!!products?.find((item) => item.id === product.id)) {
        return true;
      }
      if (
        !!categories?.find(
          (item) => item.id === product?.category?.id
        )
      ) {
        return true;
      }
      return false;
    }) || [];
  let appliedPromotion = null;
  let maxDiscount = 0;
  for (let promotion of appliedPromotions) {
    let discount = getDiscountOfProduct(
      product,
      promotion,
      pricingType,
      netPrice
    );
    if (discount > maxDiscount) {
      maxDiscount = discount;
      appliedPromotion = promotion;
    }
  }
  return appliedPromotion;
};

export const getTextOfAppliedPromotionToProduct = (
  product,
  promotion,
  t,
  pricingType,
  netPrice,
  localisation
) => {
  if (!promotion) return '';
  if (isVoucherOrPersonalPromotion(promotion))
    return promotion.promo_name;
  let text = '';
  if (promotion.type === PROMOTION_TYPE.MULTI_BUY) {
    const currency = getCurrencySymbol(localisation);
    const localisedPrice = getLocalisationVal(
      localisation,
      promotion.fixed_price
    );
    text = t('landing_screen.multi_buy_promotion_name', {
      item_numbers: promotion.item_numbers,
      price: `${currency}${localisedPrice}`,
    });
  } else if (promotion.type === PROMOTION_TYPE.BOGOF) {
    text = t('landing_screen.bogof_promotion_name', {
      total_numbers: promotion.item_numbers,
      item_numbers: promotion.item_numbers - 1,
    });
  } else if (
    promotion.type === PROMOTION_TYPE.MANUAL ||
    promotion.type === PROMOTION_TYPE.REDUCTION
  ) {
    const currency = getCurrencySymbol(localisation);
    const price =
      (calculateProductPrice(product, pricingType, {
        includeTax: !netPrice,
      }) *
        (100 - promotion.promo_value)) /
      100;
    const localisedPrice = getLocalisationVal(localisation, price);
    text = t('landing_screen.manual_promotion_name', {
      price: `${currency}${localisedPrice}`,
    });
  }
  if (netPrice) {
    text += getProductTaxNames(
      product.prices[0].price_infos,
      netPrice,
      pricingType
    );
  }
  return text;
};

export const getTextOfAppliedPromotionToOrderItem = (
  orderItem,
  promotion,
  promotions,
  t,
  netPrice,
  localisation
) => {
  const originalPromotion = promotions.find(
    (item) => item.id === promotion.id
  );
  if (!originalPromotion) return promotion.name;
  if (isVoucherOrPersonalPromotion(promotion))
    return promotion.promo_name;
  let text = '';
  if (originalPromotion.type === PROMOTION_TYPE.MULTI_BUY) {
    const currency = getCurrencySymbol(localisation);
    const localisedPrice = getLocalisationVal(
      localisation,
      originalPromotion.fixed_price
    );
    text = t('store_profile_screen.multi_buy_promotion_name', {
      item_numbers: originalPromotion.item_numbers,
      price: `${currency}${localisedPrice}`,
    });
  } else if (originalPromotion.type === PROMOTION_TYPE.BOGOF) {
    text = t('store_profile_screen.bogof_promotion_name', {
      total_numbers: originalPromotion.item_numbers,
      item_numbers: originalPromotion.item_numbers - 1,
    });
  } else if (
    originalPromotion.type === PROMOTION_TYPE.MANUAL ||
    originalPromotion.type === PROMOTION_TYPE.REDUCTION
  ) {
    const currency = getCurrencySymbol(localisation);
    const price =
      (getPrice(orderItem.price, netPrice) *
        (100 - originalPromotion.promo_value)) /
      100;
    const localisedPrice = getLocalisationVal(localisation, price);
    text = t('store_profile_screen.manual_promotion_name', {
      price: `${currency}${localisedPrice}`,
    });
  }
  if (netPrice) {
    text += getOrderItemTaxNames(orderItem, netPrice);
  }
  return text;
};

export const applyPromotionToCartItems = (
  cartItems,
  promotions,
  netPrice,
  pricingType
) => {
  let consolidatedCartItemsWithoutPromotion = [];
  for (let item of cartItems) {
    if (!!item.consolidateCartId) {
      if (
        consolidatedCartItemsWithoutPromotion.find(
          (cartItemWithoutPromotion) =>
            cartItemWithoutPromotion.cartId === item.consolidateCartId
        )
      ) {
        continue;
      } else {
        consolidatedCartItemsWithoutPromotion.push(
          omit(item, 'promotions')
        );
      }
    } else {
      const consolidatedCartItem = {
        ...item,
        quantity:
          item.quantity +
          (cartItems.find(
            (cartItem) =>
              cartItem.consolidateCartId === item.cartId &&
              item.cartId
          )?.quantity || 0),
      };
      consolidatedCartItemsWithoutPromotion.push(
        omit(consolidatedCartItem, 'promotions')
      );
    }
  }
  let cartItemsWithPromotions = [];
  let promotionProducts = [];
  let promotionCategories = [];
  const promotionsHavingOptions = promotions?.filter(
    (item) => !isEmpty(item.options)
  );
  for (let cartItem of consolidatedCartItemsWithoutPromotion) {
    let totalDiscount = 0;
    let maxTotalDiscount = 0;
    let appliedPromotion = null;
    let quantityWithPromotion = 0;
    let quantityWithoutPromotion = 0;
    for (let promotion of promotionsHavingOptions) {
      totalDiscount = 0;
      promotionProducts = promotion.options[0]?.products;
      promotionCategories = promotion.options[0]?.categories;
      if (
        !!promotionProducts?.find(
          (product) => cartItem.id === product.id
        ) ||
        !!promotionCategories?.find(
          (category) => cartItem.category.id === category.id
        )
      ) {
        const discount = getDiscountOfProduct(
          cartItem,
          promotion,
          pricingType,
          netPrice
        );
        if (
          promotion.type === PROMOTION_TYPE.MANUAL ||
          promotion.type === PROMOTION_TYPE.REDUCTION
        ) {
          totalDiscount = discount * cartItem.quantity;
        } else if (
          promotion.type === PROMOTION_TYPE.BOGOF ||
          promotion.type === PROMOTION_TYPE.MULTI_BUY
        ) {
          if (cartItem.quantity >= promotion.item_numbers) {
            totalDiscount =
              discount *
              parseInt(cartItem.quantity / promotion.item_numbers) *
              promotion.item_numbers;
          }
        }
        if (totalDiscount > maxTotalDiscount) {
          maxTotalDiscount = totalDiscount;
          appliedPromotion = promotion;
          if (
            promotion.type === PROMOTION_TYPE.MANUAL ||
            promotion.type === PROMOTION_TYPE.REDUCTION
          ) {
            quantityWithPromotion = cartItem.quantity;
            quantityWithoutPromotion = 0;
          } else {
            quantityWithoutPromotion =
              cartItem.quantity % promotion.item_numbers;
            quantityWithPromotion =
              cartItem.quantity - quantityWithoutPromotion;
          }
        }
      }
    }
    if (appliedPromotion) {
      cartItemsWithPromotions.push({
        ...cartItem,
        quantity: quantityWithPromotion,
        promotions: [appliedPromotion],
      });
      if (quantityWithoutPromotion > 0) {
        cartItemsWithPromotions.push({
          ...cartItem,
          cartId: getUUID(),
          consolidateCartId: cartItem.cartId,
          quantity: quantityWithoutPromotion,
        });
      }
    } else {
      cartItemsWithPromotions.push(cartItem);
    }
  }
  return cartItemsWithPromotions;
};

export const getSeparatedVouchersFromVoucher = (promotion) => {
  if (isEmpty(promotion.vouchers)) {
    return [promotion];
  }
  return promotion.vouchers.map((voucher) => ({
    ...promotion,
    voucher,
  }));
};

export const separateVouchers = (promotions) => {
  if (isEmpty(promotions)) {
    return [];
  }
  return promotions.reduce(
    (total, promotion) => [
      ...total,
      ...getSeparatedVouchersFromVoucher(promotion),
    ],
    []
  );
};

export const getDiscountOfTempOrderItem = (
  orderItem,
  promotion,
  netPrice
) => {
  if (!promotion || !orderItem) return 0;
  if (
    promotion.type === PROMOTION_TYPE.MANUAL ||
    promotion.type === PROMOTION_TYPE.REDUCTION
  ) {
    return (
      (getPrice(orderItem.price, netPrice) *
        (promotion.promo_value || 0)) /
      100
    );
  } else if (promotion.type === PROMOTION_TYPE.BOGOF) {
    return (
      getPrice(orderItem.price, netPrice) / promotion.item_numbers
    );
  } else if (promotion.type === PROMOTION_TYPE.MULTI_BUY) {
    let totalDiscount =
      getPrice(orderItem.price, netPrice) * promotion.item_numbers -
      promotion.fixed_price;

    return Math.max(totalDiscount / promotion.item_numbers, 0);
  }
};

export const getOrderItemWithTransformedPromotions = (
  orderItem,
  netPrice
) => {
  if (isEmpty(orderItem.promotions)) {
    return orderItem;
  }
  let priceTaxes = orderItem.price.taxes;
  if (netPrice) {
    let itemNetPrice = roundToFour(
      orderItem.price.amount -
        getDiscountOfTempOrderItem(
          orderItem,
          orderItem.promotions[0],
          netPrice
        )
    );
    priceTaxes = priceTaxes.map((tax) => ({
      ...tax,
      amount:
        tax.rate > 0
          ? roundToFour((itemNetPrice * tax.rate) / 100)
          : 0,
    }));
  }
  return {
    ...orderItem,
    price: {
      ...orderItem.price,
      taxes: priceTaxes,
    },
    promotions: orderItem.promotions.map((promotion) => {
      let promotionNetPrice = roundToFour(
        getDiscountOfTempOrderItem(orderItem, promotion, netPrice)
      );
      if (!netPrice) {
        promotionNetPrice = calcPriceFromGrossPrice(
          promotionNetPrice,
          priceTaxes
        );
      }
      return {
        id: promotion.id,
        type: promotion.type,
        name: promotion.promo_name,
        amount: roundToFour(promotionNetPrice) * -1,
        taxes: priceTaxes.map((tax) => {
          const tax_amount =
            tax.rate > 0
              ? roundToFour((promotionNetPrice * tax.rate) / 100) * -1
              : 0;
          return {
            ...tax,
            amount: !netPrice ? tax_amount : 0,
          };
        }),
        promo_no: promotion.promo_no,
        audience_id: promotion.audience_id,
        user_id: promotion.user_id,
        item_numbers: promotion.item_numbers,
        promo_name: promotion.promo_name,
        valid_from: promotion.valid_from,
        valid_to: promotion.valid_to,
        start_time: promotion.start_time,
        end_time: promotion.end_time,
        promo_value: promotion.promo_value,
        trigger_amount: promotion.trigger_amount,
        fixed_price: promotion.fixed_price,
        mix_match: promotion.mix_match,
        period: promotion.period,
        is_voucher: promotion.is_voucher,
        voucher_trigger_amount: promotion.voucher_trigger_amount,
        voucher: promotion.voucher,
        options: promotion.options?.map((option) => ({
          products: option?.products?.map((product) => ({
            id: product.id,
            name: product.name,
          })),
          categories: option?.categories?.map((category) => ({
            id: category.id,
            name: category.name,
          })),
        })),
      };
    }),
  };
};

export const applyPromotionToOrderItems = (
  orderItems,
  promotions,
  netPrice
) => {
  let orderItemsWithoutPromotion = orderItems.map((item) => {
    const itemWithNewTaxes = {
      ...item,
      price: {
        ...item.price,
        taxes: item.price.taxes.map((tax) => ({
          ...tax,
          amount: roundToFour(
            (item.price.amount * Math.max(0, tax.rate)) / 100
          ),
        })),
      },
    };
    return omit(itemWithNewTaxes, 'promotions');
  });
  let consolidatedOrderItemsWithoutPromotion = [];
  let indexFlagObj = {};
  let ithItemWithoutPrice, jthItemWithoutPrice, ithItemPriceQuantity;
  for (let i = 0; i < orderItemsWithoutPromotion.length; i++) {
    if (indexFlagObj[i]) continue;
    ithItemWithoutPrice = omit(orderItemsWithoutPromotion[i], [
      'price',
      'id',
    ]);
    ithItemPriceQuantity =
      orderItemsWithoutPromotion[i].price.quantity;
    for (let j = i + 1; j < orderItemsWithoutPromotion.length; j++) {
      jthItemWithoutPrice = omit(orderItemsWithoutPromotion[j], [
        'price',
        'id',
      ]);
      if (isEqual(ithItemWithoutPrice, jthItemWithoutPrice)) {
        ithItemPriceQuantity +=
          orderItemsWithoutPromotion[j].price.quantity;
        indexFlagObj[j] = true;
      }
    }
    consolidatedOrderItemsWithoutPromotion.push({
      ...orderItemsWithoutPromotion[i],
      price: {
        ...orderItemsWithoutPromotion[i].price,
        quantity: ithItemPriceQuantity,
      },
    });
  }
  let orderItemsWithPromotions = [];
  let promotionProducts = [];
  let promotionCategories = [];
  const promotionsHavingOptions = promotions?.filter(
    (item) => !isEmpty(item.options)
  );
  for (let orderItem of consolidatedOrderItemsWithoutPromotion) {
    let totalDiscount = 0;
    let maxTotalDiscount = 0;
    let appliedPromotion = null;
    let quantityWithPromotion = 0;
    let quantityWithoutPromotion = 0;
    for (let promotion of promotionsHavingOptions) {
      totalDiscount = 0;
      promotionProducts = promotion.options[0]?.products;
      promotionCategories = promotion.options[0]?.categories;
      if (
        !!promotionProducts?.find(
          (product) => orderItem.product_id === product.id
        ) ||
        !!promotionCategories?.find(
          (category) => orderItem.category.id === category.id
        )
      ) {
        const discount = getDiscountOfTempOrderItem(
          orderItem,
          promotion,
          netPrice
        );
        if (
          promotion.type === PROMOTION_TYPE.MANUAL ||
          promotion.type === PROMOTION_TYPE.REDUCTION
        ) {
          totalDiscount = discount * orderItem.price.quantity;
        } else if (
          promotion.type === PROMOTION_TYPE.BOGOF ||
          promotion.type === PROMOTION_TYPE.MULTI_BUY
        ) {
          if (orderItem.price.quantity >= promotion.item_numbers) {
            totalDiscount =
              discount *
              parseInt(
                orderItem.price.quantity / promotion.item_numbers
              ) *
              promotion.item_numbers;
          }
        }
        if (totalDiscount > maxTotalDiscount) {
          maxTotalDiscount = totalDiscount;
          appliedPromotion = promotion;
          if (
            promotion.type === PROMOTION_TYPE.MANUAL ||
            promotion.type === PROMOTION_TYPE.REDUCTION
          ) {
            quantityWithPromotion = orderItem.price.quantity;
            quantityWithoutPromotion = 0;
          } else {
            quantityWithoutPromotion =
              orderItem.price.quantity % promotion.item_numbers;
            quantityWithPromotion =
              orderItem.price.quantity - quantityWithoutPromotion;
          }
        }
      }
    }
    if (appliedPromotion) {
      orderItemsWithPromotions.push({
        ...orderItem,
        price: {
          ...orderItem.price,
          quantity: quantityWithPromotion,
        },
        promotions: [appliedPromotion],
      });
      if (quantityWithoutPromotion > 0) {
        orderItemsWithPromotions.push({
          ...orderItem,
          id: getUUID(),
          price: {
            ...orderItem.price,
            quantity: quantityWithoutPromotion,
          },
        });
      }
    } else {
      orderItemsWithPromotions.push(orderItem);
    }
  }

  orderItemsWithPromotions = orderItemsWithPromotions.map(
    (orderItem) => {
      return getOrderItemWithTransformedPromotions(
        orderItem,
        netPrice
      );
    }
  );
  return orderItemsWithPromotions;
};
