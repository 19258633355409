import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import _ from 'lodash';
import ProductGroupAddonOption from '../productGroupAddonOption';

const ProductGroupAddonItem = ({
  data,
  addonIndex,
  updateGroupAddonData,
  pricingType,
  productTaxes,
  isNetPrice,
  updateAddonAllowFree,
}) => {
  const classes = useStyles();
  const {
    group: groupName,
    description,
    options,
    allow_free,
    multi_selection,
    mandatoryError,
  } = data || {};

  const isAllowSelectOne =
    !multi_selection &&
    !_.isEmpty(options.filter((option) => option.quantity > 0));

  return (
    <Box paddingY={3} marginBottom={8}>
      <Box display="flex" marginBottom={2}>
        <Typography className={classes.groupName}>
          {groupName}
        </Typography>
        {mandatoryError && (
          <Typography className={classes.errorText}>
            {mandatoryError}
          </Typography>
        )}
      </Box>
      <Typography className={classes.description}>
        {description}
      </Typography>
      <Grid container spacing={10}>
        {options?.map((option, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ paddingBottom: 0 }}
            key={option.id}
          >
            <ProductGroupAddonOption
              addonIndex={addonIndex}
              optionIndex={index}
              data={option}
              updateGroupAddonData={updateGroupAddonData}
              pricingType={pricingType}
              productTaxes={productTaxes}
              isNetPrice={isNetPrice}
              allowFree={allow_free}
              isAllowSelectOne={isAllowSelectOne}
              updateAddonAllowFree={updateAddonAllowFree}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductGroupAddonItem;
