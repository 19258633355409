import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
    },
    tableText: {
      ...CommonFonts.darkFont16,
    },
    value: {
      ...CommonFonts.darkFont14,
      marginRight: theme.spacing(2),
    },
    createIcon: {
      fontSize: 20,
      color: '#bec7cc',
    },
    iconButton: {
      marginLeft: theme.spacing(4),
      padding: 0,
    },
    descriptionDialog: {
      ...CommonFonts.darkFont16,
      textAlign: 'center',
      paddingBottom: theme.spacing(7),
    },
    searchIcon: {
      position: 'absolute',
      left: 0,
      top: 16,
      color: Colors.GRAY_BORDER3,
    },
    addText: {
      ...CommonFonts.blueFont14,
      fontWeight: 600,
      cursor: 'pointer',
    },
    openingTime: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
      color: '#25ad2f',
      padding: theme.spacing(0, 2),
    },
    closedTime: {
      ...CommonFonts.darkFont14,
      color: Colors.RED,
      fontWeight: 600,
      padding: theme.spacing(0, 2),
    },
    addHours: {
      ...CommonFonts.blueFont14,
      cursor: 'pointer',
    },
    removeHours: {
      ...CommonFonts.grayFont14,
      cursor: 'pointer',
    },
    selectTimeInput: {
      width: theme.spacing(12),
      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    timeItem: {
      ...CommonFonts.darkFont16,
      fontFamily: 'Montserrat',
    },
    iconSelect: {
      display: 'none',
    },
    addSeasonal: {
      padding: theme.spacing(4, 3),
      ...CommonFonts.blueFont14,
      fontWeight: 600,
      cursor: 'pointer',
    },
  })
);

export default useStyles;
