import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayFont24,
      fontWeight: '600',
      textAlign: 'center',
      marginBottom: theme.spacing(10),
    },
    description: {
      textAlign: 'center',
      ...CommonFonts.darkFont18,
    },
    actionButton: {
      backgroundColor: Colors.RED,
      marginTop: theme.spacing(12),
      padding: theme.spacing(4),
      ...CommonFonts.whiteFont14,
      '&:active': {
        backgroundColor: '#DE3C3C',
      },
      '&:hover': {
        backgroundColor: Colors.RED,
      },
      '&:disabled': {
        backgroundColor: Colors.RED,
        color: Colors.WHITE,
        opacity: 0.5,
      },
    },
    inputText: {
      width: '100%',
      '& .MuiOutlinedInput-input': {
        ...CommonFonts.darkFont18,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: Colors.GRAY_BORDER1,
        },
        '&:hover fieldset': {
          borderColor: Colors.GRAY_BORDER1,
        },
        '&.Mui-focused fieldset': {
          borderColor: Colors.GRAY_BORDER1,
        },
      },
      '& label.Mui-focused': {
        color: Colors.GRAY_BORDER1,
      },
    },
  })
);

export default useStyles;
