import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
    },
    createIcon: {
      fontSize: 20,
      color: '#bec7cc',
    },
    iconButton: {
      marginLeft: theme.spacing(10),
      padding: 0,
    },
    time: {
      ...CommonFonts.darkFont14,
    },
    gridTitle: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '0 !important',
      },
    },
  })
);

export default useStyles;
