import * as _ from 'lodash';
import { roundToFour } from './mathUtil';

export const getProductTax = (price_infos, pricingType) => {
  var total_tax = 0;

  let price_info = price_infos?.find(
    (info) => info.price_type.type === pricingType
  );
  if (!price_info) {
    price_info = price_infos[0];
  }
  price_info?.taxes?.forEach(
    (tax) =>
      (total_tax +=
        (((tax?.rate || 0) <= 0 ? 0 : tax.rate) *
          (price_info.price || 0)) /
        100)
  );
  return total_tax;
};

export const getProductTaxNames = (
  price_infos,
  netPrice,
  pricingType
) => {
  if (!netPrice) return '';

  var names = [];
  if (pricingType) {
    let price_info = price_infos.filter(
      (info) => info.price_type.type === pricingType
    );
    if (!_.isEmpty(price_info)) {
      price_info[0].taxes.forEach(
        (tax) => tax.name !== '' && names.push(tax.name)
      );
    }
  }
  let rlt = names.join(', ');
  return rlt !== '' ? ` + ${rlt}` : rlt;
};

export const getOrderItemTaxNames = (orderItem, netPrice) => {
  if (!netPrice) return '';

  var names = orderItem.price.taxes?.map((tax) => tax.name);

  let rlt = names?.join(', ');
  return rlt !== '' ? ` + ${rlt}` : rlt;
};

export const getAddonOptionTax = (
  price_infos,
  pricingType,
  fixed_price
) => {
  var total_tax = 0;
  if (pricingType) {
    let price_info = price_infos.filter(
      (info) => info.price_type.type === pricingType
    );
    if (!_.isEmpty(price_info)) {
      price_info[0].taxes.forEach(
        (tax) =>
          (total_tax +=
            (((tax?.rate || 0) <= 0 ? 0 : tax.rate) *
              (fixed_price || 0)) /
            100)
      );
    } else total_tax = 0;
  } else {
    total_tax = 0;
  }
  return total_tax;
};

export const getAddonOptionTaxArray = (
  price_infos,
  pricingType,
  fixed_price
) => {
  let price_info = [];
  if (pricingType) {
    price_info = price_infos.filter(
      (info) => info.price_type.type === pricingType
    );
    if (!_.isEmpty(price_info)) {
      price_info = price_info[0];
    }
  }
  if (_.isEmpty(price_info)) price_info = price_infos[0];

  let taxes = [];
  price_info?.taxes?.forEach((tax) =>
    taxes.push({
      ...tax,
      amount:
        tax.rate < 0
          ? 0
          : roundToFour((fixed_price * tax.rate) / 100),
    })
  );

  return taxes;
};

export const getProductTaxArray = (price_infos, pricingType) => {
  let price_info = [];
  if (pricingType) {
    price_info = price_infos.filter(
      (info) => info.price_type.type === pricingType
    );
    if (!_.isEmpty(price_info)) {
      price_info = price_info[0];
    }
  }
  if (_.isEmpty(price_info)) price_info = price_infos[0];

  let taxes = [];
  price_info?.taxes?.forEach((tax) =>
    taxes.push({
      ...tax,
      amount:
        tax.rate < 0
          ? 0
          : roundToFour((price_info.price * tax.rate) / 100),
    })
  );

  return taxes;
};

export const calculateAmountTaxesSumma = (amount, taxes) => {
  let totalTax = 0;
  taxes.forEach((tax) => {
    if (tax.rate > 0) totalTax += (amount * (tax.rate || 0)) / 100;
  });
  return totalTax;
};

export const getTaxName = (tax) => {
  let fullText;
  if (tax.rate < 0) {
    fullText = `${tax.name} Exempt`;
  } else {
    fullText = `${tax.rate}% ${tax.name} `;
  }
  return fullText;
};
