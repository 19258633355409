export const ORDER_STATE = {
  DRAFT: 'draft',
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  REFUNDED: 'refunded',
  PART_REFUNDED: 'part-refunded',
  VOIDED: 'voided',
  REJECTED: 'rejected',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
};

export const TOUCHPOINT_TYPE = {
  POS: 'pos',
  SOK: 'self_order_kiosk',
  DIGITAL_FRONT: 'digital_front',
  ALL: 'all',
};

export const MIN_GUESTS = 1;
export const MAX_GUESTS = 50;

export const TABLE_STATUS = {
  READY: 'ready',
  BUSY: 'busy',
};

export const ONE_PAGE_LIMIT = 20;
