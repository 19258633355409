import { ASAP } from '@/constants/dateAndTime';
import { ORDER_TYPE } from '@/constants/store';
export const INITIAL_STATE = {
  currentMerchant: {},
  selectedStore: null,
  selectedRegion: null,
  localisation: {
    digit_separator: ',',
    currency_symbol: '',
    currency_decimal: false,
    currency_position: 'suffix',
  },
  merchantSettings: {},
  netPrice: false,
  selectedOrderType: {
    type: ORDER_TYPE.COLLECTION,
    pricing_type: 'pos',
    [ORDER_TYPE.COLLECTION]: {
      time: ASAP,
    },
    [ORDER_TYPE.DELIVERY]: {
      to: '',
      time: ASAP,
    },
    [ORDER_TYPE.TAKEAWAY]: {
      time: ASAP,
    },
    [ORDER_TYPE.DINING_IN]: {
      time: ASAP,
      tables: [],
      no_of_guests: 1,
    },
  },
  orderTypes: [],
  storeSearch: {
    data: [],
    temp: [],
    isInit: false,
    isLoading: false,
  },
  shoppingCart: [],
};
