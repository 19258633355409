import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    address: {
      ...CommonFonts.darkFont12,
    },
    open: {
      ...CommonFonts.darkFont12,
      color: '#9fe2a9',
    },
    closed: {
      ...CommonFonts.darkFont12,
      color: Colors.RED,
    },
    icon: {
      fontSize: 16,
      color: '#bec7cc',
      marginRight: theme.spacing(1),
    },
  })
);

export default useStyles;
