import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      backgroundColor: Colors.WHITE,
      borderRadius: theme.spacing(1),
    },
    title: {
      ...CommonFonts.grayFont14,
      color: '#a2a2a2',
    },
    text: {
      ...CommonFonts.darkFont14,
    },
    linkText: {
      fontSize: 14,
    },
    viewOrderButton: {
      textTransform: 'none',
      fontSize: 13,
      fontWeight: 600,
      marginRight: theme.spacing(7),
      padding: theme.spacing(0.5, 4),
    },
    orderAgainButton: {
      textTransform: 'none',
      fontSize: 13,
      fontWeight: 600,
      padding: theme.spacing(0.5, 4),
    },
    orderInfo: {
      width: theme.spacing(50),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(3),
      },
    },
  })
);

export default useStyles;
