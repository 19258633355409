import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import OrderTypeModal from '@/components/header/orderTypeModal';
import { useSelector } from 'react-redux';
import { selectedOrderType } from '@/store/modules/store';
import { selectCurrentUser } from '@/store/modules/auth';
import moment from 'moment';
import { ORDER_TYPE, ORDER_TYPE_NAME } from '@/constants/store';
import { ASAP } from '@/constants/dateAndTime';
import { getTableNoString } from '@/utils/orderUtil';

const OrderInfoCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const orderType = useSelector(selectedOrderType);
  const currentUser = useSelector(selectCurrentUser);

  const [openOrderTypeModal, setOpenOrderTypeModal] = useState(false);

  const contentOrderType = () => {
    const { type } = orderType || {};
    let time = '';
    let address = '';
    let table = '';
    switch (type) {
      case ORDER_TYPE.COLLECTION:
        if (orderType[ORDER_TYPE.COLLECTION].time === ASAP) {
          time = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.COLLECTION].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD MMM YYYY'
          );
          const timeRange = separateDateTime[1];
          time = `${dateFormat} ${timeRange}`;
        }
        break;
      case ORDER_TYPE.TAKEAWAY:
        if (orderType[ORDER_TYPE.TAKEAWAY].time === ASAP) {
          time = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.TAKEAWAY].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD MMM YYYY'
          );
          const timeRange = separateDateTime[1];
          time = `${dateFormat} ${timeRange}`;
        }
        break;
      case ORDER_TYPE.DINING_IN:
        if (orderType[ORDER_TYPE.DINING_IN].time === ASAP) {
          time = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.DINING_IN].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD MMM YYYY'
          );
          const timeRange = separateDateTime[1];
          time = `${dateFormat} ${timeRange}`;
        }
        if (!_.isEmpty(orderType[ORDER_TYPE.DINING_IN].tables)) {
          table = `Table ${getTableNoString(
            orderType[ORDER_TYPE.DINING_IN].tables
          )}`;
        }
        break;

      case ORDER_TYPE.DELIVERY:
        const userAddress = currentUser?.addresses?.find(
          (address) =>
            address.id === orderType[ORDER_TYPE.DELIVERY].to
        );
        if (orderType[ORDER_TYPE.DELIVERY].time === ASAP) {
          time = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.DELIVERY].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD MMM YYYY'
          );
          const timeRange = separateDateTime[1];
          time = `${dateFormat} ${timeRange}`;
        }

        address = (
          <Box>
            {userAddress?.door_number && (
              <Typography className={classes.text}>
                {userAddress.door_number}
              </Typography>
            )}
            {userAddress?.line1 && (
              <Typography className={classes.text}>
                {userAddress.line1}
              </Typography>
            )}
            {userAddress?.line2 && (
              <Typography className={classes.text}>
                {userAddress.line2}
              </Typography>
            )}
            {userAddress?.country && (
              <Typography className={classes.text}>
                {userAddress.country}
              </Typography>
            )}
            {userAddress?.postcode && (
              <Typography className={classes.text}>
                {userAddress.postcode}
              </Typography>
            )}
          </Box>
        );
        break;
      default:
        break;
    }

    return {
      time,
      address,
      table,
    };
  };

  return (
    <Box className={classes.box}>
      <Box
        paddingBottom={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.title}>
          {ORDER_TYPE_NAME[orderType.type]}
        </Typography>
        <Typography
          className={classes.change}
          onClick={() => setOpenOrderTypeModal(true)}
        >
          {t('cart.change')}
        </Typography>
      </Box>

      <Box>
        <Typography className={classes.text}>
          {contentOrderType().time}
        </Typography>
        {contentOrderType().address && contentOrderType().address}
        {contentOrderType().table && (
          <Typography className={classes.text}>
            {contentOrderType().table}
          </Typography>
        )}
      </Box>
      {openOrderTypeModal && (
        <OrderTypeModal
          isVisible={openOrderTypeModal}
          onCloseModal={() => setOpenOrderTypeModal(false)}
        />
      )}
    </Box>
  );
};

export default OrderInfoCard;
