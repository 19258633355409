import { useSelector, useDispatch } from 'react-redux';
import NotificationActions from '@/store/modules/notification/actions';

import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { UPDATE_DEVICE_TOKEN } from '@/services/notificationService';

import { NOTIFICATION_TYPE } from '@/constants/notification';
import { requestFirebaseNotificationPermission } from '../../../firebase';

const updateFirebaseTokenMutation = gql`
  ${UPDATE_DEVICE_TOKEN}
`;

export default function useHandleNotifications() {
  const dispatch = useDispatch();

  const [registerFirebaseTokenMutation] = useMutation(
    updateFirebaseTokenMutation,
    {
      context: {
        headers: {
          merchantId: -1,
          storeId: -1,
        },
        hideLoader: true,
      },
      onCompleted: (data) => {
        localStorage.setItem(
          'firebaseToken',
          data.registerDeviceToken.token
        );
      },
    }
  );

  const [unregisterFirebaseTokenMutation] = useMutation(
    updateFirebaseTokenMutation,
    {
      context: {
        headers: {
          merchantId: -1,
          storeId: -1,
        },
        hideLoader: true,
      },
      onCompleted: () => {
        localStorage.removeItem('firebaseToken');
      },
    }
  );

  const registerFirebaseToken = async () => {
    const fbToken = await requestFirebaseNotificationPermission();
    registerFirebaseTokenMutation({
      variables: {
        input: {
          token: fbToken,
          type: 'firebase',
        },
      },
    });
  };

  const updateFirebaseToken = async () => {
    const firebaseToken = localStorage.getItem('firebaseToken');
    if (firebaseToken) {
      await unregisterFirebaseTokenMutation({
        variables: {
          input: {
            token: firebaseToken,
            type: 'firebase',
            flag: 'delete',
          },
        },
      });
    }
    registerFirebaseToken();
  };

  const handleNotifications = async (message) => {
    console.log('message', message);
    const token = localStorage.getItem('token');
    if (token) {
      const notificationType = message.data?.type;
      if (
        [
          NOTIFICATION_TYPE.ORDER_CREATED,
          NOTIFICATION_TYPE.ORDER_UPDATED,
          NOTIFICATION_TYPE.DELIVERY_UPDATED,
          NOTIFICATION_TYPE.NEW_VOUCHER,
          NOTIFICATION_TYPE.VOUCHER_RETURNED,
        ].includes(notificationType)
      ) {
        dispatch(NotificationActions.addNotification(message));
      }
    }
  };

  return {
    updateFirebaseToken,
    handleNotifications,
  };
}
