import React from 'react';

import useStyles from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';

import Input from '../components/input';
import SingleCheckboxInput from '@/components/singleCheckbox';

const PreferencesPage = ({ currentUser, updateCurrentUser }) => {
  const { marketing, product_update } =
    currentUser?.config?.communication || {};
  const { t } = useTranslation();
  const classes = useStyles();

  const updateCommunication = (key, value) => {
    const communication = { marketing, product_update };
    const config = {
      communication: {
        ...communication,
        [key]: value,
      },
    };
    updateCurrentUser({ key: 'config', value: config });
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box padding={5}>
        <Typography className={classes.title}>
          {t('user.preferences.title')}
        </Typography>
        <div className={classes.boxInput}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Input
                disable
                name="language"
                label={t('user.preferences.language')}
                value={'English (UK)'}
                validationSchema={null}
                onChange={(value) => {}}
              />
            </Grid>
          </Grid>

          <Typography className={classes.title}>
            {t('user.preferences.communications')}
          </Typography>
          <Box display="flex" alignItems="center" marginY={5}>
            <Box marginRight={12}>
              <SingleCheckboxInput
                labelClass={classes.checkBoxLabel}
                style={{ paddingBottom: 0 }}
                value={
                  currentUser?.config?.communication?.marketing ||
                  false
                }
                label={t('user.preferences.marketing')}
                onChange={(value) =>
                  updateCommunication('marketing', value)
                }
                isShowToast={false}
              />
            </Box>
            <Box marginRight={12}>
              <SingleCheckboxInput
                labelClass={classes.checkBoxLabel}
                style={{ paddingBottom: 0 }}
                value={
                  currentUser?.config?.communication
                    ?.product_update || false
                }
                label={t('user.preferences.product_update')}
                onChange={(value) =>
                  updateCommunication('product_update', value)
                }
                isShowToast={false}
              />
            </Box>
          </Box>
        </div>
      </Box>
    </ErrorBoundary>
  );
};

export default PreferencesPage;
