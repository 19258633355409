import { gql } from '@apollo/client';

export const CHECK_USER_QUERY_PARAMS = `
  query checkUser($email: String!) {
    user(email: $email) {
      email  
      verified
    }
  }
`;

export const LOGIN_MUTATION_PARAMS = `
  mutation input($username: String!, $password: Password!){
    logIn(input: {
      username: $username, password: $password
    }){
      access_token,
      token_type,
      refresh_token,
      expires_in,
      user {
        id,
        email,
        mobile,
        last_login,
        first_name,
        last_name,
        dob,
        gender,
        addresses{
          id
          door_number
          building_name
          line1
          line2
          city_town
          country
          postcode
          county
          name
          lat
          lng
        },
        config {
          background_colour
        },
        profile {
          id,
          url,
          name,
          thumbnail,
          content_type,
        },
        profession,
        roles {
          id,
          name,
          slug,
          rolePermissions {
              name,
              value,
          },
          merchant_id,
          store_id
        }
        merchants {
          id,
          mid,
          tname,
          lname,
          mid,
          week_start_day
          financial_year_end
          logo{
            id
            url
            thumbnail
          }
          stores {
            id,
            merchant_id,
            sid,
            name,
            email,
            phone,
            address_id,
            localisation{
              digit_separator
              currency_symbol
              currency_decimal
              currency_position
              currency_code
            }
            region{
              id
              region_name
            }
            address {
              door_number,
              building_name,
              line1,
              line2,
              city_town,
              country,
              postcode,
              county,
            },
          },
        }
      }
    }
  }
`;

export const PHONE_OTP_MUTATION_PARAS = `
  mutation($number: String!) {
    sendPhoneOtp(number: $number)
  }
`;

export const CREATE_USER_MUTATION_PARAS = `
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      activations {
        type
        email
        code
        phone
        expires_at
      }
    }
  }
`;

export const MOBILE_LOGIN_MUTATION_PARAMS = `
  mutation logIn($user: LoginInput!){
    logIn(input: $user){
      access_token,
      token_type,
      refresh_token,
      expires_in,
      user {
        id,
        email,
        last_login,
        first_name,
        last_name,
        dob,
        gender,
        config {
          background_colour
        },
        profile {
          id,
          url,
          name,
          thumbnail,
          content_type,
        },
        profession,
        roles {
          id,
          name,
          slug,
          rolePermissions {
              name,
              value,
          },
          merchant_id,
          store_id
        }
        merchants {
          id,
          mid,
          tname,
          lname,
          mid,
          stores {
            id,
            merchant_id,
            sid,
            name,
            email,
            phone,
            address_id,
            address {
              door_number,
              line1,
              line2,
              city_town,
              country,
              postcode,
              county,
            },
          },
        }
      }
    }
  }
`;
export const LOGOUT_USER = `
  mutation {
      signOut
    }
`;

export const SEND_RESET_CODE = gql`
  mutation ($input: ResetCodeInput!) {
    sendResetCode(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($input: PasswordResetInput!) {
    passwordReset(input: $input) {
      email
    }
  }
`;

export const VERIFY_USER = gql`
  mutation ($input: [VerifyInput!]!) {
    verifyUser(input: $input)
  }
`;
