import React, { useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import _ from 'lodash';
import classNames from 'classnames';

const Input = ({
  name,
  label,
  value,
  onChange,
  validationSchema,
  disable = false,
  labelOption,
  isShowToast = true,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lowerCaseTitle = label.toLowerCase();

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    handleReset();
  };

  const {
    handleSubmit,
    handleReset,
    handleChange,
    handleBlur,
    errors,
    setFieldError,
    touched,
    values,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      [name]: value,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChange(values[name]);
        handleCloseEditModal();
        if (isShowToast) {
          enqueueSnackbar(
            `${capitalizeEachLetter(label)} ${
              value ? t('settings.updated') : t('settings.added')
            }`
          );
        }
      } catch (error) {
        setFieldError(name, t('settings.error.update'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Box
        className={classNames(
          classes.box,
          disable ? classes.disable : ''
        )}
      >
        <Box display="flex" alignItems="center">
          <Typography className={classes.title}>{label}</Typography>
          {labelOption && labelOption}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {value ? (
            <Typography className={classes.value}>{value}</Typography>
          ) : (
            <Typography
              onClick={() => (disable ? {} : handleOpenEditModal())}
              className={classes.addAction}
            >
              {t('settings.add')}
            </Typography>
          )}
          <IconButton
            disabled={disable}
            className={classes.editIcon}
            aria-label={`edit-${lowerCaseTitle}`}
            onClick={() => (disable ? {} : handleOpenEditModal())}
          >
            <CreateIcon />
          </IconButton>
        </Box>
      </Box>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {`Update ${lowerCaseTitle}`}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id={name}
              name={name}
              fullWidth
              value={values[name]}
              onChange={handleChange}
              error={touched[name] && Boolean(errors[name])}
              helperText={touched[name] && errors[name]}
              autoFocus
              required
              onBlur={handleBlur}
              InputProps={{
                className: classes.input,
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid || _.isEmpty(values[name])}
            onClick={handleSubmit}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default Input;
