import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Avatar from '../avatar';
import AuthActions from '@/store/modules/auth/actions';
import classNames from 'classnames';
import {
  getBase64FromUrl,
  getFirstLetter,
  getImageAsBase64,
} from '@/utils/stringUtil';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { MUTATE_GENERATE_UPLOAD_URL } from '@/services/storeService';
import { MUTATE_USER_DETAIL } from '@/services/userManagementService';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { useMutation, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';

const UserAvatar = ({ currentUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = currentUser;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const { profile } = user || {};

  const user_image = localStorage.getItem('user_image');

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setImageToLocalStorage(user?.profile?.url);
    }
  }, [currentUser]);

  const setImageToLocalStorage = async (url, isExist = false) => {
    const base64Image = !isExist
      ? await getBase64FromUrl(url)
      : `data:image/jpeg;base64, ${await getImageAsBase64(url)}`;
    localStorage.setItem('user_image', base64Image);
    localStorage.setItem('user_thumbnail', base64Image);
  };

  const [mutateGenerateURL] = useMutation(
    gql`
      ${MUTATE_GENERATE_UPLOAD_URL}
    `
  );

  const [mutateUserDetail] = useMutation(
    gql`
      ${MUTATE_USER_DETAIL}
    `,
    {
      context: {
        headers: { storeId: -1, merchantId: -1 },
      },
    }
  );

  const onUpload = async (file) => {
    if (file) {
      setIsLoading(true);
      try {
        const payload = profile?.id
          ? {
              id: profile?.id,
              name: profile?.name,
              content_type: 'image/jpeg',
            }
          : {
              name: file.name,
              content_type: 'image/jpeg',
            };

        const result = await mutateGenerateURL({
          variables: {
            input: payload,
          },
          ignoreResults: false,
        });
        const generateUploadUrlResponse =
          result?.data?.generateUploadUrl[0];
        if (generateUploadUrlResponse) {
          const url = generateUploadUrlResponse?.upload_url;
          const fetchingResponse = await fetch(
            new Request(url, {
              method: 'PUT',
              body: file,
              headers: new Headers({
                'Content-Type': 'image/jpeg',
              }),
            })
          );

          if (fetchingResponse) {
            if (!profile?.id) {
              await mutateUserDetail({
                variables: {
                  input: {
                    id,
                    profile: {
                      id: generateUploadUrlResponse?.id,
                    },
                  },
                },
              }).then(async (response) => {
                if (response) {
                  await setImageToLocalStorage(
                    response?.data?.updateUser?.profile.url
                  );
                  dispatch(
                    AuthActions.setUserProfile({
                      id: generateUploadUrlResponse?.id,
                      name: file.name,
                      content_type: 'image/jpeg',
                      thumbnail:
                        response?.data?.updateUser?.profile.url,
                      url: response?.data?.updateUser?.profile.url,
                    })
                  );
                  enqueueSnackbar(
                    t('button_group.profile_icon_updated')
                  );
                }
              });
            } else {
              await setImageToLocalStorage(
                URL.createObjectURL(file),
                true
              );
              dispatch(
                AuthActions.setUserProfile({
                  id: profile?.id,
                  name: file.name,
                  content_type: 'image/jpeg',
                  thumbnail: '',
                  url: '',
                })
              );
              enqueueSnackbar(t('button_group.profile_icon_updated'));
            }
          }
        }
      } catch (error) {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.boxAvatarInfo}>
        <label htmlFor="icon-button-file">
          <Avatar
            firstLetter={getFirstLetter(currentUser?.first_name)}
            secondLetter={getFirstLetter(currentUser?.last_name)}
            url={user_image || user?.profile?.thumbnail}
            width={160}
            height={160}
            backgroundColor={currentUser?.config?.background_colour}
          />
        </label>
        <input
          accept="image/*"
          className={classes.uploadInput}
          id="icon-button-file"
          type="file"
          onChange={(e) => onUpload(e.target.files[0])}
        />
        <label htmlFor="icon-button-file">
          {isLoading ? (
            <CircularProgress
              className={classNames(
                classes.cameraIcon,
                isLoading ? classes.noneBackground : ''
              )}
            />
          ) : (
            <IconButton
              className={classes.cameraIcon}
              aria-label="upload picture"
              component="span"
            >
              <CameraAltIcon size="small" />
            </IconButton>
          )}
        </label>
      </div>
    </div>
  );
};

export default UserAvatar;
