import { createSelector } from 'reselect';
import { INITIAL_STATE } from './initialState';

export const selectStore = (state) => state.store;

export const selectCurrentMerchant = createSelector(
  selectStore,
  (store) => store.currentMerchant
);

export const selectSelectedStore = createSelector(
  selectStore,
  (store) => store.selectedStore
);

export const selectSelectedRegion = createSelector(
  selectStore,
  (store) => store.selectedRegion
);

export const selectedOrderType = createSelector(
  selectStore,
  (store) => store.selectedOrderType
);

export const storeSearch = createSelector(
  selectStore,
  (store) => store.storeSearch
);

export const selectShoppingCart = createSelector(
  selectStore,
  (store) => store.shoppingCart
);

export const selectLocalisation = createSelector(
  selectStore,
  (store) => store.localisation
);

export const selectMerchantSettings = createSelector(
  selectStore,
  (store) => store.merchantSettings
);

export const selectNetPrice = createSelector(
  selectMerchantSettings,
  (merchantSettings) => !!merchantSettings?.products?.net_price
);

export const selectOrderTypes = createSelector(
  selectStore,
  (store) => store.orderTypes
);
