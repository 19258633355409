import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDyra677IXVetmO6iL-Ngiphk_6NxrPWYk',
  authDomain: 'myda-smart-app.firebaseapp.com',
  databaseURL: 'https://myda-smart-app.firebaseio.com',
  projectId: 'myda-smart-app',
  storageBucket: 'myda-smart-app.appspot.com',
  messagingSenderId: '1055215335920',
  appId: '1:1055215335920:web:09d76085698aea54090535',
  measurementId: 'G-78ED9FZTF4',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey:
        'BFIBK26yHAlAxMQwZQNROAk6TdyuO41huF9CUquQdWPeCG-WgQxAapfN6Stv_lJkCM_BzI1Mpcu8YdRvWevb6eA',
    })
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });
