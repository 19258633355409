import * as _ from 'lodash';
import {
  getProductTax,
  getAddonOptionTax,
  calculateAmountTaxesSumma,
} from './taxUtil';
import { getDiscountOfProduct } from './promotionUtil';

export const getProductPrice = (price_infos, pricingType) => {
  var price = 0;
  if (pricingType) {
    let price_info = price_infos.filter(
      (info) => info.price_type.type === pricingType
    );
    if (!_.isEmpty(price_info)) price = price_info[0]?.price || 0;
    else price = price_infos[0]?.price || 0;
  } else {
    price = price_infos[0]?.price || 0;
  }
  return price;
};

export const getTotalPriceOfCartListItem = (
  product,
  addons,
  netPrice,
  pricingType
) => {
  // cartListItem consists of product and addons
  let optionsTotalPrice = 0;
  addons.forEach((addon) => {
    let allow_free = 0; //addon.allow_free; check later
    addon.options.forEach((option) => {
      if (option.force_charge) {
        optionsTotalPrice +=
          (option.quantity || 0) *
          ((option.price.fixed_price || 0) +
            (netPrice
              ? 0
              : getAddonOptionTax(
                  product.prices[0].price_infos,
                  pricingType,
                  option.price.fixed_price
                )));
      } else {
        let quantity =
          allow_free - (option.quantity || 0) >= 0
            ? 0
            : (option.quantity || 0) - allow_free;
        allow_free =
          allow_free - (option.quantity || 0) < 0
            ? 0
            : allow_free - (option.quantity || 0);
        optionsTotalPrice +=
          quantity *
          ((option.price.fixed_price || 0) +
            (netPrice
              ? 0
              : getAddonOptionTax(
                  product.prices[0].price_infos,
                  pricingType,
                  option.price.fixed_price
                )));
      }
    });
  });

  let productTotalPrice =
    product.quantity *
    (getProductPrice(product.prices[0].price_infos, pricingType) +
      (netPrice
        ? 0
        : getProductTax(product.prices[0].price_infos, pricingType)));
  let totalDiscount = 0;
  if (!_.isEmpty(product.promotions)) {
    totalDiscount =
      getDiscountOfProduct(
        product,
        product.promotions[0],
        pricingType,
        netPrice
      ) * product.quantity;
  }

  return (
    productTotalPrice -
    totalDiscount +
    optionsTotalPrice * product.quantity
  );
};

export const getPriceOfOption = (
  price_infos,
  option,
  netPrice,
  pricingType
) => {
  let optionPrice = 0;
  optionPrice +=
    (option.quantity || 0) *
    ((option.price.fixed_price || 0) +
      (netPrice
        ? 0
        : getAddonOptionTax(
            price_infos,
            pricingType,
            option.price.fixed_price
          )));
  return optionPrice;
};

export const getTotalPriceInCart = (
  cartItems,
  netPrice,
  pricingType
) => {
  let total = 0;
  cartItems.forEach(
    (product) =>
      (total += getTotalPriceOfCartListItem(
        product,
        product.addons,
        netPrice,
        pricingType
      ))
  );
  return total;
};

export const calculateProductPrice = (
  product,
  pricingType,
  { includeTax }
) => {
  const productPrice = getProductPrice(
    product.prices[0].price_infos,
    pricingType
  );
  const productTax = getProductTax(
    product.prices[0].price_infos,
    pricingType
  );
  const itemPrice = includeTax
    ? productPrice + productTax
    : productPrice;
  return itemPrice;
};

export const getPrice = (price, netPrice) => {
  if (netPrice) {
    return price.amount;
  } else {
    return (
      price.amount +
      calculateAmountTaxesSumma(price.amount, price.taxes)
    );
  }
};

export const getOrderPaymentSum = (payments) => {
  // payments = payments.filter(payment => payment.amount > 0)
  return _.sumBy(payments, (payment) => payment.amount);
};

export const calcPriceFromGrossPrice = (grossPrice, taxes) => {
  var total_rate = 0;
  taxes.forEach(
    (tax) => (total_rate += (tax?.rate || 0) <= 0 ? 0 : tax.rate)
  );
  return grossPrice * (100 / (100 + total_rate));
};

export const calcGrossPriceFromPrice = (price, taxes) => {
  return price + calculateAmountTaxesSumma(price, taxes);
};
