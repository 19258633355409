import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '100%',
      backgroundColor: '#ffffffe3',
      padding: 0,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: 1000,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#eeeeee4f',
    },
    collectASAP: {
      width: '100%',
      height: theme.spacing(8),
      backgroundColor: '#0156b8',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    collectText: {
      ...CommonFonts.whiteFont14,
      fontWeight: 500,
      margin: theme.spacing(0, 2),
      cursor: 'pointer',
    },
    collectIcon: {
      color: Colors.WHITE,
      fontSize: 18,
    },
    wrapper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      overflow: 'hidden',
    },
    icon: {
      fill: '#939DA8',
      marginTop: '-5px',
    },
    iconContainer: {
      width: 40,
      height: 40,
      borderRadius: 20,
      backgroundColor: Colors.WHITE,
      borderWidth: 1,
      borderColor: Colors.GRAY_BORDER1,
      borderStyle: 'solid',
    },
    inputColor: {
      paddingBottom: 10,
      paddingTop: 10,

      color: Colors.DIM_GREY,
    },
    searchInput: {},
    profileActionPopoverContainer: {
      '&>div.MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        width: 300,
        marginTop: theme.spacing(5.5),
        marginLeft: -theme.spacing(3.5),
        height: 'auto !important',
      },
    },
    menuItemActive: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3, 8),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    textItemActive: {
      fontWeight: '600',
    },
    textItem: {
      ...CommonFonts.darkFont14,
    },
  })
);

export default useStyles;
