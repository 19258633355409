import {
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  getCurrencySymbol,
  getFullAddressOfStore,
  hideContent,
  capitalizeFirstLetter,
} from '@/utils/stringUtil';

const OrderCard = ({ data, onViewOrder, onRepeatOrder }) => {
  const {
    store,
    order_no,
    order_type,
    status,
    total_price,
    billing,
    payments,
  } = data;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currency = getCurrencySymbol(
    store?.localisation?.currency_symbol
  );

  return (
    <Box className={classes.box} padding={6} marginBottom={5}>
      <Box
        display={isMobile ? 'block' : 'flex'}
        alignItems="flex-start"
      >
        <Box className={classes.orderInfo}>
          <Typography className={classes.title}>
            {t('orderManagement.store')}
          </Typography>
          <Link
            underline="none"
            className={classes.linkText}
            color="primary"
            component={RouterLink}
            to={`/store/${store.merchant_id}/${store.id}`}
          >
            {hideContent(store.name, 18)}
          </Link>
        </Box>
        <Box className={classes.orderInfo}>
          <Typography className={classes.title}>
            {t('orderManagement.order_no')}
          </Typography>
          <Typography className={classes.text}>{order_no}</Typography>
        </Box>
        <Box className={classes.orderInfo}>
          <Typography className={classes.title}>
            {t('orderManagement.total')}
          </Typography>
          <Typography
            className={classes.text}
          >{`${currency}${total_price?.gross_amount?.toFixed(
            2
          )}`}</Typography>
        </Box>
        <Box className={classes.orderInfo}>
          <Typography className={classes.title}>
            {t('orderManagement.payment_status')}
          </Typography>
          <Typography className={classes.text}>
            {capitalizeFirstLetter(payments[0]?.status)}
          </Typography>
        </Box>
        <Box className={classes.orderInfo}>
          <Typography className={classes.title}>
            {t('orderManagement.order_type')}
          </Typography>
          <Typography className={classes.text}>
            {order_type?.name}
          </Typography>
        </Box>
        <Box className={classes.orderInfo}>
          <Typography className={classes.title}>
            {t('orderManagement.status')}
          </Typography>
          <Typography className={classes.text}>
            {status?.name}
          </Typography>
        </Box>
        {!_.isEmpty(billing) && (
          <Box className={classes.orderInfo}>
            <Typography className={classes.title}>
              {t('orderManagement.deliver_to')}
            </Typography>
            <Typography className={classes.text}>
              {getFullAddressOfStore(billing)}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" marginTop={6}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.viewOrderButton}
          onClick={onViewOrder}
        >
          {t('orderManagement.view_order')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.orderAgainButton}
          onClick={onRepeatOrder}
        >
          {t('orderManagement.order_again')}
        </Button>
      </Box>
    </Box>
  );
};

export default OrderCard;
