import { Grid, Hidden, Link, Box } from '@material-ui/core';
import React from 'react';
import {
  Route,
  Switch,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const VoucherPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const urlName = location.pathname;

  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Grid container></Grid>
    </AppLayout>
  );
};

export default VoucherPage;
