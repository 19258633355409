export const totalPriceAmount = (userCart, isDecimal) => {
  let price = 0;
  userCart?.map((itemCart) => {
    let addonsPrice = 0;
    if (itemCart.addons) {
      addonsPrice = itemCart?.addons?.reduce((accAddon, argAddon) => {
        return (
          accAddon + argAddon.price.amount * argAddon.price.quantity
        );
      }, 0);
    }

    price =
      parseFloat(isDecimal ? price.toFixed(2) : price) +
      (itemCart.price.amount + addonsPrice) * itemCart.price.quantity;
  }, 0);

  return parseFloat(isDecimal ? price.toFixed(2) : price);
};

export const totalTaxesAmount = (userCart, isDecimal) => {
  const taxes = userCart?.reduce((acc, arg) => {
    let addonsTaxes = [];
    if (arg.addons) {
      addonsTaxes = arg.addons
        .reduce((accAddons, argAddon) => {
          return accAddons.concat(
            argAddon.price.taxes.map((tax) => ({
              ...tax,
              amount: tax.amount * argAddon.price.quantity,
            }))
          );
        }, [])
        .map((tax) => ({
          ...tax,
          amount: tax.amount * arg.price.quantity,
        }));
    }

    const itemTaxes = arg?.price?.taxes?.map((tax) => ({
      id: tax.id,
      name: tax.name,
      rate: tax.rate,
      amount: tax.amount * arg.price.quantity,
    }));

    return acc.concat(itemTaxes, addonsTaxes);
  }, []);

  const mergingTaxes = Object.values(
    taxes.reduce((acc, { id, name, rate, amount }) => {
      const taxAmountTemp = acc[id]
        ? acc[id].amount + amount
        : amount;
      const taxAmount = parseFloat(
        isDecimal ? taxAmountTemp.toFixed(2) : taxAmountTemp
      );

      acc[id] = {
        id,
        name,
        rate: rate,
        amount: taxAmount,
      };
      return acc;
    }, {})
  );

  return mergingTaxes;
};
