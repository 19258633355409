import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const NoOrders = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={10}
      style={{ marginTop: 40 }}
    >
      <Grid item>
        <span className={classes.title}>
          {t('orderManagement.no_orders')}
        </span>
      </Grid>
    </Grid>
  );
};

export default NoOrders;
