import React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import { MEASURE_TYPE } from '@/constants/measure';
import { getTotalPriceOfOrderItem } from '@/utils/orderUtil';
import { getLocalisationVal } from '@/utils/localisationUtil';
import { getTextOfAppliedPromotionToOrderItem } from '@/utils/promotionUtil';

import useStyles from './styles';

const OrderItem = (props) => {
  const { t } = useTranslation();
  const { item, promotions, netPrice, localisation } = props;
  const classes = useStyles();
  console.log('netPrice', netPrice);
  console.log('localisation', localisation);

  const totalPriceOfOrderItem = getTotalPriceOfOrderItem(
    item,
    netPrice
  );

  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography className={classes.itemText}>
          {item.price?.item_measure?.type === MEASURE_TYPE.GRAM ||
          item.price?.item_measure?.type === MEASURE_TYPE.KGRAM
            ? `${item.price.quantity} ${item.price?.item_measure?.type}`
            : `${item.price.quantity} x`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.itemText}>
          {item.name}
        </Typography>
        {!isEmpty(item.promotions) && (
          <Typography className={classes.promotionText}>
            {getTextOfAppliedPromotionToOrderItem(
              item,
              item.promotions[0],
              promotions,
              t,
              netPrice,
              localisation
            )}
          </Typography>
        )}
        {item.addons
          ?.filter((addon) => !!addon?.price?.quantity)
          ?.map((addon) => (
            <Typography key={addon.id} className={classes.addonText}>
              {`${addon.price.quantity * item.price.quantity} x ${
                addon.name
              }`}
            </Typography>
          ))}
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end">
          <Typography className={classes.itemText}>
            {getLocalisationVal(localisation, totalPriceOfOrderItem)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderItem;
