import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    groupName: {
      ...CommonFonts.grayFont16,
      fontWeight: 600,
    },
    errorText: {
      ...CommonFonts.grayFont16,
      color: Colors.RED,
      marginLeft: theme.spacing(5),
      fontWeight: 600,
    },
    boxOptionItem: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: Colors.GRAY_BORDER1,
      height: theme.spacing(15),
      borderRadius: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    boxImage: {
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: Colors.GRAY_BORDER1,
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    description: {
      ...CommonFonts.darkFont12,
      marginBottom: theme.spacing(3),
    },
  })
);

export default useStyles;
