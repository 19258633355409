import React, { useEffect } from 'react';

import { Box, Typography, Select, MenuItem } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { TIMES } from '../helper';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { getSecondFromTimeString } from '@/utils/numberUtil';

const TimeSettingInput = ({
  open,
  close,
  day,
  index,
  storeOpenings,
  setStoreOpenings,
  setError,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isError =
    getSecondFromTimeString(open) > getSecondFromTimeString(close);

  useEffect(() => {
    setError(isError);
  }, [open, close]);

  const onUpdate = (type, value) => {
    const list = { ...storeOpenings };
    list[day].opening_times[index][type] = value;
    setStoreOpenings(list);
  };

  const onAddNew = () => {
    const list = { ...storeOpenings };
    list[day].opening_times = [
      ...list[day].opening_times,
      { open: '00:00', close: '00:00' },
    ];
    setStoreOpenings(list);
  };

  const onRemove = () => {
    setError(false);
    const list = { ...storeOpenings };
    list[day].opening_times.splice(index, 1);
    setStoreOpenings(list);
  };

  return (
    <Box paddingY={2}>
      <Box display="flex" alignItems="center" paddingY={2}>
        <Box paddingRight={28} display="flex" alignItems="center">
          <Select
            disableUnderline
            classes={{
              icon: classes.iconSelect,
              root: classes.selectTimeInput,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={open}
            onChange={(e) => onUpdate('open', e.target.value)}
          >
            {TIMES.map((time) => (
              <MenuItem key={time.id} value={time.value}>
                <Typography className={classes.timeItem}>
                  {time.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <CreateIcon className={classes.createIcon} />
        </Box>
        <Box paddingRight={28} display="flex" alignItems="center">
          <Select
            disableUnderline
            classes={{
              icon: classes.iconSelect,
              root: classes.selectTimeInput,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={close}
            onChange={(e) => onUpdate('close', e.target.value)}
          >
            {TIMES.map((time) => (
              <MenuItem key={time.id} value={time.value}>
                <Typography className={classes.timeItem}>
                  {time.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <CreateIcon className={classes.createIcon} />
        </Box>
        {storeOpenings[day].opening_times.length < 3 && index === 0 && (
          <Typography className={classes.addHours} onClick={onAddNew}>
            {t('store.add_hours')}
          </Typography>
        )}
        {index !== 0 && (
          <Typography
            className={classes.removeHours}
            onClick={onRemove}
          >
            {t('store.remove')}
          </Typography>
        )}
      </Box>
      {isError && (
        <Typography className={classes.errorText}>
          {t('store.time_validation_message')}
        </Typography>
      )}
    </Box>
  );
};

export default TimeSettingInput;
