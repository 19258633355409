import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      backgroundColor: Colors.WHITE,
      borderRadius: theme.spacing(1),
      height: theme.spacing(37.5),
      padding: theme.spacing(4),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: 600,
      paddingBottom: theme.spacing(3),
    },
    text: {
      ...CommonFonts.darkFont14,
    },
  })
);

export default useStyles;
