import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useLazyQuery } from '@apollo/client';
import { GET_BANNERS } from '@/services/landingService';
import axios from 'axios';
import Slider from 'react-slick';

const Banners = () => {
  const classes = useStyles();
  const [banners, setBanners] = useState([]);
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // Detect default country using IP
  useEffect(() => {
    axios
      .get('https://extreme-ip-lookup.com/json/')
      .then((response) => {
        getBanners({
          variables: {
            country: response.data.country,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [getBanners, { loading: loadingBanners }] = useLazyQuery(
    GET_BANNERS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.banners) {
          setBanners(data?.banners);
        }
      },
    }
  );

  const sliderSettings = {
    className: classes.root,
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    afterChange: (index) => setIndex(index),
    appendDots: (dots) => (
      <div
        style={{
          bottom: 16,
        }}
      >
        {dots}
      </div>
    ),
    customPaging: (i) => {
      return (
        <div
          style={{
            width: 15,
            height: 15,
            backgroundColor: i === index ? '#ffc624' : '#fff',
            borderRadius: '50%',
            color: 'transparent',
          }}
        >
          {i}
        </div>
      );
    },
  };

  return (
    <>
      {loadingBanners ? (
        <Box paddingX={10} paddingY={5}>
          <Skeleton variant="rect" width="100%" height={500} />
        </Box>
      ) : !_.isEmpty(banners) ? (
        <Box paddingX={isMobile ? 0 : 10} paddingY={isMobile ? 0 : 5}>
          <Slider {...sliderSettings}>
            {banners?.map((item, index) => (
              <img
                key={index}
                height={500}
                src={item.image.url}
                className={classes.image}
              />
            ))}
          </Slider>
        </Box>
      ) : null}
    </>
  );
};

export default Banners;
