import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .slick-next': {
        top: 'unset !important',
        width: 0,
        height: 0,
      },
      '& .slick-prev': {
        top: 'unset !important',
        width: 0,
        height: 0,
      },
    },
    nextIcon: {
      cursor: 'pointer',
      color: 'black',
      fontSize: 20,
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: Colors.GRAY_LIGHT,
      top: theme.spacing(1),
      right: '-52px',
      padding: theme.spacing(1.5),
      boxShadow: '0px 1px 4px #cecece',
    },
    prevIcon: {
      cursor: 'pointer',
      color: 'black',
      fontSize: 20,
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: Colors.GRAY_LIGHT,
      top: theme.spacing(1),
      left: '-52px',
      padding: theme.spacing(1.5),
      boxShadow: '0px 1px 4px #cecece',
    },
  })
);

export default useStyles;
