import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont28,
      fontWeight: 600,
    },
    groupName: {
      ...CommonFonts.darkFont14,
      fontWeight: 500,
      marginBottom: theme.spacing(4),
    },
    skeletonContainer: {
      position: 'relative',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      paddingRight: 30,
      paddingLeft: 30,
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    backToButton: {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
  })
);

export default useStyles;
