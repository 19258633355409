import moment from 'moment';

export const getOrderTime = (order) => {
  let orderTime = order.creation.time;

  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const tomorrow = moment().add(1, 'day');
  if (today.isSame(orderTime, 'day')) {
    return 'Today';
  }
  if (tomorrow.isSame(orderTime, 'day')) {
    return `Tomorrow`;
  }
  if (yesterday.isSame(orderTime, 'day')) {
    return `Yesterday`;
  }
  return moment(orderTime).format('DD MMM YYYY');
};
