import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 270,
      height: 450,
      border: '1px solid #cecece',
      borderRadius: '4px',
      marginRight: '20px',
      marginBottom: '20px',
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        marginRight: 'unset',
        width: '100%',
      },
    },
    productName: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
    },
    productPriceTitle: {
      ...CommonFonts.darkFont14,
    },
    productPriceContent: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
      marginLeft: theme.spacing(1.5),
    },
    description: {
      ...CommonFonts.darkFont12,
    },
    addUpdateCart: {
      textTransform: 'unset',
      fontSize: 12,
      position: 'absolute',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    image: {
      borderTopRightRadius: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
      objectFit: 'cover',
    },
    noImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 250,
      backgroundColor: Colors.GRAY_BORDER1,
    },
    iconsContainer: {
      position: 'absolute',
      bottom: theme.spacing(3),
      width: '100%',
      zIndex: 1000,
    },
    plusMinusIcon: {
      color: Colors.BLUE,
    },
    quantity: {
      ...CommonFonts.darkFont24,
      fontWeight: 600,
      width: 30,
      textAlign: 'center',
    },
  })
);

export default useStyles;
