import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    boxItem: {
      position: 'relative',
      cursor: 'pointer',
      height: theme.spacing(9.5),
      padding: theme.spacing(0, 5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid #e3e7e9`,
      borderRadius: theme.spacing(5),
      margin: theme.spacing(0, 2.5),
    },
    boxItemSelected: {
      backgroundColor: '#1174F223',
      fontWeight: 600,
    },
    boxContainer: {
      borderRadius: theme.spacing(1),
      backgroundColor: Colors.WHITE,
      position: 'absolute',
      bottom: theme.spacing(4),
      left: theme.spacing(1.5),
      boxShadow: `0px 1px 3px #e3e7e9`,
    },
  })
);

export default useStyles;
