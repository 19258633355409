export const DAY_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const ORDER_TYPE = {
  TAKEAWAY: 'takeaway',
  COLLECTION: 'click',
  DINING_IN: 'dining_in',
  DELIVERY: 'delivery',
};

export const ORDER_TYPE_NAME = {
  [ORDER_TYPE.TAKEAWAY]: 'Takeaway',
  [ORDER_TYPE.COLLECTION]: 'Collection',
  [ORDER_TYPE.DINING_IN]: 'Dining In',
  [ORDER_TYPE.DELIVERY]: 'Delivery',
};
