import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Colors } from '@/theme';

import useStyles from './styles';

const Avatar = ({
  url,
  firstLetter,
  secondLetter,
  width,
  height,
  backgroundColor,
  smallSize,
  handleOpenEditModal,
}) => {
  const classes = useStyles();

  return (
    <IconButton
      onClick={handleOpenEditModal}
      className={classes.button}
      aria-label="upload picture"
      component="span"
    >
      {url !== 'undefined' ? (
        <img
          src={url}
          width={width}
          height={height}
          alt={`${firstLetter}${secondLetter}`}
          className={classes.image}
          color={Colors.DIM_GREY}
        />
      ) : (
        <div
          className={
            smallSize ? classes.emptySmallSize : classes.empty
          }
          style={{
            backgroundColor: backgroundColor
              ? backgroundColor
              : '#BD10E0',
          }}
        >
          <Typography
            className={
              smallSize
                ? classes.emptyNameSmallSize
                : classes.emptyName
            }
          >{`${firstLetter}${secondLetter}`}</Typography>
        </div>
      )}
    </IconButton>
  );
};

Avatar.propTypes = {
  url: PropTypes.string,
  firstLetter: PropTypes.string,
  secondLetter: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  handleOpenDialog: PropTypes.func,
};

Avatar.defaultProps = {
  firstLetter: '',
  secondLetter: '',
  width: 50,
  height: 50,
  handleOpenDialog: () => {},
};

export default Avatar;
