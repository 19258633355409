import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    empty: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      // backgroundColor: Colors.PURPLE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    emptyName: {
      textDecoration: 'uppercase',
      color: Colors.WHITE,
      fontSize: '3.5rem',
      fontWeight: 450,
    },
    emptySmallSize: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      // backgroundColor: Colors.PURPLE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    emptyNameSmallSize: {
      textDecoration: 'uppercase',
      color: Colors.WHITE,
      fontSize: '1.5rem',
      fontWeight: 450,
    },
    image: {
      borderRadius: '50%',
      ...CommonFonts.darkFont12,
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        filter: 'brightness(0.7)',
        transition: 'all 0.2s ease-in-out',
      },
    },
    button: {
      padding: 0,
    },
  })
);

export default useStyles;
