import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmailForm from './emailForm';
import PreLoader from '../../components/preLoader';

import useStyles from './styles';

const FillFormLayout = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    defaultEmail,
    onEmailSignup,
    isSignupProgress,
    signupError,
  } = props;

  return (
    <div>
      <div className={classes.signupText}>
        {t('signup.signup_for_myda')}
      </div>
      <EmailForm
        defaultEmail={defaultEmail}
        onSignup={onEmailSignup}
        signupError={signupError}
      />
      {isSignupProgress && <PreLoader size={60} />}
    </div>
  );
};

FillFormLayout.propTypes = {
  defaultEmail: PropTypes.string,
  isUsingEmail: PropTypes.bool,
  onChangeUsingEmail: PropTypes.func,
  onEmailSignup: PropTypes.func,
  isSignupProgress: PropTypes.bool,
  signupError: PropTypes.bool,
};

export default FillFormLayout;
