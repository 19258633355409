import { Box, Typography, Hidden } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ProductItem from './ProductItem';
import { getCurrencySymbol } from '@/utils/stringUtil';
import { getLocalisationVal } from '@/utils/localisationUtil';

const ProductsCheckout = ({ userCart, isNetPrice, localisation }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currency = getCurrencySymbol(localisation?.currency_symbol);

  const taxes = Object.values(
    userCart?.reduce((acc, arg) => {
      let addonsTaxes = [];
      if (arg.addons) {
        addonsTaxes = arg.addons
          .reduce((accAddons, argAddon) => {
            return argAddon.price.quantity > 0
              ? accAddons.concat(
                  argAddon.price.taxes.map((tax) => ({
                    ...tax,
                    amount: tax.amount * argAddon.price.quantity,
                  }))
                )
              : accAddons;
          }, [])
          .map((tax) => ({
            ...tax,
            amount: tax.amount * arg.price.quantity,
          }));
      }

      const itemTaxes = arg?.price?.taxes?.map((tax) => ({
        id: tax.id,
        name: tax.name,
        amount: tax.amount * arg.price.quantity,
      }));

      return acc.concat(itemTaxes, addonsTaxes);
    }, [])
  );

  const totalTaxes = Object.values(
    taxes.reduce((acc, { name, amount }) => {
      acc[name] = {
        name,
        amount: acc[name] ? acc[name].amount + amount : amount,
      };
      return acc;
    }, {})
  );

  return (
    <Box className={classes.box}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.title}>
          {t('cart.item')}
        </Typography>
        <Typography className={classes.title}>
          {t('cart.price')}
        </Typography>
      </Box>
      <Box marginTop={10}>
        {userCart?.map((item) => (
          <ProductItem
            key={item.id}
            data={item}
            isNetPrice={isNetPrice}
            localisation={localisation}
            currency={currency}
            userCart={userCart}
          />
        ))}
        {isNetPrice && (
          <Box display="flex" marginBottom={3}>
            <Hidden smDown>
              <div className={classes.nonImage} />
            </Hidden>
            <Box display="flex" flexDirection="column" flex={1}>
              {totalTaxes?.map((tax) => (
                <Box
                  key={tax.name}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                  marginBottom={3}
                >
                  <Typography className={classes.taxName}>
                    {tax.name}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography className={classes.currency}>
                      {currency}
                    </Typography>
                    <Typography className={classes.price}>
                      {getLocalisationVal(localisation, tax.amount)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductsCheckout;
