export const STORE_DETAIL = `
    query getStoreDetail{
        merchant{
            id
            lname
            tname
            address{
                door_number
                building_name
                line1
                line2
                city_town
                postcode
                floor
                unit
                country
            }
            business_type
            business_type_id
            registrations{
              entities{
                id
                parent
                name
                reg_required
                reg_number
              }
              taxes{
                id
                parent
                name
                reg_number
                reg_required
              }
            }
            financial_year_end
            week_start_day
            day_end_time
        }
      }
`;

export const MUTATE_REGISTER_NAME = `
mutation updateMerchant($merchant: MerchantInput!) {
    updateMerchant(input: $merchant) {
      id
      lname
    }
  }
`;

export const MUTATE_TRADING_NAME = `
mutation updateMerchant($merchant: MerchantInput!) {
    updateMerchant(input: $merchant) {
      id
      tname
    }
  }
`;

export const BUSINESS_TYPE_LIST = `
  query getBusinessType{
      globalDatasets {
        business_types {
          id
          name
        }
      }
  }
`;

export const REGISTRATION_TYPE_LIST = `
  query getRegistrationTypes {
    registrationTypes {
      entities {
          id
          name
          reg_required
      }
    }
  }
`;

export const REGISTRATION_TAXES_LIST = `
  query getTaxRegistration {
    registrationTypes{
        taxes {
            id
            name
            options {
                id
                name
                reg_required
                taxes {
                    id
                    name
                    rate
                }
            }
        }
    }
}
`;

export const MUTATE_BUSINESS_TYPE = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    business_type
    business_type_id
  }
}
`;

export const MUTATE_CREATE_BUSINESS_TYPE = `
mutation($input: [BusinessTypeInput]!) {
  addBusinessTypes(input: $input) {
    id
    name
  }
}
`;

export const MUTATE_REGISTRATION_NUMBER = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    registrations{
      entities{
        id
        name
        reg_number
      }
      taxes{
        id
        name
        reg_number
        reg_required
      }
    }
  }
}
`;

export const MUTATE_REGISTRATION_TYPE = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`;

export const MUTATE_MERCHANT_LOGO = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`;

export const MUTATE_REGISTRATION_TAX = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`;

export const MUTATE_VAT_NUMBER = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    registrations{
      entities{
        id
        name
        reg_number
      }
      taxes{
        id
        name
        reg_number
        reg_required
      }
    }
  }
}
`;

export const MUTATE_FINANCIAL_YEAR_END = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`;

export const MUTATE_WEEK_COMMENCING_DAY = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`;

export const MUTATE_DAY_END_TIME = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`;

export const ADD_MERCHANT = `
mutation addMerchant($merchant: MerchantInput!) {
  addMerchant(input: $merchant) {
    id,
    mid,
    tname,
    lname,
    mid,
    stores {
      id,
      merchant_id,
      sid,
      name,
      email,
      phone,
      address_id,
      address {
        door_number,
        building_name,
        line1,
        line2,
        city_town,
        country,
        postcode,
        county,
        lat,
        lng
      },
    },
  }
}`;

export const MERCHANT_SETTINGS = `
  query getMerchantSettings{
    merchantSettings{
      products{
        net_price
        ingredient
        addon
        recipe
        allergy
        style
      }
      pricings{
        store{
          same_price
        }
      }
    }
  }
`;

export const MUTATE_MERCHANT_SETTINGS = `
  mutation merchantSettings($merchantSettings: MerchantSettingsInput!){
    merchantSettings(input: $merchantSettings){
        products{
          net_price
          ingredient
          addon
          recipe
          allergy
          style
        }
      }
    }
`;

export const PRICE_TYPE_LIST = `
    query getPriceTypeList{
      globalPriceTypes{
        id
        name
      }
    }
`;

export const MERCHANT_PRICE_TYPE = `
    query getPriceType{
      priceTypes{
        id
        name
      }
    }
`;

export const MUTATE_PRICE_TYPE = `
  mutation addPriceTypes($priceTypes: [PriceTypeInput]!) {
    addPriceTypes(input: $priceTypes) {
      id
    }
  } 
`;

export const UPDATE_STORE = `
  mutation ($store: StoreInput!) {
   updateStore(input: $store) {
    id
    name
    merchant_id
  }
 }`;

export const MUTATE_ADDRESS = `
 mutation updateAddressMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    address{
      door_number
      building_name
      line1
      line2
      city_town
      postcode
      floor
      unit
      country
    }
  }
}
 `;

export const ACCOUNT_TYPES = `
  query getAccoutTypes($id: String){
    accountTypes(id: $id){
      id
      name
      children_types{
        id
        name
        accounts{
          id
          name
          description
          starting_balance
          archived
          display_in_report
          account_type{
            id
            name
          }
        }
      }
    }
  }
 `;

export const ACCOUNT_LIST = `
  query accounts{
    accounts{
      id
      name
      description
      account_type{
        id
        name
      }
      starting_balance
    }
  }
`;

export const TOUCHPOINT_POS_SETTING = `
  query getTouchpointPosSetting{
    store{
      settings{
        touchpoint_settings{
          pos{
            auto_receipt_print
              #Print receipt automatically
            auto_expense_print
             #Print expenses receipt automatically
            float{
              status
              amount
            }
             #Float
            manual_amount{
              status
              amount
            }
              #Manual amount authorization 
            scren_lock{
              status
              time
            }
              #Auto lock 
            cashdrawer_alarm{
              status
              time
            }
               #Cash drawer alarm
            cart{
              group_by_items
            }
               #Grop items in cart 
            payment_types
              #Payment types 
            banner{
              id
              url
              thumbnail
            }
            customer_display_images{
              id
              url
              thumbnail
            }
            product_view{
              sort_by_name
                #Sort by alphabetical order: 
            }
            receipts{
              logo
              tax_summary
              phone
              cashier_name
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
            product_view{
              sort_by_name
                #Sort by alphabetical order: 
              categories{
                id
                position
              }
            }
          }
        }
      }
       payment_types{
        id
        name
      }
    }
  }
`;

export const TOUCHPOINT_STOREFRONT_SETTING = `
  query getTouchpointStorefrontSetting{
    store{
      id
      merchant_id
      name
      phone
      username
      sold_items
      specialities{
        id
        name
      }
      about_story
      localisation{
        digit_separator
        currency_symbol
        currency_decimal
        currency_position
        currency_code
      }
      address{
        door_number
        building_name
        line1
        line2
        city_town
        postcode
        floor
        unit
        country
      }
      store_openings{
        closed
        date
        day
        id
        opening_times{
          day
          open
          close
        }
      }
      merchant{
        logo{
          id
          url
          thumbnail
          name
        }
        business_type
      }
      settings{
        touchpoint_settings{
          digital_front{
            url
            banner{
              id
              url
            }
            order_types
            product_view{
              sort_by_name
              by_category
              show_selected
              number_of_products
              short_description
              products{
                id
                position
              }
              categories{
                id
                position
              }
            }
            receipts{
              logo
              phone
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
          }
        }
      }
      order_types {
        id
        name
        type
        pricing_type
        modules {
          id
          type
        }
        workflow {
          id
          start {
            id
            name
            order
          }
          stages {
            id
            order
            name
            actions {
              id
              name
            }
          }
        }
      }
      payment_types{
        id
        name
        type
      }
    }
  }
`;

export const MUTATE_TOUCHPOINT_POS_SETTING = `
mutation updateStore($store: StoreInput!) {
  updateStore(input: $store) {
    id
    settings{
      touchpoint_settings{
        pos{
          auto_receipt_print
            #Print receipt automatically
          auto_expense_print
           #Print expenses receipt automatically
          float{
            status
            amount
          }
           #Float
          manual_amount{
            status
            amount
          }
            #Manual amount authorization 
          scren_lock{
            status
            time
          }
            #Auto lock 
          cashdrawer_alarm{
            status
            time
          }
             #Cash drawer alarm
          cart{
            group_by_items
          }
             #Grop items in cart 
          payment_types
            #Payment types 
          banner{
            id
            url
            thumbnail
          }
          customer_display_images{
            id
            url
            thumbnail
          }
          product_view{
            sort_by_name
              #Sort by alphabetical order: 
          }
          receipts{
            logo
            tax_summary
            phone
            cashier_name
            address_type
            registration_number
            barcode
            website
            custom_message
            promotions
          }
          product_view{
            sort_by_name
              #Sort by alphabetical order: 
            categories{
              id
              position
            }
          }
        }
      }
    }
  }
 }
`;

export const MUTATE_GENERATE_UPLOAD_URL = `
mutation generateUploadUrl($input: [UploadInput!]) {
  generateUploadUrl(input: $input) {
    id
    name
    upload_url
  }
}
`;

export const PROMOTION_LIST = `
  query getPromotion($name: String, $filter: Filter){
    promotions(name: $name, filter: $filter){
      id
      promo_name
      options{
        products{
          id
          name
        }
      }
    }
  }
`;

export const CATEGORY_LIST = `
query getCategory{
  categories(store_id:"-1"){
    id
    name
  }
}
`;

export const MUTATE_TOUCHPOINT_STOREFRONT_SETTING = `
mutation updateStore($store: StoreInput!) {
  updateStore(input: $store) {
    id
    merchant_id
    email
    name
    phone
    merchant{
      logo{
        id
        url
        thumbnail
        name
      }
    }
    settings{
      touchpoint_settings{
        digital_front{
          url
          banner{
            id
            url
          }
          order_types
          product_view{
            sort_by_name
            by_category
            show_selected
            number_of_products
            short_description
            products{
              id
              position
            }
            categories{
              id
              position
            }
          }
          receipts{
            logo
            phone
            address_type
            registration_number
            barcode
            website
            custom_message
            promotions
          }
        }
      }
    }
  }
 }
`;

export const PRODUCT_LIST = `
query getProducts($name: String, $bar_code: String, $not_in_touchpoint_type: Boolean, $filter: Filter){
  products(name: $name, bar_code: $bar_code, touchpoint_type: "digital_front", not_in_touchpoint_type: $not_in_touchpoint_type, filter: $filter){
    id
    name
    category_id
    bar_code
  }
}
`;

export const GET_MEASURES = `
query getMeasures{
  measures {
    id
    type
    name
  }
}
`;

export const GET_ACCOUNT_TYPES = `
query getAccountTypes {
  accountTypes{
    id
    name
     children_types{
      id
      name
    }
  }
}
`;

export const ADD_ACCOUNT = `
mutation($input: [AccountInput]!) {
  addAccounts(input: $input) {
    id
  }
}
`;

export const GET_GLOBAL_MEASURES = `
query getGlobalMeasures{
  globalDatasets{
    measures {
      id
      type
      name
    }
  }
}
`;

export const MUTATE_MEASURES = `
mutation addMeasures($input: [MeasureInput]!){
  addMeasures(input: $input){
    id
  }
}
`;

export const STORE_SEARCH = `
  query storeSearch($input: String, $module: String){
    storeSearch(input: $input, module: $module){
      id
      merchant_id
      name
      address{
        door_number
        building_name
        line1
        line2
        city_town
        postcode
        floor
        unit
        country
      }
      merchant{
        business_type
        logo{
          id
          thumbnail
          url
        }
      }
      sold_items
      settings{
        touchpoint_settings{
          digital_front{
            banner{
              id
              thumbnail
              url
            }
          }
        }
      }
      store_openings{
        closed
        date
        day
        id
        opening_times{
          day
          open
          close
        }
      }
    }
  }
`;

export const STORE_SEARCH_FILTER = `
  query storeSearch($input: String, $module: String, $filter: Filter){
    storeSearch(input: $input, module: $module){
      id
      merchant_id
      name
      address{
        door_number
        building_name
        line1
        line2
        city_town
        postcode
        floor
        unit
        country
      }
      merchant{
        business_type
        logo{
          id
          thumbnail
          url
        }
      }
      sold_items
      settings{
        touchpoint_settings{
          digital_front{
            banner{
              id
              thumbnail
              url
            }
          }
        }
      }
      store_openings{
        closed
        date
        day
        id
        opening_times{
          day
          open
          close
        }
      }
    }
  }
`;

export const GET_CATEGORIES = `
  query getCategories{
    categories{
      id
      name
    }
  }
`;

export const MUTATE_ADD_ORDERS = `
  mutation addOrders($input: [OrderInput]!){
    addOrders(input: $input){
      id
      order_no
      billing{
        door_number
        building_name
        line1
        line2
        city_town
        country
        postcode
        county
    }
      shopper{
        id
        first_name
        last_name
        mobile
      }
      user {
        id
        first_name
        last_name
      }
      creation{
        time
        user_id
      }
      payments{
        id
        status
        payment_type{
          id
          name
          type
        }
        amount
        creation {
            time
        }
      }
      completion_time
      state
      order_type{
        id
        type
        name
      }
      status{
        id
        name
        order
      }
      total_price{
        amount
        tax_amount   
        gross_amount
        taxes{
          id
          name
          rate
          amount
        }
      }
      promotions{
        id
        type
        name
        amount
      }
      items{
        product{
          stocked
          images{
            id
            url
            thumbnail
          }
        }
        product_id
        bar_code
        category{
          id
          name
        }
        is_manual
        name
        price{
          quantity
          amount
          taxes{
            id
            rate
            name
            amount
          }
        }
        addons{
          id
          name
          addon_id
          group{
            id
            name
          }
          price{
            quantity
            amount
            taxes{
              id
              rate
              name
              amount
            }
          }
        }
        promotions{
          id
          type
          name
          amount
        }
      }
    }
  }
`;

export const GET_STORE_TABLES = `
  {
    tables {
      id
      duration
      status
      name
      code
      position
      max_guests
      table_no
      type
      floor {
        id
        name
        type
      }
    }
  }
`;
