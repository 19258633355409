import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: theme.spacing(100),
      paddingRight: theme.spacing(7),
      [theme.breakpoints.down('xs')]: {
        width: 'unset',
        paddingRight: 0,
      },
    },
    boxItem: {
      height: theme.spacing(71.5),
      position: 'relative',
      cursor: 'pointer',
    },
    boxContainer: {
      width: '97%',
      height: theme.spacing(45),
      borderRadius: theme.spacing(1),
      backgroundColor: Colors.WHITE,
      position: 'absolute',
      bottom: theme.spacing(4),
      left: theme.spacing(1.5),
      boxShadow: '0px 1px 3px #cecece',
      [theme.breakpoints.down('xs')]: {
        height: 'fit-content',
        width: '96%',
      },
    },
    boxLogo: {
      position: 'absolute',
      width: theme.spacing(17),
      height: theme.spacing(18.5),
      backgroundColor: '#dbe0e4',
      borderRadius: theme.spacing(1),
      top: -theme.spacing(12),
      left: '50%',
      marginLeft: -theme.spacing(8.75),
    },
    boxBanner: {
      width: '100%',
      height: '50%',
      backgroundColor: '#e7ebee',
      display: 'flex',
      justifyContent: 'center',
    },
    bannerImage: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    bannerImageEmpty: {
      height: '50%',
      margin: theme.spacing(1),
    },
    logoImage: {
      objectFit: 'cover',
      width: theme.spacing(17),
      height: theme.spacing(18.5),
      borderRadius: theme.spacing(1),
    },
    storeName: {
      textAlign: 'center',
      ...CommonFonts.darkFont18,
      fontWeight: '600',
      [theme.breakpoints.down('xs')]: {
        ...CommonFonts.darkFont16,
      },
    },
    businessType: {
      textAlign: 'center',
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        ...CommonFonts.darkFont14,
        marginTop: theme.spacing(1),
      },
    },
    soldItem: {
      ...CommonFonts.darkFont14,
      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('xs')]: {
        ...CommonFonts.darkFont12,
        padding: theme.spacing(0, 2),
      },
    },
    address: {
      ...CommonFonts.darkFont12,
    },
    boxTime: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(2.5),
      },
    },
    icon: {
      fontSize: 16,
      color: '#bec7cc',
      marginRight: theme.spacing(1),
    },
  })
);

export default useStyles;
