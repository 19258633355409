import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Typography, IconButton } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import useStyles from '../styles';
import { selectNotifications } from '@/store/modules/notification/selectors';
import NotificationActions from '@/store/modules/notification/actions';

const Notifications = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { onClose } = props;

  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      NotificationActions.updateNotifications(
        notifications.map((item) => ({ ...item, is_new: false }))
      )
    );
  }, []);

  return (
    <div className={classes.drawerContent}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.titleNotification}>
          {t('notification.title')}
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          className={classes.closeIcon}
        >
          <CloseOutlined style={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
      {notifications.map((notification, id) => (
        <Box
          key={`notification-${id}`}
          className={classes.notificationCard}
        >
          <Typography className={classes.contentTitle}>
            {notification.data?.title}
          </Typography>
          <Typography className={classes.contentDescription}>
            {notification.data?.content}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

Notifications.propTypes = {
  loading: PropTypes.bool,
  isLoading: PropTypes.bool,
  userInvitationData: PropTypes.any,
  onClose: PropTypes.func,
  onDecline: PropTypes.func,
  onAccept: PropTypes.func,
};

export default Notifications;
