import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      flexDirection: 'column',
      display: 'flex',
      maxWidth: '100%',
      padding: 0,
    },
    container: {
      flex: 'auto',
    },
  })
);

export default useStyles;
