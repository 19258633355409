import { gql } from '@apollo/client';
export const GET_CATEGORIES = gql`
  query getCategories {
    categories {
      id
      image {
        id
        thumbnail
      }
      age_restrict
      name
      parent
      productCount
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const GET_ALL_CATEGORIES = gql`
  query getAllCategories {
    categories {
      id
      image {
        id
        thumbnail
      }
      age_restrict
      name
      parent
      productCount
      taxes {
        id
        name
        rate
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts($filter: Filter) {
    products(filter: $filter) {
      id
      description
      short_description
      images {
        id
        thumbnail
        url
      }
      name
      bar_code
      category {
        id
        name
      }
      prices {
        store_id
        price_infos {
          price_type {
            id
            name
            type
          }
          price
          taxes {
            id
            rate
            name
          }
        }
      }
      inner_item {
        id
        name
        bar_code
      }
      inner_item_measure_amount
      measure {
        id
        type
      }
      addons {
        id
        group
        allow_free
        mandatory
        multi_selection
        default_all
        description
        position
      }
      expires
      sku_no
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
      }
      ingredients {
        id
        name
        ean_upc
        ingredient_measure_amount
        measure {
          id
          type
        }
      }
      allergies {
        id
        name
        image {
          id
          thumbnail
          url
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_NAME = gql`
  query getProducts(
    $name: String
    $touchpoint_type: String
    $filter: Filter
  ) {
    products(
      name: $name
      touchpoint_type: $touchpoint_type
      filter: $filter
    ) {
      id
      description
      short_description
      stocked
      images {
        id
        thumbnail
        url
      }
      name
      bar_code
      category {
        id
        name
      }
      prices {
        store_id
        price_infos {
          price_type {
            id
            name
            type
          }
          price
          taxes {
            id
            rate
            name
          }
        }
      }
      inner_item {
        id
        name
        bar_code
      }
      inner_item_measure_amount
      measure {
        id
        type
      }
      addons {
        id
        group
        description
        position
        allow_free
        mandatory
        multi_selection
        default_all
        options {
          position
          name
          images {
            id
            thumbnail
          }
          price {
            fixed_price
          }
          force_charge
          extra
        }
      }
      expires
      sku_no
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
      }
      ingredients {
        id
        name
        ean_upc
        ingredient_measure_amount
        measure {
          id
          type
        }
      }
      allergies {
        id
        name
        image {
          id
          thumbnail
          url
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY_ID = gql`
  query getProductsByCategoryId(
    $name: String
    $touchpoint_type: String
    $category_id: String
    $filter: Filter
  ) {
    products(
      name: $name
      touchpoint_type: $touchpoint_type
      category_id: $category_id
      filter: $filter
    ) {
      id
      description
      short_description
      stocked
      images {
        id
        thumbnail
        url
      }
      name
      bar_code
      category {
        id
        name
      }
      prices {
        store_id
        price_infos {
          price_type {
            id
            name
            type
          }
          price
          taxes {
            id
            rate
            name
          }
        }
      }
      inner_item {
        id
        name
        bar_code
      }
      inner_item_measure_amount
      measure {
        id
        type
      }
      addons {
        id
        group
        description
        position
        allow_free
        mandatory
        multi_selection
        default_all
        options {
          position
          name
          images {
            id
            thumbnail
          }
          price {
            fixed_price
          }
          force_charge
          extra
        }
      }
      expires
      sku_no
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
      }
      ingredients {
        id
        name
        ean_upc
        ingredient_measure_amount
        measure {
          id
          type
        }
      }
      allergies {
        id
        name
        image {
          id
          thumbnail
          url
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_ID = gql`
  query getProductsByCategoryId($id: String) {
    products(id: $id) {
      id
      description
      short_description
      stocked
      images {
        id
        thumbnail
        url
      }
      name
      bar_code
      category {
        id
        name
      }
      allergies {
        id
        name
        image {
          id
          thumbnail
        }
      }
      prices {
        store_id
        price_infos {
          price_type {
            id
            name
            type
          }
          price
          taxes {
            id
            rate
            name
          }
        }
      }
      inner_item {
        id
        name
        bar_code
      }
      inner_item_measure_amount
      measure {
        id
        type
      }
      addons {
        id
        group
        description
        position
        allow_free
        mandatory
        multi_selection
        default_all
        options {
          position
          name
          images {
            id
            thumbnail
          }
          price {
            fixed_price
          }
          force_charge
          extra
        }
      }
      expires
      sku_no
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
      }
      ingredients {
        id
        name
        ean_upc
        ingredient_measure_amount
        measure {
          id
          type
        }
      }
      allergies {
        id
        name
        image {
          id
          thumbnail
          url
        }
      }
    }
  }
`;

export const GET_GLOBAL_CATEGORIES = gql`
  query getGlobalCategory($name: String) {
    globalCategory(name: $name) {
      id
      name
      image {
        id
        url
      }
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const GET_TAXES = gql`
  query {
    store {
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const ADD_NEW_CATEGORY = gql`
  mutation ($input: CategoryInput!) {
    addCategory(input: $input) {
      id
      name
      tax_id
      parent
      age_restrict
      age_restriction
      sell_time_restrict
      sell_time_from
      sell_time_until
      commission
      kitchen_print
      image {
        id
      }
    }
  }
`;
export const EDIT_CATEGORY = gql`
  mutation ($input: CategoryInput!) {
    updateCategory(input: $input) {
      id
      name
      tax_id
      parent
      age_restrict
      age_restriction
      sell_time_restrict
      sell_time_from
      sell_time_until
      commission
      kitchen_print
      image {
        id
      }
    }
  }
`;
export const GET_CATEGORY_BY_ID = gql`
  query getCategoryById($id: String) {
    categories(id: $id) {
      id
      image {
        id
        thumbnail
      }
      name
      parent
      productCount
      taxes {
        id
        name
        rate
      }
      tax_id
      parent
      age_restrict
      age_restriction
      sell_time_restrict
      sell_time_from
      sell_time_until
      commission
      kitchen_print
    }
  }
`;

// Add product
export const ADD_PRODUCT = gql`
  mutation ($product: ProductInput!) {
    addProduct(input: $product) {
      id
    }
  }
`;

// Update product
export const UPDATE_PRODUCT = gql`
  mutation ($product: ProductInput!) {
    updateProduct(input: $product) {
      id
      images {
        thumbnail
      }
      name
      bar_code
      category {
        id
        name
      }
      prices {
        price_infos {
          price_type {
            id
            name
            type
          }
          price
          taxes {
            id
            rate
            name
          }
        }
      }
      inner_item {
        id
        name
        bar_code
      }
      inner_item_measure_amount
      measure {
        id
        type
      }
      addons {
        id
        group
        allow_free
        mandatory
        multi_selection
        default_all
        description
        position
      }
      expires
      sku_no
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
      }
      ingredients {
        id
        name
        ean_upc
        ingredient_measure_amount
        measure {
          id
          type
        }
      }
    }
  }
`;

// Add product
export const DELETE_PRODUCT = gql`
  mutation ($input: [RemoveProductInput]!) {
    removeProduct(input: $input) {
      id
    }
  }
`;

// Move products
export const MOVE_PRODUCTS = gql`
  mutation ($input: MovingCategoryProductInput!) {
    movingCategoryProducts(input: $input)
  }
`;
// Get addonGroup by name
export const GET_ADDON_GROUP_BY_NAME = `
  query getAddonGroupByName($filter: Filter){
    addonGroups(filter: $filter){
      id
      group
      allow_free
      mandatory
      multi_selection
      default_all
      description
      addons {
        id
        name
        inventory_id
        bar_code
        measure_amount
        measure_type
        measure_id
        default
        force_charge
        extra
        fixed_price
      }
    }
  }
`;

// Get addon-groups
export const GET_ADDON_GROUPS = gql`
  query {
    addonGroups {
      id
      group
      allow_free
      mandatory
      multi_selection
      default_all
      description
      addons {
        id
        name
        inventory_id
        bar_code
        measure_amount
        measure_type
        measure_id
        default
        force_charge
        extra
        fixed_price
      }
    }
  }
`;

// Add addons
export const ADD_ADDONS = gql`
  mutation ($input: [AddonInput]!) {
    addAddons(input: $input) {
      id
    }
  }
`;

// Remove addons
export const REMOVE_ADDONS = gql`
  mutation ($input: [RemoveAddonInput]!) {
    removeAddons(input: $input) {
      id
    }
  }
`;

// Add addonGroup
export const ADD_ADDON_GROUP = gql`
  mutation ($input: ProductGroupAddonInput!) {
    addAddonGroup(input: $input) {
      id
    }
  }
`;

// Remove addonGroup
export const REMOVE_ADDON_GROUP = gql`
  mutation ($input: ProductGroupAddonInput!) {
    removeAddonGroup(input: $input) {
      id
    }
  }
`;

// Get ingredients
export const GET_INGREDIENTS = gql`
  query getIngredients($filter: Filter) {
    ingredients(filter: $filter) {
      id
      name
      images {
        thumbnail
      }
      bar_code
      category {
        id
        name
      }
      measure {
        id
        type
      }
      expires
      sku_no
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
      }
    }
  }
`;

// Add ingredient
export const ADD_INGREDIENT = gql`
  mutation ($input: [IngredientInput]!) {
    addIngredients(input: $input) {
      id
    }
  }
`;

// Remove ingredient
export const REMOVE_INGREDIENT = gql`
  mutation ($input: [RemoveProductInput]!) {
    removeIngredient(input: $input) {
      id
    }
  }
`;

export const GET_INVENTORY_BY_BARCODE_ID = `
  query getInventoryByBarcodeAndId($filter: Filter){
    inventories(filter: $filter){
      id
      name
      bar_code
      measure{
        id
        type
        name
      }
    }
  }
`;

export const GET_ALLERGY_BY_NAME = `
  query getAllergy($filter: Filter){
    globalAllergies(filter: $filter){
      id
      name
      image{
        id
        thumbnail
        url
      }
    }
  }
`;

export const MUTATE_ALLERGY = `
  mutation addGlobalAllergies($input: [AllergyInput!]!) {
    addGlobalAllergies(input: $input) {
        id
        name
        image{
          id
          thumbnail
          url
        }
    }
  }
`;

export const PRODUCT_GROUP_ADD_ON = `
  query getProducGroupAddon($group: String){
    productAddons(group: $group){
      allow_free
      default_all
      description
      group
      id
      mandatory
      multi_selection
      parent
      position
      options{
        bar_code
        default
        extra
        force_charge
        id
        images{
          id
          thumbnail
          url
        }
        inventory_id
        mandatory
        name
        override_price
        position
        price{
          fixed_price
          measure
          type
          price_infos{
            price
            price_type{
              default
              description
              id
              is_trade
              name
              type
            }
            taxes{
              id
              name 
              rate
            }
          }
        }
      }
    }
  }
`;
