import { Grid, Hidden, Link, Box } from '@material-ui/core';
import React from 'react';
import {
  Route,
  Switch,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Directions } from './helper';
import OrdersPage from './orders';

const OrderManagementPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const urlName = location.pathname;

  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Grid container>
        <Hidden smDown>
          <Grid item md={2}>
            <div className={classes.boxSidebar}>
              {Directions?.map((item) => (
                <div
                  key={item.name}
                  className={
                    item.path === urlName
                      ? classes.boxLinkActive
                      : classes.boxLink
                  }
                >
                  {item.disabled ? (
                    <Box className={classes.linkText}>
                      {t(`orderManagement.${item.name}`)}
                    </Box>
                  ) : (
                    <Link
                      underline="none"
                      component={RouterLink}
                      className={classes.linkText}
                      color="textPrimary"
                      to={item.path}
                    >
                      {t(`orderManagement.${item.name}`)}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </Grid>
        </Hidden>
        <Grid item md={10} sm={12} xs={12}>
          <Switch>
            <Route
              exact
              path="/order-management/orders"
              component={OrdersPage}
            />
          </Switch>
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default OrderManagementPage;
