import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import {
  Dialog,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import AuthActions, { AuthTypes } from '@/store/modules/auth/actions';
import { selectIsApiProgress } from '@/store/modules/apiLoopState';

import images from '@/assets/images';
import useStyles from './styles';
import EmailLoginLayout from '@/pages/auth/loginPage/emailLoginLayout';
import MobileLoginLayout from '@/pages/auth/loginPage/mobileLoginLayout';
import SignupModal from '@/components/signupModal';

const LoginModal = ({
  isVisible,
  onCloseModal,
  createUser,
  isCreatingUser,
}) => {
  const classes = useStyles();

  const [emailLogin, setEmailLogin] = useState(true);
  const [needSignup, setNeedSignup] = useState(false);
  const handleLoginLayout = () => setEmailLogin(!emailLogin);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const newEmail = useRef();

  const onUserNotFound = (value) => {
    newEmail.current = value;
    setNeedSignup(true);
  };

  const [hasSignupSucceed, setHasSignupSucceed] = useState(false);
  const [hasSignupFailed, setHasSignupFailed] = useState(false);

  const onEmailSignup = ({ email, password }) => {
    createUser({
      email,
      password,
      redirect_url: '',
      onSuccess: () => setHasSignupSucceed(true),
      onFailed: () => setHasSignupFailed(true),
    });
  };

  return needSignup ? (
    <SignupModal
      open={true}
      onClose={onCloseModal}
      onEmailLogin={() => setNeedSignup(false)}
      onEmailSignup={onEmailSignup}
      defaultEmail={newEmail.current}
      isSignupProgress={isCreatingUser}
      hasSignupSucceed={hasSignupSucceed}
      handleSignupSucceed={() => setHasSignupSucceed(false)}
      hasSignupFailed={hasSignupFailed}
      handleSignupFailed={() => setHasSignupFailed(false)}
    />
  ) : (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      disableBackdropClick
      classes={{
        root: classes.dialog,
        paperWidthSm: classes.paperWidthSm,
        paper: classes.paper,
      }}
      open={isVisible}
    >
      <IconButton
        onClick={onCloseModal}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Grid
        container
        justify="center"
        className={classes.logoContainer}
      >
        <img src={images.logo} width={150} height={60} alt="logo" />
      </Grid>

      <div className={classes.mainContainer}>
        {emailLogin ? (
          <EmailLoginLayout
            centerAlign={true}
            onChangeLoginLayout={handleLoginLayout}
            styleClass={classes.root}
            onUserNotFound={onUserNotFound}
          />
        ) : (
          <MobileLoginLayout
            centerAlign={true}
            onChangeLoginLayout={handleLoginLayout}
            styleClass={classes.root}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  isCreatingUser: selectIsApiProgress(state, AuthTypes.CREATE_USER),
});

const actions = { ...AuthActions };

export default connect(mapStateToProps, actions)(LoginModal);
