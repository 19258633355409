import { createReducer } from 'reduxsauce';

import { INITIAL_STATE } from './initialState';
import { MerchantTypes } from './actions';
import { arraysEqual } from '@/utils/collectionUtil';
import _ from 'lodash';

export const setCurrentMerchant = (state, { data }) => {
  return {
    ...state,
    currentMerchant: data,
  };
};

export const setSelectedStore = (state, { data }) => {
  if (!!data?.id) {
    return {
      ...state,
      selectedStore: data,
    };
  } else {
    return { ...state };
  }
};

export const setSelectedRegion = (state, { selectedRegion }) => ({
  ...state,
  selectedRegion,
});

export const updateSelectedStore = (state, { data }) => {
  return {
    ...state,
    selectedStore: { ...state.selectedStore, ...data },
    currentMerchant: {
      ...state.currentMerchant,
      stores: state.currentMerchant.stores.map((store) =>
        store.id === data.id ? { ...store, ...data } : store
      ),
    },
  };
};

export const setMerchantSettings = (state, { merchantSettings }) => {
  return {
    ...state,
    merchantSettings,
  };
};

export const setSelectedOrderType = (state, { data }) => {
  return {
    ...state,
    selectedOrderType: data,
  };
};

export const setOrderTypes = (state, { orderTypes }) => {
  return {
    ...state,
    orderTypes,
  };
};

export const setLocalisation = (state, { localisation }) => {
  return {
    ...state,
    localisation,
  };
};

export const setStoreSearch = (state, { data }) => {
  return {
    ...state,
    storeSearch: {
      data: data.data,
      temp: data.temp,
      isInit: data.isInit,
      isLoading: data.isLoading,
    },
  };
};

export const clearCart = (state, { data }) => {
  return {
    ...state,
    shoppingCart: state.shoppingCart.filter(
      (cart) =>
        cart.merchantId !== data.merchantId &&
        cart.storeId !== data.storeId
    ),
    selectedOrderType: INITIAL_STATE.selectedOrderType,
  };
};

export const addUpdateCart = (state, { data }) => {
  const existCart = state.shoppingCart?.find(
    (item) =>
      item.merchantId === data.merchantId &&
      item.storeId === data.storeId
  );

  const existItemCart = existCart?.cart?.find(
    (item) =>
      _.isEqual(item.product_id, data.cart.product_id) &&
      (arraysEqual(item?.addons, data?.cart?.addons) ||
        (_.isEmpty(item.addons) && _.isEmpty(data.cart?.addons)))
  );

  const isUpdate = !!existItemCart;

  return {
    ...state,
    shoppingCart: existCart
      ? state.shoppingCart.map((item) => {
          return {
            ...item,
            cart: isUpdate
              ? item.cart.map((cart) =>
                  existItemCart.id === cart.id
                    ? {
                        ...cart,
                        price: {
                          ...cart.price,
                          quantity:
                            cart.price.quantity +
                            data.cart.price.quantity,
                        },
                      }
                    : cart
                )
              : [...item.cart, data.cart],
          };
        })
      : [
          ...state.shoppingCart,
          {
            merchantId: data.merchantId,
            storeId: data.storeId,
            cart: [data.cart],
          },
        ],
  };
};

export const removeItemCart = (state, { data }) => {
  return {
    ...state,
    shoppingCart: state.shoppingCart.map((item) =>
      item.merchantId === data.merchantId &&
      item.storeId === data.storeId
        ? {
            ...item,
            cart: item.cart.filter((cart) => cart.id !== data.id),
          }
        : item
    ),
  };
};

export const updateQuantityItemCart = (state, { data }) => {
  return {
    ...state,
    shoppingCart: state.shoppingCart.map((item) =>
      item.merchantId === data.merchantId &&
      item.storeId === data.storeId
        ? {
            ...item,
            cart: item.cart.map((cart) =>
              _.isEqual(cart.id, data.id)
                ? {
                    ...cart,
                    price: { ...cart.price, quantity: data.quantity },
                  }
                : cart
            ),
          }
        : item
    ),
  };
};

export const addOrderToCart = (state, { data }) => {
  return {
    ...state,
    shoppingCart: [
      ...state.shoppingCart,
      {
        merchantId: data.merchantId,
        storeId: data.storeId,
        cart: [...data.cart],
      },
    ],
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [MerchantTypes.SET_CURRENT_MERCHANT]: setCurrentMerchant,
  [MerchantTypes.SET_SELECTED_STORE]: setSelectedStore,
  [MerchantTypes.SET_SELECTED_REGION]: setSelectedRegion,
  [MerchantTypes.UPDATE_SELECTED_STORE]: updateSelectedStore,
  [MerchantTypes.SET_MERCHANT_SETTINGS]: setMerchantSettings,
  [MerchantTypes.SET_SELECTED_ORDER_TYPE]: setSelectedOrderType,
  [MerchantTypes.SET_STORE_SEARCH]: setStoreSearch,
  [MerchantTypes.CLEAR_CART]: clearCart,
  [MerchantTypes.ADD_UPDATE_CART]: addUpdateCart,
  [MerchantTypes.REMOVE_ITEM_CART]: removeItemCart,
  [MerchantTypes.UPDATE_QUANTITY_ITEM_CART]: updateQuantityItemCart,
  [MerchantTypes.SET_ORDER_TYPES]: setOrderTypes,
  [MerchantTypes.SET_LOCALISATION]: setLocalisation,
  [MerchantTypes.ADD_ORDER_TO_CART]: addOrderToCart,
});
