import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(12)}px !important`,
      border: 'unset !important',
      borderRadius: 'unset !important',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(12, 6, 18, 6),
      },
    },
    signupButton: {
      ...CommonFonts.blueFont18,
      textTransform: 'unset',
      fontWeight: '600',
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        ...CommonFonts.blueFont16,
      },
    },
    loginButton: {
      ...CommonFonts.blueFont18,
      textTransform: 'unset',
      fontWeight: '600',
      [theme.breakpoints.down('xs')]: {
        ...CommonFonts.blueFont16,
      },
    },
    authorizePopoverContainer: {
      '&>div.MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        width: 500,
        marginTop: theme.spacing(5.5),
        marginLeft: -theme.spacing(3.5),
      },
    },
    icon: {
      color: Colors.GRAY_REGULAR,
    },
  })
);

export default useStyles;
