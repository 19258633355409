import { gql } from '@apollo/client';

export const GET_BANNERS = gql`
  query getBanner($country: String) {
    banners(country: $country) {
      id
      image {
        url
      }
      merchant_id
      store_id
      country
    }
  }
`;
