import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const StoreInfoCard = ({ currentStore }) => {
  const { name, address } = currentStore || {};
  const classes = useStyles();
  const { t } = useTranslation();

  const Address = () => {
    return (
      <Box>
        {address?.door_number && (
          <Typography className={classes.text}>
            {address.door_number}
          </Typography>
        )}
        {address?.line1 && (
          <Typography className={classes.text}>
            {address.line1}
          </Typography>
        )}
        {address?.line2 && (
          <Typography className={classes.text}>
            {address.line2}
          </Typography>
        )}
        {address?.country && (
          <Typography className={classes.text}>
            {address.country}
          </Typography>
        )}
        {address?.postcode && (
          <Typography className={classes.text}>
            {address.postcode}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>
        {t('cart.store')}
      </Typography>
      <Typography className={classes.text}>{name}</Typography>
      <Address />
    </Box>
  );
};

export default StoreInfoCard;
