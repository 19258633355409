import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: '#e7ebee',
      minHeight: theme.spacing(52),
      position: 'relative',
    },
    button: {
      textTransform: 'capitalize',
      ...CommonFonts.darkFont18,
      padding: theme.spacing(4, 3),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    whiteButton: {
      ...CommonFonts.darkFont18,
      justifyContent: 'flex-start',
      width: 400,
      fontWeight: 300,
      backgroundColor: Colors.WHITE,
      bottom: 25,
    },
    greyButton: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(3),
      backgroundColor: Colors.GRAY_LIGHT,
      height: 50,
      width: 350,
    },
    name: {
      textAlign: 'center',
      letterSpacing: 0.16,
      padding: theme.spacing(4, 0, 1, 0),
      ...CommonFonts.darkFont14,
      wordBreak: 'break-word',
    },
    email: {
      textAlign: 'center',
      fontWeight: 600,
      letterSpacing: 0.56,
      ...CommonFonts.darkFont14,
      wordBreak: 'break-word',
    },
    link: {
      textDecoration: 'none',
    },
    cameraIcon: {
      position: 'absolute',
      backgroundColor: Colors.WHITE,
      bottom: theme.spacing(5),
      right: theme.spacing(6),
      padding: theme.spacing(2.5),
      '&:hover': {
        boxShadow: '10px 10px 10px 30px white inset !important',
        filter: 'brightness(0.7)',
        transition: 'all 0.2s ease-in-out',
      },
      '& .MuiSvgIcon-root': {
        color: '#99a3ad',
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    uploadInput: {
      display: 'none',
    },
    noneBackground: {
      backgroundColor: 'unset',
    },
    boxAvatarInfo: {
      position: 'relative',
      height: theme.spacing(70),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    banner: {
      width: '100%',
      objectFit: 'cover',
      maxHeight: theme.spacing(70),
    },
    emptyBanner: {
      width: '100%',
      maxHeight: theme.spacing(70),
      [theme.breakpoints.down('xs')]: {
        width: 'unset',
      },
    },
    box: {
      width: theme.spacing(39),
      height: theme.spacing(23),
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    boxUpdateImage: {
      width: theme.spacing(39),
      height: theme.spacing(7),
      borderRadius: theme.spacing(0.5),
      position: 'absolute',
      backgroundColor: '#20272F',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      opacity: 0.9,
    },
    boxWithoutImage: {
      width: theme.spacing(39),
      height: theme.spacing(23),
      backgroundColor: '#BAC3C9',
      borderRadius: theme.spacing(0.5),
      opacity: 0.4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    boxImage: {
      width: theme.spacing(39),
      height: theme.spacing(23),
      borderRadius: theme.spacing(0.5),
      objectFit: 'cover',
    },
    tab: {
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
    tabs: {
      '& .MuiTabs-flexContainer': {
        borderBottomWidth: 2,
        borderBottomColor: '#E2E2E2',
        borderBottomStyle: 'solid',
      },
    },
    boxImageUpload: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(25),
      paddingBottom: theme.spacing(25),
    },
    dropZone: {
      marginTop: theme.spacing(5),
      borderRadius: theme.spacing(1),
      width: '470px',
      height: '160px',
      backgroundColor: '#e7f1fe',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleUploadImage: {
      ...CommonFonts.darkFont24,
      fontWeight: '600',
    },
    titleDrop: { ...CommonFonts.darkFont18 },
    titleBrowse: {
      ...CommonFonts.darkFont18,
      color: '#1174F2',
      marginLeft: theme.spacing(1),
      fontWeight: '600',
      cursor: 'pointer',
    },
    boxImagePreview: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(14),
      marginBottom: theme.spacing(14),
      cursor: 'pointer',
    },
    imagePreview: {
      maxWidth: '825px',
    },
    progressBar: {
      marginTop: theme.spacing(4),
      '& .MuiLinearProgress-root': {
        width: theme.spacing(75),
        height: '5px',
      },
    },
    closeButton: {
      backgroundColor: Colors.GRAY_LIGHT,
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3.5),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1,
      },
    },
    closeIcon: {
      fontSize: 12,
    },
    changeImage: {
      ...CommonFonts.grayFont12,
      cursor: 'pointer',
      textAlign: 'center',
    },
    errorText: {
      marginTop: theme.spacing(10),
      textAlign: 'center',
      ...CommonFonts.darkFont16,
      color: Colors.RED,
      fontWeight: 500,
    },
  })
);

export default useStyles;
