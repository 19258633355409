import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Button,
  IconButton,
  Grid,
  Hidden,
  useMediaQuery,
  useTheme,
  Drawer,
  Badge,
} from '@material-ui/core';
import NotificationIcon from '@material-ui/icons/NotificationsNone';
import Notifications from './drawerContents/Notifications';

import images from '@/assets/images';
import { Colors } from '@/theme';
import Avatar from '@/components/header/avatar';
import { selectCurrentUser } from '@/store/modules/auth';
import { getFirstLetter } from '@/utils/stringUtil';

import useStyles from './styles';
import useHeaderStyles from '../styles';
import { useSelector } from 'react-redux';
import { NotificationContext } from '@/components/notification';
import _ from 'lodash';
import { selectNotifications } from '@/store/modules/notification/selectors';

const DRAWER_TYPES = {
  HELP: 'HELP',
  NOTIFICATION: 'NOTIFICATION',
};

const UserInfo = (props) => {
  const {
    handleOpenDialog,
    currentUser: { first_name, last_name, config, email },
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const theme = useTheme();

  const notifications = useSelector(selectNotifications);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const count = notifications?.filter(
    (item) => !!item.is_new
  )?.length;

  const [menuVisible, setMenuVisible] = useState(false);
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.HELP);

  const drawerContents = {
    [DRAWER_TYPES.NOTIFICATION]: (
      <Notifications onClose={() => setMenuVisible(false)} />
    ),
  };
  const thumbnail = localStorage.getItem('user_thumbnail');

  return (
    <Grid
      container
      justify={isMobile ? 'flex-end' : 'space-between'}
      alignItems="center"
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <IconButton
          className={headerClasses.iconContainer}
          onClick={() => {
            setMenuVisible(true);
            setDrawerType(DRAWER_TYPES.NOTIFICATION);
          }}
        >
          <Badge
            className={classes.badge}
            badgeContent={count}
            color="primary"
          >
            <NotificationIcon
              fontSize="medium"
              className={classes.notificationIcon}
            />
          </Badge>
        </IconButton>

        <Drawer
          anchor={'right'}
          open={menuVisible}
          onClose={() => setMenuVisible(false)}
        >
          {drawerContents[drawerType]}
        </Drawer>
      </Grid>
      <Grid item>
        <Avatar
          url={thumbnail}
          firstLetter={getFirstLetter(first_name)}
          secondLetter={getFirstLetter(last_name)}
          handleOpenDialog={handleOpenDialog}
          backgroundColor={config?.background_colour}
          smallSize
          width={40}
          height={40}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(UserInfo);
