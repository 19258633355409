import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import useStyles from './styles';
import { GET_CATEGORIES } from '@/services/storeService';
import CategorySlider from './categorySilder';
import { useTranslation } from 'react-i18next';
import {
  GET_PRODUCTS_BY_NAME,
  GET_PRODUCTS_BY_CATEGORY_ID,
} from '@/services/inventoryService';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductItem from './productItem';
import TextField from '@/components/textField';
import SearchIcon from '@material-ui/icons/Search';
import useDebounce from '@/hooks/useDebounce';
import images from '@/assets/images';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { getCurrencySymbol } from '@/utils/stringUtil';
import ProductDetail from './productDetail';
import { selectShoppingCart } from '@/store/modules/store';

const categoryQueries = gql`
  ${GET_CATEGORIES}
`;

const StoreProduct = ({ currentStore, pricingType, isNetPrice }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { merchantId, storeId, productId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const shoppingCarts = useSelector(selectShoppingCart);
  const userCart = shoppingCarts?.find(
    (cart) =>
      _.isEqual(cart.merchantId, merchantId) &&
      _.isEqual(cart.storeId, storeId)
  )?.cart;

  const [selectedCategory, setSelectedCategory] = useState('all');
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isProductsLoaded, setIsProductsLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearch = useDebounce(searchValue, 1000);

  const currency = getCurrencySymbol(
    currentStore?.localisation?.currency_symbol
  );
  const byCategory =
    currentStore?.settings?.touchpoint_settings?.digital_front
      ?.product_view?.by_category;

  const sortByCategoryName =
    currentStore?.settings?.touchpoint_settings?.digital_front
      ?.product_view?.sort_by_name;

  const categorySetting =
    currentStore?.settings?.touchpoint_settings?.digital_front
      ?.product_view?.categories;

  const categoryIdsSetting = categorySetting?.map((item) => item.id);

  const findCategorySetting = (id) =>
    categorySetting?.find((item) => _.isEqual(item.id, id));

  const { data: categoriesData, loading: loadingCategories } =
    useQuery(categoryQueries);

  const tempCategories = byCategory
    ? categoriesData?.categories
        ?.filter((category) =>
          categoryIdsSetting?.includes(category.id)
        )
        ?.map((item) =>
          !_.isEmpty(findCategorySetting(item.id)) &&
          !sortByCategoryName
            ? {
                ...item,
                position: findCategorySetting(item.id).position,
              }
            : item
        )
    : categoriesData?.categories;

  const orderingCategory = !sortByCategoryName
    ? tempCategories?.slice()?.sort((a, b) => a.position - b.position)
    : tempCategories
        ?.slice()
        ?.sort((a, b) => a.name.localeCompare(b.name));

  const categories = [
    {
      id: 'all',
      name: t('store.all_categories'),
      position: sortByCategoryName ? null : 0,
      __typename: 'Category',
    },
  ]?.concat(orderingCategory);

  const { loading: loadingProducts } = useQuery(
    _.isEqual(selectedCategory, 'all')
      ? GET_PRODUCTS_BY_NAME
      : GET_PRODUCTS_BY_CATEGORY_ID,
    {
      variables: !_.isEqual(selectedCategory, 'all')
        ? {
            name: `%${debounceSearch}%`,
            touchpoint_type: 'digital_front',
            category_id: selectedCategory,
            filter: {
              count: true,
              page: currentPage,
              limit: 10,
            },
          }
        : {
            name: `%${debounceSearch}%`,
            touchpoint_type: 'digital_front',
            filter: {
              count: true,
              page: currentPage,
              limit: 10,
            },
          },
      onCompleted: (data) => {
        // if response give us less then 10 results, set all products loaded
        if (data.products.length < 10) {
          setIsProductsLoaded(true);
        } else {
          setIsProductsLoaded(false);
        }

        // update the array holding products
        setProducts([...products, ...data.products]);
      },
      onError: () => {
        setIsProductsLoaded(false);
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: { storeId, merchantId },
      },
    }
  );

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (_.isEmpty(products)) return;
    setCurrentPage(currentPage + 1);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setCurrentPage(1);
    setProducts([]);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setCurrentPage(1);
    setProducts([]);
  };

  return (
    <>
      {_.isEmpty(productId) ? (
        <Box>
          <TextField
            className={classes.searchInput}
            name="productSearch"
            placeholder={t('store.search_product_name')}
            endAdornment={<SearchIcon />}
            value={searchValue}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          {loadingCategories ? (
            <Box display="flex" justifyContent="center" width="100%">
              <Skeleton height={80} width="100%" />
            </Box>
          ) : !_.isEmpty(tempCategories) && byCategory ? (
            <Box className={classes.boxSlider}>
              <CategorySlider
                options={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={handleCategoryChange}
              />
            </Box>
          ) : null}

          <Box>
            {!loadingProducts &&
            _.isEmpty(products) &&
            _.isEqual(searchValue, debounceSearch) ? (
              <Box
                paddingY={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={images.noProducts}
                  width={151}
                  height={151}
                />
                <Typography className={classes.noItemFound}>
                  {t('store.no_products_found')}
                </Typography>
              </Box>
            ) : (loadingProducts &&
                _.isEmpty(products) &&
                currentPage === 1) ||
              (!loadingProducts &&
                _.isEmpty(products) &&
                !_.isEqual(searchValue, debounceSearch)) ? (
              <Box
                display="flex"
                justifyContent="center"
                paddingY={6}
                flexWrap="wrap"
              >
                {[...Array(isMobile ? 1 : 10)].map((item, index) => (
                  <Box key={index} margin={4}>
                    <Skeleton
                      variant="rect"
                      width={270}
                      height={300}
                    />
                    <Skeleton
                      variant="rect"
                      width={270}
                      height={60}
                      style={{ marginTop: 10 }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <InfiniteScroll
                dataLength={products.length}
                next={handleLoadMore}
                hasMore={!isProductsLoaded}
                loader={
                  loadingProducts &&
                  currentPage !== 1 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      paddingY={6}
                      flexWrap="wrap"
                    >
                      {[...Array(isMobile ? 1 : 10)].map(
                        (item, index) => (
                          <Box key={`${index}-more`} margin={4}>
                            <Skeleton
                              variant="rect"
                              width={270}
                              height={300}
                            />
                            <Skeleton
                              variant="rect"
                              width={270}
                              height={60}
                              style={{ marginTop: 10 }}
                            />
                          </Box>
                        )
                      )}
                    </Box>
                  )
                }
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  paddingY={5}
                >
                  {products?.map((product, id) => (
                    <ProductItem
                      isMobile={isMobile}
                      key={`${id}-${product.id}`}
                      data={product}
                      currency={currency}
                      localisation={currentStore?.localisation}
                      merchantId={merchantId}
                      storeId={storeId}
                      pricingType={pricingType}
                      isNetPrice={isNetPrice}
                      userCart={userCart}
                    />
                  ))}
                </Box>
              </InfiniteScroll>
            )}
          </Box>
        </Box>
      ) : (
        <ProductDetail
          isNetPrice={isNetPrice}
          currency={currency}
          localisation={currentStore?.localisation}
        />
      )}
    </>
  );
};

export default StoreProduct;
