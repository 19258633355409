import React from 'react';

import useStyles from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';

import _ from 'lodash';
import * as Yup from 'yup';
import Input from '../components/input';

const ProfilePage = ({ currentUser, updateCurrentUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const firstNameSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t('user.profile.first_name_is_required')
    ),
  });

  const lastNameSchema = Yup.object().shape({
    lastName: Yup.string().required(
      t('user.profile.last_name_is_required')
    ),
  });

  const emailSchema = Yup.object().shape({
    email: Yup.string().required(t('user.profile.email_is_required')),
  });

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box padding={5}>
        <Typography className={classes.title}>
          {t('user.profile.title')}
        </Typography>
        <Grid container className={classes.boxInput}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="firstName"
                label={t('user.profile.first_name')}
                value={currentUser?.first_name || ''}
                validationSchema={firstNameSchema}
                onChange={(value) =>
                  updateCurrentUser({ key: 'first_name', value })
                }
                isShowToast={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="lastName"
                label={t('user.profile.last_name')}
                value={currentUser?.last_name || ''}
                validationSchema={lastNameSchema}
                onChange={(value) =>
                  updateCurrentUser({ key: 'last_name', value })
                }
                isShowToast={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="email"
                label={t('user.profile.email')}
                value={currentUser?.email || ''}
                validationSchema={emailSchema}
                onChange={(value) =>
                  updateCurrentUser({ key: 'email', value })
                }
                isShowToast={false}
                labelOption={
                  <Typography className={classes.labelOption}>
                    {currentUser?.verified
                      ? t('user.profile.verified')
                      : t('user.profile.unverified')}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="mobileNumber"
                label={t('user.profile.mobile_number')}
                value={currentUser?.mobile || ''}
                validationSchema={null}
                onChange={(value) =>
                  updateCurrentUser({ key: 'mobile', value })
                }
                isShowToast={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ErrorBoundary>
  );
};

export default ProfilePage;
