import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(12)}px !important`,
      border: 'unset !important',
      borderRadius: 'unset !important',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(12, 6, 18, 6),
      },
    },
    paperWidthSm: {
      maxWidth: 500,
    },
    closeButton: {
      position: 'absolute',
      right: 10,
      top: 10,
      padding: 6,
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    radioFormControl: {
      margin: theme.spacing(0, 5),
    },
    radioFormControlLabel: {
      ...CommonFonts.darkFont16,
    },
    title: {
      ...CommonFonts.darkFont22,
      marginTop: theme.spacing(5),
      fontWeight: 600,
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 48px 28px 48px',
      [theme.breakpoints.down('xs')]: {
        padding: '0px 20px 20px 20px',
      },
    },
    footer: {
      alignItems: 'center',
    },
    addButton: {
      ...CommonFonts.whiteFont14,
      padding: theme.spacing(3, 20),
    },
    boxGroupType: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
    },
  })
);

export default useStyles;
