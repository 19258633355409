import React, { useRef, useEffect } from 'react';
import { Dialog, Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './styles';

const Modal = ({
  children,
  maxWidth,
  openDialog,
  handleClose,
  anchorEl,
  styleClass,
}) => {
  const classes = useStyles();

  return (
    <Popover
      open={openDialog}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      className={classNames(classes.root, styleClass)}
      PaperProps={{
        elevation: 0,
        variant: 'outlined',
      }}
    >
      {children}
    </Popover>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  openDialog: PropTypes.bool,
  handleClose: PropTypes.func,
  styleClass: PropTypes.any,
};

export default Modal;
