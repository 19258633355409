import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import I18n from '@/i18n';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import Button from '@/components/button';
import PhoneInput from '@/components/phoneInput';

import { isValidPhoneNumber } from './helper';

import useStyles from '../styles';

const EnterMobileStep = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    onChangeLoginLayout,
    phone,
    onChangePhone,
    onNext,
    checkMobileError,
    centerAlign,
  } = props;

  const [isTouched, setIsTouched] = useState(false);

  const onClickNext = () => {
    if (isValidPhoneNumber(phone.number)) {
      onNext(`+${phone.number}`);
    } else {
      setIsTouched(true);
    }
  };

  return (
    <Grid container spacing={10}>
      <Grid
        item
        xs={12}
        style={{ textAlign: centerAlign ? 'center' : 'left' }}
      >
        <span className={classes.title}>
          {t('login_page.use_myda_account')}
        </span>
      </Grid>

      <Grid item xs={12}>
        <PhoneInput
          phone={phone}
          onChange={onChangePhone}
          onEnterKeyPress={onClickNext}
          onBlur={() => setIsTouched(true)}
          error={
            (isTouched && !isValidPhoneNumber(phone.number)) ||
            checkMobileError
          }
          helperText={t('signup.invalid_number')}
        />
        <Typography className={classes.smsText}>
          {t('login_page.you_receive_sms_opt')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justify="center"
          className={classes.buttonWrapper}
        >
          <Grid item xs={11} md={7}>
            <Button
              className={classes.nextButton}
              variant="contained"
              type="submit"
              color="primary"
              onClick={onClickNext}
            >
              {t('button_group.next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 30 }}>
        <Typography
          className={classes.createText}
          onClick={onChangeLoginLayout}
          style={{ textAlign: centerAlign ? 'center' : 'left' }}
        >
          {t('login_page.sign_with_email')}
        </Typography>
      </Grid>
    </Grid>
  );
};

EnterMobileStep.propTypes = {
  onChangeLoginLayout: PropTypes.func,
  phone: PropTypes.object,
  onChangePhone: PropTypes.func,
  checkMobileError: PropTypes.bool,
};

export default EnterMobileStep;
