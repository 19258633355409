import React, { useState } from 'react';

import useStyles from './styles';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';
import classNames from 'classnames';
import ChangePasswordDialog from '../components/changePasswordDialog';
import DeleteAccountDialog from '../components/deleteAccountDialog';

const PrivacyAndSecurityPage = ({ currentUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isVisibleChangePassword, setIsVisibleChangePassword] =
    useState(false);
  const [isVisibleDeleteAccount, setIsVisibleDeleteAccount] =
    useState(false);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box padding={5}>
        <Typography className={classes.title}>
          {t('user.privacyAndSecurity.title')}
        </Typography>
        <div className={classes.boxInput}>
          <Box
            marginBottom={8}
            onClick={() => setIsVisibleChangePassword(true)}
          >
            <Typography className={classes.actionText}>
              {t('user.privacyAndSecurity.change_password')}
            </Typography>
          </Box>
          <Box
            marginBottom={8}
            onClick={() => setIsVisibleDeleteAccount(true)}
          >
            <Typography
              className={classNames(
                classes.actionText,
                classes.deleteText
              )}
            >
              {t('user.privacyAndSecurity.delete_account')}
            </Typography>
          </Box>
        </div>
        <ChangePasswordDialog
          userId={currentUser?.id}
          isVisible={isVisibleChangePassword}
          onCloseDialog={() => setIsVisibleChangePassword(false)}
        />
        <DeleteAccountDialog
          isVisible={isVisibleDeleteAccount}
          onCloseDialog={() => setIsVisibleDeleteAccount(false)}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default PrivacyAndSecurityPage;
