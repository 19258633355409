import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    productName: {
      ...CommonFonts.darkFont22,
      fontWeight: '600',
      marginBottom: theme.spacing(2),
    },
    productPrice: {
      ...CommonFonts.darkFont22,
      fontWeight: '600',
    },
    currency: {
      ...CommonFonts.darkFont14,
      fontWeight: '600',
    },
    selectOptions: {
      marginTop: theme.spacing(4),
      ...CommonFonts.darkFont18,
    },
    addUpdateCart: {
      ...CommonFonts.whiteFont12,
      padding: theme.spacing(3, 8),
      margin: theme.spacing(4),
    },
    dialogActions: {
      justifyContent: 'center',
    },
    plusMinusIcon: {
      color: Colors.BLUE,
    },
    quantity: {
      ...CommonFonts.darkFont24,
      fontWeight: 600,
      width: 30,
      textAlign: 'center',
    },
    closeButton: {
      zIndex: 1000,
      position: 'absolute',
      right: 12,
      top: 20,
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
  })
);

export default useStyles;
