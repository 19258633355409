import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(12)}px !important`,
      border: 'unset !important',
      borderRadius: 'unset !important',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(12, 6, 18, 6),
      },
    },
    dialog: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(255, 255, 255, 0)',
      },
    },
    paper: {
      boxShadow: 'none',
      border: '1px solid #939DA8',
      borderRadius: 10,
    },
    paperWidthSm: {
      maxWidth: 700,
    },
    closeButton: {
      position: 'absolute',
      right: 20,
      top: 20,
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    logoContainer: {
      marginTop: 40,
    },
    mainContainer: {
      padding: '0px 80px 20px 80px',
      [theme.breakpoints.down('xs')]: {
        padding: '0px 20px 20px 20px',
      },
    },
    footer: {
      alignItems: 'center',
    },
    copyrightText: {
      ...CommonFonts.darkFont16,
    },
    copyrightWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
      },
    },
    languageWrapper: {
      alignItems: 'baseline',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);

export default useStyles;
