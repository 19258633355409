export const GET_WORKBOARD_WIDGETS = `
  query getWorkboardWidgets{
    workboard{
      id
      name
      store_id
      user_id
      team_id
      period
      widgets{
        position
        widget{
          id
          name
          type
          description
          width
          image{
            url
          }
        }
      }
    }
  }
`;

export const GET_GLOBAL_WIDGETS = `
  query getGlobalWidgets{
    globalDatasets{
      widgets{
        id
        name
        type
        description
        width
        image{
          url
        }
      }
    }
  }
`;

export const GET_SPECIALITIES = `
 query getGlobalDatasets($name: String, $country: String){
    globalDatasets {
        specialities (name: $name, country: $country){
            id
            name
            country
            city
            order
            images {
                url
            }
        }
	}
}
`;

export const MUTATE_ADD_SPECIALITY = `
  mutation($input: [SpecialityInput!]!) {
    addSpecialities(input: $input) {
        id
        name
    }
  }
`;

export const MUTATE_ADD_DASHBOARD = `
  mutation($input: [DashboardInput!]!) {
    addDashboards(input: $input) {
      id
    }
  }
`;

export const GET_INSIGHTS_PERFORMANCE_FOR_TRENDS = `
  query getInsightsPerformanceForTrends($start: FullDate, $end: FullDate){
    insights{
      performances(start: $start, end: $end){
        average_transaction_value
        average_basket_size
        no_of_transactions
        breakdown{
          hourly{
            start
            end
            average_transaction_value
            average_basket_size
            no_of_transactions
          }
          daily{
            start
            end
            average_transaction_value
            average_basket_size
            no_of_transactions
          }
          weekly{
            start
            end
            average_transaction_value
            average_basket_size
            no_of_transactions
          }
          monthly{
            start
            end
            average_transaction_value
            average_basket_size
            no_of_transactions
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_TYPES_FOR_TRENDS = `
  query getAccountTypesForTrends($start: FullDate, $end: FullDate){
    accountTypes (start: $start, end: $end) {
      id
      name
      negative
      performance{
        performance
        breakdown{
          hourly{
            start
            end
            performance
          }
          daily {
            day
            start
            end
            performance
          }
          weekly {
            week
            start
            end
            performance
          }
          monthly {
            month
            start
            end
            performance
          }
          quarterly {
            quarter
            start
            end
            performance
          }
          yearly {
            year
            start
            end
            performance
          }
        }
      }
    }
  }
`;

// Get wordboard insights in descending order
export const GET_TOP_WORKBOARD_INSIGHTS = `
  query getTopWorkboardInsights($start: FullDate, $end: FullDate, $sortBy: InsightSortType, $limit: Int){
    insights {
      best_sellers(sortBy: $sortBy, start: $start, end: $end) {
        products(top: $limit) {
          name
          bar_code
          total_sale {
            net
            tax
          }
          item_sold
        }
        categories {
          name
          total_sale {
            net
            tax
          }
          item_sold
        }
      }
    }
  }
`;

// Get wordboard insights in ascending order
export const GET_BOTTOM_WORKBOARD_INSIGHTS = `
  query getBottomWorkboardInsights($start: FullDate, $end: FullDate, $sortBy: InsightSortType, $limit: Int){
    insights {
      best_sellers(sortBy: $sortBy, start: $start, end: $end) {
        products(bottom: $limit) {
          name
          bar_code
          total_sale {
            net
            tax
          }
          item_sold
        }
        categories {
          name
          total_sale {
            net
            tax
          }
          item_sold
        }
      }
    }
  }
`;

export const GET_STORE_PERFORMANCE_FOR_OVERVIEW = `
  query getPerformanceForOverview($start: FullDate, $end: FullDate){
    insights{
      performances(start: $start, end: $end){
        average_transaction_value
        average_basket_size
        no_of_transactions
        basket_size
        total_value
      }
    }
  }
`;

export const GET_COMPARE_STORE_PERFORMANCE_FOR_OVERVIEW = `
  query getCompareStorePerformanceForOverview($start: FullDate, $end: FullDate){
    insights{
      performances(start: $start, end: $end){
        average_transaction_value
        average_basket_size
        no_of_transactions
        basket_size
        total_value
      }
    }
  }
`;

export const GET_PRODUCTS_BY_BARCODE_ID = `
  query getProductsByBarcodeAndId($filter: Filter){
    products(filter: $filter){
      id
      name
      bar_code
      category_id
      measure{
        id
        type
      }
    }
  }
`

export const GET_STOCK_FOR_INTELLIGENCE = `
  query getStockForIntelligence($inventory_id: String){
    stock(inventory_id: $inventory_id){
      current_stock
      stockout
    }
  }
`;

export const GET_COMPARED_STOCK_FOR_INTELLIGENCE = `
  query getStockForIntelligence($inventory_id: String){
    stock(inventory_id: $inventory_id){
      current_stock
      stockout
    }
  }
`;

export const GET_INSIGHTS_FOR_INTELLIGENCE = `
  query getInsightsForIntelligence($start: FullDate, $end: FullDate, $product_id: String){
    insights {
      best_sellers(start: $start, end: $end) {
        products(product_id: $product_id) {
          name
          total_sale{
            net
            tax
          }
          item_sold
          average_transaction_value
          average_basket_size
          no_of_transactions
        }
      }
    }
  }
`;

export const GET_COMPARED_INSIGHTS_FOR_INTELLIGENCE = `
  query getInsightsForIntelligence($start: FullDate, $end: FullDate, $product_id: String){
    insights {
      best_sellers(start: $start, end: $end) {
        products(product_id: $product_id) {
          name
          total_sale{
            net
            tax
          }
          item_sold
          average_transaction_value
          average_basket_size
          no_of_transactions
        }
      }
    }
  }
`;

export const GET_ACCOUNT_TYPES_BUSINESS_OVERVIEW = `
  query getAccountTypesForBusinessOverview($start: FullDate, $end: FullDate){
    accountTypes (start: $start, end: $end) {
      id
      name
      performance{
        performance
      }
      children_types{
        id
        name
        performance{
          performance
        }
        accounts{
          id
          name
          performance{
            performance
          }
          display_in_report
        }
      }
    }
  }
`;