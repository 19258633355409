import moment from 'moment';
import { ORDER_TYPE } from '@/constants/store';
import { groupBy, sumBy, isEqual, omit, isEmpty } from 'lodash';
import {
  getPrice,
  getProductPrice,
  calcPriceFromGrossPrice,
} from './priceUtil';
import {
  calculateAmountTaxesSumma,
  getProductTaxArray,
  getAddonOptionTaxArray,
} from './taxUtil';
import { roundToFour } from './mathUtil';
import {
  getDiscountOfOrderItem,
  getDiscountOfProduct,
} from './promotionUtil';

export const getTableNoString = (tables) => {
  return (
    tables
      ?.filter((item) => !!item)
      ?.map((item) => item.table_no)
      ?.join(', ') || ''
  );
};

export const getDateFromOrder = (order, format = 'YYYY-MM-DD') => {
  let orderTime = null;
  if (order.order_type.type == ORDER_TYPE.DELIVERY)
    orderTime = order?.delivery?.time;
  if (order.order_type.type == ORDER_TYPE.COLLECTION)
    orderTime = order.collection_time;
  if (order.order_type.type == ORDER_TYPE.DINING_IN)
    orderTime = order?.table?.booking_time;

  if (!orderTime) orderTime = order.creation.time;

  return moment(orderTime).format(format);
};

export const getTotalTax = (items) => {
  let totalTax = 0;
  let promotionTax = 0;
  items?.forEach((item) => {
    totalTax +=
      item.price.quantity *
      calculateAmountTaxesSumma(item.price.amount, item.price.taxes);
    item.addons.forEach((addon) => {
      totalTax +=
        item.price.quantity *
        addon.price.quantity *
        calculateAmountTaxesSumma(
          addon.price.amount,
          addon.price.taxes
        );
    });
    promotionTax = sumBy(
      item.promotions?.[0]?.taxes || [],
      (tax) => tax.amount
    );
    totalTax += promotionTax * item.price.quantity;
  });
  return totalTax;
};

export const getTotalPriceOfOrderItem = (orderItem, netPrice) => {
  let itemPrice = getPrice(orderItem.price, netPrice);
  let promotionPrice = getDiscountOfOrderItem(orderItem, netPrice);
  let addonsPrice = orderItem.addons.reduce(
    (total, addon) =>
      (total +=
        getPrice(addon.price, netPrice) * addon.price.quantity),
    0
  );

  return (
    (itemPrice + addonsPrice - promotionPrice) *
    orderItem.price.quantity
  );
};

export const getTotalPriceOfOrder = (items) => {
  return items.reduce(
    (total, item) => total + getTotalPriceOfOrderItem(item, true),
    0
  );
};

export const getTotalTaxArrayForOrderMutation = (items) => {
  let tax_arr = [];
  items.forEach((item) => {
    const price_tax_arr = item.price.taxes.map((tax) => ({
      ...tax,
      amount: tax.amount * item.price.quantity,
    }));
    tax_arr = tax_arr.concat(price_tax_arr);

    item.addons.forEach((addon) => {
      const addon_tax_arr = addon.price.taxes.map((tax) => ({
        ...tax,
        amount:
          tax.amount * addon.price.quantity * item.price.quantity,
      }));
      tax_arr = tax_arr.concat(addon_tax_arr);
    });
    item?.promotions?.forEach((promotion) => {
      const promotion_tax_arr = promotion.taxes.map((tax) => ({
        ...tax,
        amount: tax.amount * item.price.quantity,
      }));
      tax_arr = tax_arr.concat(promotion_tax_arr);
    });
  });

  let taxesById = groupBy(tax_arr, (tax) => tax.id);
  taxesById = Object.keys(taxesById).map((taxId) => {
    let taxes = taxesById[taxId];
    let sumOfAmount = sumBy(taxes, (tax) => tax.amount);
    return {
      ...taxes[0],
      amount: roundToFour(sumOfAmount),
    };
  });
  return taxesById;
};

export const getOrderItemsForOrderUpdateMutation = (
  currentItems,
  originalItems
) => {
  // remove duplicated items and get only changes
  let items1 = currentItems.map((item) => ({
    is_manual: false,
    bar_code: item.bar_code,
    category: item.category,
    product_id: item.product_id,
    name: item.name,
    price: {
      amount: item.price.amount,
      quantity: item.price.quantity,
      item_measure: item.price.item_measure,
      taxes: item.price.taxes,
    },
    addons: item.addons.map((addon) => ({
      addon_id: addon.addon_id,
      name: addon.name,
      group: {
        id: addon.group?.id,
        name: addon.group?.name,
      },
      price: {
        amount: addon.price.amount,
        quantity: addon.price.quantity,
        measure: 0,
        measure_type: '',
        taxes: addon.price.taxes,
      },
    })),
    promotions: item.promotions,
  }));
  let items2 = originalItems.map((item) => ({
    is_manual: false,
    bar_code: item.bar_code,
    category: item.category,
    product_id: item.product_id,
    name: item.name,
    price: {
      amount: item.price.amount,
      quantity: item.price.quantity,
      item_measure: item.price.item_measure,
      taxes: item.price.taxes,
    },
    addons: item.addons.map((addon) => ({
      addon_id: addon.addon_id,
      name: addon.name,
      group: {
        id: addon.group?.id,
        name: addon.group?.name,
      },
      price: {
        amount: addon.price.amount,
        quantity: addon.price.quantity,
        measure: 0,
        measure_type: '',
        taxes: addon.price.taxes,
      },
    })),
    promotions: item.promotions,
  }));
  let duplicatedQuantity = 0;
  for (let i = 0; i < items1.length; i++) {
    for (let j = 0; j < items2.length; j++) {
      if (
        isEqual(
          omit(items1[i], 'price.quantity'),
          omit(items2[j], 'price.quantity')
        )
      ) {
        duplicatedQuantity = Math.min(
          items1[i].price.quantity,
          items2[j].price.quantity
        );
        items1[i] = {
          ...items1[i],
          price: {
            ...items1[i].price,
            quantity: items1[i].price.quantity - duplicatedQuantity,
          },
        };
        items2[j] = {
          ...items2[j],
          price: {
            ...items2[j].price,
            quantity: items2[j].price.quantity - duplicatedQuantity,
          },
        };
      }
    }
  }
  items2 = items2.map((item) => ({
    ...item,
    price: { ...item.price, quantity: item.price.quantity * -1 },
  }));
  let items = items1.concat(items2);
  items = items.filter((item) => item.price.quantity !== 0);
  return items;
};

export const getTimeSlotFromOrder = (order) => {
  let orderTime = null;
  if (order.order_type.type == ORDER_TYPE.DELIVERY)
    orderTime = order?.delivery?.time;
  if (order.order_type.type == ORDER_TYPE.COLLECTION)
    orderTime = order.collection_time;
  if (order.order_type.type == ORDER_TYPE.DINING_IN)
    orderTime = order?.table?.booking_time;

  if (!orderTime) orderTime = order.creation.time;

  return `${moment(orderTime)
    .startOf('hour')
    .format('HH:mm')}-${moment(orderTime)
    .add(1, 'hour')
    .startOf('hour')
    .format('HH:mm')}`;
};

export const getAddonsOfItem = (item, pricingType) => {
  // Actually we add all options of cart item in order
  let options = [];
  item.addons.forEach((addon) => {
    addon.options.forEach((option) => {
      if (option.quantity > 0) {
        options.push({
          addon_id: option.id,
          name: option.name,
          group: {
            id: addon.id,
            name: addon.group,
          },
          price: {
            amount: option.price?.fixed_price,
            quantity: parseInt(option.quantity),
            measure: 0,
            measure_type: '',
            taxes: getAddonOptionTaxArray(
              item.prices[0].price_infos,
              pricingType,
              option.price?.fixed_price
            ),
          },
        });
      }
    });
  });

  return options;
};

export const transformProductToOrderItem = (product, pricingType) => {
  return {
    bar_code: product.bar_code,
    category: {
      id: product.category.id,
      name: product.category.name,
    },
    product_id: product.id,
    name: product.name,
    price: {
      amount: getProductPrice(
        product.prices[0].price_infos,
        pricingType
      ),
      quantity: product.quantity || 1,
      item_measure: product.measure,
      taxes: getProductTaxArray(
        product.prices[0].price_infos,
        pricingType
      ),
    },
    addons: getAddonsOfItem(product, pricingType),
    product,
  };
};

export const getItems = (cartItems, pricingType, netPrice) => {
  let items = [];
  let taxes = [];
  let itemNetPrice = 0;
  cartItems.forEach((item) => {
    taxes = getProductTaxArray(
      item.prices[0].price_infos,
      pricingType
    );
    itemNetPrice = getProductPrice(
      item.prices[0].price_infos,
      pricingType
    );
    items.push({
      is_manual: false,
      bar_code: item.bar_code,
      notes: [],
      category: item.category,
      product_id: item.id,
      name: item.name,
      price: {
        amount: roundToFour(itemNetPrice),
        quantity: item.quantity,
        item_measure: {
          id: item.measure.id,
          type: item.measure.type,
        },
        taxes: taxes.map((tax) => {
          if (netPrice) {
            itemNetPrice = roundToFour(
              itemNetPrice -
                getDiscountOfProduct(
                  item,
                  item?.promotions?.[0],
                  pricingType,
                  netPrice
                )
            );
          } else {
            itemNetPrice = roundToFour(itemNetPrice);
          }
          return {
            ...tax,
            amount:
              tax.rate > 0
                ? roundToFour((itemNetPrice * tax.rate) / 100)
                : 0,
          };
        }),
      },
      addons: getAddonsOfItem(item, pricingType),
      promotions: item?.promotions?.map((promotion) => {
        let promotionNetPrice = roundToFour(
          getDiscountOfProduct(item, promotion, pricingType, netPrice)
        );
        if (!netPrice) {
          promotionNetPrice = calcPriceFromGrossPrice(
            promotionNetPrice,
            taxes
          );
        }
        return {
          id: promotion.id,
          type: promotion.type,
          name: promotion.promo_name,
          amount: roundToFour(promotionNetPrice) * -1,
          taxes: taxes.map((tax) => {
            const tax_amount =
              tax.rate > 0
                ? roundToFour((promotionNetPrice * tax.rate) / 100) *
                  -1
                : 0;
            return {
              ...tax,
              amount: !netPrice ? tax_amount : 0,
            };
          }),
          promo_no: promotion.promo_no,
          audience_id: promotion.audience_id,
          user_id: promotion.user_id,
          item_numbers: promotion.item_numbers,
          promo_name: promotion.promo_name,
          valid_from: promotion.valid_from,
          valid_to: promotion.valid_to,
          start_time: promotion.start_time,
          end_time: promotion.end_time,
          promo_value: promotion.promo_value,
          trigger_amount: promotion.trigger_amount,
          fixed_price: promotion.fixed_price,
          mix_match: promotion.mix_match,
          period: promotion.period,
          is_voucher: promotion.is_voucher,
          voucher_trigger_amount: promotion.voucher_trigger_amount,
          voucher: promotion.voucher,
          options: promotion.options?.map((option) => ({
            products: option?.products?.map((product) => ({
              id: product.id,
              name: product.name,
            })),
            categories: option?.categories?.map((category) => ({
              id: category.id,
              name: category.name,
            })),
          })),
        };
      }),
    });
  });

  return items;
};

export const getTotalAmount = (cartItems, pricingType, netPrice) => {
  let total_amount = 0;
  let total_discount = 0;
  cartItems.forEach((item) => {
    total_amount +=
      getProductPrice(item.prices[0].price_infos, pricingType) *
      item.quantity;
    item.addons.forEach((addon) => {
      addon.options.forEach((option) => {
        total_amount +=
          (option.price?.fixed_price || 0) *
          item.quantity *
          (option.quantity || 0);
      });
    });
    if (!isEmpty(item.promotions)) {
      let taxes = getProductTaxArray(
        item.prices[0].price_infos,
        pricingType
      );
      let discount = roundToFour(
        getDiscountOfProduct(
          item,
          item.promotions[0],
          pricingType,
          netPrice
        )
      );
      if (!netPrice) {
        discount = roundToFour(
          calcPriceFromGrossPrice(discount, taxes)
        );
      }
      total_discount += discount * item.quantity;
    }
  });
  return total_amount - total_discount;
};
