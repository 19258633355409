import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import useStyles from './styles';

const BackButton = ({ color, fontSize, onClick }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.backIcon}>
      <ArrowBack fontSize={fontSize} color={color} />
    </IconButton>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  fontSize: PropTypes.number,
  color: PropTypes.string,
};

BackButton.defaultProps = {
  onClick: PropTypes.func,
};

export default BackButton;
