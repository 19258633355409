import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import I18n from '@/i18n';
import PropTypes from 'prop-types';

import Button from '@/components/button';
import { Grid, Typography } from '@material-ui/core';

import AuthActions, { AuthTypes } from '@/store/modules/auth/actions';
import { selectIsApiProgress } from '@/store/modules/apiLoopState';

import useStyles from '../styles';

const ResetPasswordConfirmation = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { setCurrentStep, initialEmail, centerAlign } = props;

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <span className={classes.title}>
          {t('login_page.reset_password')}
        </span>
      </Grid>

      <Grid item xs={12}>
        <span className={classes.resetPasswordText}>
          An email with instruction sent to {initialEmail}. <br />
          Follow instruction to reset password.
        </span>
      </Grid>
      <Grid item xs={12} className={classes.resetPasswordLoginButton}>
        <Button
          style={{ textAlign: centerAlign ? 'center' : 'left' }}
          className={classes.nextButton}
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => {
            setCurrentStep('EMAIL');
          }}
          // style={{ width: 280 }}
        >
          {t('button_group.login')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordConfirmation;
