import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from './styles';
import _ from 'lodash';
import Slider from 'react-slick';
import SliderItem from './sliderItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const SliderContainer = ({ options, group }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const sliderSettings = {
    className: classes.root,
    dots: false,
    infinite: options?.length > 4 || isMobile,
    arrows: options?.length > 4 || isMobile,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    variableWidth: !isMobile,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  function NextArrow({
    currentSlide,
    slideCount,
    children,
    ...props
  }) {
    return (
      <ChevronRightIcon {...props} className={classes.nextIcon} />
    );
  }

  function PrevArrow({
    currentSlide,
    slideCount,
    children,
    ...props
  }) {
    return (
      currentSlide !== 0 && (
        <ChevronLeftIcon {...props} className={classes.prevIcon} />
      )
    );
  }

  return (
    <Slider {...sliderSettings}>
      {options?.map((item, index) => (
        <SliderItem
          key={`${item.id}-${group}-${index}`}
          data={item}
        />
      ))}
    </Slider>
  );
};

export default SliderContainer;
