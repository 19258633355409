import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
    },
    value: {
      ...CommonFonts.darkFont14,
    },
    createIcon: {
      fontSize: 20,
      color: '#bec7cc',
    },
    iconButton: {
      marginLeft: theme.spacing(4),
      padding: 0,
    },
    descriptionDialog: {
      ...CommonFonts.darkFont16,
      textAlign: 'center',
      paddingBottom: theme.spacing(7),
    },
    searchIcon: {
      position: 'absolute',
      left: 0,
      top: 16,
      color: Colors.GRAY_BORDER3,
    },
    addText: {
      ...CommonFonts.blueFont14,
      fontWeight: 600,
      cursor: 'pointer',
    },
    inputText: {
      width: '100%',
      fontSize: 16,
    },
    character_limit: {
      ...CommonFonts.grayFont12,
      padding: theme.spacing(5, 0),
      textAlign: 'center',
    },
  })
);

export default useStyles;
