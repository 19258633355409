import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      borderRadius: theme.spacing(1),
      width: theme.spacing(55),
      position: 'absolute',
      top: theme.spacing(25),
      right: theme.spacing(5),
      backgroundColor: 'white',
      padding: theme.spacing(4, 6),
      [theme.breakpoints.down('xs')]: {
        top: theme.spacing(25),
      },
    },
    boxDialog: {
      '& .MuiPaper-root': {
        borderRadius: theme.spacing(2),
        maxWidth: theme.spacing(70),
        position: 'absolute',
        top: theme.spacing(27.5),
        right: theme.spacing(2.5),
      },
      '& .MuiDialog-scrollPaper': {
        display: 'block',
      },
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(255, 255, 255, 0)',
      },
    },
    paper: {
      boxShadow: 'none',
      borderRadius: theme.spacing(1),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: 600,
    },
    subTotal: {
      ...CommonFonts.darkFont16,
    },
    checkoutButton: {
      marginTop: theme.spacing(3),
      width: '100%',
      fontSize: 16,
      textTransform: 'capitalize',
    },
    clearCart: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
      ...CommonFonts.grayFont12,
      cursor: 'pointer',
    },
    currency: {
      ...CommonFonts.grayFont12,
      fontWeight: 600,
    },
    price: {
      ...CommonFonts.grayFont16,
      fontWeight: 600,
    },
    cartBtnWrapper: {
      width: '100%',
      position: 'sticky',
      bottom: 0,
    },
    bottomCheckoutButton: {
      width: '100%',
      fontSize: 22,
      textTransform: 'capitalize',
      fontWeight: 'bold',
      height: 65,
    },
  })
);

export default useStyles;
