import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Container,
  Hidden,
  MenuList,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import Menu from '@/components/header/menu';
import UserInfo from '@/components/header/userInfo';
import Modal from '@/components/modal';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  selectUserIsLoggedIn,
  selectCurrentUser,
} from '@/store/modules/auth/selectors';
import MerchantActions from '@/store/modules/store/actions';
import AuthActions from '@/store/modules/auth/actions';

import useStyles from './styles';
import Authorize from './authorize';
import StoreSearchInput from './storeSearchInput';
import CreateIcon from '@material-ui/icons/Create';
import OrderTypeModal from './orderTypeModal';
import { selectedOrderType } from '@/store/modules/store';
import { getFullAddressOfStore } from '@/utils/stringUtil';
import { ORDER_TYPE } from '@/constants/store';
import { ASAP } from '@/constants/dateAndTime';
import { getTableNoString } from '@/utils/orderUtil';
import LoginModal from './authorize/loginModal';

const Header = ({ withServiceDropdown, withAllStoresOption }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const urlName = location.pathname;
  const orderType = useSelector(selectedOrderType);
  const currentUser = useSelector(selectCurrentUser);
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const dispatch = useDispatch();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openOrderTypeModal, setOpenOrderTypeModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    dispatch(MerchantActions.setSelectedRegion(null));
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setOpenDialog(!openDialog);
  }, []);

  const profileActions = [
    {
      id: 1,
      name: t('user.setting_profile'),
      pathname: '/user/profile',
      action: () => history.push('/user/profile'),
      disabled: false,
    },
    {
      id: 2,
      name: t('user.setting_preferences'),
      pathname: '/user/preferences',
      action: () => history.push('/user/preferences'),
      disabled: false,
    },
    {
      id: 3,
      name: t('user.setting_pivacy_and_security'),
      pathname: '/user/privacy-and-security',
      action: () => history.push('/user/privacy-and-security'),
      disabled: false,
    },
    {
      id: 4,
      name: t('user.setting_logout'),
      pathname: '',
      action: () => dispatch(AuthActions.logout()),
      disabled: false,
    },
  ];

  const contentOrderType = () => {
    const { type } = orderType || {};

    let header = '';
    let body = '';
    switch (type) {
      case ORDER_TYPE.COLLECTION:
        header = t('header.order_type.collect');
        if (orderType[ORDER_TYPE.COLLECTION].time === ASAP) {
          body = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.COLLECTION].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD/MM/YYYY'
          );
          const time = separateDateTime[1];
          body = `${dateFormat} ${time}`;
        }
        break;
      case ORDER_TYPE.TAKEAWAY:
        header = t('header.order_type.takeaway');
        if (orderType[ORDER_TYPE.TAKEAWAY].time === ASAP) {
          body = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.TAKEAWAY].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD/MM/YYYY'
          );
          const time = separateDateTime[1];
          body = `${dateFormat} ${time}`;
        }
        break;
      case ORDER_TYPE.DINING_IN:
        header = t('header.order_type.dining_in');
        if (orderType[ORDER_TYPE.DINING_IN].time === ASAP) {
          body = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.DINING_IN].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD/MM/YYYY'
          );
          const tables = orderType[ORDER_TYPE.DINING_IN].tables;
          const time = separateDateTime[1];
          body = `Table ${getTableNoString(
            tables
          )} • ${dateFormat} ${time}`;
        }
        break;

      case ORDER_TYPE.DELIVERY:
        header = t('header.order_type.deliver');
        if (orderType[ORDER_TYPE.DELIVERY].time === ASAP) {
          body = t('header.order_type.asap');
        } else {
          const separateDateTime =
            orderType[ORDER_TYPE.DELIVERY].time.split(' ');
          const dateFormat = moment(separateDateTime[0]).format(
            'DD/MM/YYYY'
          );
          const time = separateDateTime[1];
          body = `${
            orderType[ORDER_TYPE.DELIVERY].to === ''
              ? ''
              : t('header.order_type.to')
          } ${getFullAddressOfStore(
            currentUser?.addresses?.find(
              (address) =>
                address.id === orderType[ORDER_TYPE.DELIVERY].to
            )
          )} ${dateFormat} ${time}`;
        }
        break;
      default:
        break;
    }

    const content = `${header} ${body}`;
    const result =
      content.length >= 40 && isMobile
        ? `${content.substring(0, 40)}...`
        : content;

    return result;
  };

  const onLogin = () => setOpenLoginModal(true);

  return (
    <>
      <Container fixed className={classes.container}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.wrapper}
        >
          <Grid item xs={6} sm={9} md={9} lg={10} xl={10}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.wrapper}
              spacing={10}
            >
              <Grid item>
                <Menu isLoggedIn={isLoggedIn} onLogin={onLogin} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
            {isLoggedIn ? (
              <UserInfo handleOpenDialog={handleOpenDialog} />
            ) : (
              <Authorize />
            )}
          </Grid>
        </Grid>
      </Container>

      <Modal
        openDialog={openDialog}
        handleClose={handleCloseDialog}
        anchorEl={anchorEl}
        styleClass={classes.profileActionPopoverContainer}
      >
        <MenuList>
          {profileActions?.map((item) => (
            <MenuItem
              disabled={item.disabled}
              onClick={() => {
                item.action();
                handleCloseDialog();
              }}
              key={item.id}
              className={classNames(
                classes.menuItem,
                _.isEqual(item.pathname, urlName)
                  ? classes.menuItemActive
                  : ''
              )}
            >
              <Typography
                className={classNames(
                  classes.textItem,
                  _.isEqual(item.pathname, urlName)
                    ? classes.textItemActive
                    : ''
                )}
              >
                {item.name}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Modal>
      {openOrderTypeModal && (
        <OrderTypeModal
          isVisible={openOrderTypeModal}
          onCloseModal={() => setOpenOrderTypeModal(false)}
        />
      )}
      {!isLoggedIn && openLoginModal && (
        <LoginModal
          isVisible={openLoginModal}
          onCloseModal={() => setOpenLoginModal(false)}
        />
      )}
    </>
  );
};

Header.propTypes = {
  withServiceDropdown: PropTypes.bool,
  withAllStoresOption: PropTypes.bool,
};

export default Header;
