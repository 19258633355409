import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    boxInfo: {
      padding: theme.spacing(0, 5),
      flexGrow: 1,
    },
    title: {
      ...CommonFonts.darkFont20,
      fontWeight: '600',
    },
    backToButton: {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    saveForLaterButton: {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        marginRight: 0,
      },
    },
    boxFeature: {
      padding: theme.spacing(4, 0),
    },
  })
);

export default useStyles;
