import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';

import useStyles from './styles';
import _ from 'lodash';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import imageAssets from '@/assets/images';
import { hideContent } from '@/utils/stringUtil';

const ProductGroupAddonOption = ({
  data,
  addonIndex,
  optionIndex,
  updateGroupAddonData,
  pricingType,
  productTaxes,
  isNetPrice,
  allowFree,
  isAllowSelectOne,
  updateAddonAllowFree,
}) => {
  const {
    name,
    images,
    price,
    extra,
    mandatory,
    quantity,
    freeQuantity,
    force_charge,
  } = data || {};
  const classes = useStyles();

  const mappingPrice = price?.fixed_price
    ? price?.fixed_price
    : price?.price_infos?.find((info) =>
        _.isEqual(info.price_type?.type, pricingType)
      )?.price;

  const totalTaxesAmount = productTaxes
    ?.map((tax) => ({
      ...tax,
      amount: parseFloat(
        (mappingPrice * (Math.max(tax.rate, 0) / 100)).toFixed(4)
      ),
    }))
    ?.reduce((acc, arg) => acc + arg.amount, 0);

  const optionPrice = !mappingPrice
    ? parseFloat('0').toFixed(2)
    : (
        (mappingPrice + (isNetPrice ? 0 : totalTaxesAmount)) *
        (quantity - freeQuantity)
      ).toFixed(2);

  const optionImage =
    images?.find((image) => !_.isEmpty(image))?.thumbnail ||
    imageAssets.noImage;

  const isIncreaseHidden =
    (extra && quantity >= extra) || isAllowSelectOne;
  const isDecreaseHidden =
    _.isEqual(quantity, 0) || (mandatory && _.isEqual(quantity, 1));

  const onIncreaseQty = (event) => {
    event.stopPropagation();
    if (!isIncreaseHidden) {
      updateGroupAddonData(addonIndex, optionIndex, {
        key: 'quantity',
        value: quantity + 1,
      });

      if (!force_charge && allowFree > 0) {
        updateGroupAddonData(addonIndex, optionIndex, {
          key: 'freeQuantity',
          value: freeQuantity + 1,
        });

        updateAddonAllowFree(allowFree - 1);
      }
    }
  };

  const onDecreaseQty = (event) => {
    event.stopPropagation();
    if (!isDecreaseHidden) {
      updateGroupAddonData(addonIndex, optionIndex, {
        key: 'quantity',
        value: quantity - 1,
      });

      if (_.isEqual(quantity, freeQuantity)) {
        updateGroupAddonData(addonIndex, optionIndex, {
          key: 'freeQuantity',
          value: freeQuantity - 1,
        });

        updateAddonAllowFree(allowFree + 1);
      }
    }
  };

  const IncreaseButton = () => {
    return (
      <>
        {isIncreaseHidden ? (
          <div className={classes.hiddenIcon} />
        ) : (
          <IconButton
            onClick={onIncreaseQty}
            className={classes.iconButton}
          >
            <AddIcon className={classes.icon} />
          </IconButton>
        )}
      </>
    );
  };

  const DecreaseButton = () => {
    return (
      <>
        {isDecreaseHidden ? (
          <div className={classes.hiddenIcon} />
        ) : (
          <IconButton
            onClick={onDecreaseQty}
            className={classes.iconButton}
          >
            <RemoveIcon className={classes.icon} />
          </IconButton>
        )}
      </>
    );
  };

  return (
    <Box className={classes.boxOptionItem} onClick={onIncreaseQty}>
      <Box className={classes.boxImage}>
        <img
          width={60}
          height={60}
          src={optionImage}
          className={classes.image}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={4}
        width="83%"
      >
        <Typography>{hideContent(name, 25)}</Typography>
        <Box display="flex" alignItems="center">
          {!_.isEqual(quantity, 0) && (
            <Box display="flex" alignItems="center" marginRight={8}>
              <DecreaseButton />
              <Typography className={classes.quantity}>
                {quantity}
              </Typography>
              <IncreaseButton />
            </Box>
          )}
          <Typography className={classes.price}>
            {optionPrice}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductGroupAddonOption;
