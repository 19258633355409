import { createReducer } from 'reduxsauce';

import { INITIAL_STATE } from './initialState';
import { NotificationTypes } from './actions';
import { LIMIT_OF_NOTIFICATIONS } from '@/constants/notification';

export const updateNotifications = (state, { notifications }) => {
  return {
    ...state,
    notifications: notifications?.slice(0, LIMIT_OF_NOTIFICATIONS),
  };
};

export const addNotification = (state, { notification }) => {
  const notifications = [
    { ...notification, is_new: true },
    ...state.notifications,
  ];
  return {
    ...state,
    notifications: notifications?.slice(0, LIMIT_OF_NOTIFICATIONS),
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [NotificationTypes.UPDATE_NOTIFICATIONS]: updateNotifications,
  [NotificationTypes.ADD_NOTIFICATION]: addNotification,
});
