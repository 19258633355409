import { Box, Grid, Typography, Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import {
  TOUCHPOINT_STOREFRONT_SETTING,
  MERCHANT_SETTINGS,
} from '@/services/storeService';
import StoreAvatar from './components/storeAvatar';
import MerchantActions from '@/store/modules/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUser,
  selectUserIsLoggedIn,
} from '@/store/modules/auth';
import Banner from './components/banner';
import AppLayout from '@/components/appLayout';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { DAY_OF_WEEK } from '@/constants/store';
import { getFullAddressOfStore } from '@/utils/stringUtil';
import StoreProduct from './components/product';
import StoreAbout from './components/about';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { GET_ORDER_TYPE } from '@/services/orderTypesWorkflowsService';
import {
  selectedOrderType,
  selectNetPrice,
  selectLocalisation,
} from '@/store/modules/store';
import ShoppingCart from '@/components/shoppingCart';
import { getCurrencySymbol } from '@/utils/stringUtil';
import { ORDER_TYPE } from '@/constants/store';

const storefrontQueries = gql`
  ${TOUCHPOINT_STOREFRONT_SETTING}
`;

const orderTypeQueries = gql`
  ${GET_ORDER_TYPE}
`;

const merchantSettingsQueries = gql`
  ${MERCHANT_SETTINGS}
`;

const TABS = {
  PRODUCT: 'Product',
  ABOUT: 'about',
};

const StorePage = ({ defaultTabId = 0 }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { merchantId, storeId } = useParams();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const currentUser = useSelector(selectCurrentUser);
  const selectOrderType = useSelector(selectedOrderType);
  const pricingType = selectOrderType.pricing_type;
  const netPrice = useSelector(selectNetPrice);
  const localisation = useSelector(selectLocalisation);

  const hasUpdateStore =
    currentUser?.roles
      ?.find(
        (role) =>
          _.isEqual(role.store_id, storeId) ||
          (_.isEqual(role.store_id, '-1') &&
            _.isEqual(role.merchant_id, merchantId))
      )
      ?.permissions?.includes('store.update') && isLoggedIn;

  const [tabId, setTabId] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(merchantId) && !_.isEmpty(storeId)) {
      dispatch(
        MerchantActions.setSelectedStore({
          merchant_id: merchantId,
          id: storeId,
        })
      );
    }
  }, [currentStore]);

  useEffect(() => {
    if (defaultTabId) {
      setTabId(defaultTabId);
    }
  }, [defaultTabId]);

  const { loading: loadingMerchantSettings } = useQuery(
    merchantSettingsQueries,
    {
      context: {
        headers: { storeId, merchantId },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        dispatch(
          MerchantActions.setMerchantSettings(data?.merchantSettings)
        );
      },
    }
  );

  const {
    data,
    loading: loadingStorefront,
    refetch,
  } = useQuery(storefrontQueries, {
    context: {
      headers: { storeId, merchantId },
    },
    onCompleted: (data) => {
      const store = data.store;
      const digitalFrontOrderTypes =
        store?.settings?.touchpoint_settings?.digital_front?.order_types
          ?.map((id) => _.find(store?.order_types, { id }))
          ?.filter(
            (item) =>
              !!item &&
              !![
                ORDER_TYPE.COLLECTION,
                ORDER_TYPE.DELIVERY,
                ORDER_TYPE.DINING_IN,
                ORDER_TYPE.TAKEAWAY,
              ].includes(item?.type)
          );
      dispatch(MerchantActions.setOrderTypes(digitalFrontOrderTypes));
      dispatch(MerchantActions.setLocalisation(store.localisation));
      if (!_.isEmpty(digitalFrontOrderTypes)) {
        dispatch(
          MerchantActions.setSelectedOrderType({
            ...selectOrderType,
            type: digitalFrontOrderTypes[0].type,
            pricing_type: digitalFrontOrderTypes[0].pricing_type,
          })
        );
      }
    },
    fetchPolicy: 'no-cache',
  });
  const currentStore = data?.store;

  const loading = loadingStorefront || loadingMerchantSettings;

  const tabs = [
    {
      id: TABS.PRODUCT,
      label: t('store.products'),
      enabled: true,
    },
    {
      id: TABS.ABOUT,
      label: t('store.about'),
      enabled: true,
    },
  ];

  const onChangeTab = (event, value) => setTabId(value);

  const OpeningTime = () => {
    const { store_openings } = currentStore || {};
    const title = store_openings?.closed
      ? t('store.closed')
      : t('store.open_now');

    const dayOfWeek = DAY_OF_WEEK[new Date().getDay()];
    const storeOpenings = store_openings?.find((storeDay) =>
      _.isEqual(storeDay.day, dayOfWeek)
    );

    return (
      <Box
        padding={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            className={
              store_openings?.closed
                ? classes.closedTime
                : classes.openingTime
            }
          >
            {title}
          </Typography>
          <Box>
            {storeOpenings?.opening_times?.map((time) => (
              <Typography
                key={`${time.open}${time.close}`}
                className={classes.time}
              >{`${time.open} - ${time.close}`}</Typography>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const Address = () => {
    return (
      <Box
        paddingY={4}
        paddingX={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <LocationOnOutlinedIcon className={classes.timeIcon} />
          <Typography className={classes.address}>
            {getFullAddressOfStore(currentStore?.address)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const StoreInfo = () => {
    return (
      <Box padding={4}>
        <Typography className={classes.storeName}>
          {currentStore?.name}
        </Typography>
        <Typography className={classes.textItem}>
          {currentStore?.merchant?.business_type}
        </Typography>
        <Typography className={classes.soldItems}>
          {currentStore?.specialities
            ?.map((item) => item.name)
            ?.join(', ')}
        </Typography>
        <OpeningTime />
      </Box>
    );
  };

  return (
    <AppLayout header withFooter className={classes.appLayout}>
      <div className={classes.boxBannerAvatar}>
        <Banner
          key={
            currentStore?.settings?.touchpoint_settings?.digital_front
              ?.banner
          }
          storeBanner={
            currentStore?.settings?.touchpoint_settings?.digital_front
              ?.banner
          }
          storeId={currentStore?.id}
          onRefetchStore={refetch}
          hasUpdateStore={hasUpdateStore}
          widthCrop={1200}
          heightCrop={628}
        />
        <StoreAvatar
          currentStore={currentStore}
          onRefetchStore={refetch}
          hasUpdateStore={hasUpdateStore}
          storeLoading={loading}
          widthCrop={240}
          heightCrop={240}
          key={currentStore?.merchant?.logo?.url}
        />
      </div>
      <div className={classes.box}>
        <Grid
          className={classes.boxInfo}
          container
          justify="center"
          alignItems="flex-start"
        >
          {loading ? (
            <Box display="flex" width="100%" justifyContent="center">
              <Skeleton height={130} width="60%" />
            </Box>
          ) : (
            <>
              <Grid item xs={12} md={3} lg={3}>
                <StoreInfo />
              </Grid>
            </>
          )}
        </Grid>
        <div className={classes.boxTabs}>
          <Tabs
            className={classes.tabs}
            value={tabId}
            onChange={onChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs
              .filter((item) => item.enabled)
              .map((item, index) => (
                <Tab
                  className={classes.tab}
                  style={{ fontSize: 14, fontWeight: 600 }}
                  key={`tab-${index}`}
                  label={item.label}
                />
              ))}
          </Tabs>
        </div>
        <div className={classes.boxPanel}>
          {tabs
            .filter((item) => item.enabled)
            .map((item, index) => {
              if (item.id === TABS.PRODUCT) {
                return (
                  <TabPanel
                    key={`tab-panel-${index}`}
                    value={tabId}
                    index={index}
                  >
                    <StoreProduct
                      currentStore={currentStore}
                      pricingType={pricingType}
                      isNetPrice={netPrice}
                    />
                  </TabPanel>
                );
              } else if (item.id === TABS.ABOUT) {
                return (
                  <TabPanel
                    key={`tab-panel-${index}`}
                    value={tabId}
                    index={index}
                  >
                    <StoreAbout
                      currentStore={currentStore}
                      onRefetchStore={refetch}
                      hasUpdateStore={hasUpdateStore}
                      loadingStorefront={loadingStorefront}
                    />
                  </TabPanel>
                );
              }
            })}
        </div>
      </div>
      <ShoppingCart
        currency={getCurrencySymbol(
          currentStore?.localisation?.currency_symbol
        )}
        merchantId={merchantId}
        storeId={storeId}
        isNetPrice={netPrice}
        localisation={localisation}
        loading={loading}
      />
    </AppLayout>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

export default StorePage;
