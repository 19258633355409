import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.WHITE,
    },
    title: {
      ...CommonFonts.darkFont20,
      fontWeight: '600',
    },
  })
);

export default useStyles;
