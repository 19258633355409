import React, { memo } from 'react';
import classNames from 'classnames';

import { Button } from '@material-ui/core';

import styles from './styles';

const Tab = ({
  id,
  lang,
  onClick,
  label,
  active,
}) => {
  const classes = styles();

  return(
   <Button
       variant="contained"
       className={classNames(classes.buttonTab, {
         [classes.buttonTabActive]: id === active.id,
         [classes.buttonTabDeactivated]: id !== active.id,
       })}
       onClick={() => onClick(lang)}
      >
    {label}
   </Button>
  )
}

export default memo(Tab);
