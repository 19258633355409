import React from 'react';

import {
  Paper,
  Box,
  Button,
  Typography,
  Slide,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectShoppingCart } from '@/store/modules/store';
import MerchantActions from '@/store/modules/store/actions';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { getLocalisationVal } from '@/utils/localisationUtil';

const ShoppingCart = ({
  currency,
  merchantId,
  storeId,
  isNetPrice,
  localisation,
  loading,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const shoppingCarts = useSelector(selectShoppingCart);
  const currentCart = shoppingCarts?.find(
    (item) =>
      _.isEqual(item.merchantId, merchantId) &&
      _.isEqual(item.storeId, storeId)
  )?.cart;
  const itemsCart = currentCart?.length;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const onClearCart = () =>
    dispatch(MerchantActions.clearCart({ merchantId, storeId }));

  const onCheckout = () =>
    history.push(`/cart/${merchantId}/${storeId}`);

  const calculatingTotalPrice = () => {
    let price = 0;
    currentCart?.map((itemCart) => {
      if (itemCart.addons) {
        const addonsPrice = itemCart?.addons?.reduce(
          (accAddon, argAddon) => {
            const addonTaxesAmount = argAddon?.price?.taxes?.reduce(
              (accTax, argTax) =>
                accTax +
                argTax.amount *
                  argAddon.price.quantity *
                  itemCart.price.quantity,
              0
            );
            return (
              accAddon +
              (isNetPrice ? 0 : addonTaxesAmount) +
              argAddon.price.amount *
                argAddon.price.quantity *
                itemCart.price.quantity
            );
          },
          0
        );
        price = price + addonsPrice;
      }

      const itemTaxesAmount =
        itemCart?.price?.taxes?.reduce(
          (accTax, argTax) => accTax + argTax.amount,
          0
        ) || 0;

      price =
        price +
        (itemCart.price.amount + (isNetPrice ? 0 : itemTaxesAmount)) *
          itemCart.price.quantity;
    }, 0);

    return getLocalisationVal(localisation, price);
  };

  return (
    <>
      {!_.isEmpty(currentCart) && itemsCart > 0 && !loading && (
        <Slide in timeout={1500} direction="left">
          {isMobile ? (
            <Box className={classes.cartBtnWrapper}>
              <Button
                onClick={onCheckout}
                color="primary"
                variant="contained"
                className={classes.bottomCheckoutButton}
              >
                {`${t(
                  'store.shoppingCart.total'
                )}: ${currency}${calculatingTotalPrice()}`}
              </Button>
            </Box>
          ) : (
            <Paper className={classes.box} elevation={3}>
              <Typography className={classes.title}>
                {t('store.shoppingCart.title')}
              </Typography>
              <Box marginTop={1} display="flex" alignItems="center">
                <Typography className={classes.subTotal}>
                  {t('store.shoppingCart.sub_total', {
                    quantity: itemsCart,
                  })}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  marginLeft={2}
                >
                  <Typography className={classes.currency}>
                    {currency}
                  </Typography>
                  <Typography className={classes.price}>
                    {calculatingTotalPrice()}
                  </Typography>
                </Box>
              </Box>
              <Button
                onClick={onCheckout}
                color="primary"
                variant="contained"
                className={classes.checkoutButton}
              >
                {t('store.shoppingCart.checkout')}
              </Button>
              <Typography
                className={classes.clearCart}
                onClick={onClearCart}
              >
                {t('store.shoppingCart.clear_cart')}
              </Typography>
            </Paper>
          )}
        </Slide>
      )}
    </>
  );
};

export default ShoppingCart;
