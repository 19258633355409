export const drawerMenus = (t) => [
  {
    id: 0,
    name: t('menu.orders'),
    pathname: '/order-management/orders',
    disabled: false,
  },
  {
    id: 1,
    name: t('menu.vouchers'),
    pathname: '/voucher',
    disabled: false,
  },
];
