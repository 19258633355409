import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: theme.spacing(5),
      backgroundColor: Colors.WHITE,
      borderRadius: theme.spacing(1),
      margin: theme.spacing(10, 5),
    },

    title: {
      ...CommonFonts.darkFont18,
      fontWeight: '600',
    },
    nonImage: {
      width: theme.spacing(49),
    },
    taxName: {
      ...CommonFonts.darkFont12,
      fontWeight: 600,
    },
    currency: {
      ...CommonFonts.darkFont12,
      fontWeight: 600,
    },
    price: {
      ...CommonFonts.darkFont16,
      fontWeight: 600,
    },
  })
);

export default useStyles;
