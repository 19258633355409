import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .slick-next': {
        top: 'unset !important',
        width: 0,
        height: 0,
      },
      '& .slick-prev': {
        top: 'unset !important',
        width: 0,
        height: 0,
      },
      '& .slick-track': {
        marginLeft: 0,
      },
    },
    nextIcon: {
      cursor: 'pointer',
      color: 'black',
      fontSize: 26,
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: Colors.GRAY_LIGHT,
      top: theme.spacing(25),
      right: '3%',
      padding: theme.spacing(2.5),
      zIndex: 1,
      boxShadow: '0px 1px 4px #cecece',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5),
      },
    },
    prevIcon: {
      cursor: 'pointer',
      color: 'black',
      fontSize: 26,
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: Colors.GRAY_LIGHT,
      top: theme.spacing(25),
      left: '3%',
      padding: theme.spacing(2.5),
      zIndex: 1,
      boxShadow: '0px 1px 4px #cecece',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5),
      },
    },
  })
);

export default useStyles;
