import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
    },
    value: {
      ...CommonFonts.darkFont14,
    },
    createIcon: {
      fontSize: 20,
      color: '#bec7cc',
    },
    iconButton: {
      marginLeft: theme.spacing(4),
      padding: 0,
    },
    descriptionDialog: {
      ...CommonFonts.darkFont16,
      textAlign: 'center',
      paddingBottom: theme.spacing(4),
    },
    searchInput: {
      '& .MuiInput-root': {
        padding: '11px 26px',
        ...CommonFonts.darkFont18,
      },
    },
    noItemFound: {
      ...CommonFonts.grayFont14,
    },
    searchIcon: {
      position: 'absolute',
      left: 0,
      top: 16,
      color: Colors.GRAY_BORDER3,
    },
    chipCategory: {
      marginRight: theme.spacing(4),
      height: theme.spacing(11),
      padding: theme.spacing(1.5),
    },
    addText: {
      ...CommonFonts.blueFont14,
      fontWeight: 600,
      cursor: 'pointer',
    },
    addButton: {
      textTransform: 'none',
      marginRight: theme.spacing(3),
    },
  })
);

export default useStyles;
