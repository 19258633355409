import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import SwitchLanguage from './components/switchLanguage';
import FillFormLayout from './layouts/fillFormLayout';
import SignupSuccessLayout from './layouts/signupSuccessLayout';

import images from '@/assets/images';
import useStyles from './styles';

const SignupModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    open,
    onClose,
    onEmailLogin,
    onEmailSignup,
    defaultEmail = '',
    isSignupProgress,
    hasSignupSucceed,
    handleSignupSucceed,
    hasSignupFailed,
    handleSignupFailed,
  } = props;

  const STEP = {
    FILL_FORM: 'fillForm',
    SIGNUP_SUCCESS: 'signupSuccess',
  };

  const [currentStep, setCurrentStep] = useState(STEP.FILL_FORM);

  const [emailFormData, setEmailFormData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');

  const [signupError, setSignupError] = useState(false);

  const layout = {
    [STEP.FILL_FORM]: (
      <FillFormLayout
        defaultEmail={defaultEmail}
        onEmailSignup={(values) => {
          setEmailFormData(values);
          onEmailSignup(values);
        }}
        isSignupProgress={isSignupProgress}
        signupError={signupError}
      />
    ),
    [STEP.SIGNUP_SUCCESS]: (
      <SignupSuccessLayout
        emailFormData={emailFormData}
        phoneNumber={phoneNumber}
        onEmailLogin={onEmailLogin}
        onEmailSignup={onEmailSignup}
        onChangeCredential={() => setCurrentStep(STEP.FILL_FORM)}
        isSignupProgress={isSignupProgress}
      />
    ),
  };

  useEffect(() => {
    if (hasSignupSucceed) {
      setCurrentStep(STEP.SIGNUP_SUCCESS);
      handleSignupSucceed();
    }
    if (hasSignupFailed) {
      setSignupError(true);
      handleSignupFailed();
    }
  }, [hasSignupSucceed, hasSignupFailed]);

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      disableBackdropClick
      classes={{
        root: classes.root,
        paperWidthSm: classes.paperWidthSm,
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon fontSize="small" />
      </IconButton>

      <Grid
        container
        justify="center"
        className={classes.logoContainer}
      >
        <img src={images.logo} width={150} height={60} alt="logo" />
      </Grid>

      <div className={classes.mainContainer}>
        <div className={classes.layout}>{layout[currentStep]}</div>
        <Grid
          container
          direction="row-reverse"
          className={classes.footer}
        >
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.languageWrapper}
          >
            <SwitchLanguage />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.copyrightWrapper}
          >
            <span className={classes.copyrightText}>
              {t('signup.copyright')}
            </span>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

SignupModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEmailLogin: PropTypes.func, // Params: null. Simply direct user to login screen
  onEmailSignup: PropTypes.func, // Params: ({ email, password }). Call emailSignup mutation.
  isSignupProgress: PropTypes.bool,
  hasSignupSucceed: PropTypes.bool,
  handleSignupSucceed: PropTypes.func,
  hasSignupFailed: PropTypes.bool,
  handleSignupFailed: PropTypes.func,
};

export default SignupModal;
