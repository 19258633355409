export const CURRENT_USER = `
  {
    currentUser{
      id
      first_name
      last_name
      email
      mobile
      dob
      gender
      last_login
      verified
      profession
      addresses{
        id
        door_number
        building_name
        line1
        line2
        city_town
        country
        postcode
        county
        name
        lat
        lng
      }
      merchants{
        id
        mid
        tname
        lname
        week_start_day
        financial_year_end
        logo{
          url
          thumbnail
        }
        stores{
          id
          merchant_id
          sid
          name
          email
          phone
          address_id
          localisation{
            digit_separator
            currency_symbol
            currency_decimal
            currency_position
            currency_code
          }
          region{
            id
            region_name
          }
          address{
            door_number
            building_name
            line1
            line2
            city_town
            country
            postcode
            county
          }
        }
      }
      invitations{
        id
        invite_id
        expired
        merchant_name
        created_at
        stores{
            id
            permissions
            role_id
        }
        teams{
            id
            name
        }
      }
      config{
        background_colour
        language
        communication{
          marketing
          product_update
        }
        tutorials{
          type
          tutorials{
            feature
            status
            order
          }
        }
      }
      profile{
        id
        url
        thumbnail
      }
      roles{
        name
        permissions
        store_id
        merchant_id
      }
    }
  }
`;

export const MUTATE_DELETE_ACCOUNT = `
  mutation deleteUser{
    deleteUser
  }
`;
