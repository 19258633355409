import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    boxSidebar: {
      height: '100%',
      backgroundColor: Colors.WHITE,
      borderRadius: theme.spacing(1),
    },
    boxLink: {
      padding: theme.spacing(4, 8),
    },
    boxLinkActive: {
      padding: theme.spacing(4, 8),
      backgroundColor: Colors.GRAY_LIGHT,
      fontWeight: '600',
    },
    linkText: {
      ...CommonFonts.darkFont18,
    },
  })
);

export default useStyles;
