import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Dialog,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import useStyles from './styles';
import CollectionType from './collection';
import DeliveryType from './delivery';
import TableType from './table';
import TakeawayType from './takeaway';
import Button from '@/components/button';
import { selectCurrentUser } from '@/store/modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import MerchantActions from '@/store/modules/store/actions';
import {
  selectedOrderType,
  selectOrderTypes,
} from '@/store/modules/store';
import { isMobile } from 'react-device-detect';
import { ORDER_TYPE } from '@/constants/store';
import { GET_STORE_TABLES } from '@/services/storeService';
import { TABLE_STATUS } from '@/constants/order';

const storeTablesQueries = gql`
  ${GET_STORE_TABLES}
`;

const OrderTypeModal = ({ isVisible, onCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { merchantId, storeId } = useParams();
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const orderType = useSelector(selectedOrderType);
  const orderTypes = useSelector(selectOrderTypes);

  const { data } = useQuery(storeTablesQueries, {
    context: {
      headers: { storeId, merchantId },
    },
    skip: !storeId || !merchantId,
  });
  const tables =
    data?.tables?.filter(
      (item) => item.status === TABLE_STATUS.READY
    ) || [];

  const updateOrderType = (key, value) => {
    dispatch(
      MerchantActions.setSelectedOrderType({
        ...orderType,
        [key]: value,
      })
    );
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
      open={isVisible}
      onClose={onCloseModal}
      disableBackdropClick
    >
      <IconButton
        onClick={onCloseModal}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Grid container justify="center">
        <Typography className={classes.title}>
          {t('header.order_type.change_order_type')}
        </Typography>
      </Grid>

      <div className={classes.mainContainer}>
        <RadioGroup
          key={orderType.type}
          className={classes.boxGroupType}
          aria-label="order-type-group"
          name="order-type-group"
          onChange={(e) => {
            dispatch(
              MerchantActions.setSelectedOrderType({
                ...orderType,
                type: e.target.value,
                pricing_type: orderTypes.find(
                  (item) => item.type === e.target.value
                )?.pricing_type,
              })
            );
          }}
        >
          <>
            <Box
              display="flex"
              alignItems="center"
              marginY={5}
              justifyContent="center"
              flexWrap={'wrap'}
            >
              {orderTypes.map((item) => (
                <FormControlLabel
                  key={item.id}
                  classes={{
                    label: classes.radioFormControlLabel,
                  }}
                  className={classes.radioFormControl}
                  control={
                    <CustomRadio
                      checked={_.isEqual(item.type, orderType.type)}
                      value={item.type}
                      name={item.type}
                    />
                  }
                  label={t(`header.order_type.${item.type}`)}
                />
              ))}
            </Box>
            <Divider />
          </>
        </RadioGroup>

        <Box padding={5} width="100%">
          {_.isEqual(ORDER_TYPE.COLLECTION, orderType.type) && (
            <CollectionType
              key={'collection-type'}
              updateOrderType={updateOrderType}
              collectionData={orderType[ORDER_TYPE.COLLECTION]}
            />
          )}
          {_.isEqual(ORDER_TYPE.DELIVERY, orderType.type) && (
            <DeliveryType
              key={'delivery-type'}
              updateOrderType={updateOrderType}
              deliveryData={orderType[ORDER_TYPE.DELIVERY]}
              currentUser={currentUser}
            />
          )}
          {_.isEqual(ORDER_TYPE.DINING_IN, orderType.type) && (
            <TableType
              key={'table-type'}
              updateOrderType={updateOrderType}
              tableData={orderType[ORDER_TYPE.DINING_IN]}
              storeTables={tables}
            />
          )}
          {_.isEqual(ORDER_TYPE.TAKEAWAY, orderType.type) && (
            <TakeawayType
              key={'takeaway-type'}
              updateOrderType={updateOrderType}
              takeawayData={orderType[ORDER_TYPE.TAKEAWAY]}
            />
          )}
        </Box>

        {!_.isEmpty(orderType) && (
          <Box>
            <Button
              className={classes.addButton}
              variant="contained"
              color="primary"
              onClick={onCloseModal}
              pendingClassname={classes.pendingClassname}
            >
              {t('header.order_type.confirm')}
            </Button>
          </Box>
        )}
      </div>
    </Dialog>
  );
};

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default OrderTypeModal;
