import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setCurrentMerchant: ['data'],
  updateMerchant: ['merchant'],
  setSelectedStore: ['data'],
  setSelectedRegion: ['selectedRegion'],
  addNewStore: ['data'],
  updateSelectedStore: ['data'],
  setMerchantSettings: ['merchantSettings'],
  setSelectedOrderType: ['data'],
  setStoreSearch: ['data'],
  clearCart: ['data'],
  addUpdateCart: ['data'],
  removeItemCart: ['data'],
  updateQuantityItemCart: ['data'],
  setOrderTypes: ['orderTypes'],
  setLocalisation: ['localisation'],
  addOrderToCart: ['data'],
});

export const MerchantTypes = Types;
export default Creators;
