import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Divider,
  Typography,
  Popper,
} from '@material-ui/core';
import useStyles from './styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GET_GLOBAL_MODULE } from '@/services/modulesService';
import { STORE_SEARCH } from '@/services/storeService';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import _, { debounce } from 'lodash';
import { getFullAddressOfStore } from '@/utils/stringUtil';
import MerchantActions from '@/store/modules/store/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectedOrderType,
  storeSearch as storeSearchRedux,
  selectSelectedStore,
  selectOrderTypes,
} from '@/store/modules/store';
import { useSelector } from 'react-redux';
import { MODULE_STORE } from './helper';

// const globalModuleQueries = gql`
//   ${GET_GLOBAL_MODULE}
// `;

const storeSearchQueries = gql`
  ${STORE_SEARCH}
`;

const StoreSearchInput = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const orderType = useSelector(selectedOrderType);
  const storeSearchSelector = useSelector(storeSearchRedux);
  const store = useSelector(selectSelectedStore);
  const orderTypes = useSelector(selectOrderTypes);

  const [open, setOpen] = useState(false);

  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (_.isEqual(history.location.pathname, '/')) {
      storeSearch('');
    }
  }, []);

  const handleModuleChange = (event) => {
    const type = event.target.value;
    dispatch(
      MerchantActions.setSelectedOrderType({
        ...orderType,
        type,
        pricing_type: orderTypes.find((item) => item.type === type)
          ?.pricing_type,
      })
    );

    // getStore({
    //   variables: {
    //     module: module?.type,
    //   },
    // });
  };

  // const [getStore] = useLazyQuery(storeSearchQueries, {
  //   fetchPolicy: 'no-cache',
  //   onCompleted: (data) => {
  //     if (_.isEmpty(data?.storeSearch)) {
  //       setStores([{ empty: true }]);
  //     } else {
  //       setStores(data?.storeSearch);
  //     }

  //     dispatch(
  //       MerchantActions.setStoreSearch({
  //         data: _.isEmpty(data?.storeSearch) ? [] : data?.storeSearch,
  //         temp: _.isEmpty(data?.storeSearch)
  //           ? []
  //           : _.take(data?.storeSearch, 20),
  //         isLoading: false,
  //         isInit: false,
  //       })
  //     );
  //   },
  // });

  const storeSearch = (input) => {
    // setStores([]);
    // dispatch(
    //   MerchantActions.setStoreSearch({
    //     ...storeSearchSelector,
    //     isLoading:
    //       _.isEmpty(storeSearchSelector.temp) || !_.isEmpty(input),
    //     isInit: _.isEmpty(input),
    //   })
    // );
    // getStore({
    //   variables: {
    //     input: _.isEmpty(input) ? '' : `*${input}*`,
    //     module: selectedModule?.type,
    //   },
    // });
    // if (input) {
    //   setOpen(true);
    // } else {
    //   setOpen(false);
    // }
  };

  const onClickStore = (event, store) => {
    // if (store) {
    //   dispatch(
    //     MerchantActions.setSelectedStore({
    //       merchant_id: store.merchant_id,
    //       id: store.id,
    //     })
    //   );
    //   history.push(`/store/${store.merchant_id}/${store.id}`);
    // }
  };

  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        className={classes.autoCompleteInput}
        placement="bottom-start"
      />
    );
  };

  return (
    <Autocomplete
      open={open}
      onClose={() => setOpen(false)}
      className={classes.root}
      key={orderType.type}
      id="store-search-input"
      freeSolo
      options={stores}
      getOptionLabel={(option) => option?.name}
      onInputChange={debounce(
        (event, value) => storeSearch(value),
        1000
      )}
      filterOptions={(x) => x}
      PopperComponent={PopperMy}
      onChange={onClickStore}
      loading={storeSearchSelector.isLoading}
      loadingText={t('store.loading')}
      renderOption={(option) => {
        if (option.empty) {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={classes.title}>
                {t('store.no_store_found')}
              </Typography>
            </Box>
          );
        } else {
          return (
            <Box style={{ width: '100%' }} paddingY={1}>
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.boxStoreNameAddress}>
                  <Typography className={classes.title}>
                    {option.name}
                  </Typography>
                  <Typography className={classes.description}>
                    {getFullAddressOfStore(option.address)}
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.description}>
                    {option.merchant.business_type}
                  </Typography>
                </Box>
              </Box>
              <Divider className={classes.divider} />
            </Box>
          );
        }
      }}
      renderInput={(params) => (
        <Box
          display="flex"
          alignItems="center"
          className={classes.boxInput}
        >
          <Select
            value={orderType.type}
            className={classes.boxSelectInput}
            IconComponent={ExpandMoreIcon}
            onChange={handleModuleChange}
          >
            {orderTypes?.map((item) => (
              <MenuItem key={item.id} value={item.type}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            {...params}
            placeholder={t('store.search_placeholder')}
            className={classes.searchInput}
          />
          <Box className={classes.boxSearchIcon}>
            <SearchIcon
              className={classes.searchIcon}
              fontSize="small"
            />
          </Box>
        </Box>
      )}
    />
  );
};

export default StoreSearchInput;
