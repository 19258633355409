import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Grid,
  Button,
  Drawer,
  Typography,
} from '@material-ui/core';

import { LANGUAGES } from '../../constants/languages'
import Tab from './tab';

import useStyles from './styles';
import i18next from 'i18next';


const SwitchLanguage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchor, setAnchor] = useState('bottom');
  const [open, setOpen] = useState(false);


  const toggleDrawer = (position, state) => {
    setAnchor(position);
    setOpen(state);
  }

  const switchLang = (lang) => {
    i18next.changeLanguage(lang);
    setOpen(false);
  }

  const activeLang = LANGUAGES.find(({ lang }) => lang === i18next.language);

  return (
    <Grid item xs={12} sm={12} md={1} className={classes.container}>
      <Button
        className={classes.buttonLang}
        startIcon={<Flag code={activeLang?.flag} height={11} width={16} />}
        onClick={() => toggleDrawer('bottom', true)}
        endIcon={<ExpandMoreIcon />}
      >{activeLang?.label}</Button>
      <Drawer anchor={anchor} open={open} onClose={() => toggleDrawer(anchor, false)}>
        <Grid className={classes.drawer}>
          <header className={classes.headerDrawer}>
            <Typography className={classes.title}>{t('signup.change_language')}</Typography>
            <Button
              className={classes.button}
              color='primary'
              onClick={() => toggleDrawer(anchor, false)}
            >
              {t('signup.close')}
            </Button>
          </header>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            {LANGUAGES.map(({ label, id, lang }) => (
              <Tab key={id} onClick={switchLang} label={label} id={id} lang={lang} active={activeLang} />
            ))}
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  )
}

export default SwitchLanguage;
