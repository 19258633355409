import 'regenerator-runtime/runtime';
import 'intl';
import 'intl/locale-data/jsonp/id';
import 'intl/locale-data/jsonp/en';

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from '@/routes';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { THEME } from '@/theme';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from '@/store';
import { ApolloProvider } from '@apollo/client/react';
import MydaApolloClient from './components/apolloClient';
import SentryWrapper from './components/sentryWrapper';
import NotifyStackProvider from './components/notifyStack';
import 'react-image-crop/dist/ReactCrop.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-calendar/dist/Calendar.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { CookiesProvider } from 'react-cookie';
import AuthenticationProvider from './components/authenticationProvider';

const { store, persistor } = createStore();
const history = createBrowserHistory();

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <MuiThemeProvider theme={THEME}>
        <SentryWrapper>
          <CookiesProvider>
            <Provider store={store}>
              <NotifyStackProvider>
                <MydaApolloClient>
                  {(client) => (
                    <ApolloProvider client={client}>
                      <PersistGate
                        loading={null}
                        persistor={persistor}
                      >
                        <Router history={history}>
                          <AuthenticationProvider>
                            <Routes />
                          </AuthenticationProvider>
                        </Router>
                      </PersistGate>
                    </ApolloProvider>
                  )}
                </MydaApolloClient>
              </NotifyStackProvider>
            </Provider>
          </CookiesProvider>
        </SentryWrapper>
      </MuiThemeProvider>
    </I18nextProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));

module.hot.accept();
