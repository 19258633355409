import { Box, Typography, Select, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const QuantityInput = ({ quantity, onChangeQuantity }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState(quantity);

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    onChangeQuantity(value);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.title}>
        {t('cart.qty')}
      </Typography>
      <Select
        disableUnderline
        value={value}
        className={classes.selectInput}
        IconComponent={ExpandMoreIcon}
        onChange={handleChange}
      >
        {[...Array(50)]?.map((item, index) => (
          <MenuItem
            key={index + 1}
            value={index + 1}
            className={classes.menuItem}
          >
            {index + 1}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default QuantityInput;
