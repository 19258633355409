import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { UPDATE_STORE } from '@/services/storeService';
import { DAY_OF_WEEK } from '@/constants/store';
import { DAYS } from './helper';
import TimeSettingInput from './timeSettingInput';

const OpeningTimeInput = ({
  currentStore,
  onRefetchStore,
  hasUpdateStore,
}) => {
  const { store_openings, id } = currentStore || {};
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [storeOpenings, setStoreOpenings] = useState({});
  const [isMutating, setIsMutating] = useState(false);
  const [error, setError] = useState(false);

  const dayOfWeek = DAY_OF_WEEK[new Date().getDay()];
  const currentOpeningHour = store_openings?.find((storeDay) =>
    _.isEqual(storeDay.day, dayOfWeek)
  );

  const mappingHours = () => {
    return DAYS.reduce((acc, arg) => {
      return {
        ...acc,
        [`${arg}`]: {
          day: arg,
          opening_times: store_openings?.find(
            (storeDay) =>
              _.isEqual(storeDay.day, arg) &&
              !_.isEmpty(storeDay.opening_times)
          )?.opening_times || [{ open: '00:00', close: '00:00' }],
        },
      };
    }, {});
  };

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = (value) => {
    setOpenEditModal(false);
    if (!value) {
      setStoreOpenings(mappingHours());
    }
  };

  useEffect(() => {
    if (openEditModal) {
      const mapping = mappingHours();
      setStoreOpenings(mapping);
    }
  }, [openEditModal]);

  const [mutateStore] = useMutation(
    gql`
      ${UPDATE_STORE}
    `
  );

  const updateOpeningHours = async () => {
    setIsMutating(true);
    const updateData = Object.keys(storeOpenings).map((key) => ({
      day: key,
      opening_times: storeOpenings[key].opening_times.map((time) => ({
        open: time.open,
        close: time.close,
      })),
    }));

    const payload = {
      variables: {
        store: {
          id,
          store_openings: updateData,
        },
      },
    };
    return await mutateStore(payload)
      .then(async () => {
        await onRefetchStore?.();
        enqueueSnackbar(t('store.opening_hours_updated'));
        handleCloseEditModal(storeOpenings);
      })
      .catch((error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => {
        setIsMutating(false);
      });
  };

  return (
    <>
      {!currentOpeningHour ? (
        <Box>
          <Typography
            className={classes.addText}
            onClick={handleOpenEditModal}
          >
            {t('store.add')}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="flex-start">
          {currentOpeningHour?.opening_times?.map((time) => (
            <Typography
              key={`${time.open}${time.close}`}
              className={classes.value}
            >{`${time.open} - ${time.close}`}</Typography>
          ))}
          <Typography
            className={
              store_openings?.closed
                ? classes.closedTime
                : classes.openingTime
            }
          >
            {currentOpeningHour?.closed
              ? t('store.closed')
              : t('store.open_now')}
          </Typography>
          {hasUpdateStore && (
            <IconButton
              onClick={handleOpenEditModal}
              className={classes.iconButton}
            >
              <CreateIcon className={classes.createIcon} />
            </IconButton>
          )}
        </Box>
      )}
      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        onClose={() => handleCloseEditModal()}
        aria-labelledby={`{update-opening-hours-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{update-opening-hours-dialog-title}`}
          onClose={() => handleCloseEditModal()}
        >
          {t('store.update_opening_hours')}
        </DialogTitle>
        <DialogContent>
          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Table className={classes.boxTable}>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '250px' }} />
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '180px' }}
                  >
                    {t('store.open')}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '180px' }}
                  >
                    {t('store.close')}
                  </StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object?.keys(storeOpenings)?.map((row) => (
                  <TableRow
                    hover
                    key={row}
                    style={{ verticalAlign: 'baseline' }}
                  >
                    <StyledTableCell style={{ width: '250px' }}>
                      <Typography className={classes.tableText}>
                        {row}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      <div className={classes.boxFullName}>
                        {storeOpenings[row]?.opening_times?.map(
                          (time, index) => (
                            <TimeSettingInput
                              key={index}
                              open={time.open}
                              close={time.close}
                              day={row}
                              index={index}
                              setStoreOpenings={setStoreOpenings}
                              storeOpenings={storeOpenings}
                              setError={setError}
                            />
                          )
                        )}
                      </div>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Typography className={classes.addSeasonal}>
            {t('store.add_seasonal_opening_hours')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseEditModal()}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={isEmpty(storeOpenings) || error}
            onClick={updateOpeningHours}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isMutating && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 12,
  },
  head: {
    borderBottom: 'none',
  },
  body: {
    borderBottom: 'none',
    fontSize: 16,
  },
}))(TableCell);

export default OpeningTimeInput;
