import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const PaymentInfoCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.box}>
      <Box
        paddingBottom={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.title}>
          {t('cart.payment_type')}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <FormControlLabel
          classes={{
            label: classes.radioFormControlLabel,
          }}
          className={classes.radioFormControl}
          control={<CustomRadio checked={true} />}
          label={'Cash'}
        />
      </Box>
    </Box>
  );
};

const CustomRadio = withStyles({
  root: {
    padding: '4px',
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" size="small" {...props} />);

export default PaymentInfoCard;
