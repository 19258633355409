export const NOTIFICATION_TYPE = {
  ORDER_CREATED: 'OrderCreated',
  ORDER_UPDATED: 'OrderUpdated',
  ORDER_PAID: 'OrderPaid',
  ORDER_REFUNDED: 'OrderRefund',
  DELIVERY_UPDATED: 'DeliveryUpdated',
  RIDER_LOCATION_CHANGED: 'RiderLocationChanged',
  ORDER_RECEIPT: 'OrderReceipt',
  REQUEST_LOGIN_TOKEN: 'RequestLoginToken',
  NEW_VOUCHER: 'NewVoucher',
  VOUCHER_RETURNED: 'VoucherReturned',
};

export const LIMIT_OF_NOTIFICATIONS = 10;
