import React, { useEffect, useState } from 'react';
import { Box, Select, MenuItem, Typography } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import useStyles from './styles';
import { TIME_RANGE } from './helper';
import moment from 'moment';

const OrderDateTimePicker = ({
  onChange,
  defaultDate,
  defaultTime,
}) => {
  const classes = useStyles();
  const invalidDateTime = defaultDate && defaultTime;
  const dateComparison =
    invalidDateTime &&
    new Date(defaultDate).getTime() > new Date().getTime()
      ? new Date(defaultDate)
      : new Date();

  const [date, setDate] = useState(
    defaultDate ? dateComparison : new Date()
  );
  const [time, setTime] = useState(
    defaultTime ? defaultTime : TIME_RANGE[0].value
  );

  const onDateTimeChange = (type, value) => {
    switch (type) {
      case 'date':
        setDate(value);
        onChange?.(`${moment(value).format('YYYY-MM-DD')} ${time}`);
        break;
      case 'time':
        setTime(value);
        onChange?.(`${moment(date).format('YYYY-MM-DD')} ${value}`);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker
              autoOk
              variant="inline"
              className={classes.selectDateInput}
              value={date}
              disablePast
              onChange={(value) => onDateTimeChange('date', value)}
              format="dd/MM/yyyy"
            />
            <Select
              classes={{
                icon: classes.iconSelect,
                root: classes.selectTimeInput,
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={time}
              onChange={(e) =>
                onDateTimeChange('time', e.target.value)
              }
            >
              {TIME_RANGE.map((time) => (
                <MenuItem key={time.id} value={time.value}>
                  <Typography className={classes.timeItem}>
                    {time.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#0156b8',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'unset',
        },
        '&:after': {
          borderBottom: 'unset',
        },
        '&:hover': {
          borderBottom: 'unset',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'unset',
        },
      },
    },
  },
});

export default OrderDateTimePicker;
