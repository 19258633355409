import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Chip,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import { isEmpty, find } from 'lodash';
import { useQuery, gql, useMutation } from '@apollo/client';
import { UPDATE_STORE } from '@/services/storeService';
import { GET_SPECIALITIES, MUTATE_ADD_SPECIALITY } from '@/services/widgetService';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import useDebounce from '@/hooks/useDebounce';

const specialitiesQueries = gql`
  ${GET_SPECIALITIES}
`;

const SpecialitiesInput = ({
  currentStore,
  loadingStorefront,
  onRefetchStore,
  hideLabel = false,
  hasUpdateStore,
}) => {
  const { specialities: storeSpecialities, id } = currentStore || {};
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [specialities, setSpecialities] = useState([])
  const [soldItemList, setSoldItemList] = useState([]);

  const debounceSearch = useDebounce(inputValue, 1000);

  const newSpecialities = soldItemList.filter(item => !!item.isNew)
  const country = localStorage.getItem('country')

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setSoldItemList(
      storeSpecialities
        ?.filter(item => !!item)
        ?.map(item => ({
          id: item.id,
          name: item.name,
          isNew: false
        })) || []
    )
    setOpenEditModal(false);
  }

  const { loading: isLoadingSpecialities } = useQuery(
    specialitiesQueries,
    {
      skip: !openEditModal,
      variables: {
        country: currentStore?.address?.country || 'United Kingdom',
        name: `%${debounceSearch.toLowerCase()}%`,
      },
      onCompleted: (data) => {
        if (isEmpty(data.globalDatasets.specialities)) {
          setSpecialities([{ empty: true }]);
        } else {
          setSpecialities(data.globalDatasets.specialities);
        }
      },
      onError: () => { },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [mutateStore, { loading: isUpdatingStore }] = useMutation(
    gql`
      ${UPDATE_STORE}
    `,
    {
      onCompleted: () => {
        onRefetchStore?.();
        enqueueSnackbar(t('store.specialities_updated'));
        handleCloseEditModal();
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  );

  const [mutateSpeciality, { loading: isAddingSpeciality }] = useMutation(
    gql`
      ${MUTATE_ADD_SPECIALITY}
    `,
    {
      onCompleted: (data) => {
        mutateStore({
          variables: {
            store: {
              id,
              specialities: soldItemList
                .filter(soldItem => !soldItem.isNew)
                .concat(data.addSpecialities)
                ?.map(item => item.id)
            },
          },
        })
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  );

  useEffect(() => {
    setSoldItemList(
      storeSpecialities
        ?.filter(item => !!item)
        ?.map(item => ({
          id: item.id,
          name: item.name,
          isNew: false
        })) || []
    )
  }, [storeSpecialities])

  const updateSpeciality = () => {
    if (isEmpty(newSpecialities)) {
      mutateStore({
        variables: {
          store: {
            id,
            specialities: soldItemList.map(soldItem => soldItem.id),
          },
        },
      })
    } else {
      mutateSpeciality({
        variables: {
          input: newSpecialities.map(item => ({
            country: currentStore?.address?.country || 'United Kingdom',
            name: item.name
          }))
        },
      })
    }
  };

  return (
    <>
      {!hideLabel ? (
        <Box paddingY={4} paddingX={6}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.title}>
              {t('store.specialities')}
            </Typography>
            {hasUpdateStore && (
              <IconButton
                onClick={handleOpenEditModal}
                className={classes.iconButton}
              >
                <CreateIcon className={classes.createIcon} />
              </IconButton>
            )}
          </Box>
        </Box>
      ) : (
        hideLabel && (
          <>
            {isEmpty(storeSpecialities) && hasUpdateStore ? (
              <Box>
                <Typography
                  className={classes.addText}
                  onClick={handleOpenEditModal}
                >
                  {t('store.add')}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="flex-start">
                <Typography className={classes.value}>
                  {storeSpecialities?.map(speciality => speciality.name)?.join(', ')}
                </Typography>
                {hasUpdateStore && (
                  <IconButton
                    onClick={handleOpenEditModal}
                    className={classes.iconButton}
                  >
                    <CreateIcon className={classes.createIcon} />
                  </IconButton>
                )}
              </Box>
            )}
          </>
        )
      )}

      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={handleCloseEditModal}
        aria-labelledby={`{update-specialities-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{update-specialities-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {t('store.update_specialities')}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.descriptionDialog}>
            {t('store.description_specialities')}
          </Typography>
          <Autocomplete
            disabled={soldItemList?.length >= 3}
            inputValue={inputValue}
            value={value}
            id="allergy-search-input"
            freeSolo
            options={specialities}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <Box style={{ position: 'relative' }}>
                <TextField
                  {...params}
                  placeholder={t('store.speciality_placeholder')}
                  className={classes.searchInput}
                />
                <SearchIcon
                  className={classes.searchIcon}
                  fontSize="small"
                />
              </Box>
            )}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(option, { inputValue }) => {
              if (option?.empty) {
                return (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    paddingY={1}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        marginLeft: 16,
                      }}
                    >
                      {inputValue}
                    </span>
                    <Button
                      color="primary"
                      className={classes.addButton}
                      onClick={() => {
                        setSoldItemList([...soldItemList, { name: inputValue, isNew: true }])
                      }}
                    >
                      {`+ ${t('store.add_new')}`}
                    </Button>
                  </Box>
                );
              } else {
                const nameMatches = match(option.name, inputValue);
                const nameParts = parse(option.name, nameMatches);

                return (
                  <Box paddingX={2} paddingY={3}>
                    <Box>
                      {nameParts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 600 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </Box>
                  </Box>
                );
              }
            }}
            onInputChange={(e, newInputValue) =>
              setInputValue(newInputValue)
            }
            loading={isLoadingSpecialities}
            loadingText={t(
              'workboard.product_intelligence.loading_products'
            )}
            onChange={(event, item) => {
              if (item?.id) {
                if (!find(soldItemList, { name: item.name })) {
                  setSoldItemList([
                    ...soldItemList,
                    { id: item.id, name: item.name, isNew: false }
                  ]);
                  setValue(null);
                  setInputValue('');
                }
              } else {
                return;
              }
            }}
          />
          <Box marginTop={8}>
            {soldItemList?.map((item, index) => (
              <Chip
                className={classes.chipCategory}
                key={`${item}-${index}`}
                label={item.name}
                onDelete={() =>
                  setSoldItemList(
                    soldItemList.filter(soldItem => soldItem.name !== item.name)
                  )
                }
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={isEmpty(soldItemList) && isEmpty(storeSpecialities)}
            onClick={updateSpeciality}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {(isAddingSpeciality || isUpdatingStore || loadingStorefront) && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 12),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default SpecialitiesInput;
