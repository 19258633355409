import React from 'react';
import jwtDecode from 'jwt-decode';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { API_URL } from '@/constants/env';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import AuthActions from '@/store/modules/auth/actions';

import {
  selectAccessToken,
  selectRefreshToken,
} from '@/store/modules/auth/selectors';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { selectSelectedStore } from '@/store/modules/store/selectors';
import { isUnauthorized, notGuestPath } from './helper';

const MydaApolloClient = ({ children }) => {
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const selectedStore = useSelector(selectSelectedStore);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const httpLink = createHttpLink({
    uri: API_URL,
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        if (isUnauthorized(graphQLErrors) && notGuestPath()) {
          return;
        }
      if (networkError)
        console.log(`[Network error]: ${networkError}`);
    }
  );

  const authLink = setContext((_, { headers }) => {
    try {
      const merchantId =
        headers?.merchantId || selectedStore?.merchant_id;
      const storeId = headers?.storeId || selectedStore?.id;
      return {
        headers: {
          Authorization: accessToken
            ? `Bearer ${accessToken}`
            : 'Bearer Guest',
          'content-type': 'application/json',
          ...(merchantId && { 'x-metadata-merchant': merchantId }),
          ...(storeId && { 'x-metadata-store': storeId }),
        },
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  });

  const refreshTokenLink = new TokenRefreshLink({
    accessTokenField: 'refreshToken',
    isTokenValidOrUndefined: () => {
      if (!accessToken) return true;
      try {
        const { exp } = jwtDecode(accessToken);
        const expires = new Date(exp * 1000);
        if (Date.now() >= expires) return false;
        return true;
      } catch {
        return false;
      }
    },
    fetchAccessToken: () => {
      return fetch(API_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer Guest`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `mutation refreshToken {
            refreshToken(input: {refresh_token: "${refreshToken}"}) {
              access_token,
                token_type,
                refresh_token,
                expires_in,
            }
          }`,
        }),
      });
    },
    handleFetch: ({ access_token, refresh_token }) => {
      console.log('handleFetch--->');
      dispatch(AuthActions.setAccessToken(access_token));
      dispatch(AuthActions.setRefreshToken(refresh_token));
      //save token to ls
      localStorage.setItem('token', access_token);
    },
    handleError: (error) => {
      console.log('refresh token error--->', JSON.stringify(error));
      dispatch(AuthActions.logout());
    },
  });

  const client = new ApolloClient({
    link: from([refreshTokenLink, authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  if (!accessToken) {
    client.cache.reset();
  }

  return children(client);
};

export default MydaApolloClient;
