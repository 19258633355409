import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  autoCompleteInput: {
    zIndex: 999,
    width: '554px !important',
    top: `${theme.spacing(1)}px !important`,
    left: '-153px !important',
    [theme.breakpoints.down('lg')]: {
      width: '523px !important',
      top: `${theme.spacing(1)}px !important`,
      left: '-153px !important',
    },
    [theme.breakpoints.down('xs')]: {
      left: '0px !important',
      width: 'auto !important',
    },
    [theme.breakpoints.between('770', '1120')]: {
      width: '414px !important',
    },
  },
  boxStoreNameAddress: {
    width: '65%',
  },
  boxInput: {
    border: `1px solid ${Colors.GRAY_BORDER1}`,
    borderRadius: theme.spacing(1),
  },
  boxSearchIcon: {
    padding: theme.spacing(2.5, 3, 1.5, 3.5),
    backgroundColor: '#ffc624',
    borderRadius: theme.spacing(0, 1, 1, 0),
  },
  boxSelectInput: {
    ...CommonFonts.darkFont16,
    width: theme.spacing(35),
    padding: theme.spacing(1, 3),
    borderRight: `1px solid ${Colors.GRAY_BORDER1}`,
    '&:before': {
      borderBottom: 'unset',
    },
    '&:after': {
      borderBottom: 'unset',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'unset',
    },
    '& .MuiSvgIcon-root': {
      width: theme.spacing(5),
      height: theme.spacing(6),
      marginRight: theme.spacing(2),
    },
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(4),
      '&:focus': {
        backgroundColor: 'unset',
      },
    },
  },
  searchIcon: {
    color: Colors.WHITE,
  },
  searchInput: {
    padding: theme.spacing(1, 3),
    width: theme.spacing(85),
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(77),
    },

    [theme.breakpoints.between('770', '1120')]: {
      width: '200px !important',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiInput-root': {
      ...CommonFonts.darkFont14,
      '&:before': {
        borderBottom: 'unset',
      },
      '&:after': {
        borderBottom: 'unset',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'unset',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'unset',
      },
    },
  },
  title: {
    ...CommonFonts.darkFont14,
    width: theme.spacing(55),
    wordBreak: 'break-word',
  },
  description: {
    ...CommonFonts.grayFont12,
  },
  divider: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
}));

export default useStyles;
