import React, { useState } from 'react';
import {
  Box,
  Grid,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import _ from 'lodash';
import ProductGroupAddonItem from '../productGroupAddonItem';
import { useQuery, gql } from '@apollo/client';
import { PRODUCT_GROUP_ADD_ON } from '@/services/inventoryService';
import { Skeleton } from '@material-ui/lab';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import MerchantActions from '@/store/modules/store/actions';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { getPriceOfOption } from '@/utils/priceUtil';
import { getLocalisationVal } from '@/utils/localisationUtil';

const productGroupAddonQueries = gql`
  ${PRODUCT_GROUP_ADD_ON}
`;

const ProductGroupAddonModal = ({
  isVisible,
  onCloseModal,
  product,
  productPrice,
  productTaxes,
  currency,
  groupAddonIds,
  pricingType,
  quantity = 1,
  isNetPrice,
  localisation,
}) => {
  const { id, name, bar_code, category, addons } = product || {};

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { merchantId, storeId } = useParams();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [groupAddon, setGroupAddon] = useState([]);
  const [currentQuantity, setCurrentQuantity] = useState(quantity);

  const onClose = () => {
    onCloseModal();
    setGroupAddon([]);
  };

  const findAddons = (addonId) =>
    addons?.find((item) => _.isEqual(item.id, addonId));

  const { loading: loadingGroupAddon } = useQuery(
    productGroupAddonQueries,
    {
      context: {
        headers: { storeId, merchantId },
      },
      variables: {
        id: groupAddonIds,
      },
      skip: !isVisible,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const product_addons = data.productAddons;
        if (!_.isEmpty(product_addons)) {
          const result = product_addons.reduce((acc, arg) => {
            const productAddons = findAddons(arg.id);
            if (productAddons) {
              return acc.concat({
                ...arg,
                allow_free: productAddons.allow_free,
                default_all: productAddons.default_all,
                multi_selection: productAddons.multi_selection,
                position: productAddons.position,
                options: arg.options.map((option) => ({
                  ...option,
                  groupId: arg.id,
                  groupName: arg.group,
                  quantity: productAddons.default_all
                    ? option.price.measure
                    : 0,
                  freeQuantity: 0,
                })),
              });
            } else return acc;
          }, []);
          setGroupAddon(
            result?.sort((a, b) => a.position - b.position)
          );
        }
      },
    }
  );

  const onAddToCart = async () => {
    const result = groupAddon?.reduce((acc, arg) => {
      return acc.concat({
        ...arg,
        mandatoryError:
          arg.mandatory &&
          _.isEmpty(arg.options.find((option) => option.quantity > 0))
            ? t('store.cart.select_an_option')
            : '',
      });
    }, []);
    setGroupAddon(result);

    const hasError = !_.isEmpty(
      result.filter((addon) => !_.isEmpty(addon.mandatoryError))
    );

    if (!hasError) {
      const addonsOptions = groupAddon?.reduce((acc, arg) => {
        return acc.concat(
          arg.options
            ?.filter((option) => option.quantity > 0)
            ?.map((option) => {
              const optionPriceInfo =
                option?.price?.price_infos?.find((info) =>
                  _.isEqual(info.price_type?.type, pricingType)
                );
              const optionPrice = parseFloat(
                (
                  option?.price?.fixed_price || optionPriceInfo?.price
                ).toFixed(4)
              );

              const optionTaxes = productTaxes.map((tax) => ({
                id: tax.id,
                name: tax.name,
                rate: tax.rate,
                amount: parseFloat(
                  (
                    optionPrice *
                    (Math.max(tax.rate, 0) / 100)
                  ).toFixed(4)
                ),
              }));

              return {
                addon_id: option.id,
                name: option.name,
                quantity: option.quantity,
                group: {
                  id: option.groupId,
                  name: option.groupName,
                },
                price: {
                  amount: optionPrice,
                  taxes: optionTaxes,
                  quantity: option.quantity - option.freeQuantity,
                },
              };
            })
        );
      }, []);

      const cart = {
        id: uuid(),
        product_id: id,
        product,
        name: name,
        bar_code: bar_code,
        addons: addonsOptions,
        measure: product.measure,
        category: {
          id: category.id,
          name: category.name,
        },
        price: {
          amount: productPrice,
          quantity: currentQuantity,
          taxes: productTaxes,
        },
      };

      dispatch(
        MerchantActions.addUpdateCart({
          storeId,
          merchantId,
          cart: cart,
        })
      );

      onClose();

      enqueueSnackbar(
        t('store.shoppingCart.product_added_to_cart', { name })
      );
    }
  };

  const productTaxesAmount = productTaxes?.reduce(
    (acc, arg) => acc + arg.amount,
    0
  );

  const calculatingProductPrice = () => {
    const addonsPrice = groupAddon.reduce(
      (groupAcc, addon) =>
        groupAcc +
        addon.options.reduce((optionAcc, arg) => {
          const optionPriceInfo = arg?.price?.price_infos?.find(
            (info) => _.isEqual(info.price_type?.type, pricingType)
          );
          var optionPrice = parseFloat(
            (
              arg?.price?.fixed_price || optionPriceInfo?.price
            ).toFixed(4)
          );

          const optionTaxes = productTaxes?.reduce(
            (taxAcc, tax) =>
              taxAcc + optionPrice * (Math.max(tax.rate, 0) / 100),
            0
          );
          if (!isNetPrice) {
            optionPrice += optionTaxes;
          }
          return (
            optionAcc +
            optionPrice *
              currentQuantity *
              (arg.quantity - arg.freeQuantity)
          );
        }, 0),
      0
    );

    const taxingNames = productTaxes
      ?.map((tax) => tax.name)
      ?.join('&');
    const price = isNetPrice
      ? productPrice * currentQuantity + addonsPrice
      : productPrice * currentQuantity +
        addonsPrice +
        productTaxesAmount;
    const result = getLocalisationVal(localisation, price);
    return `${result} ${isNetPrice ? `+ ${taxingNames}` : ''}`;
  };

  const updateGroupAddonData = (addonIndex, optionIndex, datas) => {
    const result = [...groupAddon];
    if (Array.isArray(datas)) {
      datas.map((data) => {
        result[addonIndex].options[optionIndex][data.key] =
          data.value;
        setGroupAddon(result);
      });
    } else {
      result[addonIndex].options[optionIndex][datas.key] =
        datas.value;
      setGroupAddon(result);
    }
  };

  const updateAddonAllowFree = (addonId, value) => {
    const result = groupAddon.map((addon) =>
      _.isEqual(addon.id, addonId)
        ? { ...addon, allow_free: value }
        : addon
    );
    setGroupAddon(result);
  };

  const TempLayout = () => {
    return (
      <Grid container>
        <Grid
          item
          sm={12}
          md={12}
          xl={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Skeleton width="60%" height={100} />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          xl={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Skeleton width="40%" height={100} />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          xl={12}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <Skeleton width="30%" height={100} />
        </Grid>
        <Grid container spacing={3}>
          {[...Array(12)].map((x, i) => (
            <Grid key={i} item sm={6} xl={6}>
              <Skeleton height={100} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const onClickMinus = () => {
    setCurrentQuantity(currentQuantity - 1);
  };

  const onClickPlus = () => {
    setCurrentQuantity(currentQuantity + 1);
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      fullScreen={fullScreen}
      onClose={(event, reason) => false}
      aria-labelledby={`{group-addon-dialog-title}`}
      open={isVisible}
    >
      {/* <DialogTitle
        id={`{group-addon-dialog-title}`}
        onClose={onClose}
      /> */}
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent>
        {loadingGroupAddon ? (
          <TempLayout />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={classes.productName}>
                {name}
              </Typography>
              <Box
                display="flex"
                alignItems="baseline"
                marginBottom={2}
              >
                <Typography className={classes.currency}>
                  {currency}
                </Typography>
                <Typography className={classes.productPrice}>
                  {calculatingProductPrice()}
                </Typography>
              </Box>
              <Typography className={classes.selectOptions}>
                {t('store.product.select_options')}
              </Typography>
            </Box>
            <Box marginY={5}>
              {groupAddon?.map((addon, index) => (
                <ProductGroupAddonItem
                  key={`${addon.id}-${index}`}
                  addonIndex={index}
                  data={addon}
                  updateGroupAddonData={updateGroupAddonData}
                  pricingType={pricingType}
                  productTaxes={productTaxes}
                  isNetPrice={isNetPrice}
                  updateAddonAllowFree={(value) =>
                    updateAddonAllowFree(addon.id, value)
                  }
                />
              ))}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            disabled={currentQuantity <= 1}
            onClick={onClickMinus}
            className={classes.plusMinusIcon}
          >
            <RemoveIcon />
          </IconButton>
          <Typography className={classes.quantity}>
            {currentQuantity}
          </Typography>
          <IconButton
            onClick={onClickPlus}
            className={classes.plusMinusIcon}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Button
          className={classes.addUpdateCart}
          variant="contained"
          onClick={onAddToCart}
          color="primary"
        >
          {t('store.add_to_cart')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(4),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default ProductGroupAddonModal;
