import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import { getTimeStringFromHourAndMinute } from '@/utils/stringUtil';
import { DAY_OF_WEEK } from '@/constants/store';
import { getSecondFromTimeString } from '@/utils/numberUtil';

const StoreOpenings = ({ openingData }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let time = '';
  const dayOfWeek = DAY_OF_WEEK[new Date().getDay()];
  const storeOpenings = openingData?.find((storeDay) =>
    _.isEqual(storeDay.day, dayOfWeek)
  );
  const { closed, opening_times } = storeOpenings || {};
  const currentTime = getSecondFromTimeString(
    getTimeStringFromHourAndMinute(new Date())
  );

  const timeRange = opening_times?.map((item) =>
    closed
      ? {
          label: item.open,
          value: getSecondFromTimeString(item.open),
        }
      : {
          label: item.close,
          value: getSecondFromTimeString(item.close),
        }
  );

  const timeComparisonList = timeRange
    ?.map((item) => item.value)
    ?.filter((item) => item > currentTime);
  const minTime = _.min(timeComparisonList);

  if (closed && _.isEmpty(timeComparisonList)) {
    time = timeRange[0]?.label;
  } else {
    time =
      timeRange?.find((item) => _.isEqual(item.value, minTime))
        ?.label || '';
  }

  return (
    <Box display="flex" alignItems="center" marginTop={1}>
      <AccessTimeOutlinedIcon
        classes={{
          root: classes.icon,
        }}
      />
      <Box display="flex" alignItems="center">
        <Typography
          className={closed ? classes.closed : classes.open}
        >
          {closed ? t('landing.closed') : t('landing.open')}
        </Typography>
        <Typography className={classes.address}>
          {`${
            closed ? t('landing.opening_at') : t('landing.closing_at')
          } ${time}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default StoreOpenings;
