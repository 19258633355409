import { Box, Typography, Divider, Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MerchantActions from '@/store/modules/store/actions';
import _ from 'lodash';
import { useParams } from 'react-router';
import QuantityInput from './QuantityInput';
import { getLocalisationVal } from '@/utils/localisationUtil';

const ProductItem = ({
  data,
  isNetPrice,
  localisation,
  currency,
}) => {
  const { name, product, addons, price, id } = data || {};

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { merchantId, storeId } = useParams();

  const productImage = product?.images?.find(
    (image) => !_.isEmpty(image.id)
  );

  const convertingDecimnal = (value) =>
    getLocalisationVal(localisation, value);

  const productTaxesCalculation = (taxes) =>
    taxes?.reduce((accTaxes, argTax) => {
      return accTaxes + argTax.amount;
    }, 0);

  const pricingCalculation = () => {
    let result = '';
    if (!_.isEmpty(addons)) {
      const addonsPrice = addons?.reduce((acc, arg) => {
        return (
          acc +
          arg?.price?.amount * arg?.price?.quantity * price?.quantity
        );
      }, 0);

      const addonsTaxes = addons?.reduce((acc, arg) => {
        return (
          acc +
          arg.price.taxes.reduce(
            (accTax, argTax) =>
              accTax +
              argTax.amount * arg.price.quantity * price?.quantity,
            0
          )
        );
      }, 0);

      const temp = isNetPrice
        ? price?.amount * price?.quantity + addonsPrice
        : (price?.amount + productTaxesCalculation(price?.taxes)) *
            price?.quantity +
          (addonsPrice + addonsTaxes);
      result = convertingDecimnal(temp);
    } else {
      const temp = isNetPrice
        ? price?.amount * price?.quantity
        : (price?.amount + productTaxesCalculation(price?.taxes)) *
          price?.quantity;
      result = convertingDecimnal(temp);
    }
    return `${result}`;
  };

  const onRemove = () =>
    dispatch(
      MerchantActions.removeItemCart({
        merchantId,
        storeId,
        id,
      })
    );

  const onChangeQuantity = (quantity) => {
    dispatch(
      MerchantActions.updateQuantityItemCart({
        merchantId,
        storeId,
        id,
        quantity,
      })
    );
  };

  return (
    <>
      <Grid container>
        <Grid item sm={9} lg={10}>
          <Grid container spacing={6}>
            <Grid item>
              <img
                className={classes.image}
                src={productImage?.thumbnail}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.productName}>
                {name}
              </Typography>
              {(!product?.stocked || !_.isEmpty(addons)) && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={2}
                >
                  {!product?.stocked && (
                    <Box marginRight={5}>
                      <Typography className={classes.stock}>
                        {t('cart.stock', {
                          quantity: product?.stocks?.length,
                        })}
                      </Typography>
                    </Box>
                  )}
                  {!_.isEmpty(addons) && (
                    <Box className={classes.stock}>
                      {addons
                        ?.map(
                          (item) =>
                            `${item.price.quantity} x ${item.name}`
                        )
                        .join(', ')}
                    </Box>
                  )}
                </Box>
              )}
              <Grid
                container
                spacing={6}
                className={classes.boxOption}
              >
                <Grid item sx={12}>
                  <QuantityInput
                    onChangeQuantity={onChangeQuantity}
                    quantity={price?.quantity}
                  />
                </Grid>
                <Grid item sx={12} className={classes.boxAddRemove}>
                  <Typography
                    className={classes.remove}
                    onClick={onRemove}
                  >
                    {t('cart.remove')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3} lg={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.boxPriceTax}
          >
            <Typography className={classes.currency}>
              {currency}
            </Typography>
            <Typography className={classes.price}>
              {pricingCalculation()}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

export default ProductItem;
