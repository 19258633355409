import React, { createContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Button,
  DialogContent as MuiDialogContent,
  Typography,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { CURRENT_USER } from '@/services/userService';
import { CREATE_USER_MUTATION_PARAS } from '@/services/authService';
import { MUTATE_DECLINE_INVITATION } from '@/services/invitationService';
import { MUTATE_REGISTER_TOKEN } from '@/services/notificationService';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUser,
  selectUserIsLoggedIn,
} from '@/store/modules/auth';
import { selectCurrentMerchant } from '@/store/modules/store';
import AuthActions from '@/store/modules/auth/actions';
import MerchantActions from '@/store/modules/store/actions';
import queryString from 'query-string';

const currentUserQuery = gql`
  ${CURRENT_USER}
`;

export const NotificationContext = createContext({
  count: 0,
  setCount: () => {},
});

const NotificationProvider = ({
  children,
  getFirebaseToken,
  history,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const currentMerchants = useSelector(selectCurrentMerchant);
  const { email } = currentUser;
  const dispatch = useDispatch();
  const { setMerchants } = AuthActions;
  const { setCurrentMerchant } = MerchantActions;
  const { enqueueSnackbar } = useSnackbar();

  const [openInvitationModal, setOpenInvitationModal] =
    useState(false);
  const [count, setCount] = useState(0);
  const [token, setToken] = useState('');
  const [invitationIds, setInvitationsIds] = useState([]);
  const [userInvitations, setUserInvitations] = useState([]);
  const inviteId = queryString.parse(
    history.location.search
  )?.invite_id;

  const handleOpenInvitationModal = () =>
    setOpenInvitationModal(true);
  const handleCloseInvitationModal = () =>
    setOpenInvitationModal(false);

  const [
    getCurrentUser,
    { loading: getCurrentUserLoading, data, refetch },
  ] = useLazyQuery(currentUserQuery, {
    fetchPolicy: 'no-cache',
  });

  const currentUserData = data?.currentUser;
  const currentInvitation = userInvitations[0];

  const [mutateRegisterToken] = useMutation(
    gql`
      ${MUTATE_REGISTER_TOKEN}
    `
  );

  const [declineInvitation, { loading: declineLoading }] =
    useMutation(
      gql`
        ${MUTATE_DECLINE_INVITATION}
      `,
      {
        variables: {
          id: currentInvitation?.invite_id,
        },
      }
    );

  const [acceptInvitation, { loading: acceptLoading }] =
    useMutation(gql`
      ${CREATE_USER_MUTATION_PARAS}
    `);

  const setInvitationToStorage = (invitations) => {
    setInvitationsIds(invitations);
    localStorage.setItem(
      'invitationIds',
      _.isEmpty(invitations) ? [] : invitations?.toString()
    );
  };

  useEffect(
    function fetchCurrentUser() {
      if (isLoggedIn) {
        getCurrentUser();
        const invitationIds = localStorage.getItem('invitationIds');
        setInvitationsIds(
          _.isEmpty(invitationIds) ? [] : invitationIds?.split(',')
        );
      }
    },
    [isLoggedIn, inviteId]
  );

  // Handle Firebase message on background
  useEffect(() => {
    if (!_.isEmpty(currentUserData) && isLoggedIn) {
      async function handleMessageBackground() {
        const userInvitationData = currentUserData?.invitations;
        const invitationIdInStorage =
          localStorage.getItem('invitationIds');
        setInvitationsIds(
          _.isEmpty(invitationIdInStorage)
            ? []
            : userInvitationData?.map((item) => item.invite_id)
        );
        const allInvitations = !_.isEmpty(inviteId)
          ? [...invitationIds, inviteId]
          : !_.isEmpty(invitationIdInStorage)
          ? [...invitationIds]
          : userInvitationData?.map((item) => item.invite_id);
        setInvitationToStorage(allInvitations);
        if (
          !_.isEmpty(userInvitationData) &&
          !_.isEmpty(allInvitations)
        ) {
          const result = userInvitationData?.filter((item) =>
            allInvitations.includes(item.invite_id)
          );
          if (!_.isEmpty(result)) {
            setUserInvitations(result);
            setCount(result?.length);
            handleOpenInvitationModal();
          }
        } else {
          setCount(invitationIds?.length);
        }
      }
      handleMessageBackground();
    }
  }, [currentUserData]);

  useEffect(() => {
    async function registerToken() {
      if (_.isEmpty(token) && isLoggedIn) {
        const firebaseToken = await getFirebaseToken();
        if (firebaseToken) {
          setToken(firebaseToken);
          mutateRegisterToken({
            variables: {
              input: {
                token: firebaseToken,
              },
            },
          });
        }
      }
    }
    registerToken();
  }, [isLoggedIn, currentUserData, token]);

  // Listen message from Firebase

  const onDecline = async () => {
    try {
      await declineInvitation();
      handleCloseInvitationModal();

      const removeCurrentInvitation = invitationIds?.filter(
        (item) => item !== currentInvitation?.invite_id
      );
      setInvitationToStorage(removeCurrentInvitation);
      setCount(removeCurrentInvitation?.length || 0);

      enqueueSnackbar(
        t('invitation.invite.decline_invitation_message')
      );
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const onAccept = async () => {
    try {
      await acceptInvitation({
        variables: {
          input: {
            email: email,
            password: null,
            invite_id: currentInvitation?.invite_id,
            redirect_url: '',
          },
        },
      });

      const removeCurrentInvitation = invitationIds?.filter(
        (item) => item !== currentInvitation?.invite_id
      );
      setInvitationToStorage(removeCurrentInvitation);
      setCount(removeCurrentInvitation?.length || 0);

      const response = await refetch();
      const userMerchantData = response?.data?.currentUser?.merchants;

      handleCloseInvitationModal();
      enqueueSnackbar(
        t('invitation.invite.accept_invitation_message', {
          name: currentInvitation?.merchant_name,
        })
      );

      //Dispatch to update user merchants & current merchant
      dispatch(setMerchants(userMerchantData));
      dispatch(
        setCurrentMerchant(
          _.isEmpty(currentMerchants)
            ? userMerchantData[0]
            : userMerchantData?.find(
                (item) => item.id === currentMerchants?.id
              )
        )
      );

      // Redirect when merchants only one store
      if (
        userMerchantData?.length === 1 &&
        userMerchantData[0]?.stores?.length === 1
      ) {
        history.push('');
      }
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const isLoading =
    getCurrentUserLoading || declineLoading || acceptLoading;

  return (
    <NotificationContext.Provider
      value={{
        count,
        setCount,
      }}
    >
      {children}
      <Dialog
        id={currentInvitation?.id}
        className={classes.boxDialog}
        maxWidth={false}
        fullWidth
        fullScreen={isMobile}
        aria-labelledby={`{invitation-dialog-title}`}
        open={openInvitationModal}
        disableBackdropClick
      >
        <DialogContent className={classes.dialogContent}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.title}>
              {currentInvitation?.merchant_name}
            </Typography>
            <Typography className={classes.description}>
              {t('invitation.invite.description')}
            </Typography>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                className={classes.boxButton}
                spacing={6}
              >
                <Grid item md={6}>
                  <Button
                    variant="outlined"
                    className={classes.actionButton}
                    onClick={() => onDecline()}
                  >
                    {t('invitation.invite.decline')}
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    variant="outlined"
                    className={classes.actionButton}
                    onClick={() => onAccept()}
                  >
                    {t('invitation.invite.accept')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Typography
              className={classes.remind}
              onClick={() => {
                handleCloseInvitationModal();
                localStorage.setItem(
                  'invitationIds',
                  invitationIds.toString()
                );
              }}
            >
              {t('invitation.invite.remind_later')}
            </Typography>
          </Box>
          {isLoading && <PreLoader size={25} />}
        </DialogContent>
      </Dialog>
    </NotificationContext.Provider>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

export default NotificationProvider;
