import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    itemText: {
      ...CommonFonts.darkFont18,
    },
    itemContainer: {
      marginTop: theme.spacing(3),
    },
    promotionText: {
      ...CommonFonts.darkFont14,
      color: Colors.PURPLE,
    },
    addonText: {
      ...CommonFonts.darkFont14,
    },
  })
);

export default useStyles;
