import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import UserPage from '@/pages/user';
import NotFoundPage from '@/pages/notFoundPage';
import { userIsLoggedIn, userIsNotLoggedIn } from '@/hoc/authWrapper';
import LoginPage from '@/pages/auth/loginPage';
import StorePage from '@/pages/store';
import LandingPage from '@/pages/landing';
import CartPage from '@/pages/cart';
import OrderManagementPage from '@/pages/orderManagement';
import VoucherPage from '@/pages/voucher';

import { selectCurrentUser } from '@/store/modules/auth/selectors';

import axios from 'axios';
import { requestFirebaseNotificationPermission } from '../../firebase';
import { getMessaging, onMessage } from 'firebase/messaging';
import useHandleNotifications from '@/utils/hooks/useHandleNotifications';

const WrappedLoginPage = userIsNotLoggedIn(LoginPage);
const WrappedOrderManagementPage = userIsLoggedIn(
  OrderManagementPage
);
const WrappedVoucherPage = userIsLoggedIn(VoucherPage);

const Routes = () => {
  const currentUser = useSelector(selectCurrentUser);
  const { updateFirebaseToken, handleNotifications } =
    useHandleNotifications();

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        localStorage.setItem('country', data.country_name);
      })
      .catch((error) => {});

    const messaging = getMessaging();
    const unsubscribeMessage = onMessage(
      messaging,
      handleNotifications
    );

    return () => {
      unsubscribeMessage();
    };
  }, []);

  useEffect(() => {
    if (!!currentUser) {
      updateFirebaseToken();
    }
  }, [currentUser?.id]);

  return (
    <div>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={WrappedLoginPage} />
        <Route path="/user/:tab" component={UserPage} />
        <Route
          exact
          path={[
            '/store/:merchantId/:storeId/:productId',
            '/store/:merchantId/:storeId',
          ]}
          component={StorePage}
        />
        <Route
          exact
          path="/cart/:merchantId/:storeId"
          component={CartPage}
        />
        <Route
          path="/order-management"
          component={WrappedOrderManagementPage}
        />
        <Route exact path="/voucher" component={WrappedVoucherPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default Routes;
