import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
  Typography,
  Button,
} from '@material-ui/core';

import useStyles from './styles';
import OrderDateTimePicker from '../dateTimePicker';
import AddressAutocomplete from '@/components/addressAutocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserIsLoggedIn } from '@/store/modules/auth';
import { MUTATE_USER_DETAIL } from '@/services/userManagementService';
import AuthActions from '@/store/modules/auth/actions';
import PreLoader from '@/components/preLoader';
import { gql, useMutation } from '@apollo/client';
import { getFullAddressOfStore } from '@/utils/stringUtil';
import { useSnackbar } from 'notistack';
import { TIME_RANGE } from '../dateTimePicker/helper';
import moment from 'moment';
import { ORDER_TYPE } from '@/constants/store';
import { ASAP } from '@/constants/dateAndTime';

const DeliveryType = ({
  deliveryData,
  updateOrderType,
  currentUser,
}) => {
  const { time } = deliveryData;
  const separateDateTime = time?.split(' ');
  const initialDate = separateDateTime[0];
  const initialTime = separateDateTime[1];

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [address, setAddress] = useState({});
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const country = localStorage.getItem('country');

  useEffect(() => {
    if (
      initialDate &&
      initialTime &&
      new Date(initialDate).getTime() < new Date().getTime()
    ) {
      updateOrderType(ORDER_TYPE.DELIVERY, {
        ...deliveryData,
        time: `${moment(new Date()).format(
          'YYYY-MM-DD'
        )} ${initialTime}`,
      });
    }
  }, []);

  const [mutateUserDetail] = useMutation(
    gql`
      ${MUTATE_USER_DETAIL}
    `
  );

  const onAddressChange = async (address) => {
    setIsLoading(true);
    const payload = {
      variables: {
        input: {
          id: currentUser.id,
          addresses: {
            flag: 'add',
            door_number: address.door_number || '',
            building_name: address.building_name || '',
            line1: address.line1 || '',
            line2: address.line2 || '',
            city_town: address.city_town || '',
            postcode: address.postcode || '',
            county: address.county || '',
            country: address.country,
            lat: address.lat,
            lng: address.lng,
          },
        },
      },
      context: {
        headers: {
          merchantId: -1,
          storeId: -1,
        },
      },
    };
    return mutateUserDetail(payload)
      .then((res) => {
        if (res) {
          dispatch(
            AuthActions.setCurrentUser({
              ...currentUser,
              addresses: [
                ...currentUser.addresses,
                ...res?.data?.updateUser?.addresses,
              ],
            })
          );
          setShowNewAddress(false);
          enqueueSnackbar(t('header.order_type.new_address_added'));
        }
      })
      .catch((error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="100%">
        <Typography className={classes.title}>
          {t('header.order_type.delivery_to')}
        </Typography>

        {isLoggedIn && (
          <Box marginY={2}>
            {!_.isEmpty(currentUser?.addresses) && (
              <RadioGroup
                aria-label="collection-type-group"
                name="collection-type-group"
                onChange={(e) =>
                  updateOrderType(ORDER_TYPE.DELIVERY, {
                    ...deliveryData,
                    to: e.target.value,
                  })
                }
                className={classes.collectionTypeGroup}
              >
                <Box
                  marginY={4}
                  display="flex"
                  flexDirection="column"
                >
                  {currentUser?.addresses?.map((address) => (
                    <FormControlLabel
                      key={address.id}
                      classes={{
                        label: classes.radioFormControlLabel,
                      }}
                      className={classes.radioFormControl}
                      control={
                        <CustomRadio
                          checked={_.isEqual(
                            address.id,
                            deliveryData.to
                          )}
                          value={address.id}
                          name="collection"
                        />
                      }
                      label={getFullAddressOfStore(address)}
                    />
                  ))}
                </Box>
              </RadioGroup>
            )}
            {showNewAddress && (
              <Box marginY={4}>
                <AddressAutocomplete
                  placeholder={t(
                    'header.order_type.address_placeholder'
                  )}
                  getValues={(value) => onAddressChange(value)}
                  getFullAdress={() => {}}
                />
              </Box>
            )}
            <Box marginY={5}>
              <Button
                disabled={showNewAddress}
                onClick={() => setShowNewAddress(true)}
                color="primary"
                className={classes.addNewAddress}
              >
                {t('header.order_type.add_new_address')}
              </Button>
            </Box>
          </Box>
        )}

        {!isLoggedIn && (
          <Box marginTop={5} marginBottom={6}>
            <AddressAutocomplete
              placeholder={t('header.order_type.address_placeholder')}
              getValues={(value) => {
                setAddress({
                  door_number: value.door_number || '',
                  building_name: value.building_name || '',
                  line1: value.line1 || '',
                  line2: value.line2 || '',
                  city_town: value.city_town || '',
                  postcode: value.postcode || '',
                  county: value.county || '',
                  country: value.country,
                });
              }}
              getFullAdress={() => {}}
            />
          </Box>
        )}
      </Box>

      <Box width="100%" marginY={2}>
        <Typography className={classes.title}>
          {t('header.order_type.delivery_time')}
        </Typography>

        <RadioGroup
          aria-label="collection-type-group"
          name="collection-type-group"
          onChange={(e) =>
            updateOrderType(ORDER_TYPE.DELIVERY, {
              ...deliveryData,
              time: e.target.value,
            })
          }
          className={classes.collectionTypeGroup}
        >
          <Box marginY={4} display="flex" flexDirection="column">
            <FormControlLabel
              classes={{
                label: classes.radioFormControlLabel,
              }}
              className={classes.radioFormControl}
              control={
                <CustomRadio
                  checked={_.isEqual(time, ASAP)}
                  value={ASAP}
                  name={ASAP}
                />
              }
              label={t('header.order_type.today_asap')}
            />
            <FormControlLabel
              classes={{
                label: classes.radioFormControlLabel,
              }}
              className={classes.radioFormControl}
              control={
                <CustomRadio
                  checked={!_.isEqual(time, ASAP)}
                  value={`${moment(new Date()).format(
                    'YYYY-MM-DD'
                  )} ${TIME_RANGE[0].value}`}
                  name="selectDateTime"
                />
              }
              label={
                <OrderDateTimePicker
                  defaultDate={initialDate}
                  defaultTime={initialTime}
                  onChange={(value) =>
                    updateOrderType(ORDER_TYPE.DELIVERY, {
                      ...deliveryData,
                      time: value,
                    })
                  }
                />
              }
            />
          </Box>
        </RadioGroup>
      </Box>
      {isLoading && <PreLoader size={30} />}
    </Box>
  );
};

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default DeliveryType;
