import { takeLatest, put, call } from 'redux-saga/effects';

import AuthActions, { AuthTypes } from './actions';

import { apiCallSaga } from '@/utils/apiCall';
import produce from 'immer';

import {
  LOGIN_MUTATION_PARAMS,
  CHECK_USER_QUERY_PARAMS,
  PHONE_OTP_MUTATION_PARAS,
  CREATE_USER_MUTATION_PARAS,
  MOBILE_LOGIN_MUTATION_PARAMS,
  LOGOUT_USER,
} from '@/services/authService';

import {
  getBase64FromUrl,
  getImageAsBase64,
} from '@/utils/stringUtil';

// CHECK USER NAME ACTION
function* checkUser(action) {
  const { data } = action;
  const { email, onSuccess, onFailed } = data;
  const payload = {
    query: CHECK_USER_QUERY_PARAMS,
    variables: { email },
  };
  yield call(apiCallSaga, {
    payload,
    actionType: AuthTypes.CHECK_USER,
    onSuccess: onSuccess,
    onFailed: (error) => onFailed(error),
  });
}

// LOGIN ACTION
function* login(action) {
  const { username, password, onFailed } = action.data;
  const payload = {
    query: LOGIN_MUTATION_PARAMS,
    variables: { username, password },
  };
  yield call(apiCallSaga, {
    payload,
    actionType: AuthTypes.LOGIN,
    onSuccess: onSuccessLogin,
    onFailed: (error) => onFailed(error),
  });
}

function* onSuccessLogin(data) {
  const { merchants } = data.logIn.user;

  const updateUrlsOfMerchants = (merchants) => {
    const promises = merchants.map(async (merchant) => {
      return {
        ...merchant,
        logo: {
          ...merchant.logo,
          thumbnail: merchant?.logo?.thumbnail
            ? `data:image/png;base64, ${await getImageAsBase64(
                merchant?.logo?.thumbnail
              )}`
            : null,
        },
      };
    });
    return Promise.all(promises);
  };

  const merchantsUpdated = yield call(
    updateUrlsOfMerchants,
    merchants
  );

  try {
    const { thumbnail } = data.logIn.user.profile;
    const thumbnail_pic = yield call(getBase64FromUrl, thumbnail);
    localStorage.setItem('user_thumbnail', thumbnail_pic);
  } catch (error) {
    localStorage.setItem('user_thumbnail', undefined);
  }

  try {
    const { url } = data.logIn.user.profile;
    const url_pic = yield call(getBase64FromUrl, url);
    localStorage.setItem('user_image', url_pic);
  } catch (error) {
    localStorage.setItem('user_image', undefined);
  }

  //save token to ls
  const accessToken = data.logIn.access_token;
  localStorage.setItem('token', accessToken);

  //update data with base64 mechants logos
  const newData = {
    ...data,
    logIn: {
      ...data.logIn,
      user: { ...data.logIn.user, merchants: merchantsUpdated },
    },
  };

  yield put(AuthActions.loginSuccess(newData));
}

//SET MERCHANTS
function* setMerchants(action) {
  const merchants = action.data;
  const updateUrlsOfMerchants = (merchants) => {
    const promises = merchants.map(async (merchant) => {
      return {
        ...merchant,
        logo: {
          ...merchant.logo,
          thumbnail: merchant?.logo?.thumbnail
            ? `data:image/png;base64, ${await getImageAsBase64(
                merchant?.logo?.thumbnail
              )}`
            : null,
        },
      };
    });
    return Promise.all(promises);
  };

  const merchantsUpdated = yield call(
    updateUrlsOfMerchants,
    merchants
  );

  yield put(AuthActions.setUserMerchants(merchantsUpdated));
}

// SEND PHONE_OTP ACTION
function* sendPhoneOtp(action) {
  const { number, onSuccess, onFailed } = action.data;
  const payload = {
    query: PHONE_OTP_MUTATION_PARAS,
    variables: { number },
  };
  yield call(apiCallSaga, {
    payload,
    actionType: AuthTypes.SEND_PHONE_OTP,
    onSuccess,
    onFailed,
  });
}

// CREATE USER ACTION
function* createUser(action) {
  const {
    email,
    password,
    first_name = '',
    last_name = '',
    invite_id = '',
    redirect_url,
    onSuccess,
    onFailed,
  } = action.data;

  const payload = {
    query: CREATE_USER_MUTATION_PARAS,
    variables:
      invite_id !== ''
        ? {
            input: {
              email,
              password,
              first_name,
              last_name,
              invite_id,
              redirect_url,
            },
          }
        : { input: { email, password, redirect_url } },
  };
  yield call(apiCallSaga, {
    payload,
    actionType: AuthTypes.CREATE_USER,
    onSuccess,
    onFailed,
  });
}

// MOBILE LOGIN ACTION
function* mobileLogin(action) {
  const { username, password, onFailed } = action.data;
  const payload = {
    query: MOBILE_LOGIN_MUTATION_PARAMS,
    variables: { user: { username, password, type: 'mobile' } },
  };
  yield call(apiCallSaga, {
    payload,
    actionType: AuthTypes.MOBILE_LOGIN,
    onSuccess: onSuccessLogin,
    onFailed: onFailed,
  });
}

function* logout() {
  yield put(AuthActions.logoutSuccess());
  const payload = {
    query: LOGOUT_USER,
  };
  yield call(apiCallSaga, {
    payload,
    actionType: AuthTypes.LOGOUT,
    onSuccess: console.log('succesfully logged out'),
    onFailed: (error) => console.log(error),
  });
  localStorage.removeItem('token');
  //Remove cookie
  document.cookie = `bm_token=`;
  document.cookie = `bm_merchant_id=`;
  document.cookie = `bm_store_id=`;
}

export const saga = function* () {
  yield takeLatest(AuthTypes.CHECK_USER, checkUser);
  yield takeLatest(AuthTypes.LOGIN, login);
  yield takeLatest(AuthTypes.SEND_PHONE_OTP, sendPhoneOtp);
  yield takeLatest(AuthTypes.CREATE_USER, createUser);
  yield takeLatest(AuthTypes.MOBILE_LOGIN, mobileLogin);
  yield takeLatest(AuthTypes.LOGOUT, logout);
  yield takeLatest(AuthTypes.SET_MERCHANTS, setMerchants);
};
