import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import AppLayout from '@/components/appLayout';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import SliderContainer from './slider';
import {
  selectedOrderType,
  storeSearch,
} from '@/store/modules/store';
import { MOCK_DATA } from './helper';
import { Skeleton } from '@material-ui/lab';
import Banners from './banners';

const TYPES = {
  Collection: 'collect',
  Delivery: 'deliver',
};

const LandingPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const searchingStoreData = useSelector(storeSearch);
  const orderType = useSelector(selectedOrderType);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const stores =
    searchingStoreData?.isInit && _.isEmpty(searchingStoreData?.temp)
      ? searchingStoreData?.temp
      : searchingStoreData?.data;

  const uniqueSoldItemsFromStore = _.uniq(
    stores?.reduce((acc, arg) => {
      return acc.concat(
        arg?.sold_items ? arg?.sold_items?.split(',') : null
      );
    }, [])
  );

  const groupByStores = uniqueSoldItemsFromStore?.reduce(
    (acc, arg) => {
      if (!arg) {
        return {
          ...acc,
          ['Other']: stores?.filter((store) => !store?.sold_items),
        };
      } else {
        return {
          ...acc,
          [`${arg}`]: stores?.filter((store) =>
            store?.sold_items?.split(',').includes(arg)
          ),
        };
      }
    },
    {}
  );

  const TempLayout = () => (
    <>
      <Box>
        <Skeleton height={80} width={isMobile ? '50%' : '25%'} />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {isMobile ? (
            <Skeleton height={280} width={330} />
          ) : (
            MOCK_DATA.map((item) => (
              <Skeleton
                key={`${item.key}-group-1`}
                height={280}
                width={330}
                style={{ marginRight: 24 }}
              />
            ))
          )}
        </Box>
      </Box>
      <Box>
        <Skeleton height={80} width={isMobile ? '50%' : '25%'} />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {isMobile ? (
            <Skeleton height={280} width={330} />
          ) : (
            MOCK_DATA.map((item) => (
              <Skeleton
                key={`${item.key}-group-2`}
                height={280}
                width={330}
                style={{ marginRight: 24 }}
              />
            ))
          )}
        </Box>
      </Box>
    </>
  );

  return (
    <AppLayout header withFooter className={classes.appLayout}>
      <Banners />
      <Box padding={10}>
        {searchingStoreData?.isLoading ? (
          <TempLayout />
        ) : !_.isEmpty(groupByStores) ? (
          Object?.keys(groupByStores)
            ?.sort()
            ?.map((group, index) => (
              <Box key={`${group}-${index}`}>
                <Typography className={classes.title}>{`${t(
                  `header.order_type.${orderType.type}`
                )} ${group}`}</Typography>
                <Box paddingY={8}>
                  <SliderContainer
                    options={groupByStores[group]}
                    group={group}
                  />
                </Box>
              </Box>
            ))
        ) : null}
      </Box>
    </AppLayout>
  );
};

export default LandingPage;
