import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    groupName: {
      ...CommonFonts.grayFont16,
      fontWeight: '600',
      marginBottom: theme.spacing(2),
    },
    boxOptionItem: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: Colors.GRAY_BORDER1_20,
      height: theme.spacing(15),
      borderRadius: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
    },
    boxImage: {
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: Colors.GRAY_BORDER1_20,
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    iconButton: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(2),
      backgroundColor: Colors.GRAY_BORDER1_20,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_20,
      },
    },
    icon: {
      fontSize: 18,
    },
    quantity: { ...CommonFonts.darkFont18, fontWeight: 600 },
    price: {
      ...CommonFonts.darkFont18,
    },
    hiddenIcon: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(2),
      width: 18,
      height: 18,
    },
    image: {
      objectFit: 'cover',
    },
  })
);

export default useStyles;
