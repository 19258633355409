import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayBoldFont22,
      fontWeight: 600,
    },
  })
);

export default useStyles;
