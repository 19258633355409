import React, { useState } from 'react';

import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { isEmpty } from 'lodash';
import { GET_PRODUCTS_BY_ID } from '@/services/inventoryService';
import AllergyItem from './components/allergyItem';
import ImageGallery from 'react-image-gallery';
import {
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import Editor from 'mui-rte';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrderType } from '@/store/modules/store';
import ProductGroupAddonModal from '../productGroupAddon';
import { useParams } from 'react-router';
import { GET_ORDER_TYPE } from '@/services/orderTypesWorkflowsService';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import MerchantActions from '@/store/modules/store/actions';
import { isJson } from '@/utils/collectionUtil';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router';
import { getLocalisationVal } from '@/utils/localisationUtil';

const orderTypeQueries = gql`
  ${GET_ORDER_TYPE}
`;

const defaultTheme = createTheme();
Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: '10px',
      },
    },
  },
});

const ProductDetail = ({ isNetPrice, localisation, currency }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { merchantId, storeId, productId } = useParams();
  const selectOrderType = useSelector(selectedOrderType);

  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});
  const [openGroupAddon, setOpenGroupAddon] = useState(false);

  const { loading: loadingProduct } = useQuery(GET_PRODUCTS_BY_ID, {
    variables: {
      id: productId,
    },
    context: {
      headers: {
        storeId: storeId,
        merchantId: merchantId,
      },
    },
    onCompleted: (data) => {
      if (!isEmpty(data.products)) {
        setProduct(data?.products[0]);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const productPriceInfo = product?.prices
    ?.find((price) => _.isEqual(price.store_id, storeId))
    ?.price_infos?.find((info) =>
      _.isEqual(info.price_type.type, selectOrderType?.pricing_type)
    );

  const productTaxesAmount = productPriceInfo?.taxes?.reduce(
    (acc, arg) => {
      return (
        acc +
        parseFloat(
          (
            productPriceInfo?.price *
            (Math.max(arg.rate, 0) / 100)
          ).toFixed(4)
        )
      );
    },
    0
  );

  const productPrice = parseFloat(
    (productPriceInfo?.price || Number(0))?.toFixed(4)
  );

  const productTaxes = productPriceInfo?.taxes?.map((item) => ({
    id: item.id,
    rate: item.rate,
    name: item.name,
    amount: parseFloat(
      (productPrice * (Math.max(item.rate, 0) / 100)).toFixed(4)
    ),
  }));

  const productImage = product?.images?.map((image) => ({
    original: image.url,
    thumbnail: image.thumbnail,
    originalHeight: 350,
    originalWidth: 500,
    thumbnailHeight: 62,
    thumbnailWidth: 92,
  }));

  const groupAddonIds = product?.addons?.map((addon) => addon.id);

  const onAddToCart = async () => {
    if (!_.isEmpty(groupAddonIds)) {
      setOpenGroupAddon(true);
    } else {
      const cart = {
        id: uuid(),
        product_id: product.id,
        product,
        name: product.name,
        bar_code: product.bar_code,
        measure: product.measure,
        category: {
          id: product.category.id,
          name: product.category.name,
        },
        price: {
          amount: productPrice,
          quantity: quantity,
          taxes: productTaxes,
        },
      };

      dispatch(
        MerchantActions.addUpdateCart({
          storeId,
          merchantId,
          cart: cart,
        })
      );

      enqueueSnackbar(
        t('store.shoppingCart.product_added_to_cart', {
          name: product.name,
        })
      );
    }
  };

  const calculatingProductPrice = () => {
    const taxingNames = productPriceInfo?.taxes
      ?.map((tax) => tax.name)
      ?.join('&');
    const price = isNetPrice
      ? productPrice
      : productPrice + productTaxesAmount;
    const result = getLocalisationVal(localisation, price);
    return `${result} ${isNetPrice ? `+ ${taxingNames}` : ''}`;
  };

  const onBack = () =>
    history.push(`/store/${merchantId}/${storeId}`);

  return (
    <>
      <Box
        padding={5}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <ChevronLeftIcon
          className={classes.leftIcon}
          onClick={onBack}
        />
        <Typography className={classes.backText} onClick={onBack}>
          {t('store.product.back')}
        </Typography>
      </Box>
      <Grid container>
        <Grid item md={6} sm={12} xs={12} style={{ padding: 20 }}>
          {loadingProduct ? (
            <Box>
              <Skeleton variant="rect" width="100%" height={350} />
              <Box display="flex" alignItems="center">
                <Skeleton
                  height={120}
                  width={130}
                  style={{ marginRight: 16 }}
                />
                <Skeleton
                  height={120}
                  width={130}
                  style={{ marginRight: 16 }}
                />
                <Skeleton
                  height={120}
                  width={130}
                  style={{ marginRight: 16 }}
                />
                <Skeleton height={120} width={130} />
              </Box>
            </Box>
          ) : (
            productImage && (
              <ImageGallery
                additionalClass={classes.imageGallery}
                showNav={false}
                showPlayButton={false}
                showFullscreenButton={false}
                items={productImage}
              />
            )
          )}
        </Grid>
        <Grid item md={6} sm={12} xs={12} style={{ padding: 20 }}>
          {loadingProduct ? (
            <Box>
              <Skeleton height={80} />
              <Skeleton width="50%" height={80} />
            </Box>
          ) : (
            <>
              <Box>
                <Typography className={classes.productName}>
                  {product?.name}
                </Typography>
                <Box display="flex" alignItems="center" paddingY={3}>
                  <Typography className={classes.priceTitle}>
                    {t('store.price')}
                  </Typography>
                  <Typography
                    className={classes.price}
                  >{`${currency}${calculatingProductPrice()}`}</Typography>
                  <Typography className={classes.inStock}>
                    {t('store.in_stock', {
                      qty: product?.stocks?.length,
                    })}
                  </Typography>
                </Box>
              </Box>
              <Box paddingY={3} display="flex" alignItems="center">
                <TextField
                  type="number"
                  value={quantity}
                  onChange={(e) =>
                    setQuantity(Number(e.target.value))
                  }
                  variant="outlined"
                  className={classes.quantityInput}
                />
                <Button
                  onClick={onAddToCart}
                  color="primary"
                  variant="contained"
                  className={classes.addUpdateCart}
                >
                  {t('store.add_to_cart')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.buyNow}
                >
                  {t('store.product.buy_now')}
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      {loadingProduct ? (
        <Grid container>
          <Grid item md={12} sm={12} xs={12} style={{ padding: 20 }}>
            <Skeleton width="25%" height={80} />
            <Skeleton width="100%" height={80} />
            <Skeleton width="100%" height={80} />
            <Skeleton width="100%" height={80} />
          </Grid>
        </Grid>
      ) : (
        !_.isEmpty(product.description) &&
        isJson(product.description) && (
          <Grid container>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={{ padding: 20 }}
            >
              <Typography className={classes.allergyTitle}>
                {t('store.product.product_description')}
              </Typography>
              <MuiThemeProvider theme={defaultTheme}>
                <Editor
                  id={product.id}
                  defaultValue={product.description}
                  controls={[]}
                  readOnly
                />
              </MuiThemeProvider>
            </Grid>
          </Grid>
        )
      )}
      {loadingProduct ? (
        <Grid container>
          <Grid item md={12} sm={12} xs={12} style={{ padding: 20 }}>
            <Skeleton width="25%" height={80} />
            <Skeleton width="100%" height={80} />
            <Skeleton width="100%" height={80} />
            <Skeleton width="100%" height={80} />
          </Grid>
        </Grid>
      ) : (
        !_.isEmpty(product?.allergies) && (
          <Grid container>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={{ padding: 20 }}
            >
              <Typography className={classes.allergyTitle}>
                {t('store.product.allergy_information')}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                paddingY={3}
              >
                {product?.allergies?.map((allergy) => (
                  <AllergyItem key={allergy.id} allergy={allergy} />
                ))}
              </Box>
            </Grid>
          </Grid>
        )
      )}
      {openGroupAddon && (
        <ProductGroupAddonModal
          isVisible={openGroupAddon}
          onCloseModal={() => setOpenGroupAddon(false)}
          groupAddonIds={groupAddonIds}
          productPrice={productPrice}
          productTaxes={productTaxes}
          product={product}
          currency={currency}
          pricingType={selectOrderType.pricing_type}
          quantity={quantity}
          isNetPrice={isNetPrice}
          localisation={localisation}
        />
      )}
    </>
  );
};

export default ProductDetail;
