import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import OrderDateTimePicker from '../dateTimePicker';
import _ from 'lodash';
import { TIME_RANGE } from '../dateTimePicker/helper';
import moment from 'moment';
import { ORDER_TYPE } from '@/constants/store';
import { ASAP } from '@/constants/dateAndTime';
import { MAX_GUESTS, MIN_GUESTS } from '@/constants/order';

import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const TABLE_GUESTS = Array.from(
  { length: MAX_GUESTS - MIN_GUESTS + 1 },
  (_, i) => i + 1
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TableType = ({ tableData, updateOrderType, storeTables }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { time, no_of_guests, tables } = tableData;

  const separateDateTime = time?.split(' ');
  const initialDate = separateDateTime[0];
  const initialTime = separateDateTime[1];

  useEffect(() => {
    if (
      initialDate &&
      initialTime &&
      new Date(initialDate).getTime() < new Date().getTime()
    ) {
      updateOrderType(ORDER_TYPE.DINING_IN, {
        ...tableData,
        time: `${moment(new Date()).format(
          'YYYY-MM-DD'
        )} ${initialTime}`,
      });
    }
  }, []);

  const onChangeGuestNo = (e) => {
    updateOrderType(ORDER_TYPE.DINING_IN, {
      ...tableData,
      no_of_guests: e.target.value,
    });
  };

  const onChangeTableNo = (e) => {
    updateOrderType(ORDER_TYPE.DINING_IN, {
      ...tableData,
      tables: e.target.value
        ?.map((id) => storeTables.find((item) => item.id === id))
        ?.filter((item) => !!item),
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className={classes.title}>
        {t('header.order_type.table_info')}
      </Typography>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="guest-select-label"
          className={classes.formControlLabel}
        >
          {t('header.order_type.no_of_guests')}
        </InputLabel>
        <Select
          labelId="no-of-guests-label"
          id="no-of-guests-select"
          value={no_of_guests}
          onChange={onChangeGuestNo}
        >
          {TABLE_GUESTS.map((no) => (
            <MenuItem
              key={`guest-${no}`}
              value={no}
              className={classes.formControlLabel}
            >
              {no}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="table-mutiple-checkbox-label"
          className={classes.formControlLabel}
        >
          {t('header.order_type.select_table_no')}
        </InputLabel>
        <Select
          labelId="table-mutiple-checkbox-label"
          id="table-mutiple-checkbox"
          multiple
          value={tables.map((item) => item.id)}
          onChange={onChangeTableNo}
          input={<Input />}
          renderValue={(selected) =>
            selected
              .map(
                (id) =>
                  storeTables?.find((item) => item.id === id)
                    ?.table_no
              )
              .join(', ')
          }
          MenuProps={MenuProps}
        >
          {storeTables.map((table) => (
            <MenuItem key={table.id} value={table.id}>
              <Checkbox
                checked={
                  !!tables.find((item) => item.id === table.id)
                }
              />
              <ListItemText primary={table.table_no} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography className={classes.timeTitle}>
        {t('header.order_type.dining_in_time')}
      </Typography>

      <RadioGroup
        aria-label="collection-type-group"
        name="collection-type-group"
        onChange={(e) =>
          updateOrderType(ORDER_TYPE.DINING_IN, {
            ...tableData,
            time: e.target.value,
          })
        }
        className={classes.collectionTypeGroup}
      >
        <Box marginY={4} display="flex" flexDirection="column">
          <FormControlLabel
            classes={{
              label: classes.radioFormControlLabel,
            }}
            className={classes.radioFormControl}
            control={
              <CustomRadio
                checked={_.isEqual(time, ASAP)}
                value={ASAP}
                name={ASAP}
              />
            }
            label={t('header.order_type.today_asap')}
          />
          <FormControlLabel
            classes={{
              label: classes.radioFormControlLabel,
            }}
            className={classes.radioFormControl}
            control={
              <CustomRadio
                checked={!_.isEqual(time, ASAP)}
                value={`${moment(new Date()).format('YYYY-MM-DD')} ${
                  TIME_RANGE[0].value
                }`}
                name="selectDateTime"
              />
            }
            label={
              <OrderDateTimePicker
                defaultDate={initialDate}
                defaultTime={initialTime}
                onChange={(value) =>
                  updateOrderType(ORDER_TYPE.DINING_IN, {
                    ...tableData,
                    time: value,
                  })
                }
              />
            }
          />
        </Box>
      </RadioGroup>
    </Box>
  );
};

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default TableType;
