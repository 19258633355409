export const GET_USER_ORDERS = `
    query getUserOrders{
        currentUser{
            id
            orders{
                id
                creation{
                    time
                }
                billing{
                    door_number
                    building_name
                    line1
                    line2
                    city_town
                    country
                    postcode
                    county
                }
                order_no
                order_type{
                    name
                }
                total_price{
                    amount
                    gross_amount
                }
                store{
                    id
                    merchant_id
                    name
                    localisation{
                        digit_separator
                        currency_symbol
                        currency_decimal
                        currency_position
                        currency_code
                      }
                }
                status{
                    name
                }
                payments{
                    id
                    status
                }
            }
        }
    }
`;

// Get order by id
export const GET_ORDER_BY_ID = `
  query getOrders($id: String) {
    orders(id: $id) {
      id
      order_no
      order_series
      shopper {
        id
        first_name
        last_name
        mobile
        audiences {
          id
          name
        }
      }
      device {
        device_id
        device_name
        device_type
      }
      store {
        id
        name
        localisation {
          digit_separator
          currency_decimal
          currency_symbol
          currency_code
          distance_unit
        }
        merchant_id
        merchant {
          tname
          logo {
            id
            url
            thumbnail
          }
        }
        phone
      }
      creation {
        time
        user_id
      }
      collection_time
      delivery {
        address {
          door_number
          line1
          line2
          city_town
          postcode
          lat
          lng
          country
        }
        distance
        id
        mobile
        name
        notes
        time
        type
      }
      delivery_request {
        id
        status {
          id
          name
          order
        }
        creation {
          time
        }
        distance
        fee
        rate
        minimum_fee
        rider {
          id
          first_name
          last_name
          email
          mobile
          location {
            lat
            lng
          }
          profile {
            id
            url
            thumbnail
          }
        }
        drop_off {
          type
          profile {
            thumbnail
          }
          name
          phone
          distance
          address {
            building_name
            door_number
            line1
            line2
            city_town
            postcode
            lat
            lng
          }
        }
        pick_up {
          type
          profile {
            thumbnail
          }
          name
          phone
          distance
          address {
            building_name
            door_number
            line1
            line2
            city_town
            postcode
            lat
            lng
          }
        }
        state
      }
      table {
        booking_time
        no_of_guests
        table_id
        table_no
        tables {
          table_id
          table_no
        }
      }
      payments {
        id
        status
        payment_type {
          id
          name
        }
        change_due
        amount
        creation {
          time
        }
        resource {
          id
          card {
            brand
            exp_month
            exp_year
            funding
            last4
            wallet
          }
          provider
          source
        }
      }
      completion_time
      state
      order_type {
        id
        type
        name
        pricing_type
      }
      status {
        id
        name
        order
        lock_order
      }
      items {
        id
        product_id
        bar_code
        category {
          id
          name
        }
        is_manual
        name
        price {
          quantity
          refunded
          rejected
          amount
          tax_amount
          gross_amount
          total_gross_amount
          taxes {
            id
            rate
            name
            amount
          }
          item_measure {
            id
            name
            type
          }
        }
        addons {
          id
          name
          addon_id
          group {
            id
            name
          }
          price {
            quantity
            amount
            tax_amount
            gross_amount
            total_gross_amount
            taxes {
              id
              rate
              name
              amount
            }
          }
        }
        promotions {
          id
          type
          name
          amount
          taxes {
            id
            rate
            name
            amount
          }
          promo_no
          audience_id
          user_id
          item_numbers
          promo_name
          valid_from
          valid_to
          start_time
          end_time
          promo_value
          trigger_amount
          fixed_price
          mix_match
          period
          is_voucher
          voucher_trigger_amount
          voucher {
            id
            expired_time
          }
          options {
            products {
              id
              name
            }
            categories {
              id
              name
            }
          }
        }
        product {
          id
          name
          measure {
            id
            type
            name
          }
          stocks {
            current_stock
            available
          }
          bar_code
          prices {
            price_infos {
              price_type {
                type
                id
                name
              }
              price
              taxes {
                id
                name
                rate
              }
            }
          }
          addons {
            id
            group
            description
            parent
            allow_free
            mandatory
            multi_selection
            default_all
            position
            options {
              inventory_id
              #product_id
              id
              images {
                id
                thumbnail
                url
              }
              name
              force_charge
              default
              position
              extra
              override_price
              price {
                fixed_price
              }
            }
          }
        }
      }
      total_price {
        amount
        tax_amount
        gross_amount
        taxes {
          id
          name
          rate
          amount
        }
        extra_fee
      }
      promotions {
        id
        type
        name
        amount
      }
      payment_requests {
        id
        status
      }
    }
  }
`;

export const GET_ORDERS = `
  query getOrders(
    $filter: Filter
    $state: String
    $start: Date
    $end: Date
    $order_no: String
    $order_type_id: [String]
    $is_shopper: Boolean
    $touchpoint_type: String
    $table_id: String
    $shopper_id: String
  ) {
    orders(
      filter: $filter
      state: $state
      start: $start
      end: $end
      order_no: $order_no
      order_type_id: $order_type_id
      is_shopper: $is_shopper
      touchpoint_type: $touchpoint_type
      table_id: $table_id
      shopper_id: $shopper_id
    ) {
      id
      order_no
      order_series
      is_shopper
      shopper {
        id
        first_name
        last_name
        mobile
        audiences {
          id
          name
        }
      }
      device {
        device_id
        device_name
        device_type
      }
      creation {
        time
        user_id
      }
      store {
        id
        name
        localisation {
          digit_separator
          currency_decimal
          currency_symbol
          currency_code
          distance_unit
        }
        merchant_id
        merchant {
          tname
          logo {
            id
            url
            thumbnail
          }
          merchant_settings {
            products {
              net_price
            }
          }
        }
      }
      collection_time
      delivery {
        address {
          door_number
          line1
          line2
          city_town
          postcode
          lat
          lng
        }
        distance
        id
        mobile
        name
        notes
        time
        type
      }
      delivery_request {
        id
        status {
          id
          name
          order
        }
        creation {
          time
        }
        distance
        fee
        rate
        rider {
          id
          first_name
          last_name
          email
          mobile
          location {
            lat
            lng
          }
          profile {
            id
            url
            thumbnail
          }
        }
        state
      }
      table {
        booking_time
        no_of_guests
        table_id
        table_no
        tables {
          table_id
          table_no
        }
      }
      payments {
        id
        status
        payment_type {
          id
          name
        }
        change_due
        amount
        creation {
          time
        }
        resource {
          id
          card {
            brand
            exp_month
            exp_year
            funding
            last4
            wallet
          }
        }
      }
      completion_time
      state
      order_type {
        id
        type
        name
      }
      status {
        id
        name
      }
      items {
        id
        item_id
        order_id
        product_id
        bar_code
        category {
          id
          name
        }
        is_manual
        name
        price {
          quantity
          refunded
          rejected
          amount
          tax_amount
          gross_amount
          total_gross_amount
          taxes {
            id
            rate
            name
            amount
          }
          item_measure {
            id
            name
            type
          }
        }
        addons {
          id
          name
          addon_id
          group {
            id
            name
          }
          price {
            quantity
            amount
            tax_amount
            gross_amount
            total_gross_amount
            taxes {
              id
              rate
              name
              amount
            }
          }
        }
        promotions {
          id
          type
          name
          amount
          taxes {
            id
            rate
            name
            amount
          }
          promo_no
          audience_id
          user_id
          item_numbers
          promo_name
          valid_from
          valid_to
          start_time
          end_time
          promo_value
          trigger_amount
          fixed_price
          mix_match
          period
          is_voucher
          voucher_trigger_amount
          voucher {
            id
            expired_time
          }
          options {
            products {
              id
              name
            }
            categories {
              id
              name
            }
          }
        }
        product {
          id
          name
          measure {
            id
            type
            name
          }
          images {
            id
            thumbnail
            url
          }
          stocks {
            current_stock
            available
          }
          bar_code
          prices {
            price_infos {
              price_type {
                type
                id
                name
              }
              price
              taxes {
                id
                name
                rate
              }
            }
          }
          addons {
            id
            group
            description
            parent
            allow_free
            mandatory
            multi_selection
            default_all
            position
            options {
              inventory_id
              #product_id
              id
              images {
                id
                thumbnail
                url
              }
              name
              force_charge
              default
              position
              extra
              override_price
              price {
                fixed_price
              }
            }
          }
        }
      }
      total_price {
        amount
        tax_amount
        gross_amount
        taxes {
          id
          name
          rate
          amount
        }
        extra_fee
      }
      promotions {
        id
        type
        name
        amount
      }
      payment_requests {
        id
        status
      }
    }
  }
`;
