import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 450,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      position: 'absolute',
      bottom: -theme.spacing(10),
      left: 0,
      right: 0,
      margin: '0 auto',
    },
    button: {
      textTransform: 'capitalize',
      ...CommonFonts.darkFont18,
      padding: theme.spacing(4, 3),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    whiteButton: {
      ...CommonFonts.darkFont18,
      justifyContent: 'flex-start',
      width: 400,
      fontWeight: 300,
      backgroundColor: Colors.WHITE,
      bottom: 25,
    },
    greyButton: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(3),
      backgroundColor: Colors.GRAY_LIGHT,
      height: 50,
      width: 350,
    },
    name: {
      textAlign: 'center',
      letterSpacing: 0.16,
      padding: theme.spacing(4, 0, 1, 0),
      ...CommonFonts.darkFont14,
      wordBreak: 'break-word',
    },
    email: {
      textAlign: 'center',
      fontWeight: 600,
      letterSpacing: 0.56,
      ...CommonFonts.darkFont14,
      wordBreak: 'break-word',
    },
    link: {
      textDecoration: 'none',
    },
    cameraIcon: {
      position: 'absolute',
      backgroundColor: Colors.WHITE,
      top: theme.spacing(25),
      right: -theme.spacing(1),
      padding: theme.spacing(2.5),
      '&:hover': {
        boxShadow: '10px 10px 10px 30px white inset !important',
        filter: 'brightness(0.7)',
        transition: 'all 0.2s ease-in-out',
      },
      '& .MuiSvgIcon-root': {
        color: '#99a3ad',
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    uploadInput: {
      display: 'none',
    },
    noneBackground: {
      backgroundColor: 'unset',
    },
    boxAvatarInfo: {
      position: 'relative',
    },
  })
);

export default useStyles;
