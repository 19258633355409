import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useStyles from './styles';
import AuthActions, { AuthTypes } from '@/store/modules/auth/actions';
import { selectIsApiProgress } from '@/store/modules/apiLoopState';
import LoginModal from './loginModal';
import useHeaderStyles from '../styles';

const Authorize = (props) => {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify={'flex-end'}
      alignItems="center"
      spacing={8}
    >
      <Grid item>
        <IconButton
          className={headerClasses.iconContainer}
          onClick={() => setOpenLoginModal(true)}
        >
          <ExitToAppIcon fontSize="medium" className={classes.icon} />
        </IconButton>
      </Grid>
      {openLoginModal && (
        <LoginModal
          isVisible={openLoginModal}
          onCloseModal={() => setOpenLoginModal(false)}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isCreatingUser: selectIsApiProgress(state, AuthTypes.CREATE_USER),
});

const actions = { ...AuthActions };

export default connect(mapStateToProps, actions)(Authorize);
