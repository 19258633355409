import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    selectDateInput: {
      width: theme.spacing(25),
      '& .MuiInputBase-input': {
        ...CommonFonts.darkFont16,
        fontFamily: 'Montserrat',
      },
    },
    selectTimeInput: {
      width: theme.spacing(30),
      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    timeItem: {
      ...CommonFonts.darkFont16,
      fontFamily: 'Montserrat',
    },
    iconSelect: {
      display: 'none',
    },
  })
);

export default useStyles;
