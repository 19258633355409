import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    addHours: {
      ...CommonFonts.blueFont14,
      cursor: 'pointer',
    },
    removeHours: {
      ...CommonFonts.grayFont14,
      cursor: 'pointer',
    },
    selectTimeInput: {
      width: theme.spacing(12),
      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    timeItem: {
      ...CommonFonts.darkFont16,
      fontFamily: 'Montserrat',
    },
    iconSelect: {
      display: 'none',
    },
    createIcon: {
      fontSize: 20,
      color: '#bec7cc',
    },
    errorText: {
      ...CommonFonts.grayFont12,
      color: Colors.RED,
    },
  })
);

export default useStyles;
