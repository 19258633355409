import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import _ from 'lodash';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import {
  getFullAddressOfStore,
  hideContent,
} from '@/utils/stringUtil';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MerchantActions from '@/store/modules/store/actions';
import StoreOpenings from './storeOpenings';
import { isMobile } from 'react-device-detect';
import images from '@/assets/images';

const SliderItem = ({ data }) => {
  const {
    settings,
    name,
    merchant,
    address,
    sold_items,
    store_openings,
    id,
    merchant_id,
  } = data;
  const soldItems = sold_items?.split(',');
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const onStoreClick = () => {
    dispatch(MerchantActions.setSelectedStore(data));
    history.push(`/store/${merchant_id}/${id}`);
  };

  const shortAddress = () => {
    const addressStr = getFullAddressOfStore(address);
    return addressStr?.length >= 50 && !isMobile
      ? `${addressStr.substring(0, 50)}...`
      : addressStr;
  };

  const banner =
    settings?.touchpoint_settings?.digital_front?.banner?.thumbnail;

  return (
    <Box className={classes.root}>
      <Box className={classes.boxItem} onClick={onStoreClick}>
        <Box className={classes.boxBanner}>
          <img
            className={
              banner ? classes.bannerImage : classes.bannerImageEmpty
            }
            src={banner || images.shoppingBag}
          />
        </Box>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxLogo}>
            <img
              className={classes.logoImage}
              src={merchant?.logo?.thumbnail || images.emptyAddLogo}
            />
          </Box>
          <Box
            marginTop={5}
            padding={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.storeName}>
              {hideContent(name, 26)}
            </Typography>
            <Typography className={classes.businessType}>
              {merchant.business_type}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              marginTop={1}
            >
              {soldItems?.map((item) => (
                <Typography className={classes.soldItem}>
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box paddingX={2} className={classes.boxTime}>
            <Box display="flex" alignItems="center">
              <LocationOnOutlinedIcon
                classes={{
                  root: classes.icon,
                }}
              />
              <Typography className={classes.address}>
                {shortAddress()}
              </Typography>
            </Box>
            <StoreOpenings openingData={store_openings} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SliderItem;
