import React from 'react';
import useStyles from './styles';
import _ from 'lodash';
import Slider from 'react-slick';
import CategoryItem from './categoryItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const CategorySlider = ({
  options,
  selectedCategory,
  setSelectedCategory,
}) => {
  const classes = useStyles();

  const sliderSettings = {
    className: classes.root,
    dots: false,
    infinite: false,
    arrows: options?.length >= 6,
    slidesToShow: 6,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          arrows: options?.length >= 4,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 3,
          arrows: options?.length >= 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          arrows: options?.length >= 2,
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  function NextArrow({
    currentSlide,
    slideCount,
    children,
    ...props
  }) {
    return (
      <ChevronRightIcon {...props} className={classes.nextIcon} />
    );
  }

  function PrevArrow({
    currentSlide,
    slideCount,
    children,
    ...props
  }) {
    return (
      <ChevronLeftIcon {...props} className={classes.prevIcon} />
    );
  }

  return (
    <Slider {...sliderSettings}>
      {options?.map((item) => (
        <CategoryItem
          key={`${item?.id}`}
          data={item}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      ))}
    </Slider>
  );
};

export default CategorySlider;
