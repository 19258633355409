import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import BackButton from '@/components/backButton';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ProfilePage from './profile';
import { CURRENT_USER } from '@/services/userService';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import PreLoader from '@/components/preLoader';
import { MUTATE_USER_DETAIL } from '@/services/userManagementService';
import { useSnackbar } from 'notistack';
import PrivacyAndSecurityPage from './privacyAndSecurity';
import PreferencesPage from './preferences';
import UserAvatar from './components/profileAvatar';
import AppLayout from '@/components/appLayout';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import AuthActions from '@/store/modules/auth/actions';

const userQueries = gql`
  ${CURRENT_USER}
`;

const TABS = {
  PROFILE: 'profile',
  PREFERENCES: 'preferences',
  SECURITY_AND_PRIVACY: 'security-and-privacy',
};

const tabIds = ['profile', 'preferences', 'privacy-and-security'];

const UserPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tabId, setTabId] = useState(0);

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (params.tab) {
      setTabId(tabIds.indexOf(params.tab) || 0);
    }
  }, [params?.tab]);

  const onChangeTab = (event, value) => {
    setTabId(value);
    history.push(`/user/${tabIds[value]}`);
  };

  const [getCurrentUser, { loading: loadingCurrentUser }] =
    useLazyQuery(userQueries, {
      onCompleted: (data) => {
        if (data.currentUser) {
          setCurrentUser(data.currentUser);
        }
      },
      onError: () => {
        history.push('/login');
      },
      fetchPolicy: 'no-cache',
    });

  const [mutateUserDetail] = useMutation(
    gql`
      ${MUTATE_USER_DETAIL}
    `,
    {
      context: {
        headers: { storeId: -1, merchantId: -1 },
      },
    }
  );

  const updateCurrentUser = async (tabName, item) => {
    const { key, value } = item;
    setIsLoading(true);
    const payload = {
      variables: {
        input: { id: currentUser.id, [key]: value },
      },
    };
    return mutateUserDetail(payload)
      .then((res) => {
        if (res) {
          setCurrentUser(res?.data?.updateUser);
          dispatch(AuthActions.setCurrentUser(res?.data?.updateUser));
          enqueueSnackbar(t('user.tab_updated', { name: tabName }));
        }
      })
      .catch((error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loading = isLoading || loadingCurrentUser;

  const tabs = [
    {
      id: TABS.PROFILE,
      label: t('user.setting_profile'),
      enabled: true,
    },
    {
      id: TABS.PREFERENCES,
      label: t('user.setting_preferences'),
      enabled: true,
    },
    {
      id: TABS.SECURITY_AND_PRIVACY,
      label: t('user.setting_pivacy_and_security'),
      enabled: true,
    },
  ];

  const UserInfo = () => {
    const fullName = `${currentUser?.first_name || ''} ${
      currentUser?.last_name || ''
    }`;
    return (
      <Box padding={4}>
        <Typography className={classes.storeName}>
          {fullName}
        </Typography>
        <Typography className={classes.textItem}>
          {/* {currentStore?.merchant?.business_type} */}
        </Typography>
        <Typography className={classes.soldItems}>
          {/* {currentStore?.sold_items?.replaceAll(',', ', ')} */}
        </Typography>
      </Box>
    );
  };

  return (
    <AppLayout header withFooter className={classes.appLayout}>
      <div className={classes.boxBannerAvatar}>
        <Box className={classes.boxHeader}>
          <div className={classes.backWrapper}>
            <BackButton onClick={() => history.goBack()} />
          </div>
        </Box>
        <UserAvatar currentUser={currentUser} />
      </div>

      <div className={classes.box}>
        <Box className={classes.boxInfo}>
          <UserInfo />
        </Box>
        <div className={classes.boxTabs}>
          <Tabs
            className={classes.tabs}
            value={tabId}
            onChange={onChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs
              .filter((item) => item.enabled)
              .map((item, index) => (
                <Tab
                  className={classes.tab}
                  style={{ fontSize: 14, fontWeight: 600 }}
                  key={`tab-${index}`}
                  label={item.label}
                />
              ))}
          </Tabs>
        </div>
        <div className={classes.boxPanel}>
          {tabs
            .filter((item) => item.enabled)
            .map((item, index) => {
              if (item.id === TABS.PROFILE) {
                return (
                  <TabPanel
                    key={`tab-panel-${index}`}
                    value={tabId}
                    index={index}
                  >
                    <ProfilePage
                      currentUser={currentUser}
                      updateCurrentUser={(value) =>
                        updateCurrentUser(
                          t('user.profile.title'),
                          value
                        )
                      }
                    />
                  </TabPanel>
                );
              } else if (item.id === TABS.PREFERENCES) {
                return (
                  <TabPanel
                    key={`tab-panel-${index}`}
                    value={tabId}
                    index={index}
                  >
                    <PreferencesPage
                      currentUser={currentUser}
                      updateCurrentUser={(value) =>
                        updateCurrentUser(
                          t('user.preferences.title'),
                          value
                        )
                      }
                    />
                  </TabPanel>
                );
              } else if (item.id === TABS.SECURITY_AND_PRIVACY) {
                return (
                  <TabPanel
                    key={`tab-panel-${index}`}
                    value={tabId}
                    index={index}
                  >
                    <PrivacyAndSecurityPage
                      currentUser={currentUser}
                      updateCurrentUser={(value) =>
                        updateCurrentUser(
                          t('user.privacyAndSecurity.title'),
                          value
                        )
                      }
                    />
                  </TabPanel>
                );
              }
            })}
        </div>
      </div>
      {loading && <PreLoader size={30} />}
    </AppLayout>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

export default UserPage;
