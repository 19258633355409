export const GET_NOTIFICATION = `
    query getNotification{
        notifications{
            content
            created_at
            data
            id
            status
            store_id
            title
            type
            user_id
            username
        }
    }
`;

export const MUTATE_SUBSCRIBE_TOPIC = `
    mutation subscribeTopics($input: FirebaseInput!){
        subscribeTopics(input: $input)
    }
`;

export const MUTATE_REGISTER_TOKEN = `
    mutation registerToken($input: FirebaseInput!){
        registerToken(input: $input)
    }
`;

export const UPDATE_DEVICE_TOKEN = `
  mutation ($input: DeviceTokenInput!) {
    registerDeviceToken(input: $input) {
      type
      token
    }
  }
`;
