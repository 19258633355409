import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: theme.spacing(12),
      padding: theme.spacing(0, 7),
      backgroundColor: '#505c69',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(3),
        position: 'relative',
      },
    },
    footerText: {
      ...CommonFonts.whiteFont16,
    },
    copywrightWrapper: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    termsWrapper: {
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        gap: 13,
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        gap: 10,
        justifyContent: 'space-between',
      },
    },
  })
);

export default useStyles;
