import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import images from '@/assets/images';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  IconButton,
  Drawer,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import BackButton from '@/components/backButton';
import { drawerMenus } from './helper';
import useHeaderStyles from '../styles';

const Menu = ({ isLoggedIn, onLogin }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const history = useHistory();

  const [anchor, setAnchor] = useState('left');
  const [open, setOpen] = useState(false);

  const toggleDrawer = (position, state) => {
    setAnchor(position);
    setOpen(state);
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={headerClasses.iconContainer}
        onClick={() => toggleDrawer('left', true)}
      >
        <MenuIcon fontSize="medium" className={classes.menuIcon} />
      </IconButton>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={() => toggleDrawer(anchor, false)}
      >
        <div className={classes.drawerContent}>
          <div className={classes.backWrapper}>
            <BackButton onClick={() => toggleDrawer(anchor, false)} />
          </div>
          <div className={classes.menuList}>
            <MenuList className={classes.list}>
              {drawerMenus(t).map(({ id, name, pathname }) => (
                <MenuItem
                  onClick={() => {
                    if (isLoggedIn) {
                      history.push(pathname);
                    } else {
                      onLogin();
                    }

                    toggleDrawer(anchor, false);
                  }}
                  key={id}
                  className={classes.menuItem}
                >
                  <Typography className={classes.nameMenuItem}>
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
