import React, { useMemo, useState } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { hideContent } from '@/utils/stringUtil';
import imagesAsset from '@/assets/images';
import ProductGroupAddonModal from '../productGroupAddon';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import MerchantActions from '@/store/modules/store/actions';
import { selectedOrderType } from '@/store/modules/store';
import { GET_ORDER_TYPE } from '@/services/orderTypesWorkflowsService';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { Colors } from '@/theme';
import { getLocalisationVal } from '@/utils/localisationUtil';

const ProductItem = ({
  data,
  currency,
  merchantId,
  storeId,
  pricingType,
  isNetPrice,
  localisation,
  isMobile,
  userCart,
}) => {
  const {
    name,
    images,
    stocks,
    prices,
    short_description,
    addons,
    id,
    stocked,
    bar_code,
    category,
  } = data || {};

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const selectOrderType = useSelector(selectedOrderType);

  const [openGroupAddon, setOpenGroupAddon] = useState(false);

  const productQuantityInCart = userCart
    ?.filter((cart) => cart.product.id === id)
    ?.reduce((acc, arg) => acc + arg.price.quantity, 0);

  const productImage = images?.find(
    (image) => !_.isEmpty(image.thumbnail)
  )?.thumbnail;

  const productPriceInfo = prices
    ?.find((price) => _.isEqual(price.store_id, storeId))
    ?.price_infos?.find((info) =>
      _.isEqual(info.price_type.type, pricingType)
    );

  const productTaxesAmount = productPriceInfo?.taxes?.reduce(
    (acc, arg) => {
      return (
        acc +
        parseFloat(
          (
            productPriceInfo?.price *
            (Math.max(arg.rate, 0) / 100)
          ).toFixed(4)
        )
      );
    },
    0
  );

  const productPrice = parseFloat(
    (productPriceInfo?.price || Number(0))?.toFixed(4)
  );

  const productTaxes = productPriceInfo?.taxes?.map((item) => ({
    id: item.id,
    rate: item.rate,
    name: item.name,
    amount: parseFloat(
      (productPrice * (Math.max(item.rate, 0) / 100)).toFixed(4)
    ),
  }));

  const groupAddonIds = addons?.map((addon) => addon.id);

  const onAddToCart = async (event) => {
    event.stopPropagation();
    if (!_.isEmpty(groupAddonIds)) {
      setOpenGroupAddon(true);
    } else {
      const cart = {
        id: uuid(),
        product_id: id,
        product: data,
        name: name,
        bar_code: bar_code,
        measure: data.measure,
        category: {
          id: category.id,
          name: category.name,
        },
        price: {
          amount: productPrice,
          quantity: 1,
          taxes: productTaxes,
        },
      };

      dispatch(
        MerchantActions.addUpdateCart({
          storeId,
          merchantId,
          cart: cart,
        })
      );

      enqueueSnackbar(
        t('store.shoppingCart.product_added_to_cart', { name })
      );
    }
  };

  const calculatingProductPrice = () => {
    const taxingNames = productPriceInfo?.taxes
      ?.map((tax) => tax.name)
      ?.join('&');
    const price = isNetPrice
      ? productPrice
      : productPrice + productTaxesAmount;
    const result = getLocalisationVal(localisation, price);
    return `${result} ${isNetPrice ? `+ ${taxingNames}` : ''}`;
  };

  const onClickMinus = (event) => {
    event.stopPropagation();
    const lastCart = _.findLast(
      userCart,
      (cart) => cart.product.id === id
    );
    if (lastCart?.price?.quantity <= 1) {
      dispatch(
        MerchantActions.removeItemCart({
          merchantId,
          storeId,
          id: lastCart?.id,
        })
      );
    } else {
      dispatch(
        MerchantActions.updateQuantityItemCart({
          merchantId,
          storeId,
          id: lastCart?.id,
          quantity: lastCart?.price?.quantity - 1,
        })
      );
    }
  };

  const onClickPlus = (event) => {
    event.stopPropagation();
    const lastCart = _.findLast(
      userCart,
      (cart) => cart.product.id === id
    );
    dispatch(
      MerchantActions.updateQuantityItemCart({
        merchantId,
        storeId,
        id: lastCart?.id,
        quantity: lastCart?.price?.quantity + 1,
      })
    );
  };

  return (
    <>
      <Box
        className={classes.root}
        onClick={() =>
          history.push(`/store/${merchantId}/${storeId}/${id}`)
        }
      >
        {!_.isEmpty(productImage) ? (
          <img
            className={classes.image}
            width={isMobile ? '100%' : 270}
            height={250}
            src={productImage}
          />
        ) : (
          <div className={classes.noImage}>
            <img
              className={classes.image}
              width={120}
              height={120}
              src={imagesAsset.noImage}
            />
          </div>
        )}

        <Box padding={3}>
          <Typography className={classes.productName}>
            {hideContent(name, 30)}
          </Typography>
          <Box
            paddingY={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography className={classes.productPriceTitle}>
                {t('store.price')}
              </Typography>
              <Typography
                className={classes.productPriceContent}
              >{`${currency}${calculatingProductPrice()}`}</Typography>
            </Box>
            {!stocked && (
              <Typography className={classes.productPriceTitle}>
                {t('store.in_stock', { qty: stocks?.length })}
              </Typography>
            )}
          </Box>
          <Typography className={classes.description}>
            {hideContent(short_description, 140)}
          </Typography>
        </Box>
        {productQuantityInCart > 0 ? (
          <Box
            className={classes.iconsContainer}
            display={'flex'}
            justifyContent={'center'}
          >
            <Box display={'flex'} alignItems={'center'}>
              <IconButton
                onClick={onClickMinus}
                className={classes.plusMinusIcon}
              >
                <RemoveIcon />
              </IconButton>
              <Typography className={classes.quantity}>
                {productQuantityInCart}
              </Typography>
              <IconButton
                onClick={onClickPlus}
                className={classes.plusMinusIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Button
            onClick={onAddToCart}
            variant="contained"
            color="primary"
            className={classes.addUpdateCart}
          >
            {t('store.add_to_cart')}
          </Button>
        )}
      </Box>
      <ProductGroupAddonModal
        isVisible={openGroupAddon}
        onCloseModal={() => setOpenGroupAddon(false)}
        groupAddonIds={groupAddonIds}
        productPrice={productPrice}
        productTaxes={productTaxes}
        product={data}
        currency={currency}
        pricingType={pricingType}
        isNetPrice={isNetPrice}
        localisation={localisation}
      />
    </>
  );
};

export default ProductItem;
