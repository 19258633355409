import CryptoJS from 'crypto-js';
import { ENCRYPT_KEY } from '@/constants/env';

export const contains = (str1, str2) => {
  return (
    (str1 || '').toLowerCase().indexOf((str2 || '').toLowerCase()) !==
    -1
  );
};
export const getFirstLetter = (name) =>
  name?.split('')[0]?.toUpperCase() || '';

export const hideEmail = (email) => {
  var split = email.split('@');
  var split1 = split[0];
  var avg = split1.length / 2;
  split1 = split1.substring(0, split1.length - avg);
  var split2 = split[1];
  return split1 + '...@' + split2;
};

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const toBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });

export const getImageAsBase64 = (url) =>
  fetch(url)
    .then((resp) => resp.blob())
    .then(toBase64);

export const getFullAddressOfStore = (address) => {
  var temp = [];
  if (address) {
    if (address.door_number) temp.push(address?.door_number + ' ');
    if (address.building_name)
      temp.push(address?.building_name + ', ');
    if (address.line1) temp.push(address?.line1 + ', ');
    if (address.line2) temp.push(address?.line2 + ', ');
    if (address.city_town) temp.push(address?.city_town + ', ');
    if (address.postcode) temp.push(address?.postcode + ', ');
    if (address.country) temp.push(address?.country);
  }
  return temp.join('');
};

export const transformAdressComponentsFromGoogleApi = (
  adressComponents
) => {
  let obj = {};
  for (const component of adressComponents) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'premise': {
        Object.assign(obj, { building_name: component.long_name });
        break;
      }

      case 'street_number': {
        Object.assign(obj, { door_number: component.long_name });
        break;
      }

      case 'route': {
        Object.assign(obj, { line1: component.long_name });
        break;
      }

      case 'locality':
        Object.assign(obj, { line2: component.long_name });

        break;
      case 'postal_town': {
        Object.assign(obj, { city_town: component.long_name });

        break;
      }
      case 'postal_code': {
        Object.assign(obj, { postcode: component.long_name });

        break;
      }

      case 'administrative_area_level_1': {
        Object.assign(obj, { city_town: component.long_name });

        break;
      }
      case 'administrative_area_level_2': {
        Object.assign(obj, { county: component.long_name });

        break;
      }
      case 'country':
        Object.assign(obj, { country: component.long_name });

        break;
      default:
        break;
    }
  }
  return obj;
};

export const capitalizeFirstLetter = (string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1) || string;

export const capitalizeEachLetter = (string) =>
  string?.replace(
    /(^\w{1})|(\s{1}\w{1})/g,
    (match) => match.toUpperCase() || ''
  );

export const convertUnderToTitleCase = (string) =>
  string.includes('_')
    ? string
        .split('_')
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(' ')
    : capitalizeEachLetter(string);

export const getOmissionString = (originalString, threshHold) => {
  if (originalString.length <= threshHold) return originalString;
  return `${originalString.substr(0, threshHold)}...`;
};

export const decryptString = (string) =>
  string
    ? CryptoJS.AES.decrypt(
        string?.replaceAll(' ', '+'),
        ENCRYPT_KEY
      ).toString(CryptoJS.enc.Utf8)
    : '';

export const getTimeStringFromHourAndMinute = (today) =>
  today
    ? ('0' + today.getHours()).slice(-2) +
      ':' +
      ('0' + today.getMinutes()).slice(-2)
    : '';

export const hideContent = (content, maxLength) =>
  content?.length >= maxLength
    ? `${content?.substring(0, maxLength)}...`
    : content;

export const getCurrencySymbol = (value) => {
  return (value || '').replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
};

export const randomString = () =>
  (Math.random() + 1).toString(36).substring(7);

export const getLastAndFirstName = (firstName, lastName) => {
  return [firstName, lastName].filter((item) => !!item).join(' ');
};
