const TRANSLATIONS_ZH = {
  button_group: {
    yes: 'Yes',
    no: 'No',
    next: 'NEXT',
    ok: 'OK',
    login: 'LOGIN',
    logout: 'Logout',
    managePreferences: 'Manage Preferences',
    changePassword: 'Change Password',
  },

  footer: {
    copywright: 'Karan Retail Ltd © 2024',
    terms_of_use: 'Terms of use',
    privacy_policy: 'Privacy Policy',
    contact: 'Contact',
  },

  login_page: {
    login_description:
      'Login to connect your business with your suppliers & customers.',
    use_myda_account: 'Use your Myda Account to login.',
    email_address: 'Email address',
    create_account: 'Create Myda Account',
    no_account_exist: "That Myda Account doesn't exist",
    enter_valid_email: 'Enter a valid email address',
    email_required: 'Email required',
    password: 'Password',
    password_invalid: 'That password is incorrect',
    password_required: 'Password required',
    forgot_password: 'Forgot password',
  },

  select_store_page: {
    select_store_or_business: 'Select store or business',
    search_name: 'Search name',
  },
  language_popup: {
    change_language: 'Change language',
  },
  error_group: {
    error_email: 'That Myda account doesn’t exist',
    error_password: 'That password is incorrect',
  },
  header: {
    workBoard: 'Workboard',
    help: 'help',
  },
  menu: {
    services: 'Services',
    workBoard: 'Workboard',
    businessManager: 'Business Manager',
    insights: 'Insights',
    admin: 'Admin',
    stores: 'Stores',
    warehouses: 'Ware houses',
    userManagement: 'User management',
    settings: 'Settings',
    touchPoints: 'Touchpoints',
    automationWorkflows: 'Automation & Workflows',
    subscriptions: 'Subscriptions',
  },
};

export default TRANSLATIONS_ZH;
