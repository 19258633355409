export const Directions = [
  {
    name: 'shopping_lists',
    path: '/order-management/shopping',
    disabled: true,
  },
  {
    name: 'orders',
    path: '/order-management/orders',
    disabled: false,
  },
];
