import React, { useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { UPDATE_STORE } from '@/services/storeService';

const StoryInput = ({
  currentStore,
  onRefetchStore,
  hasUpdateStore,
}) => {
  const { about_story, id } = currentStore || {};
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [value, setValue] = useState(about_story);
  const [isMutating, setIsMutating] = useState(false);

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = (value) => {
    setOpenEditModal(false);
    if (!value) {
      setValue(about_story);
    }
  };

  const [mutateStore] = useMutation(
    gql`
      ${UPDATE_STORE}
    `
  );

  const updateStory = async () => {
    setIsMutating(true);
    const payload = {
      variables: {
        store: {
          id,
          about_story: value,
        },
      },
    };
    return await mutateStore(payload)
      .then(async () => {
        await onRefetchStore?.();
        enqueueSnackbar(t('store.about_description_updated'));
        handleCloseEditModal(value);
      })
      .catch((error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => {
        setIsMutating(false);
      });
  };

  return (
    <>
      {!about_story && hasUpdateStore ? (
        <Box>
          <Typography
            className={classes.addText}
            onClick={handleOpenEditModal}
          >
            {t('store.add')}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="flex-start">
          <Typography className={classes.value}>
            {about_story}
          </Typography>
          {hasUpdateStore && (
            <IconButton
              onClick={handleOpenEditModal}
              className={classes.iconButton}
            >
              <CreateIcon className={classes.createIcon} />
            </IconButton>
          )}
        </Box>
      )}
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={() => handleCloseEditModal()}
        aria-labelledby={`{update-about-us-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{update-about-us-dialog-title}`}
          onClose={() => handleCloseEditModal()}
        >
          {t('store.update_about_us')}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.descriptionDialog}>
            {t('store.description_about_us')}
          </Typography>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            multiline
            inputProps={{ maxLength: 125 }}
            className={classes.inputText}
          />
          <Typography className={classes.character_limit}>
            {t('store.about_us_character_length')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseEditModal()}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={isEmpty(value)}
            onClick={updateStory}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isMutating && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 12),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default StoryInput;
