export const GET_ORDER_TYPE = `
    query getOrderType{
        orderTypes{
          id
          name
          type
          pricing_type
          workflow{
            id
            name
          }
        }
      }
`;

export const GLOBAL_ORDER_TYPES = `
  query getGlobalOrderTypes{
    globalOrderTypes{
      id
      name
      type
      pricing_type
      workflow_id
  }
}
`;

export const GET_PRICE_TYPE = `
  query getPriceType{
    priceTypes{
      id
      name
      type
    }
  }
`;

export const GET_WORKFLOWS = `
  query getWorkflows{
    workflows{
      id
      name
    }
  }
`;

export const MUTATE_ORDER_TYPE = `
  mutation addOrderTypes($input: [OrderTypeInput!]!) {
    addOrderTypes(input: $input) {
      id
    }
  }
`;

export const GET_ORDER_TYPE_WORKFLOW = `
  query getWorkflowOrder{
    orderTypes{
      id
      name
      type
      pricing_type
      workflow{
        stages{
          id
          name
          order
        }
      }
    }
  }
`;
