import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    container: {
      backgroundColor: Colors.GRAY_LIGHT,
      flexDirection: 'column',
      display: 'flex',
      maxWidth: '100%',
    },
    box: {
      flex: 'auto',
      padding: theme.spacing(0, 5),
    },
    boxInfo: {
      backgroundColor: Colors.WHITE,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4),
    },
    boxBannerAvatar: {
      position: 'relative',
      padding: 0,
    },
    boxLink: {
      padding: theme.spacing(4),
    },
    boxLinkActive: {
      padding: theme.spacing(4),
      backgroundColor: '#e7f1fe',
      fontWeight: '600',
    },
    breadcrumbsText: {
      fontSize: 16,
    },
    linkText: {
      ...CommonFonts.darkFont18,
    },
    boxHeader: {
      backgroundColor: '#08385C',
      display: 'flex',
      minHeight: theme.spacing(70),
    },
    boxContent: {
      backgroundColor: Colors.WHITE,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(6),
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3, 8),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    menuItemActive: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    textItem: {
      ...CommonFonts.darkFont14,
    },
    textItemActive: {
      fontWeight: '600',
    },
    image: {
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(5),
    },
    arrowIcon: {
      fontSize: '12px !important',
    },
    image: {
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(5),
    },
    openingTime: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
      color: '#25ad2f',
      padding: theme.spacing(1, 0),
    },
    closedTime: {
      ...CommonFonts.darkFont14,
      color: Colors.RED,
      fontWeight: 600,
      padding: theme.spacing(1, 0),
    },
    time: {
      ...CommonFonts.darkFont14,
    },
    address: {
      ...CommonFonts.darkFont14,
      textAlign: 'center',
      marginTop: theme.spacing(1),
    },
    timeIcon: {
      fontSize: 24,
      color: '#bec7cc',
    },
    createIcon: {
      fontSize: 20,
      color: '#bec7cc',
    },
    iconButton: {
      padding: theme.spacing(1),
      position: 'absolute',
      right: theme.spacing(4),
    },
    storeName: {
      textAlign: 'center',
      letterSpacing: 0.16,
      fontWeight: 600,
      ...CommonFonts.darkFont16,
      wordBreak: 'break-word',
    },
    soldItems: {
      ...CommonFonts.darkFont14,
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    boxTabs: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(6),
      borderRadius: theme.spacing(1),
      backgroundColor: Colors.WHITE,
    },
    tab: {
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
    boxPanel: {
      margin: theme.spacing(6, 0),
      borderRadius: theme.spacing(1),
      backgroundColor: Colors.WHITE,
    },
    backWrapper: {
      padding: theme.spacing(4),
    },
  })
);

export default useStyles;
