import React, { useState, useCallback } from 'react';
import {
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  LinearProgress,
  useMediaQuery,
  useTheme,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import {
  MUTATE_GENERATE_UPLOAD_URL,
  MUTATE_TOUCHPOINT_STOREFRONT_SETTING,
} from '@/services/storeService';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { useMutation, gql } from '@apollo/client';
import images from '@/assets/images';
import CloseIcon from '@material-ui/icons/Close';
import { Colors, CommonFonts } from '@/theme';
import { useDropzone } from 'react-dropzone';
import ImageCrop from 'react-image-crop';
import PreLoader from '@/components/preLoader';

const Banner = ({
  storeBanner,
  storeId,
  onRefetchStore,
  hasUpdateStore,
  widthCrop = 508,
  heightCrop = 286,
  imageSizeRequired = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [imageProperty, setImageProperty] = useState({
    file: null,
    imageRef: null,
    aspect: 16 / 9,
    isCircle: false,
    preview: storeBanner?.url,
    contentType: '',
  });
  const [crop, setCrop] = useState({
    aspect: imageProperty.aspect,
    x: 164,
    y: 94,
    unit: 'px',
    width: widthCrop,
    height: heightCrop,
  });

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = (generateUploadUrlResponse) => {
    setOpenEditModal(false);
    setIsError(false);
    setImageProperty({
      ...imageProperty,
      preview: generateUploadUrlResponse
        ? URL.createObjectURL(imageProperty.file)
        : storeBanner?.url,
      file: null,
    });
  };

  const getCroppedImage = async (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          const file = new File([blob], 'crop-image');
          resolve(file);
          reject((error) => console.log(error));
        },
        imageProperty.contentType,
        1
      );
    });
  };

  const onCropComplete = async (crop) => {
    if (imageProperty.imageRef && crop.width && crop.height) {
      const file = await getCroppedImage(
        imageProperty.imageRef,
        crop
      );
      setImageProperty({ ...imageProperty, file });
    }
  };

  const onDrop = useCallback((value) => {
    const file = value[0];
    setIsError(false);
    setProgressLoading(true);
    const objURL = URL.createObjectURL(file);

    const image = new Image();
    image.src = objURL;
    image.onload = function () {
      if (
        this.width < widthCrop &&
        this.height < heightCrop &&
        this.width * this.height < widthCrop * heightCrop
      ) {
        if (imageSizeRequired) {
          setIsError(true);
        } else {
          setImageProperty({
            ...imageProperty,
            file,
            preview: objURL,
            contentType: file.type,
          });
          setCrop({
            ...crop,
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
          });
        }
      } else {
        setImageProperty({
          ...imageProperty,
          file,
          preview: objURL,
          contentType: file.type,
        });
      }
    };
    setProgressLoading(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const [mutateGenerateURL] = useMutation(
    gql`
      ${MUTATE_GENERATE_UPLOAD_URL}
    `
  );

  const [mutateStore] = useMutation(
    gql`
      ${MUTATE_TOUCHPOINT_STOREFRONT_SETTING}
    `
  );

  const onSave = async () => {
    setIsLoading(true);
    try {
      const payload = storeBanner?.id
        ? {
            id: storeBanner?.id,
            name: storeBanner?.name,
            content_type: imageProperty.contentType,
          }
        : {
            name: imageProperty.file.name,
            content_type: imageProperty.contentType,
          };

      const result = await mutateGenerateURL({
        variables: {
          input: payload,
        },
        ignoreResults: false,
      });
      const generateUploadUrlResponse =
        result?.data?.generateUploadUrl[0];
      if (generateUploadUrlResponse) {
        const url = generateUploadUrlResponse?.upload_url;
        const fetchingResponse = await fetch(
          new Request(url, {
            method: 'PUT',
            body: imageProperty.file,
            headers: new Headers({
              'Content-Type': imageProperty.contentType,
            }),
          })
        );

        if (fetchingResponse) {
          await mutateStore({
            variables: {
              store: {
                id: storeId,
                settings: {
                  touchpoint_settings: {
                    digital_front: {
                      banner: {
                        id: generateUploadUrlResponse?.id,
                      },
                    },
                  },
                },
              },
            },
          }).then(async (response) => {
            if (response) {
              await onRefetchStore();
              handleCloseEditModal(generateUploadUrlResponse);
              enqueueSnackbar(t('store.banner_updated'));
            }
          });
        }
      }
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.boxAvatarInfo}>
          {!_.isEmpty(storeBanner?.url) ? (
            <img
              src={storeBanner?.url}
              className={classes.banner}
              height={280}
            />
          ) : (
            <img
              className={classes.emptyBanner}
              src={images.shoppingBag}
              width={80}
              height={80}
            />
          )}
        </div>
        {hasUpdateStore && (
          <div>
            <div>
              {isLoading ? (
                <CircularProgress
                  className={classNames(
                    classes.cameraIcon,
                    isLoading ? classes.noneBackground : ''
                  )}
                />
              ) : (
                <IconButton
                  onClick={handleOpenEditModal}
                  className={classes.cameraIcon}
                >
                  <CameraAltIcon size="small" />
                </IconButton>
              )}
            </div>
          </div>
        )}
      </div>
      <Dialog
        maxWidth={'xl'}
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-banner-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-banner-dialog-title}`}
          onClose={() => handleCloseEditModal()}
        >
          {t('store.update_store_banner')}
        </DialogTitle>
        <DialogContent>
          <>
            {isError && (
              <Typography className={classes.errorText}>
                {t('image.dimension_error', {
                  width: widthCrop,
                  height: heightCrop,
                })}
              </Typography>
            )}
            {!imageProperty.preview ? (
              <div className={classes.boxImageUpload}>
                <Typography className={classes.titleUploadImage}>
                  {t('image.upload_image')}
                </Typography>
                {!progressLoading ? (
                  <div
                    {...getRootProps()}
                    className={classes.dropZone}
                  >
                    <input {...getInputProps()} />
                    <Typography className={classes.titleDrop}>
                      {t('image.drop_title')}
                    </Typography>
                    <Typography className={classes.titleBrowse}>
                      {t('image.browse')}
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.progressBar}>
                    <LinearProgress />
                  </div>
                )}
                {isLoading && <PreLoader size={30} />}
              </div>
            ) : (
              <div className={classes.boxImagePreview}>
                {!imageProperty.file ? (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img
                      className={classes.imagePreview}
                      src={imageProperty.preview}
                    />
                  </div>
                ) : (
                  <ImageCrop
                    imageStyle={{ width: '100%' }}
                    circularCrop={imageProperty.isCircle}
                    src={imageProperty.preview}
                    crop={crop}
                    locked
                    onChange={(newCrop) => setCrop(newCrop)}
                    onComplete={onCropComplete}
                    onImageLoaded={(image) =>
                      setImageProperty({
                        ...imageProperty,
                        imageRef: image,
                      })
                    }
                  />
                )}
              </div>
            )}
            {imageProperty.preview && (
              <Typography
                className={classes.changeImage}
                onClick={() => {
                  setImageProperty({
                    ...imageProperty,
                    file: null,
                    preview: null,
                  });
                }}
              >
                {t('image.change_image')}
              </Typography>
            )}

            {isLoading && <PreLoader size={30} />}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseEditModal()}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={isLoading || isError}
            onClick={onSave}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
    paddingTop: theme.spacing(5),
    paddingBottom: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default Banner;
