import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts, Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.WHITE,
    },
    root: {
      padding: theme.spacing(40, 10, 40, 10),
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(30, 0, 30, 16),
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(20, 10, 20, 10),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(22.5, 8, 22.5, 8),
      },
    },
    loginDescription: {
      lineHeight: '40px',
      marginTop: theme.spacing(7),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(8),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      },
      letterSpacing: 0,
      fontWeight: '200',
      ...CommonFonts.darkFont30,
    },
    loginLayout: {
      width: 491,
      marginTop: theme.spacing(18),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(10),
        marginTop: 0,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(34),
        paddingRight: theme.spacing(12),
        padding: 0,
        justifyContent: 'flex-end',
      },
    },
    loginLayoutWrapper: {
      position: 'relative',
      maxWidth: 494,
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('md')]: {
        margin: 'auto',
      },
    },
    textsContainer: {
      textAlign: 'center',
      marginTop: theme.spacing(6),
    },
    createText: {
      letterSpacing: 0.3,
      ...CommonFonts.blueFont16,
      cursor: 'pointer',
      fontWeight: 500,
      marginLeft: theme.spacing(2),
    },
    newMydaText: {
      letterSpacing: 0.3,
      ...CommonFonts.darkFont16,
    },
  })
);

export default useStyles;
